import { NewDrawer } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import { EnvironmentsObject, PythonVersionToEnvironmentsObject } from "@sandtable/utils";
import { buildDockerfile } from "components/Environments/NewEnvironmentDrawer/buildDockerfile";
import EnvironmentDependenciesTab from "components/Environments/NewEnvironmentDrawer/EnvironmentDependenciesTab";
import EnvironmentDetailsTab from "components/Environments/NewEnvironmentDrawer/EnvironmentDetailsTab";
import EnvironmentDockerfileTab from "components/Environments/NewEnvironmentDrawer/EnvironmentDockerfileTab";
import * as React from "react";

const DOCKERFILE_FROM = "FROM {}\n\n";

interface Props {
  baseEnvironmentsObject: PythonVersionToEnvironmentsObject;
  environmentsObject: EnvironmentsObject;
  onClose: () => void;
  onEnvironmentAdd: (e: Environment) => void;
}

interface State {
  condaChannel: string;
  environment: Environment; // TODO: Add `NewEnvironment` type
  error: boolean;
}

const initialState: State = {
  condaChannel: "defaults",
  environment: {
    condaRequirements: [],
    created: new Date(),
    dockerfile: buildDockerfile(),
    isPublic: false,
    name: "",
    parentEnvUid: "",
    pipRequirements: [],
    pythonVersion: "3.6",
    runtimeVersion: "",
    status: "",
    uid: "",
  },
  error: true,
};

const NewEnvironmentDrawer = (props: Props) => {
  const [state, setState] = React.useState<State>({
    ...initialState,
    environment: {
      ...initialState.environment,
      parentEnvUid: Object.values(props.baseEnvironmentsObject)[0][0].uid, // TODO: Handle undefined
    },
  });

  // TODO: Refactor into handleChange callback to avoid extraneous re-renders.
  // Update dockerfile when condaChannel/condaRequirements/pipRequirements are changed.
  const { condaChannel } = state;
  const { condaRequirements, pipRequirements } = state.environment;
  React.useEffect(() => {
    const dockerfile = buildDockerfile(condaRequirements!, pipRequirements!, condaChannel);
    setState((prev: State) => ({ ...prev, environment: { ...prev.environment, dockerfile } }));
  }, [condaChannel, condaRequirements, pipRequirements]);

  const handleChange = React.useCallback(
    (e: Partial<Environment>, err?: boolean, channel?: string) =>
      setState((prev: State) => ({
        ...prev,
        condaChannel: channel !== undefined ? channel : prev.condaChannel,
        environment: { ...prev.environment, ...e },
        error: err !== undefined ? err : prev.error,
      })),
    [],
  );

  const handleSubmit = () => {
    const e = { ...state.environment };
    e.dockerfile = DOCKERFILE_FROM + e.dockerfile;
    props.onEnvironmentAdd(e);
  };

  return (
    <NewDrawer
      onClose={props.onClose}
      decrementStepGaAction={"new-environment-drawer-previous-step"}
      error={state.error}
      incrementStepGaAction={"new-environment-drawer-next-step"}
      maxWidth={"800px"}
      onSubmit={handleSubmit}
      open={true}
      stepContents={[
        <EnvironmentDetailsTab
          {...props}
          environment={state.environment}
          key={"environment-details-tab"}
          onChange={handleChange}
        />,
        <EnvironmentDependenciesTab
          condaChannel={state.condaChannel}
          environment={state.environment}
          key={"dependencies-tab"}
          onChange={handleChange}
        />,
        <EnvironmentDockerfileTab environment={state.environment} key={"dockerfile-tab"} onChange={handleChange} />,
      ]}
      stepNames={["Environment details", "Dependencies", "Dockerfile"]}
      title={"New Environment"}
    />
  );
};

export default NewEnvironmentDrawer;
