import { FormGroup as MFormGroup } from "@material-ui/core";
import { Button, Stepper } from "@sandtable/component-library";
import * as React from "react";

const BUTTON_GROUP_STYLES: React.CSSProperties = { display: "flex", justifyContent: "space-between" };
const BUTTON_STYLES: React.CSSProperties = { flex: "0 0 48%" };

export interface Props {
  decrementStepGaAction: string;
  error: boolean;
  incrementStepGaAction: string;
  onSubmit: () => void;
  stepContents: JSX.Element[];
  stepNames: string[];
}

const New = (props: Props) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  if (props.stepNames.length !== props.stepContents.length) {
    throw new Error("Length of tab names and tab contents must be equal.");
  }

  const isFinalStep = currentStep === props.stepContents.length - 1;

  return (
    <>
      <Stepper currentStep={currentStep} steps={props.stepNames} />
      {props.stepContents[currentStep]}
      <MFormGroup row={true} style={BUTTON_GROUP_STYLES}>
        {currentStep > 0 && (
          <Button
            disabled={props.error}
            gaAction={props.decrementStepGaAction}
            icon="arrow-alt-circle-left"
            key={`previous-button-${currentStep}`}
            onButtonClick={() => setCurrentStep(currentStep - 1)}
            style={BUTTON_STYLES}
            text="Previous step"
          />
        )}
        <Button
          disabled={props.error}
          gaAction={props.incrementStepGaAction}
          iconRight={isFinalStep ? "check" : "arrow-alt-circle-right"}
          key={`next-button-${currentStep}`}
          onButtonClick={() => (isFinalStep ? props.onSubmit() : setCurrentStep(currentStep + 1))}
          style={currentStep > 0 ? BUTTON_STYLES : { flex: "0 0 100%" }}
          text={isFinalStep ? "Complete" : "Next step"}
        />
      </MFormGroup>
    </>
  );
};

export default New;
