import {
  ClustersActionTypes,
  ClusterState,
  RECEIVE_ADD_CLUSTER,
  RECEIVE_CLUSTER_LOGS,
  RECEIVE_CLUSTER_UPDATE,
  RECEIVE_CLUSTERS,
  RECEIVE_RECENT_CLUSTER_CONFIGURATIONS,
  RECEIVE_RESTART_CLUSTER,
  RECEIVE_SCALE_CLUSTER,
  RECEIVE_START_CLUSTER,
  RECEIVE_STOP_CLUSTER,
  RECEIVE_UPDATE_CLUSTER,
  RECEIVE_UPGRADE_CLUSTER,
  SUBSCRIBE_CLUSTER_UPDATES,
} from "@sandtable/datastore/clusters";
import { REQUEST_CLUSTERS } from "./types";

const initialState: ClusterState = {
  clusters: {},
  isFetching: true,
  pagination: {
    clustersCount: 0,
    page: 1,
    perPage: 10,
  },
  recentClusterConfigurations: {},
  searchQuery: "",
  subscribedToClusterUpdates: false,
};

export const clustersReducer = (state = initialState, action: ClustersActionTypes): ClusterState => {
  switch (action.type) {
    case REQUEST_CLUSTERS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_ADD_CLUSTER:
      return {
        ...state,
        clusters: {
          ...state.clusters,
          ...action.clusters,
        },
        recentClusterConfigurations: {
          ...state.recentClusterConfigurations, // TODO: "Slice" this: max 5 elements
          ...action.clusters,
        },
      };
    case RECEIVE_CLUSTERS:
      return {
        ...state,
        clusters: action.clusters,
        isFetching: false,
        pagination: action.pagination,
        searchQuery: action.searchQuery,
      };
    case RECEIVE_CLUSTER_LOGS:
      return {
        ...state,
        clusters: {
          ...state.clusters,
          [action.clusterId]: {
            ...state.clusters[action.clusterId],
            logs: Object.values(action.clusterLogs.all),
          },
        },
      };
    case RECEIVE_CLUSTER_UPDATE:
      const clusters = { ...state.clusters };

      let cluster: any = Object.values(action.clusters);
      if (cluster.length > 1) {
        throw new Error("Incorrect RECEIVE_CLUSTER_UPDATE response.");
      }
      cluster = cluster[0];

      if (cluster.status === "terminated") {
        // If cluster is in a terminated status then remove it from the list
        // Otherwise update it
        delete clusters[cluster.clusterId!];
      } else {
        clusters[cluster.clusterId!] = cluster;
      }

      return {
        ...state,
        clusters,
      };
    case RECEIVE_RECENT_CLUSTER_CONFIGURATIONS:
      return {
        ...state,
        recentClusterConfigurations: action.clusters,
      };
    case SUBSCRIBE_CLUSTER_UPDATES:
      return {
        ...state,
        subscribedToClusterUpdates: true,
      };
    // We rely on update messages flowing in via WS connections
    // TODO: Update the API responses for the following actions:
    case RECEIVE_START_CLUSTER:
    case RECEIVE_STOP_CLUSTER:
    case RECEIVE_SCALE_CLUSTER:
    case RECEIVE_UPDATE_CLUSTER:
    case RECEIVE_UPGRADE_CLUSTER:
    case RECEIVE_RESTART_CLUSTER:
    default:
      return state;
  }
};
