import { Step as MStep, StepLabel as MStepLabel, Stepper as MStepper, WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import * as React from "react";

const styles = createStyles({
  root: {
    padding: "24px 0 24px 0",
  },
});

export interface Props extends WithStyles {
  currentStep: number;
  nonLinear?: boolean;
  steps: string[];
  style?: React.CSSProperties;
}

const Stepper = ({ currentStep, steps, nonLinear = true, style, classes }: Props) => {
  const stepItems = steps.map((label, index) => (
    <MStep key={label}>
      <MStepLabel>{label}</MStepLabel>
    </MStep>
  ));
  return (
    <MStepper nonLinear={nonLinear} activeStep={currentStep} classes={classes} style={style}>
      {stepItems}
    </MStepper>
  );
};

export default withStyles(styles)(Stepper);
