import {
  FormControlLabel as MFormControlLabel,
  FormGroup as MFormGroup,
  Switch as MSwitch,
  Tooltip as MTooltip,
  WithStyles,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import * as React from "react";

const styles = createStyles({
  root: {
    marginLeft: "-6px",
    marginRight: "10px",
  },
});

export interface Props extends WithStyles {
  checked: boolean;
  disabled?: boolean;
  groupStyle?: React.CSSProperties;
  label?: string;
  name?: string;
  onChange: any;
  ownGroup?: boolean;
  tooltipLabel?: string;
  value: boolean;
}

const Switch = ({
  name,
  checked,
  disabled = false,
  groupStyle,
  label,
  value,
  onChange,
  ownGroup = true,
  tooltipLabel,
  classes,
}: Props) => {
  const control = (
    <MSwitch style={!label ? { height: "20px" } : {}} checked={checked} onChange={onChange} value={value} name={name} />
  );
  let controlLabel = (
    <MFormControlLabel
      style={!label ? { marginLeft: "-14px" } : {}}
      classes={classes}
      control={control}
      label={label}
      disabled={disabled}
    />
  );
  if (tooltipLabel) {
    controlLabel = <MTooltip title={tooltipLabel}>{controlLabel}</MTooltip>;
  }
  if (!ownGroup) {
    return controlLabel;
  }
  return (
    <MFormGroup row={true} style={groupStyle}>
      {controlLabel}
    </MFormGroup>
  );
};

export default withStyles(styles)(Switch);
