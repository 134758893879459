import { User } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  children: JSX.Element;
  user: User;
}

export const UserContext = React.createContext({} as User); // tslint:disable-line

export const useUser = () => React.useContext(UserContext);

const UserProvider = (props: Props) => {
  return <UserContext.Provider {...props} value={props.user} />;
};

export default UserProvider;
