import { applicationsReducer } from "@sandtable/datastore/applications";
import { clustersReducer } from "@sandtable/datastore/clusters";
import { configurationsReducer } from "@sandtable/datastore/configurations";
import { deploymentsReducer } from "@sandtable/datastore/deployments";
import { environmentsReducer } from "@sandtable/datastore/environments";
import { environmentsV2Reducer } from "@sandtable/datastore/environments_v2";
import { executionsReducer } from "@sandtable/datastore/executions";
import { experimentsReducer } from "@sandtable/datastore/experiments";
import api from "@sandtable/datastore/middleware/api";
import ws from "@sandtable/datastore/middleware/ws";
import { organisationsReducer } from "@sandtable/datastore/organisations";
import { reposReducer } from "@sandtable/datastore/repos";
import { snackbarNotificationsReducer } from "@sandtable/datastore/snackbar";
import { uploadsReducer } from "@sandtable/datastore/uploads";
import { userReducer } from "@sandtable/datastore/user";
import { usersReducer } from "@sandtable/datastore/users";
import { whitelistReducer } from "@sandtable/datastore/whitelist";
import { applyMiddleware, combineReducers, createStore, StoreEnhancer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";

const rootReducer = combineReducers({
  applications: applicationsReducer,
  clusters: clustersReducer,
  configurations: configurationsReducer,
  deployments: deploymentsReducer,
  environments: environmentsReducer,
  environmentsV2: environmentsV2Reducer,
  executions: executionsReducer,
  experiments: experimentsReducer,
  organisations: organisationsReducer,
  repos: reposReducer,
  snackbarNotifications: snackbarNotificationsReducer,
  uploads: uploadsReducer,
  user: userReducer,
  users: usersReducer,
  whitelist: whitelistReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

let middleware: StoreEnhancer;
if (process.env.NODE_ENV === "development") {
  const loggerMiddleWare = createLogger();
  middleware = composeWithDevTools(applyMiddleware(api, ws, thunkMiddleware, loggerMiddleWare));
} else {
  middleware = applyMiddleware(api, ws, thunkMiddleware);
}

const configureStore = () => {
  return createStore(rootReducer, middleware);
};

export default configureStore;

// Export member actions/reducers/types:
export * from "@sandtable/datastore/applications";
export * from "@sandtable/datastore/clusters";
export * from "@sandtable/datastore/configurations";
export * from "@sandtable/datastore/environments";
export * from "@sandtable/datastore/environments_v2";
export * from "@sandtable/datastore/executions";
export * from "@sandtable/datastore/experiments";
export * from "@sandtable/datastore/middleware";
export * from "@sandtable/datastore/organisations";
export * from "@sandtable/datastore/repos";
export * from "@sandtable/datastore/snackbar";
export * from "@sandtable/datastore/uploads";
export * from "@sandtable/datastore/user";
export * from "@sandtable/datastore/users";
export * from "@sandtable/datastore/whitelist";
export * from "@sandtable/datastore/deployments";
