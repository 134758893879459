import { AuthProvider } from "@sandtable/component-library";
import configureStore from "@sandtable/datastore";
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_REDIRECT_URI } from "@sandtable/utils/constants";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";

const store = configureStore();

ReactDOM.render(
  <AuthProvider
    audience={AUTH0_AUDIENCE}
    domain={AUTH0_DOMAIN}
    client_id={AUTH0_CLIENT_ID}
    redirect_uri={AUTH0_REDIRECT_URI}
    scope={"openid"}
    response_type={"token id_token"}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </AuthProvider>,
  document.getElementById("root"),
);
