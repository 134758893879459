export const clickInputElement = (inputId: string) => {
  const element = document.getElementById(inputId);
  if (element) {
    element.click();
  }
};

export const readFile = (file: File, callback: any) => {
  const reader = new FileReader();
  reader.onloadend = (event: any) => {
    const data = event.target.result;
    callback(data);
  };
  reader.readAsText(file);
};
