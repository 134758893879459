import {
  ENVIRONMENT_V2_ARRAY_AND_PAGINATION,
  ENVIRONMENT_V2_SCHEMA,
  EnvironmentV2,
  NewEnvironmentV2,
  RECEIVE_ADD_ENVIRONMENT_V2,
  RECEIVE_CANCEL_ENVIRONMENT_V2,
  RECEIVE_DELETE_ENVIRONMENT_V2,
  RECEIVE_ENVIRONMENTS_V2,
  RECEIVE_GET_ENVIRONMENT_V2,
  RECEIVE_UPDATE_ENVIRONMENT_V2,
  REQUEST_ADD_ENVIRONMENT_V2,
  REQUEST_CANCEL_ENVIRONMENT_V2,
  REQUEST_DELETE_ENVIRONMENT_V2,
  REQUEST_ENVIRONMENTS_V2,
  REQUEST_GET_ENVIRONMENT_V2,
  REQUEST_UPDATE_ENVIRONMENT_V2,
  SUBSCRIBE_ENVIRONMENT_V2_UPDATES,
} from "@sandtable/datastore/environments_v2";
import {
  API_GET,
  API_PATCH,
  API_POST,
  ApiMiddlewareAction,
  WS_SUBSCRIBE,
  WsMiddlewareAction,
} from "@sandtable/datastore/middleware";
import { enqueueSnackbarNotification, SnackbarNotificationActionTypes } from "@sandtable/datastore/snackbar";

export const fetchEnvironmentsV2 = (page?: number, perPage?: number, searchQuery?: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    const params = {
      ...(page && { page }),
      ...(perPage && { perPage }),
      ...(searchQuery && { searchQuery }),
    };
    dispatch({
      [API_GET]: {
        endpoint: "v2/environments",
        params,
        schema: ENVIRONMENT_V2_ARRAY_AND_PAGINATION,
        types: [REQUEST_ENVIRONMENTS_V2, RECEIVE_ENVIRONMENTS_V2, RECEIVE_ENVIRONMENTS_V2],
      },
    });
  };
};

export const getEnvironmentV2 = (environment: EnvironmentV2) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: `v2/environments/${environment.uid}`,
        schema: ENVIRONMENT_V2_SCHEMA,
        types: [REQUEST_GET_ENVIRONMENT_V2, RECEIVE_GET_ENVIRONMENT_V2, RECEIVE_GET_ENVIRONMENT_V2],
      },
    });
  };
};

export const addEnvironmentV2 = (environment: NewEnvironmentV2) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: "v2/environments",
        params: environment,
        schema: ENVIRONMENT_V2_SCHEMA,
        types: [REQUEST_ADD_ENVIRONMENT_V2, RECEIVE_ADD_ENVIRONMENT_V2, RECEIVE_ADD_ENVIRONMENT_V2],
      },
    });
  };
};

export const updateEnvironmentV2 = (environment: EnvironmentV2) => {
  // TODO: Retype to only accept attributes that can actually be updated.
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_PATCH]: {
        endpoint: `v2/environments/${environment.uid}`,
        params: {
          condaEnvironmentFile: environment.condaEnvironmentFile,
          dockerfile: environment.dockerfile,
        },
        schema: ENVIRONMENT_V2_SCHEMA,
        types: [REQUEST_UPDATE_ENVIRONMENT_V2, RECEIVE_UPDATE_ENVIRONMENT_V2, RECEIVE_UPDATE_ENVIRONMENT_V2],
      },
    });
  };
};

export const cancelEnvironmentV2 = (environment: EnvironmentV2) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `v2/environments/${environment.uid}/cancel`,
        schema: ENVIRONMENT_V2_SCHEMA,
        types: [REQUEST_CANCEL_ENVIRONMENT_V2, RECEIVE_CANCEL_ENVIRONMENT_V2, RECEIVE_CANCEL_ENVIRONMENT_V2],
      },
    });
  };
};

export const deleteEnvironmentV2 = (environment: EnvironmentV2) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `v2/environments/${environment.uid}/delete`,
        failureMessage: "Unable to delete environment. Is it being used by a cluster?",
        schema: ENVIRONMENT_V2_SCHEMA,
        successMessage: "Deleting environment.",
        types: [REQUEST_DELETE_ENVIRONMENT_V2, RECEIVE_DELETE_ENVIRONMENT_V2, RECEIVE_DELETE_ENVIRONMENT_V2],
      },
    });
  };
};

export const subscribeToEnvironmentV2Updates = (orgName: string) => {
  return (dispatch: (action: WsMiddlewareAction | SnackbarNotificationActionTypes) => void) => {
    dispatch({
      [WS_SUBSCRIBE]: {
        callback: (res: any) => {
          const e = Object.values(res.environments)[0] as EnvironmentV2;
          const envName = e.name || "";
          const status = e.status || "updated";
          dispatch(
            enqueueSnackbarNotification({
              text: `Environment V2 ${envName} ${status}.`,
            }),
          );
        },
        endpoint: "environments-v2",
        room: orgName,
        schema: ENVIRONMENT_V2_SCHEMA,
        types: [SUBSCRIBE_ENVIRONMENT_V2_UPDATES, RECEIVE_UPDATE_ENVIRONMENT_V2, RECEIVE_UPDATE_ENVIRONMENT_V2],
      },
    });
  };
};
