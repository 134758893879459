import { Loading, NavTabs } from "@sandtable/component-library";
import { Organisation, PlatformUser } from "@sandtable/datastore";
import OrganisationsTab from "components/Management/OrganisationsTab";
import UsersTab from "components/Management/UsersTab";
import * as React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";

const MANAGEMENT_PATH = "/management";
const ORGANISATIONS_PATH = "/management/organisations";
const USERS_PATH = "/management/users";

interface Props {
  addMemberToOrganisation: (organisationId: string, memberEmail: string) => any;
  addMemberToProject: (organisationId: string, projectId: string, memberEmail: string) => any;
  addOrganisation: (name: string) => any;
  addProjectToOrganisation: (organisationId: string, name: string) => any;
  deactivateUser: (user: PlatformUser) => void;
  fetchOrganisations: () => void;
  fetchUsers: () => void;
  isFetching: boolean;
  organisations: Organisation[];
  reactivateUser: (user: PlatformUser) => void;
  removeMemberFromOrganisation: (organisationId: string, memberId: string) => any;
  removeMemberFromProject: (organisationId: string, projectId: string, memberId: string) => any;
  updateMemberOfOrganisation: (organisationId: string, memberId: string, owner: boolean) => any;
  updateMemberOfProject: (organisationId: string, projectId: string, memberId: string, owner: boolean) => any;
  users: PlatformUser[];
}

const Management = (props: Props) => {
  const history = useHistory();

  const handleChange = (n: number) => {
    switch (n) {
      case 0:
        history.push(ORGANISATIONS_PATH);
        return;
      case 1:
        history.push(USERS_PATH);
        return;
      default:
        throw Error();
    }
  };

  if (props.isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Route path={MANAGEMENT_PATH} exact={true}>
        <Redirect to={ORGANISATIONS_PATH} />
      </Route>
      <NavTabs tabs={["Organisations", "Users"]} onChange={handleChange} />
      <Route path={ORGANISATIONS_PATH}>
        <OrganisationsTab {...props} />
      </Route>
      <Route path={USERS_PATH}>
        <UsersTab {...props} />
      </Route>
    </>
  );
};

export default Management;
