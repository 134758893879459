// Misc helper functions

export const capitaliseString = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const mapEnvironmentStateToColorClass = (state: string | undefined): string => {
  switch (state) {
    case "failed":
      return "stopped";
    case "completed":
      return "running";
    default:
      return "pending";
  }
};

export const removeDups = (x: any[]) => {
  return Array.from(new Set(x));
};

export const openNewTab = (event: React.ChangeEvent<{}>, url: string | undefined): void => {
  // Prevent drawer from opening
  event.stopPropagation();
  event.preventDefault();
  // Copy text to clipboard
  if (!url) {
    return;
  }
  const win = window.open(url);
  if (win) {
    win.focus();
  }
};

export const copyToClipboard = (event: React.ChangeEvent<{}>, str: string | undefined): void => {
  // Prevent drawer from opening
  event.stopPropagation();
  event.preventDefault();
  // Copy text to clipboard
  if (!str) {
    return;
  }
  const field = document.createElement("textarea");
  field.innerText = str;
  document.body.append(field);
  field.select();
  document.execCommand("copy");
  field.remove();
};

export const redirect = (url: string): void => {
  window.location.href = url;
};

export const pluraliseString = (s: string, n: number): string => {
  // TODO: check if string is already plural
  // Probably not worth checking abs
  if (n === 0 || Math.abs(n) > 1) {
    return s + "s";
  }
  return s;
};

export const downloadFile = (url: string, name: string) => {
  const link = document.createElement("a");
  link.setAttribute("type", "hidden");
  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();
};
