import { Button } from "@sandtable/component-library";
import { EnvironmentV2 } from "@sandtable/datastore";
import * as React from "react";
import { useHistory } from "react-router-dom";

const ACTIVE_STATUSES = new Set(["prebuild", "building", "postbuild"]);
const FINAL_STATUSES = new Set(["completed", "failed", "cancelled", "aborted"]);

interface Props {
  cancelEnvironmentV2: (environment: EnvironmentV2) => void;
  deleteEnvironmentV2: (environment: EnvironmentV2) => void;
  environment: EnvironmentV2;
}

const EnvironmentV2ActionsTab = (props: Props) => {
  const history = useHistory();

  const { cancelEnvironmentV2, deleteEnvironmentV2, environment } = props;
  const handleDeleteClick = React.useCallback(() => {
    deleteEnvironmentV2(environment);
    history.push(`/environments-v2/view-environments/${props.environment.name}`); // TODO: Move to EnvironmentsV2
  }, [deleteEnvironmentV2, environment]);
  const handleCancelClick = React.useCallback(() => cancelEnvironmentV2(environment), [
    cancelEnvironmentV2,
    environment,
  ]);

  const isActive = ACTIVE_STATUSES.has(props.environment.status);
  const isFinal = FINAL_STATUSES.has(props.environment.status);

  return (
    <>
      <div style={{ display: "flex" }}>
        <Button
          disabled={props.environment.status === "deleting"}
          gaAction={"view-environment-v2-drawer-update"}
          gaLabel={props.environment.uid}
          icon="arrow-circle-up"
          // TODO: Move to EnvironmentsV2:
          onButtonClick={() => history.push(`/environments-v2/update-environment/${props.environment.uid}`)}
          style={{ flexGrow: 1, marginRight: "14px" }}
          text={"Update environment"}
        />
        <Button
          disabled={!isActive}
          gaAction={"view-environment-v2-drawer-cancel"}
          gaLabel={props.environment.uid}
          icon="ban"
          onButtonClick={handleCancelClick}
          secondary={true}
          style={{ flexGrow: 1, marginRight: "14px" }}
          text={"Cancel environment"}
        />
        <Button
          disabled={!isFinal}
          gaAction={"view-environment-v2-drawer-delete"}
          gaLabel={props.environment.uid}
          icon="trash"
          onButtonClick={handleDeleteClick}
          secondary={true}
          style={{ flexGrow: 1 }}
          text={"Delete environment"}
        />
      </div>
    </>
  );
};

export default EnvironmentV2ActionsTab;
