import { Card as MCard } from "@material-ui/core";
import { Icon } from "@sandtable/component-library";
import { readFile } from "@sandtable/utils";
import * as React from "react";
import { UnControlled as CodeMirror } from "react-codemirror2";

/* tslint:disable */
import "codemirror/addon/display/placeholder";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/diff/diff";
import "codemirror/mode/dockerfile/dockerfile";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/yaml/yaml";
import "codemirror/theme/idea.css";
/* tslint:enable */

const CODE_MIRROR_OPTIONS = {
  lineWrapping: true,
  theme: "idea",
};

const REFRESH_ICON_STYLES = {
  marginTop: "-12px",
  position: "absolute",
  right: "34px",
  zIndex: 1,
} as React.CSSProperties; //tslint:disable-line

const TITLE_STYLES = {
  backgroundColor: "white",
  color: "rgba(0, 0, 0, 0.54)",
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: "0.75em",
  padding: "0 6px 0 6px",
  position: "absolute",
  transform: "translate(8px, -6px)",
} as React.CSSProperties; //tslint:disable-line

interface Props {
  height?: string;
  width?: string;
  maxHeight?: string;
  maxWidth?: string;
  mode?: string;
  onChange?: any;
  onRefreshClick?: any;
  placeholder?: string;
  title?: string;
  titleStyles?: object;
  text: string | string[];
}

const Syntax = (props: Props): JSX.Element | null => {
  let text = props.text;
  if (text === undefined || text === null) {
    text = "N/A";
  } else if (typeof text !== "string") {
    text = text.join("\n\n");
  }
  const cardStyles = {
    height: props.height,
    lineHeight: "1.2em",
    marginBottom: "26px",
    marginTop: "14px",
    maxHeight: props.maxHeight,
    maxWidth: props.maxWidth,
    padding: "18px 12px 20px 18px",
    width: props.width,
  };
  const refreshIcon = (
    <div style={REFRESH_ICON_STYLES} onClick={props.onRefreshClick}>
      <Icon icon="redo" container={false} style={{ cursor: "pointer" }} />
    </div>
  );
  return (
    <>
      <div>
        {props.title && (
          <div style={{ ...TITLE_STYLES, ...props.titleStyles }}>
            <label>{props.title}</label>
          </div>
        )}
        <MCard style={cardStyles}>
          {props.onRefreshClick && refreshIcon}
          <SyntaxSection code={text} mode={props.mode} onChange={props.onChange} placeholder={props.placeholder} />
        </MCard>
      </div>
    </>
  );
};

interface SectionProps {
  code: string;
  mode?: string;
  onChange: any;
  placeholder?: string;
}

export const SyntaxSection = (props: SectionProps) => {
  const handleDrop = React.useCallback((editor, event) => {
    const files = event.dataTransfer.files;
    if (files.length) {
      readFile(files[0], (data: string) => props.onChange(undefined, undefined, data));
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);

  return (
    <CodeMirror
      autoCursor={false}
      value={props.code}
      options={{
        ...CODE_MIRROR_OPTIONS,
        mode: props.mode || "dockerfile",
        placeholder: props.placeholder,
        readOnly: !props.onChange,
      }} // tslint:disable-line
      onChange={props.onChange} // TODO: Simplify interface
      onDrop={handleDrop}
    />
  );
};

export default Syntax;
