// SnackbarNotification interfaces:
type SnackbarNotificationKeyType = number;

export interface SnackbarNotification {
  key?: SnackbarNotificationKeyType;
  text: string;
}

export interface SnackbarNotificationList {
  notifications: SnackbarNotification[];
}

// Action constants:
export const ENQUEUE_SNACKBAR_NOTIFICATION = "ENQUEUE_SNACKBAR_NOTIFICATION";
export const REMOVE_SNACKBAR_NOTIFICATION = "REMOVE_SNACKBAR_NOTIFICATION";

// Action types:
interface EnqueueSnackbarNotificationAction {
  item: SnackbarNotification;
  type: typeof ENQUEUE_SNACKBAR_NOTIFICATION;
}

interface RemoveSnackbarNotificationAction {
  key: SnackbarNotificationKeyType;
  type: typeof REMOVE_SNACKBAR_NOTIFICATION;
}

export type SnackbarNotificationActionTypes = EnqueueSnackbarNotificationAction | RemoveSnackbarNotificationAction;
