import { SectionList, SectionListObject } from "@sandtable/component-library";
import { Upload, UploadMap } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  fetchUploads: () => void;
  onCardClick: (v: string) => void;
  uploads: UploadMap;
}

const StagedList = (props: Props) => {
  let objects: SectionListObject[] = [];
  if (typeof props.uploads !== "undefined") {
    objects = Object.values(props.uploads).map((upload: Upload, i: number) => {
      return {
        data: upload,
        gaAction: "staged-list-card",
        gaLabel: "",
        onCardClick: () => props.onCardClick(upload.id),
        primaryText: upload.originalFilename,
        secondaryText: `${new Date(upload.datetimeUploaded).toUTCString()} • ${upload.uploader}`,
      } as SectionListObject; //tslint:disable-line
    });
  }

  return (
    <SectionList
      objects={objects}
      fetchResources={props.fetchUploads}
      searchKeys={["uploader", "original_filename", "id"]}
      showSearch={true}
    />
  );
};

export default StagedList;
