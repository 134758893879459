import {
  ExperimentsActionTypes,
  ExperimentsState,
  RECEIVE_ADD_EXPERIMENT,
  RECEIVE_EXPERIMENT_UPDATE,
  RECEIVE_EXPERIMENTS,
  REQUEST_EXPERIMENTS,
  SUBSCRIBE_EXPERIMENT_UPDATES,
} from "@sandtable/datastore/experiments";

const initialState: ExperimentsState = {
  experiments: {},
  isFetching: true,
  subscribedToExperimentUpdates: false,
};

export const experimentsReducer = (state = initialState, action: ExperimentsActionTypes): ExperimentsState => {
  switch (action.type) {
    case REQUEST_EXPERIMENTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_EXPERIMENTS:
      return {
        ...state,
        experiments: action.experiments,
        isFetching: false,
      };
    case RECEIVE_ADD_EXPERIMENT:
    case RECEIVE_EXPERIMENT_UPDATE:
      return {
        ...state,
        experiments: {
          ...state.experiments,
          ...action.experiments,
        },
      };
    case SUBSCRIBE_EXPERIMENT_UPDATES:
      return {
        ...state,
        subscribedToExperimentUpdates: true,
      };
    default:
      return state;
  }
};
