import { IconProp, library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip as MTooltip } from "@material-ui/core";
import joinClassNames from "classnames";
import * as React from "react";
import "./Icon.css";

// TODO: Refactor this component. Move onClick to icon, not container?

// Import custom icons from public folder
const CUSTOM_ICONS = new Map<string, string>();
CUSTOM_ICONS.set("applications", "/applications.svg");
CUSTOM_ICONS.set("clusters", "/clusters.svg");
CUSTOM_ICONS.set("environments", "/environments.svg");
CUSTOM_ICONS.set("experiments", "/experiments.svg");
CUSTOM_ICONS.set("management", "/management.svg");

library.add(fab);
library.add(fas);

export enum IconSize {
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export interface Props {
  className?: string;
  gaAction?: string;
  gaLabel?: string;
  container?: boolean;
  icon: IconProp | string;
  onIconClick?: any;
  size?: IconSize;
  style?: object;
  tooltipText?: string;
}

const Icon = ({
  className,
  gaAction,
  gaLabel,
  icon,
  onIconClick,
  size = IconSize.Large,
  style,
  container = true,
  tooltipText,
}: Props): JSX.Element => {
  let icon_; //tslint:disable-line
  if (typeof icon === "string" && CUSTOM_ICONS.has(icon)) {
    icon_ = <img src={CUSTOM_ICONS.get(icon)} style={style} />;
  } else {
    icon_ = (
      // https://github.com/FortAwesome/react-fontawesome/pull/200
      <span>
        <FontAwesomeIcon
          className={joinClassNames("icon", size, onIconClick && "pointer")}
          style={style}
          icon={icon as IconProp}
          data-ga-event-category={window.location.pathname.slice(1)}
          data-ga-event-action={(gaAction || "").toLowerCase()}
          data-ga-event-label={(gaLabel || "").toLowerCase()}
        />
      </span>
    );
  }
  if (tooltipText) {
    icon_ = <MTooltip title={tooltipText}>{icon_}</MTooltip>;
  }
  if (!container) {
    return icon_;
  }
  return (
    <div className={joinClassNames("icon-container", className)} onClick={onIconClick}>
      {icon_}
    </div>
  );
};

export default Icon;
