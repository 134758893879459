import { schema } from "normalizr";

const COLLECTION_NAME = "repos";

const repoSchema = new schema.Entity(
  COLLECTION_NAME,
  {},
  {
    idAttribute: (repo: any) => repo.name,
    processStrategy: (repo: any) => ({ objects: repo.content.objects, name: repo.name }),
  },
);

export const REPO_LIST = [repoSchema];

export interface RepoObject {
  uri: string;
  path: string;
  name: string;
}

export interface Repo {
  objects: RepoObject[];
  name: string;
}

export interface RepoMap {
  [name: string]: Repo;
}

export interface RepoState {
  isFetching: boolean;
  repos: RepoMap;
}

export const REFRESH_REPOS = "REFRESH_REPOS";
export const REQUEST_REPOS = "REQUEST_REPOS";
export const RECEIVE_REPOS = "RECEIVE_REPOS";

interface RequestReposAction {
  type: typeof REQUEST_REPOS;
}

interface ReceiveReposAction {
  type: typeof RECEIVE_REPOS;
  [COLLECTION_NAME]: RepoMap;
}

export type ReposActionTypes = RequestReposAction | ReceiveReposAction;
