import { FormGroup as MFormGroup } from "@material-ui/core";
import { Button } from "@sandtable/component-library";
import { Cluster } from "@sandtable/datastore";
import { openNewTab } from "@sandtable/utils";
import { GRAFANA_URL, KIBANA_URL } from "@sandtable/utils/constants";
import * as React from "react";

export interface Props {
  cluster: Cluster;
}

const ClusterMonitoringTab = (props: Props) => {
  const cluster = props.cluster;

  const grafanaGraphUrl = `${GRAFANA_URL}/d-solo/pKEhukbZk/sandman-clusters?refresh=5s&orgId=1&var-cluster_id=${cluster.clusterId}&panelId=7&theme=light`; // tslint:disable-line
  const openGrafanaUrl = `${GRAFANA_URL}/d/pKEhukbZk/pods-per-cluster?var-cluster_id=${cluster.clusterId}`;

  const openGrafana = (e: React.ChangeEvent<{}>) => openNewTab(e, openGrafanaUrl);
  const openKibana = (e: React.ChangeEvent<{}>) => openNewTab(e, KIBANA_URL);
  const openClusterUi = (e: React.ChangeEvent<{}>) => openNewTab(e, `https://${cluster.masterDomainName}`);

  const isMonitoringAvailable =
    ["available", "updating", "scaling_up", "scaling_down", "restarting"].indexOf(cluster.status) > -1;

  return (
    <div>
      <iframe src={grafanaGraphUrl} width="640" height="160" frameBorder="0" />
      <MFormGroup row={true} style={{ marginTop: "8px" }}>
        <Button
          disabled={!isMonitoringAvailable}
          gaAction={"view-cluster-drawer-monitoring-tab-grafana"}
          gaLabel={cluster.clusterId}
          icon="chart-line"
          text="Grafana"
          onButtonClick={openGrafana}
          style={{ flex: 1, marginRight: "5px" }}
        />
        <Button
          disabled={!isMonitoringAvailable}
          gaAction={"view-cluster-drawer-monitoring-tab-kibana"}
          gaLabel={cluster.clusterId}
          icon="newspaper"
          text="Kibana"
          onButtonClick={openKibana}
          style={{ flex: 1, margin: "0 5px 0 5px" }}
        />
        <Button
          disabled={!isMonitoringAvailable}
          gaAction={"view-cluster-drawer-monitoring-tab-cluster-ui"}
          gaLabel={cluster.clusterId}
          icon="clone"
          text="Cluster UI"
          onButtonClick={openClusterUi}
          style={{ flex: 1, marginLeft: "5px" }}
        />
      </MFormGroup>
    </div>
  );
};

export default ClusterMonitoringTab;
