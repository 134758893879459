import { RECEIVE_REPOS, ReposActionTypes, RepoState, REQUEST_REPOS } from "@sandtable/datastore/repos";

const initialState: RepoState = {
  isFetching: true,
  repos: {},
};

export const reposReducer = (state = initialState, action: ReposActionTypes): RepoState => {
  switch (action.type) {
    case REQUEST_REPOS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_REPOS:
      return {
        ...state,
        isFetching: false,
        repos: action.repos,
      };
    default:
      return state;
  }
};
