import { AppBar as MAppBar, Toolbar as MToolbar, Typography as MTypography } from "@material-ui/core";
import * as React from "react";

interface Props {
  items: JSX.Element;
  text: string;
}

const Nav = (props: Props) => {
  return (
    <MAppBar color="primary" position="sticky">
      <MToolbar>
        <MTypography style={{ paddingLeft: "6px" }} variant="h5" color="inherit">
          Sandman
        </MTypography>
        <MTypography style={{ marginLeft: "auto", paddingRight: "14px" }} variant="h6" color="inherit">
          {props.text}
        </MTypography>
        {props.items}
      </MToolbar>
    </MAppBar>
  );
};

export default Nav;
