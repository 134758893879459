import { Syntax } from "@sandtable/component-library";
import { EnvironmentV2 } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  environment: EnvironmentV2;
}

const EnvironmentV2DockerfileTab = (props: Props) => (
  <Syntax text={props.environment.dockerfile} mode={"dockerfile"} title={"Environment Dockerfile"} />
);

export default EnvironmentV2DockerfileTab;
