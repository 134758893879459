import { Organisation } from "@sandtable/datastore";
import { schema } from "normalizr";

const COLLECTION_NAME = "users";
const ID_KEY = "email";

const usersSchema = new schema.Entity(
  COLLECTION_NAME,
  {},
  {
    idAttribute: (user: PlatformUser) => user[ID_KEY],
  },
);

export interface PlatformUserResource {
  service: string;
  id: string;
  name: string;
}

export interface PlatformUser {
  id: string;
  deactivated: boolean;
  email: string;
  name: string;
  organisations: Organisation[];
  resources: PlatformUserResource[];
}

export interface PlatformUserMap {
  [ID_KEY: string]: PlatformUser;
}

export interface UsersState {
  isFetching: boolean;
  [COLLECTION_NAME]: PlatformUserMap;
}

export const REQUEST_USERS = "REQUEST_USERS";
export const RECEIVE_USERS = "RECEIVE_USERS";
export const REQUEST_DEACTIVATE_USER = "REQUEST_DEACTIVATE_USER";
export const RECEIVE_DEACTIVATE_USER = "RECEIVE_DEACTIVATE_USER";
export const REQUEST_REACTIVATE_USER = "REQUEST_REACTIVATE_USER";
export const RECEIVE_REACTIVATE_USER = "RECEIVE_REACTIVATE_USER";

interface RequestUsersAction {
  type: typeof REQUEST_USERS;
}

interface ReceiveUsersAction {
  type: typeof RECEIVE_USERS;
  [COLLECTION_NAME]: PlatformUserMap;
}

interface RequestDeactivateUserAction {
  type: typeof REQUEST_DEACTIVATE_USER;
}

interface ReceiveDeactivateUserAction {
  type: typeof RECEIVE_DEACTIVATE_USER;
  userEmail: string;
}

interface RequestReactivateUserAction {
  type: typeof REQUEST_REACTIVATE_USER;
}

interface ReceiveReactivateUserAction {
  type: typeof RECEIVE_REACTIVATE_USER;
  userEmail: string;
}

export const USERS_ARRAY_SCHEMA = [usersSchema];

export type UsersActionTypes =
  | RequestUsersAction
  | ReceiveUsersAction
  | RequestDeactivateUserAction
  | ReceiveDeactivateUserAction
  | RequestReactivateUserAction
  | ReceiveReactivateUserAction;
