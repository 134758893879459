import { Tabs } from "@sandtable/component-library";
import ClusterEnvironmentV1Form, {
  Props as ClusterEnvironmntV1FormProps,
} from "components/Clusters/NewClusterDrawer/ClusterEnvironmentV1Form";
import ClusterEnvironmentV2Form, {
  Props as ClusterEnvironmntV2FormProps,
} from "components/Clusters/NewClusterDrawer/ClusterEnvironmentV2Form";
import * as React from "react";

const ClusterEnvironmentForm = (props: ClusterEnvironmntV1FormProps & ClusterEnvironmntV2FormProps) => {
  const [tab, setTab] = React.useState(1); // V2 Environments are shown by default

  const resetEnvironmentId = () => {
    props.onChange({ environmentId: "" }, true);
  };

  React.useEffect(resetEnvironmentId, []);

  const handleTabChange = (newTab: number) => {
    resetEnvironmentId();
    setTab(newTab);
  };

  return (
    <>
      <Tabs gaAction={""} labels={["V1 Environments", "V2 Environments"]} value={tab} onChange={handleTabChange} />
      {tab === 0 && <ClusterEnvironmentV1Form {...props} />}
      {tab === 1 && <ClusterEnvironmentV2Form {...props} />}
    </>
  );
};

export default ClusterEnvironmentForm;
