import { FormControl as MFormControl, TextField as MTextField } from "@material-ui/core";
import * as React from "react";

export interface Props {
  className?: any;
  defaultValue?: number | string;
  error?: boolean;
  label: string;
  name?: string;
  numerical?: boolean;
  onChange: any;
  placeholder: string;
  style?: object;
  value?: any;
  autoFocus?: boolean;
  onKeyPress?: any;
}

const TextField = ({
  className,
  name,
  label,
  placeholder,
  numerical,
  defaultValue,
  error,
  onChange,
  style,
  value,
  autoFocus,
  onKeyPress,
}: Props) => {
  return (
    <>
      <MFormControl className={className} fullWidth={true}>
        <MTextField
          defaultValue={defaultValue}
          error={error}
          fullWidth={true}
          inputProps={numerical ? { min: "1", max: "100" } : {}}
          label={label}
          margin="normal"
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          style={{ ...{ margin: 0 }, ...style }}
          type={numerical ? "number" : "text"}
          variant="outlined"
          value={value}
          autoFocus={autoFocus}
          onKeyPress={onKeyPress}
        />
      </MFormControl>
    </>
  );
};

export default TextField;
