import { MenuItem as MMenuItem } from "@material-ui/core";
import { NavButton, NavMenu, useAnalytics } from "@sandtable/component-library";
import { User } from "@sandtable/datastore";
import { getAuthToken, openNewTab } from "@sandtable/utils";
import { DOCS_URL } from "@sandtable/utils/constants";
import * as React from "react";

interface Props {
  user: User;
}

const ApiButton = (props: Props) => {
  const analytics = useAnalytics();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const closeMenu = () => setAnchorEl(undefined);

  return (
    <>
      <NavButton
        icon="code-branch"
        onClick={(e: React.ChangeEvent<{}>) => {
          analytics.trackTopNavClick("api-menu");
          setAnchorEl(e.currentTarget as HTMLElement);
        }} // tslint:disable-line
        tooltipText="API menu"
      />
      <NavMenu anchorEl={anchorEl} onClose={closeMenu} open={!!anchorEl}>
        <MMenuItem
          onClick={async (e: React.ChangeEvent<{}>) => {
            e.persist();
            analytics.trackTopNavClick("api-docs");
            const token = await getAuthToken();
            openNewTab(e, `${DOCS_URL}/?link=api&token=${token}`);
            closeMenu();
          }} // tslint:disable-line
        >
          API Reference
        </MMenuItem>
        <MMenuItem
          onClick={(e: React.ChangeEvent<{}>) => {
            analytics.trackTopNavClick("api-library");
            openNewTab(e, props.user ? props.user.downloadUrl : "");
            closeMenu();
          }} // tslint:disable-line
        >
          Download library
        </MMenuItem>
      </NavMenu>
    </>
  );
};

export default ApiButton;
