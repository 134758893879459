import {
  Membership,
  Organisation,
  OrganisationActionTypes,
  Project,
  RECEIVE_ADD_MEMBER_TO_ORG,
  RECEIVE_ADD_MEMBER_TO_PROJECT,
  RECEIVE_ADD_ORGANISATION,
  RECEIVE_ADD_PROJECT_TO_ORG,
  RECEIVE_ORGANISATIONS,
  RECEIVE_REMOVE_MEMBER_FROM_ORGANISATION,
  RECEIVE_REMOVE_MEMBER_FROM_PROJECT,
  RECEIVE_UPDATE_MEMBER_OF_ORGANISATION,
  RECEIVE_UPDATE_MEMBER_OF_PROJECT,
  REQUEST_ADD_MEMBER_TO_ORG,
  REQUEST_ADD_MEMBER_TO_PROJECT,
  REQUEST_ADD_ORGANISATION,
  REQUEST_ADD_PROJECT_TO_ORG,
  REQUEST_ORGANISATIONS,
  REQUEST_REMOVE_MEMBER_FROM_ORGANISATION,
  REQUEST_REMOVE_MEMBER_FROM_PROJECT,
  REQUEST_UPDATE_MEMBER_OF_ORGANISATION,
  REQUEST_UPDATE_MEMBER_OF_PROJECT,
} from "@sandtable/datastore/organisations";
import { enqueueSnackbarNotification } from "@sandtable/datastore/snackbar";
import { apiDelete, apiGet, apiPatch, apiPost } from "@sandtable/utils";

// TODO: Use API MW

export const requestOrganisations = (): OrganisationActionTypes => {
  return {
    type: REQUEST_ORGANISATIONS,
  };
};

export const receiveOrganisations = (organisations: Organisation[]): OrganisationActionTypes => {
  return {
    organisations,
    type: RECEIVE_ORGANISATIONS,
  };
};

export const requestAddOrganisation = (name: string): OrganisationActionTypes => {
  return {
    name,
    type: REQUEST_ADD_ORGANISATION,
  };
};

export const receiveAddOrganisation = (organisation: Organisation): OrganisationActionTypes => {
  return {
    organisation,
    type: RECEIVE_ADD_ORGANISATION,
  };
};

export const requestAddProjectToOrganisation = (organisationId: string, name: string): OrganisationActionTypes => {
  return {
    name,
    organisationId,
    type: REQUEST_ADD_PROJECT_TO_ORG,
  };
};

export const receiveAddProjectToOrganisation = (project: Project, organisationId: string): OrganisationActionTypes => {
  return {
    organisationId,
    project,
    type: RECEIVE_ADD_PROJECT_TO_ORG,
  };
};

export const requestAddMemberToOrganisation = (
  organisationId: string,
  memberEmail: string,
): OrganisationActionTypes => {
  return {
    memberEmail,
    organisationId,
    type: REQUEST_ADD_MEMBER_TO_ORG,
  };
};

export const receiveAddMemberToOrganisation = (
  membership: Membership,
  organisationId: string,
): OrganisationActionTypes => {
  return {
    membership,
    organisationId,
    type: RECEIVE_ADD_MEMBER_TO_ORG,
  };
};

export const requestAddMemberToProject = (
  organisationId: string,
  projectId: string,
  memberEmail: string,
): OrganisationActionTypes => {
  return {
    memberEmail,
    organisationId,
    projectId,
    type: REQUEST_ADD_MEMBER_TO_PROJECT,
  };
};

export const receiveAddMemberToProject = (
  membership: Membership,
  organisationId: string,
  projectId: string,
): OrganisationActionTypes => {
  return {
    membership,
    organisationId,
    projectId,
    type: RECEIVE_ADD_MEMBER_TO_PROJECT,
  };
};

export const requestRemoveMemberFromProject = (
  organisationId: string,
  projectId: string,
  memberId: string,
): OrganisationActionTypes => {
  return {
    memberId,
    organisationId,
    projectId,
    type: REQUEST_REMOVE_MEMBER_FROM_PROJECT,
  };
};

export const receiveRemoveMemberFromProject = (
  organisationId: string,
  projectId: string,
  memberId: string,
): OrganisationActionTypes => {
  return {
    memberId,
    organisationId,
    projectId,
    type: RECEIVE_REMOVE_MEMBER_FROM_PROJECT,
  };
};

export const requestUpdateMemberOfProject = (
  organisationId: string,
  projectId: string,
  memberId: string,
  owner: boolean,
): OrganisationActionTypes => {
  return {
    memberId,
    organisationId,
    owner,
    projectId,
    type: REQUEST_UPDATE_MEMBER_OF_PROJECT,
  };
};

export const receiveUpdateMemberOfProject = (
  organisationId: string,
  projectId: string,
  memberId: string,
  owner: boolean,
): OrganisationActionTypes => {
  return {
    memberId,
    organisationId,
    owner,
    projectId,
    type: RECEIVE_UPDATE_MEMBER_OF_PROJECT,
  };
};

export const requestUpdateMemberOfOrganisation = (
  organisationId: string,
  memberId: string,
  owner: boolean,
): OrganisationActionTypes => {
  return {
    memberId,
    organisationId,
    owner,
    type: REQUEST_UPDATE_MEMBER_OF_ORGANISATION,
  };
};

export const receiveUpdateMemberOfOrganisation = (
  organisationId: string,
  memberId: string,
  owner: boolean,
): OrganisationActionTypes => {
  return {
    memberId,
    organisationId,
    owner,
    type: RECEIVE_UPDATE_MEMBER_OF_ORGANISATION,
  };
};

export const requestRemoveMemberFromOrganisation = (
  organisationId: string,
  memberId: string,
): OrganisationActionTypes => {
  return {
    memberId,
    organisationId,
    type: REQUEST_REMOVE_MEMBER_FROM_ORGANISATION,
  };
};

export const receiveRemoveMemberFromOrganisation = (
  organisationId: string,
  memberId: string,
): OrganisationActionTypes => {
  return {
    memberId,
    organisationId,
    type: RECEIVE_REMOVE_MEMBER_FROM_ORGANISATION,
  };
};

export const fetchOrganisations = () => {
  return async (dispatch: any) => {
    dispatch(requestOrganisations());
    const res = await apiGet("organisations");
    dispatch(receiveOrganisations(res.data));
  };
};

export const addOrganisation = (name: string) => {
  return async (dispatch: any) => {
    dispatch(requestAddOrganisation(name));
    try {
      const res = await apiPost("organisations", { name });
      dispatch(receiveAddOrganisation(res.data));
      dispatch(enqueueSnackbarNotification({ text: "Organisation created" }));
    } catch (e) {
      dispatch(
        enqueueSnackbarNotification({
          text: "Failed to create organisation",
        }),
      );
    }
  };
};

export const addMemberToOrganisation = (organisationId: string, memberEmail: string) => {
  return async (dispatch: any) => {
    dispatch(requestAddMemberToOrganisation(organisationId, memberEmail));
    try {
      const res = await apiPost(`organisations/${organisationId}/members`, { user_email: memberEmail });
      dispatch(receiveAddMemberToOrganisation(res.data, organisationId));
      dispatch(enqueueSnackbarNotification({ text: "User added to organisation" }));
    } catch (e) {
      dispatch(
        enqueueSnackbarNotification({
          text: "Failed to add user to organisation",
        }),
      );
    }
  };
};

export const addMemberToProject = (organisationId: string, projectId: string, memberEmail: string) => {
  return async (dispatch: any) => {
    dispatch(requestAddMemberToProject(organisationId, projectId, memberEmail));
    try {
      const res = await apiPost(`projects/${projectId}/members`, { user_email: memberEmail });
      dispatch(receiveAddMemberToProject(res.data, organisationId, projectId));
      dispatch(enqueueSnackbarNotification({ text: "User added to project" }));
    } catch (e) {
      dispatch(
        enqueueSnackbarNotification({
          text: "Failed to add user to project",
        }),
      );
    }
  };
};

export const removeMemberFromProject = (organisationId: string, projectId: string, memberId: string) => {
  return async (dispatch: any) => {
    dispatch(requestRemoveMemberFromProject(organisationId, projectId, memberId));
    try {
      await apiDelete(`projects/${projectId}/members/${memberId}`, {});
      dispatch(receiveRemoveMemberFromProject(organisationId, projectId, memberId));
      dispatch(enqueueSnackbarNotification({ text: "User removed from project" }));
    } catch (e) {
      dispatch(
        enqueueSnackbarNotification({
          text: "Failed to remove user from project",
        }),
      );
    }
  };
};

export const removeMemberFromOrganisation = (organisationId: string, memberId: string) => {
  return async (dispatch: any) => {
    dispatch(requestRemoveMemberFromOrganisation(organisationId, memberId));
    try {
      await apiDelete(`organisations/${organisationId}/members/${memberId}`, {});
      dispatch(receiveRemoveMemberFromOrganisation(organisationId, memberId));
      dispatch(
        enqueueSnackbarNotification({
          text: "User removed from organisation",
        }),
      );
    } catch (e) {
      dispatch(
        enqueueSnackbarNotification({
          text: "Failed to remove user from organisation. Is the user a member of a project under this organisation?",
        }),
      );
    }
  };
};

export const updateMemberOfProject = (organisationId: string, projectId: string, memberId: string, owner: boolean) => {
  return async (dispatch: any) => {
    dispatch(requestUpdateMemberOfProject(organisationId, projectId, memberId, owner));
    try {
      await apiPatch(`projects/${projectId}/members/${memberId}`, { owner });
      dispatch(receiveUpdateMemberOfProject(organisationId, projectId, memberId, owner));
      dispatch(enqueueSnackbarNotification({ text: "User updated" }));
    } catch (e) {
      dispatch(
        enqueueSnackbarNotification({
          text: "Failed to update user",
        }),
      );
    }
  };
};

export const updateMemberOfOrganisation = (organisationId: string, memberId: string, owner: boolean) => {
  return async (dispatch: any) => {
    dispatch(requestUpdateMemberOfOrganisation(organisationId, memberId, owner));
    try {
      await apiPatch(`organisations/${organisationId}/members/${memberId}`, { owner });
      dispatch(receiveUpdateMemberOfOrganisation(organisationId, memberId, owner));
      dispatch(enqueueSnackbarNotification({ text: "User updated" }));
    } catch (e) {
      dispatch(
        enqueueSnackbarNotification({
          text: "Failed to update user",
        }),
      );
    }
  };
};

export const addProjectToOrganisation = (organisationId: string, name: string) => {
  return async (dispatch: any) => {
    dispatch(requestAddProjectToOrganisation(organisationId, name));
    try {
      const res = await apiPost(`projects`, { organisation_id: organisationId, name });
      dispatch(receiveAddProjectToOrganisation(res.data, organisationId));
      dispatch(enqueueSnackbarNotification({ text: "Project created" }));
    } catch (e) {
      dispatch(enqueueSnackbarNotification({ text: "Failed to create project" }));
    }
  };
};
