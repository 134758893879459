import { Loading } from "@sandtable/component-library";
import { EnvironmentsV2Map, EnvironmentV2, NewEnvironmentV2 } from "@sandtable/datastore";
import { EnvironmentsV2Object } from "@sandtable/utils";
import EnvironmentsV2List from "components/EnvironmentsV2/EnvironmentsV2List";
import NewEnvironmentV2Drawer from "components/EnvironmentsV2/NewEnvironmentV2Drawer/NewEnvironmentV2Drawer";
import UpdateEnvironmentV2Drawer from "components/EnvironmentsV2/UpdateEnvironmentV2Drawer";
import ViewEnvironmentsV2Drawer from "components/EnvironmentsV2/ViewEnvironmentsV2Drawer";
import ViewEnvironmentV2Drawer from "components/EnvironmentsV2/ViewEnvironmentV2Drawer/ViewEnvironmentV2Drawer";
import * as React from "react";
import { generatePath, Route, useHistory } from "react-router-dom";

const ENVIRONMENTS_PATH = "/environments-v2";
const NEW_ENVIRONMENT_PATH = "/environments-v2/new-environment";
const VIEW_ENVIRONMENTS_PATH = "/environments-v2/view-environments/:name";
const VIEW_ENVIRONMENT_PATH = "/environments-v2/view-environment/:uid";
const UPDATE_ENVIRONMENT_PATH = "/environments-v2/update-environment/:uid";

interface Props {
  addEnvironmentV2: (environment: NewEnvironmentV2) => void;
  cancelEnvironmentV2: (environment: EnvironmentV2) => void;
  deleteEnvironmentV2: (environment: EnvironmentV2) => void;
  environments: EnvironmentsV2Map;
  environmentsObject: EnvironmentsV2Object;
  fetchEnvironmentsV2: (page?: number, perPage?: number, searchQuery?: string) => void;
  getEnvironmentV2: (environment: EnvironmentV2) => void;
  isFetching: boolean;
  updateEnvironmentV2: (environment: EnvironmentV2) => void;
}

const EnvironmentsV2 = (props: Props) => {
  const history = useHistory();

  const handleGoBack = React.useCallback(() => history.push(ENVIRONMENTS_PATH), [history]);
  const handleEnvironmentsSelect = React.useCallback(
    (name: string) => history.push(generatePath(VIEW_ENVIRONMENTS_PATH, { name })),
    [history],
  );
  const handleNewEnvironmentClick = React.useCallback(() => history.push(NEW_ENVIRONMENT_PATH), [history]);
  const handleEnvironmentSelect = React.useCallback(
    (e: EnvironmentV2) => history.push(generatePath(VIEW_ENVIRONMENT_PATH, { uid: e.uid })),
    [history],
  );
  const { updateEnvironmentV2 } = props;
  const handleUpdateEnvironmentSubmit = React.useCallback(
    (e: EnvironmentV2) => {
      updateEnvironmentV2(e);
      history.replace(generatePath(VIEW_ENVIRONMENTS_PATH, { name: e.name }));
    },
    [history, updateEnvironmentV2],
  );
  const handleGoBackEnvironments = React.useCallback(
    (e: EnvironmentV2) => history.push(generatePath(VIEW_ENVIRONMENTS_PATH, { name: e.name })),
    [history],
  );

  if (props.isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Route path={ENVIRONMENTS_PATH}>
        <EnvironmentsV2List
          {...props}
          onEnvironmentsSelect={handleEnvironmentsSelect}
          onNewEnvironmentClick={handleNewEnvironmentClick}
        />
      </Route>
      <Route path={NEW_ENVIRONMENT_PATH}>
        <NewEnvironmentV2Drawer {...props} onClose={handleGoBack} />
      </Route>
      <Route path={VIEW_ENVIRONMENTS_PATH}>
        <ViewEnvironmentsV2Drawer {...props} onEnvironmentSelect={handleEnvironmentSelect} onClose={handleGoBack} />
      </Route>
      <Route path={VIEW_ENVIRONMENT_PATH}>
        <ViewEnvironmentV2Drawer {...props} onClose={handleGoBackEnvironments} />
      </Route>
      <Route path={UPDATE_ENVIRONMENT_PATH}>
        <UpdateEnvironmentV2Drawer
          {...props}
          onUpdateEnvironmentSubmit={handleUpdateEnvironmentSubmit}
          onClose={handleGoBackEnvironments}
        />
      </Route>
    </>
  );
};

export default EnvironmentsV2;
