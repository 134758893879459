import { Syntax } from "@sandtable/component-library";
import { EnvironmentV2 } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  environment: EnvironmentV2;
}

const EnvironmentV2YamlTab = (props: Props) => (
  <Syntax text={props.environment.condaEnvironmentFile} mode={"yaml"} title={"Environment Conda YAML"} />
);

export default EnvironmentV2YamlTab;
