import {
  Execution,
  RECEIVE_DELETE_EXECUTION,
  RECEIVE_EXECUTIONS_FOR_APP,
  REQUEST_DELETE_EXECUTION,
  REQUEST_EXECUTIONS_FOR_APP,
} from "@sandtable/datastore/executions";
import { EXECUTION_ARRAY } from "@sandtable/datastore/executions/schema";
import { API_DELETE, API_GET, ApiMiddlewareAction } from "@sandtable/datastore/middleware";

export const fetchExecutionsForApplication = (applicationId: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: `applications/executions/${applicationId}`,
        schema: EXECUTION_ARRAY,
        successActionParams: {
          applicationId,
        },
        types: [REQUEST_EXECUTIONS_FOR_APP, RECEIVE_EXECUTIONS_FOR_APP, RECEIVE_EXECUTIONS_FOR_APP],
      },
    });
  };
};

export const deleteExecution = (execution: Execution) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_DELETE]: {
        endpoint: `applications/executions/${execution.id}`,
        successActionParams: {
          applicationId: execution.applicationId,
          executionId: execution.id,
        },
        types: [REQUEST_DELETE_EXECUTION, RECEIVE_DELETE_EXECUTION, "RECEIVE_DELETE_EXECUTION_FAILURE"],
      },
    });
  };
};
