import { Paper as MPaper, Typography as MTypography } from "@material-ui/core";
import { Icon } from "@sandtable/component-library";
import * as React from "react";
import { useDropzone } from "react-dropzone";

const UPLOAD_PAPER_STYLES: React.CSSProperties = {
  alignItems: "center",
  backgroundColor: "#DCDCDC",
  display: "flex",
  height: "200px",
  justifyContent: "center",
};

interface Props {
  onFileUpload: (f: File) => void;
}

const UploadTab = (props: Props) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".nlogo",
  });
  const { onFileUpload } = props;
  React.useEffect(() => {
    if (acceptedFiles.length) {
      if (acceptedFiles.length > 1) {
        alert("Please select only one file.");
      } else {
        onFileUpload(acceptedFiles[0]);
      }
    }
  }, [acceptedFiles, onFileUpload]);
  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <MPaper style={UPLOAD_PAPER_STYLES}>
          <Icon icon="file-upload" />
          <MTypography style={{ marginTop: "2px" }}>Upload a .nlogo file</MTypography>
        </MPaper>
      </div>
    </>
  );
};

export default UploadTab;
