// TODO: Abstract all pagination stuff into new component. See PaginatedCardList.
import { PaginatedTreeViewObject, Pagination, TreeView } from "@sandtable/component-library";
import * as React from "react";

interface Props {
  objects: PaginatedTreeViewObject;
}

const PaginatedTreeView = (props: Props) => {
  const [pagination, setPagination] = React.useState({ offset: 0, limit: 10 });

  const { objects } = props;
  const slicedObjects = React.useMemo(() => objects.slice(pagination.offset, pagination.limit), [objects, pagination]);

  return (
    <>
      <TreeView objects={slicedObjects} />
      <Pagination
        count={objects.length}
        handleChange={(offset: number, limit: number) => setPagination({ offset, limit })}
      />
    </>
  );
};

export default PaginatedTreeView;
