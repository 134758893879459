import { PlatformUser } from "@sandtable/datastore";
import { API_GET, API_POST, ApiMiddlewareAction } from "@sandtable/datastore/middleware";
import {
  RECEIVE_DEACTIVATE_USER,
  RECEIVE_REACTIVATE_USER,
  RECEIVE_USERS,
  REQUEST_DEACTIVATE_USER,
  REQUEST_REACTIVATE_USER,
  REQUEST_USERS,
  USERS_ARRAY_SCHEMA,
} from "@sandtable/datastore/users";

export const fetchUsers = () => {
  return (dispatch: (action: ApiMiddlewareAction) => void) =>
    dispatch({
      [API_GET]: {
        endpoint: "users",
        schema: USERS_ARRAY_SCHEMA,
        types: [REQUEST_USERS, RECEIVE_USERS, RECEIVE_USERS],
      },
    });
};

export const deactivateUser = (user: PlatformUser) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) =>
    dispatch({
      [API_POST]: {
        endpoint: `user/${user.id}/deactivate`,
        successActionParams: {
          userEmail: user.email,
        },
        types: [REQUEST_DEACTIVATE_USER, RECEIVE_DEACTIVATE_USER, "RECEIVE_DEACTIVATE_USER_FAILURE"],
      },
    });
};

export const reactivateUser = (user: PlatformUser) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) =>
    dispatch({
      [API_POST]: {
        endpoint: `user/${user.id}/reactivate`,
        successActionParams: {
          userEmail: user.email,
        },
        types: [REQUEST_REACTIVATE_USER, RECEIVE_REACTIVATE_USER, "RECEIVE_REACTIVATE_USER_FAILURE"],
      },
    });
};
