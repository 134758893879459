import { Syntax } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  environment: Environment;
  onChange: (e: Partial<Environment>, error?: boolean, condaChannel?: string) => void;
}

const DockerfileTab = (props: Props): JSX.Element => {
  return (
    <Syntax
      text={props.environment.dockerfile}
      onChange={(a: any, b: any, dockerfile: any) => props.onChange({ dockerfile }, dockerfile === "")}
      height={"506px"}
      title="Edit Dockerfile"
    />
  );
};

export default DockerfileTab;
