import { Button, CardList, CardListObject, Tabs, TextField } from "@sandtable/component-library";
import { Organisation, Project } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  addProjectToOrganisation: (organisationId: string, name: string) => any;
  onProjectSelect: (p: Project) => void;
  organisation: Organisation;
}

const OrganisationProjectsTab = (props: Props) => {
  const objects: CardListObject[] = props.organisation.projects.map(
    (p: Project, key: any) =>
      ({
        gaAction: "view-organisation-drawer-organisation-projects-tab-card",
        gaLabel: p.name,
        onCardClick: () => props.onProjectSelect(p),
        primaryIcon: "project-diagram",
        primaryText: p.name,
      } as CardListObject), //tslint:disable-line
  );

  return (
    <>
      <CardList objects={objects} />
      <OrganisationProjectsActions {...props} />
    </>
  );
};

const OrganisationProjectsActions = (props: Props) => {
  const [newProjectName, setNewProjectName] = React.useState("");

  return (
    <>
      <Tabs value={0} labels={["Actions"]} gaAction={""} />
      <TextField
        label="Project name"
        onChange={(e: React.ChangeEvent<any>) => setNewProjectName(e.target.value)}
        placeholder="Project name"
        style={{ marginBottom: "14px" }}
        value={newProjectName}
      />
      <Button
        fullWidth={true}
        disabled={!newProjectName.length}
        gaAction={"view-organisation-drawer-organisation-projects-tab-create-project"}
        iconRight="check"
        onButtonClick={() => {
          props.addProjectToOrganisation(props.organisation.id, newProjectName);
          setNewProjectName("");
        }}
        text={"Create project"}
      />
    </>
  );
};

export default OrganisationProjectsTab;
