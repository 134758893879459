import { ViewDrawer } from "@sandtable/component-library";
import { Application, ApplicationExecutionMap, ApplicationMap, Execution } from "@sandtable/datastore";
import ApplicationActionSection from "components/Applications/ViewApplicationDrawer/ApplicationActionSection";
import ApplicationTab from "components/Applications/ViewApplicationDrawer/ApplicationTab";
import ExecutionsTab from "components/Applications/ViewApplicationDrawer/ExecutionsTab";
import UsersTab from "components/Applications/ViewApplicationDrawer/UsersTab";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  addApplicationUser: any;
  applications: ApplicationMap;
  deleteApplication: (application: Application) => void;
  deleteExecution: (execution: Execution) => void;
  executions: ApplicationExecutionMap;
  inheritApplicationUsers: any;
  onClose: () => void;
  onApplicationLoad: (applicationId: string) => void;
  onExecutionSelect: (e: Execution) => void;
  removeApplicationUser: any;
}

const ViewApplicationDrawer = (props: Props) => {
  const { applicationId } = useParams();

  // Load executions + users
  const { onApplicationLoad } = props;
  React.useEffect(() => {
    if (applicationId) {
      onApplicationLoad(applicationId);
    }
  }, [applicationId]);

  const application = props.applications[applicationId!]; // TODO: Handle undefined.
  const executions = props.executions[applicationId!] || [];

  const selectedApplicationVersions = React.useMemo(
    () => Object.values(application || {}).filter((a: Application) => a.name === (application && application.name)),
    [application],
  );

  if (!application) {
    props.onClose(); // TODO: Handle more gracefully.
    return <></>;
  }

  return (
    <ViewDrawer
      actionSection={<ApplicationActionSection {...props} application={application} />}
      title={"View Application"}
      open={true}
      onClose={props.onClose}
      tabNames={["Application", "Executions", "External Users"]}
      tabContents={[
        <ApplicationTab key={"applicationTab"} {...props} application={application} />,
        <ExecutionsTab key={"executionsTab"} {...props} executions={executions} />,
        <UsersTab
          key={"usersTab"}
          {...props}
          application={application}
          selectedApplicationVersions={selectedApplicationVersions}
        />,
      ]}
      tabGaAction={"view-application-drawer-tabs"}
    />
  );
};

export default ViewApplicationDrawer;
