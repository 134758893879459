import {
  addMemberToOrganisation,
  addMemberToProject,
  addOrganisation,
  addProjectToOrganisation,
  AppState,
  deactivateUser,
  fetchOrganisations,
  fetchUsers,
  Organisation,
  PlatformUser,
  reactivateUser,
  removeMemberFromOrganisation,
  removeMemberFromProject,
  updateMemberOfOrganisation,
  updateMemberOfProject,
} from "@sandtable/datastore";
import Management from "components/Management/Management";
import * as React from "react";
import { connect } from "react-redux";

interface Props {
  addMemberToOrganisation: (organisationId: string, memberEmail: string) => any;
  addMemberToProject: (organisationId: string, projectId: string, memberEmail: string) => any;
  addOrganisation: (name: string) => any;
  addProjectToOrganisation: (organisationId: string, name: string) => any;
  deactivateUser: (user: PlatformUser) => void;
  fetchOrganisations: () => void;
  fetchUsers: () => void;
  isFetching: boolean;
  organisations: Organisation[];
  reactivateUser: (user: PlatformUser) => void;
  removeMemberFromOrganisation: (organisationId: string, memberId: string) => any;
  removeMemberFromProject: (organisationId: string, projectId: string, memberId: string) => any;
  updateMemberOfOrganisation: (organisationId: string, memberId: string, owner: boolean) => any;
  updateMemberOfProject: (organisationId: string, projectId: string, memberId: string, owner: boolean) => any;
  users: PlatformUser[];
}

const ManagementContainer = (props: Props) => {
  const { fetchOrganisations, fetchUsers } = props;

  React.useEffect(() => {
    fetchOrganisations();
    fetchUsers();
  }, []);

  return <Management {...props} />;
};

const mapStateToProps = (state: AppState) => {
  return {
    isFetching: state.organisations.isFetching || state.users.isFetching,
    organisations: state.organisations.organisations,
    users: Object.values(state.users.users).sort((a: PlatformUser, b: PlatformUser) => a.name.localeCompare(b.name)),
  };
};

export default connect(mapStateToProps, {
  addMemberToOrganisation,
  addMemberToProject,
  addOrganisation,
  addProjectToOrganisation,
  deactivateUser,
  fetchOrganisations,
  fetchUsers,
  reactivateUser,
  removeMemberFromOrganisation,
  removeMemberFromProject,
  updateMemberOfOrganisation,
  updateMemberOfProject,
})(ManagementContainer);
