import { Typography as MTypography } from "@material-ui/core";
import { capitaliseString } from "@sandtable/utils";
import * as React from "react";

interface Props {
  state: string;
}

// MVP: clusters only.
const INACTIVE_COLOUR = "#B6D5FF";
const ACTIVE_COLOUR = "#408FF6";

const SUCCESS_COLOUR = "#70DB62";
const FAILURE_COLOUR = "#EA3131";
const NEUTRAL_COLOUR = "#A1A1A1";

interface ClusterStateMapping {
  [key: string]: string;
}

const stableClusterStateColours: ClusterStateMapping = {
  available: SUCCESS_COLOUR,
  stopped: NEUTRAL_COLOUR,
  terminated: NEUTRAL_COLOUR,
  unavailable: FAILURE_COLOUR,
};

const inProgressClusterStateWidths: ClusterStateMapping = {
  created: "20%",
  initialising: "30%",
  restarting: "70%",
  scaling_down: "70%",
  scaling_up: "70%",
  starting: "30%",
  stopping: "70%",
  terminating: "70%",
  updating: "70%",
  upgrading: "70%",
};

const StatusIndicator = (props: Props) => {
  const formattedString = capitaliseString(props.state.split("_").join(" "));
  if (props.state in stableClusterStateColours) {
    const colour = stableClusterStateColours[props.state];
    return <StableIndicator text={formattedString} colour={colour} />;
  } else if (props.state in inProgressClusterStateWidths) {
    const width = inProgressClusterStateWidths[props.state];
    return <InProgressIndicator text={formattedString} width={width} />;
  }
  return <></>;
};

const STABLE_INDICATOR_STYLES = {
  borderRadius: "9px",
  height: "18px",
  width: "130px",
};

interface StableIndicatorProps {
  text: string;
  colour: string;
}

const StableIndicator = (props: StableIndicatorProps) => {
  return (
    <div style={{ ...STABLE_INDICATOR_STYLES, backgroundColor: props.colour }}>
      <MTypography style={{ fontSize: "0.75em", color: "white" }} align="center">
        {props.text}
      </MTypography>
    </div>
  );
};

const IN_PROGRESS_WIDTH = "420px";

interface InProgressIndicatorProps {
  text: string;
  width: string;
}

const InProgressIndicator = (props: InProgressIndicatorProps) => {
  return (
    <div style={{ position: "relative", width: IN_PROGRESS_WIDTH }}>
      <InProgressIndicatorSection
        colour={ACTIVE_COLOUR}
        style={{ position: "absolute" }}
        text={props.text}
        width={props.width}
      />
      <InProgressIndicatorSection width={"100%"} colour={INACTIVE_COLOUR} />
    </div>
  );
};

const CONTAINER_STYLES: React.CSSProperties = {
  borderBottomLeftRadius: "9px",
  borderTopLeftRadius: "9px",
  display: "flex",
  flexDirection: "row-reverse",
  height: "18px",
};

const CONTAINER_ARROW_STYLES: React.CSSProperties = {
  borderBottom: "8px solid transparent",
  borderLeft: "12px solid",
  borderTop: "8px solid transparent",
};

interface InProgressIndicatorSectionProps {
  colour: string;
  style?: object;
  text?: string;
  width: string;
}

const InProgressIndicatorSection = (props: InProgressIndicatorSectionProps) => {
  return (
    <div style={{ ...props.style, display: "flex", width: props.width }}>
      <div
        style={{
          ...CONTAINER_STYLES,
          backgroundColor: props.colour,
          width: "100%",
        }}
      >
        <MTypography style={{ fontSize: "0.75em", color: "white", paddingRight: "4px" }}>{props.text}</MTypography>
      </div>
      <div style={{ ...CONTAINER_ARROW_STYLES, color: props.colour }} />
    </div>
  );
};

export default StatusIndicator;
