import { SideNav as SideNavComponent } from "@sandtable/component-library";
import { useWindowWidth } from "@sandtable/utils";
import React from "react";

const MAX_WINDOW_WIDTH_WIDE_NAV = 900;
const CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
};

const MENU = [
  { name: "Clusters", url: "/clusters", icon: "clusters", style: { width: "18px" } },
  { name: "Environments", url: "/environments", icon: "environments", style: { width: "16px" } },
  { name: "Environments V2", url: "/environments-v2", icon: "environments", style: { width: "16px" } },
  { name: "Experiments", url: "/experiments", icon: "experiments", style: { width: "18px" } },
  { name: "Applications", url: "/applications", icon: "applications", style: { width: "16px" } },
  { name: "Deployments", url: "/deployments", icon: "applications", style: { width: "16px" } },
  { name: "Data", url: "/data", icon: "archive", style: { width: "18px" } },
  { name: "Management", url: "/management", icon: "management", style: { width: "18px" } },
];

const SideNav = () => {
  const width = useWindowWidth();
  const isOpen = width > MAX_WINDOW_WIDTH_WIDE_NAV;
  return (
    <div style={CONTAINER_STYLES}>
      <SideNavComponent open={isOpen} menu={MENU} />
    </div>
  );
};

export default SideNav;
