import { SectionList, SectionListObject } from "@sandtable/component-library";
import { Deployment, DeploymentMap } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  deployments: DeploymentMap;
  fetchDeployments: () => void;
  onDeploymentSelect: (d: Deployment) => void;
}

const DeploymentsList = (props: Props) => {
  let objects: SectionListObject[] = [];
  if (typeof props.deployments !== "undefined") {
    objects = Object.values(props.deployments).map((d: Deployment) => {
      const secondaryText = `${d.project} project • ${d.creator}`;

      return {
        data: d,
        gaAction: "deployments-list-card",
        gaLabel: d.id,
        onCardClick: () => props.onDeploymentSelect(d),
        primaryText: d.name,
        secondaryText,
      } as SectionListObject; //tslint:disable-line
    });
  }

  return (
    <SectionList
      objects={objects}
      fetchResources={props.fetchDeployments}
      searchKeys={["name", "description", "creator"]}
      showSearch={true}
    />
  );
};

export default DeploymentsList;
