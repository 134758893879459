import { schema } from "normalizr";

const uploadSchema = new schema.Entity(
  "uploads",
  {},
  {
    idAttribute: (upload: any) => upload.id,
  },
);

export const UPLOAD = uploadSchema;
export const UPLOAD_ARRAY = [uploadSchema];
