import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  Drawer as MDrawer,
  List as MList,
  ListItem as MListItem,
  ListItemIcon as MListItemIcon,
  ListItemText as MListItemText,
  Typography as MTypography,
  WithStyles,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import { Icon, useUser } from "@sandtable/component-library";
import { COMMIT_HASH } from "@sandtable/utils/constants"; // tslint:disable-line
import * as React from "react";
import { Route } from "react-router-dom";
import theme from "../theme";
import "./SideNav.css";

export const DATE_TODAY: Date = new Date(); // Today
export const DEFAULT_COPYRIGHT: string = `choreograph © ${DATE_TODAY.getFullYear()}`;

const styles = createStyles({
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    borderRight: "none",
    flexGrow: 1,
    position: "inherit",
  },
  listItemText: {
    color: "white",
  },
});

export interface MenuItem {
  name: string;
  url: string;
  icon: IconProp | string;
  style: object;
}

interface Props extends WithStyles {
  menu: MenuItem[];
  open?: boolean;
}

const SideNav = (props: Props) => {
  // TODO: Refactor this
  /* tslint:disable */
  const user = useUser();
  const listItems = props.menu
    .filter(({ name, url, icon }) => {
      return user.visibleRoutes.indexOf(url) > -1;
    })
    .map(({ name, url, icon, style }, key) => (
      <Route
        key={key}
        render={({ history }) => (
          <MListItem
            button={true}
            selected={(window.location.pathname.match(/(\/[^/]*)/) || [])[0] === url}
            onClick={(e: any) => history.push(url)}
            data-ga-event-category={"side-nav"}
            data-ga-event-action={name.toLowerCase()}
          >
            <MListItemIcon
              style={{
                paddingLeft: props.open ? "22px" : "16px",
                paddingRight: props.open ? "32px" : "16px",
                minWidth: "0px",
              }}
            >
              <Icon icon={icon} className={props.classes.listItemText} style={style} />
            </MListItemIcon>
            {props.open ? (
              <MListItemText
                style={{ paddingRight: "10px", whiteSpace: "nowrap" }}
                primary={name}
                classes={{ primary: props.classes.listItemText }}
              />
            ) : (
              ""
            )}
          </MListItem>
        )}
      />
    ));
  /* tslint:enable */
  return (
    <MDrawer classes={{ paper: props.classes.drawerPaper }} variant="permanent">
      <MList>{listItems}</MList>
      {props.open && <SideNavFooter />}
    </MDrawer>
  );
};

SideNav.defaultProps = {
  open: true,
};

const SideNavFooter = () => {
  return (
    <div className="sidenav-footer-container">
      <MTypography>{COMMIT_HASH || "Local"}</MTypography>
      <MTypography>{DEFAULT_COPYRIGHT}</MTypography>
    </div>
  );
};

export default withStyles(styles)(SideNav);
