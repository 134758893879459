import { Organisation, PlatformUser } from "@sandtable/datastore";
import UsersList from "components/Management/UsersList";
import ViewUserDrawer from "components/Management/ViewUserDrawer/ViewUserDrawer";
import * as React from "react";
import { generatePath, Route, useHistory } from "react-router-dom";

// TODO: Inherit path(s) from parent?
const USERS_PATH = "/management/users";
const VIEW_USER_PATH = "/management/users/view-user/:id";

interface Props {
  deactivateUser: (user: PlatformUser) => void;
  fetchUsers: () => void;
  organisations: Organisation[];
  reactivateUser: (user: PlatformUser) => void;
  users: PlatformUser[];
}

const UsersTab = (props: Props) => {
  const history = useHistory();

  const handleGoBack = () => history.push(USERS_PATH);
  const handleUserSelect = (u: PlatformUser) => history.push(generatePath(VIEW_USER_PATH, { id: u.id }));

  return (
    <>
      <UsersList {...props} onUserSelect={handleUserSelect} />
      <Route path={VIEW_USER_PATH}>
        <ViewUserDrawer {...props} onClose={handleGoBack} />
      </Route>
    </>
  );
};

export default UsersTab;
