import {
  Card as MCard,
  CardContent as MCardContent,
  FormGroup as MFormGroup,
  Typography as MTypography,
} from "@material-ui/core";
import { Chip, Icon, Select, Switch, TextField } from "@sandtable/component-library";
import { Configuration, NewCluster } from "@sandtable/datastore";
import { pluraliseString } from "@sandtable/utils";
import * as React from "react";

export interface Props {
  cluster: NewCluster;
  configuration: Configuration;
  onChange: (c: Partial<NewCluster>, e: boolean) => void;
  projects: string[];
}

const ClusterConfigurationForm: React.StatelessComponent<Props> = (props: Props) => {
  const { cluster, onChange } = props;
  React.useEffect(() => {
    onChange({}, !cluster.project || !+cluster.instances);
  }, []);

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    const error = (name === "project" && !value) || (name === "instances" && !+value);
    onChange({ [name]: value }, error);
  };

  const inCores = cluster.hyperthreading ? props.configuration.vcpus : props.configuration.cores;
  const inMemory = props.configuration.totalMemory / inCores;
  const clCores = inCores * cluster.instances;

  return (
    <>
      <Chip
        icon={<Icon icon={"server"} />}
        label={`${props.configuration.name} • ${inCores} ${pluraliseString("core", inCores)} • ${inMemory.toFixed(
          2,
        )} GB memory/core`} // tslint:disable-line
        fullWidth={true}
      />
      <ConfigurationCard
        title={"Cluster configuration"}
        {...props}
        cores={clCores}
        memory={inMemory}
        style={{ marginBottom: "14px" }}
      />
      <TextField
        defaultValue={cluster.instances}
        label={"Number of instances"}
        name={"instances"}
        numerical={true}
        onChange={handleChange}
        placeholder={"Number of instances"}
        style={{ marginBottom: "14px" }}
      />
      <Select
        insertNoneOption={false}
        label={"Project"}
        labelWidth={50}
        name={"project"}
        onChange={handleChange}
        options={props.projects.map((s: string) => ({ text: s }))}
        value={props.cluster.project}
        style={{ marginBottom: "14px" }}
      />
      <SwitchTags onChange={handleChange} cluster={props.cluster} style={{ marginBottom: "14px" }} />
    </>
  );
};

interface ConfigurationCardProps extends Props {
  title: string;
  cores: number;
  memory: number;
  style: React.CSSProperties;
}

const ConfigurationCard = (props: ConfigurationCardProps): JSX.Element => {
  return (
    <MCard style={props.style}>
      <MCardContent>
        <MTypography color={"textSecondary"}>{props.title}</MTypography>
        <MTypography style={{ fontSize: "1.1em" }} variant="h6" component="h2">
          {props.cores} core{props.cores > 1 ? "s" : ""} • {props.memory.toFixed(2)} GB memory/core
        </MTypography>
      </MCardContent>
    </MCard>
  );
};

interface SwitchTagsProps {
  cluster: NewCluster;
  style: React.CSSProperties;
  onChange: any;
}

const SwitchTags = (props: SwitchTagsProps): JSX.Element => {
  const { onChange } = props;
  const handleSwitchChange = React.useCallback(
    (e: React.ChangeEvent<any>) => onChange({ target: { value: !(e.target.value === "true"), name: e.target.name } }),
    [onChange],
  );
  const tags = [
    {
      checked: props.cluster.hyperthreading,
      label: "Hyperthreading",
      name: "hyperthreading",
      tooltipLabel:
        "Advanced. A single physical core behaves like two logical cores. Only change if you know what you’re doing. Recommended: on.", // tslint:disable-line
      value: props.cluster.hyperthreading,
    },
    {
      checked: props.cluster.spotInstances,
      label: "Spot instances",
      name: "spotInstances",
      tooltipLabel:
        "Spot instances are spare cloud compute capacity available at discounted rates. Otherwise uses on-demand instance types. Recommended: on.", // tslint:disable-line
      value: props.cluster.spotInstances,
    },
    {
      checked: props.cluster.useIdleShutdown,
      label: "Idle shutdown",
      name: "useIdleShutdown",
      tooltipLabel: "Shutdown cluster after a idle period (default: 60 mins). Recommended: on.",
      value: props.cluster.useIdleShutdown,
    },
  ].map((switchProps: any, key: number) => {
    return <Switch onChange={handleSwitchChange} ownGroup={false} key={key} {...switchProps} />;
  });
  return (
    <MFormGroup row={true} style={{ ...props.style, display: "flex", justifyContent: "space-evenly" }}>
      {tags}
    </MFormGroup>
  );
};

export default ClusterConfigurationForm;
