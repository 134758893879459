import { CardList, CardListObject } from "@sandtable/component-library";
import { Organisation, PlatformUser, Project } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  user: PlatformUser;
}

const ProjectsTab = (props: Props) => {
  return (
    <CardList
      objects={props.user.organisations.flatMap((o: Organisation, key: any) =>
        o.projects.map(
          (p: Project) =>
            ({
              gaAction: "view-user-drawer-user-tab-projects-card",
              gaLabel: p.id,
              primaryIcon: "project-diagram",
              primaryText: `${p.name} (${o.name})`,
            } as CardListObject), //tslint:disable-line
        ),
      )}
    />
  );
};

export default ProjectsTab;
