import { Loading } from "@sandtable/component-library";
import { Environment, EnvironmentsMap } from "@sandtable/datastore";
import { EnvironmentsObject, PythonVersionToEnvironmentsObject } from "@sandtable/utils";
import DiffEnvironmentsDrawer from "components/Environments/DiffEnvironmentsDrawer/DiffEnvironmentsDrawer";
import EnvironmentsList from "components/Environments/EnvironmentsList";
import NewEnvironmentDrawer from "components/Environments/NewEnvironmentDrawer/NewEnvironmentDrawer";
import UpdateEnvironmentDrawer from "components/Environments/UpdateEnvironmentDrawer/UpdateEnvironmentDrawer";
import ViewEnvironmentDrawer from "components/Environments/ViewEnvironmentDrawer/ViewEnvironmentDrawer";
import ViewEnvironmentsDrawer from "components/Environments/ViewEnvironmentsDrawer";
import * as React from "react";
import { generatePath, Route, useHistory } from "react-router-dom";

const ENVIRONMENTS_PATH = "/environments";
const NEW_ENVIRONMENT_PATH = "/environments/new-environment";
const VIEW_ENVIRONMENTS_PATH = "/environments/view-environments/:name";
const VIEW_ENVIRONMENT_PATH = "/environments/view-environment/:uid";
const UPDATE_ENVIRONMENT_PATH = "/environments/update-environment/:uid";
const DIFF_ENVIRONMENTS_PATH = "/environments/diff-environments/:uid/:uid2";

interface Props {
  baseEnvironmentsObject: PythonVersionToEnvironmentsObject;
  addEnvironment: (e: Environment) => void;
  deleteEnvironment: (e: Environment) => void;
  environments: EnvironmentsMap;
  environmentsObject: EnvironmentsObject;
  isFetching: boolean;
  fetchEnvironments: (page?: number, perPage?: number, searchQuery?: string) => void;
  fetchEnvironmentLogs: (e: Environment) => void;
  updateEnvironment: (e: Environment) => void;
}

const Environments = (props: Props) => {
  const history = useHistory();

  const handleGoBack = () => history.push(ENVIRONMENTS_PATH);
  const handleNewEnvironmentClick = () => history.push(NEW_ENVIRONMENT_PATH);
  const handleEnvironmentsClick = (name: string) => history.push(generatePath(VIEW_ENVIRONMENTS_PATH, { name }));
  const handleEnvironmentSelect = (e: Environment) => history.push(generatePath(VIEW_ENVIRONMENT_PATH, { uid: e.uid }));
  const handleEnvironmentAdd = (e: Environment) => {
    handleGoBack();
    props.addEnvironment(e);
  };
  const handleDiffEnvironmentsClick = (uid: string, uid2: string) =>
    history.push(generatePath(DIFF_ENVIRONMENTS_PATH, { uid, uid2 }));
  const handleUpdateEnvironmentClick = (e: Environment) =>
    history.push(generatePath(UPDATE_ENVIRONMENT_PATH, { uid: e.uid }));
  const handleDeleteEnvironmentClick = (e: Environment) => {
    handleGoBack();
    props.deleteEnvironment(e);
  };
  const handleEnvironmentUpdate = (e: Environment) => {
    handleGoBack();
    props.updateEnvironment(e);
  };

  if (props.isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Route path={ENVIRONMENTS_PATH}>
        <EnvironmentsList
          {...props}
          onEnvironmentSelect={handleEnvironmentsClick}
          onNewEnvironmentClick={handleNewEnvironmentClick}
        />
      </Route>
      <Route path={NEW_ENVIRONMENT_PATH}>
        <NewEnvironmentDrawer {...props} onEnvironmentAdd={handleEnvironmentAdd} onClose={handleGoBack} />
      </Route>
      <Route path={VIEW_ENVIRONMENTS_PATH}>
        <ViewEnvironmentsDrawer
          {...props}
          onClose={handleGoBack}
          onDiffEnvironmentsClick={handleDiffEnvironmentsClick}
          onEnvironmentSelect={handleEnvironmentSelect}
          open={true}
        />
      </Route>
      <Route path={VIEW_ENVIRONMENT_PATH}>
        <ViewEnvironmentDrawer
          {...props}
          onDeleteEnvironmentClick={handleDeleteEnvironmentClick}
          onClose={handleGoBack} // TODO: Go back to "view environments"
          onUpdateEnvironmentClick={handleUpdateEnvironmentClick}
        />
      </Route>
      <Route path={UPDATE_ENVIRONMENT_PATH}>
        <UpdateEnvironmentDrawer
          {...props}
          onClose={handleGoBack} // TODO: Go back to "view environment"
          onEnvironmentUpdate={handleEnvironmentUpdate}
        />
      </Route>
      <Route path={DIFF_ENVIRONMENTS_PATH}>
        <DiffEnvironmentsDrawer
          {...props}
          open={true}
          onClose={handleGoBack} // TODO: Go back to "view environment"
        />
      </Route>
    </>
  );
};

export default Environments;
