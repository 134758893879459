import * as Diff from "diff";

interface TransformedDiff {
  added: boolean;
  removed: boolean;
  value: string;
}

export const diffEnvironmentRequirements = (reqsOld: string[], reqsNew: string[]): TransformedDiff[] => {
  const diff = Diff.diffArrays(reqsOld, reqsNew);
  const transformedDiff: TransformedDiff[] = [];
  diff.forEach((d: any) => {
    d.value.forEach((e: any) => {
      transformedDiff.push({
        added: d.added || false,
        removed: d.removed || false,
        value: e,
      });
    });
  });
  return transformedDiff;
};
