import { ProtectedComponent, useAnalytics } from "@sandtable/component-library";
import Deployments from "components/Deployments/Deployments";
import Applications from "containers/Applications/Applications";
import Clusters from "containers/Clusters/Clusters";
import Data from "containers/Data/Data";
import Environments from "containers/Environments/Environments";
import EnvironmentsV2 from "containers/EnvironmentsV2/EnvironmentsV2";
import Experiments from "containers/Experiments/Experiments";
import Management from "containers/Management/Management";
import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

interface RouteI {
  exact?: boolean;
  path: string;
  protected?: boolean;
  component: JSX.Element;
}

const BASE_PATH = "/";
const CLUSTERS_PATH = "/clusters";
const ENVIRONMENTS_PATH = "/environments";
const ENVIRONMENTS_V2_PATH = "/environments-v2";
const EXPERIMENTS_PATH = "/experiments";
const APPLICATIONS_PATH = "/applications";
const DEPLOYMENTS_PATH = "/deployments";
const MANAGEMENT_PATH = "/management";
const DATA_PATH = "/data";

const ROUTES: RouteI[] = [
  {
    component: <Redirect to={CLUSTERS_PATH} />,
    exact: true,
    path: BASE_PATH,
  },
  {
    component: <Clusters />,
    path: CLUSTERS_PATH,
  },
  {
    component: <Experiments />,
    path: EXPERIMENTS_PATH,
  },
  {
    component: <Environments />,
    path: ENVIRONMENTS_PATH,
    protected: true,
  },
  {
    component: <EnvironmentsV2 />,
    path: ENVIRONMENTS_V2_PATH,
    protected: true,
  },
  {
    component: <Applications />,
    path: APPLICATIONS_PATH,
    protected: true,
  },
  {
    component: <Deployments />,
    path: DEPLOYMENTS_PATH,
    protected: true,
  },
  {
    component: <Management />,
    path: MANAGEMENT_PATH,
    protected: true,
  },
  {
    component: <Data />,
    path: DATA_PATH,
    protected: true,
  },
];

const Routes = (): JSX.Element => {
  const analytics = useAnalytics();
  const location = useLocation();

  React.useEffect(() => {
    analytics.trackPageView(location.pathname);
  }, [location]);

  const routes = ROUTES.map((r: RouteI) => {
    const component = r.protected ? <ProtectedComponent {...r} redirectPath={CLUSTERS_PATH} /> : r.component;
    return <Route key={r.path} path={r.path} exact={r.exact} render={() => component} />;
  });
  return <>{routes}</>;
};

export default Routes;
