import { ViewDrawer } from "@sandtable/component-library";
import { Membership, Organisation, Project } from "@sandtable/datastore";
import MembersTab from "components/Management/ViewOrganisationDrawer/MembersTab";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  addMemberToProject: (organisationId: string, projectId: string, memberEmail: string) => any;
  onClose: () => void;
  organisations: Organisation[];
  removeMemberFromProject: (organisationId: string, projectId: string, memberId: string) => any;
  updateMemberOfProject: (organisationId: string, projectId: string, memberId: string, owner: boolean) => any;
}

const ViewProjectDrawer = (props: Props) => {
  const { id, pid } = useParams();
  const organisation = props.organisations.find((o: Organisation) => o.id === id)!; // TODO: Handle undefined
  const project = organisation.projects.find((p: Project) => p.id === pid)!; // TODO: Handle undefined

  return (
    <ViewDrawer
      onClose={props.onClose}
      maxWidth={"700px"}
      open={true}
      tabGaAction={"view-project-drawer-tabs"}
      tabContents={[
        <MembersTab
          {...props}
          key={"members-tab"}
          onMemberAdd={(email: string) => props.addMemberToProject(organisation.id, project.id, email)}
          onMemberDelete={(memberId: string) => props.removeMemberFromProject(organisation.id, project.id, memberId)}
          onMemberUpdate={(memberId: string, owner: boolean) =>
            props.updateMemberOfProject(organisation.id, project.id, memberId, owner)
          }
          memberships={project.memberships}
          membersToAdd={organisation.memberships.filter(
            (m: Membership) => project.memberships.findIndex((n: Membership) => m.email === n.email) === -1,
          )}
        />,
      ]}
      tabNames={["Members"]}
      title={"View Project"}
    />
  );
};

export default ViewProjectDrawer;
