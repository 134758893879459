import { Drawer as MDrawer, Typography as MTypography, WithStyles } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import { Icon } from "@sandtable/component-library";
import * as React from "react";
import "./Drawer.css";

const styles = createStyles({
  paper: {
    maxWidth: "100%",
  },
});

export interface Props extends WithStyles {
  children: any;
  onClose?: any;
  open: boolean;
  title: string;
}

const Drawer = (props: Props) => {
  return (
    <MDrawer {...props} anchor="right">
      <div className="drawer-header-container">
        <MTypography style={{ fontSize: "1em" }}>{props.title}</MTypography>
        <Icon icon="times" className="close-button" onIconClick={props.onClose} />
      </div>
      {props.children}
    </MDrawer>
  );
};

export default withStyles(styles)(Drawer);
