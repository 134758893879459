import { Info, Switch } from "@sandtable/component-library";
import { NewEnvironmentV2 } from "@sandtable/datastore";
import CodeSection from "components/EnvironmentsV2/NewEnvironmentV2Drawer/CodeSection";
import * as React from "react";
import semver from "semver";

interface Props {
  environment: NewEnvironmentV2;
  onChange: (e: Partial<NewEnvironmentV2>, error?: boolean) => void;
  showIsPublicSwitch?: boolean;
}

export const isCondaEnvironmentFileValid = (yaml: string) => {
  // 3.6.* or 3.6, ending in either newline or null:
  const isValidPythonVersion = !!yaml.match(/python=3.6(?:\.\d+)?(?!\S)/);
  // Allows for ".post" etc, as long as the version is valid:
  const sm2Version = yaml.match(/sandman2[=>]=(\d*\.\d*\.\d*)/);
  const isValidSm2Version = sm2Version && semver.valid(sm2Version[1]) && semver.gte(sm2Version[1], "3.21.13");
  return !isValidPythonVersion || !isValidSm2Version;
};

const EnvironmentV2YamlTab = (props: Props): JSX.Element => {
  return (
    <>
      <Info type={"info"} style={{ marginBottom: "24px" }}>
        <span>
          Conda YAML must include <span style={{ fontFamily: "courier" }}>python=3.6[.x]</span> and{" "}
          <span style={{ fontFamily: "courier" }}>sandman2>=3.21.13</span>
        </span>
      </Info>
      <CodeSection
        onChange={(condaEnvironmentFile: string) => {
          const error = isCondaEnvironmentFileValid(condaEnvironmentFile);
          props.onChange({ condaEnvironmentFile }, error);
        }}
        title={"Environment Conda YAML"}
        value={props.environment.condaEnvironmentFile!}
      />
      {props.showIsPublicSwitch && (
        <Switch
          checked={props.environment.isPublic}
          label="Public"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.onChange({ isPublic: !props.environment.isPublic })
          }
          tooltipLabel="Public environments can be viewed by users outside of your organisation."
          value={props.environment.isPublic}
          groupStyle={{ justifyContent: "center", marginBottom: "14px" }}
        />
      )}
    </>
  );
};

EnvironmentV2YamlTab.defaultProps = {
  showIsPublicSwitch: true,
};

export default EnvironmentV2YamlTab;
