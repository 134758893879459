import {
  Environment,
  ENVIRONMENT,
  ENVIRONMENT_ARRAY_AND_PAGINATION,
  ENVIRONMENT_LOGS,
  RECEIVE_ADD_ENVIRONMENT,
  RECEIVE_DELETE_ENVIRONMENT,
  RECEIVE_ENVIRONMENT_LOGS,
  RECEIVE_ENVIRONMENTS,
  RECEIVE_UPDATE_ENVIRONMENT,
  REQUEST_ADD_ENVIRONMENT,
  REQUEST_DELETE_ENVIRONMENT,
  REQUEST_ENVIRONMENT_LOGS,
  REQUEST_ENVIRONMENTS,
  REQUEST_UPDATE_ENVIRONMENT,
  SUBSCRIBE_ENVIRONMENT_UPDATES,
} from "@sandtable/datastore/environments";
import {
  API_DELETE,
  API_GET,
  API_POST,
  ApiMiddlewareAction,
  WS_SUBSCRIBE,
  WsMiddlewareAction,
} from "@sandtable/datastore/middleware";
import { enqueueSnackbarNotification, SnackbarNotificationActionTypes } from "@sandtable/datastore/snackbar";

export const fetchEnvironments = (page?: number, perPage?: number, searchQuery?: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    const params = {
      ...(page && { page }),
      ...(perPage && { perPage }),
      ...(searchQuery && { searchQuery }),
    };
    dispatch({
      [API_GET]: {
        endpoint: "environments",
        params,
        schema: ENVIRONMENT_ARRAY_AND_PAGINATION,
        types: [REQUEST_ENVIRONMENTS, RECEIVE_ENVIRONMENTS, RECEIVE_ENVIRONMENTS],
      },
    });
  };
};

export const deleteEnvironment = (environment: Environment) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_DELETE]: {
        endpoint: `environments/${environment.uid}`,
        failureMessage: "Unable to delete environment. Is it being used by a cluster?",
        successActionParams: {
          environmentId: environment.uid,
        },
        types: [REQUEST_DELETE_ENVIRONMENT, RECEIVE_DELETE_ENVIRONMENT, RECEIVE_DELETE_ENVIRONMENT],
      },
    });
  };
};

export const addEnvironment = (environment: Environment) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `environments/${environment.uid}`,
        params: {
          ...environment,
        },
        schema: ENVIRONMENT,
        types: [REQUEST_ADD_ENVIRONMENT, RECEIVE_ADD_ENVIRONMENT, RECEIVE_ADD_ENVIRONMENT],
      },
    });
  };
};

export const updateEnvironment = (environment: Environment) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `environments/${environment.uid}`,
        params: {
          ...environment,
        },
        schema: ENVIRONMENT,
        types: [REQUEST_UPDATE_ENVIRONMENT, RECEIVE_UPDATE_ENVIRONMENT, RECEIVE_UPDATE_ENVIRONMENT],
      },
    });
  };
};

export const subscribeToEnvironmentUpdates = (orgName: string) => {
  return (dispatch: (action: WsMiddlewareAction | SnackbarNotificationActionTypes) => void) => {
    dispatch({
      [WS_SUBSCRIBE]: {
        callback: (res: any) => {
          const e = Object.values(res.environments)[0] as Environment;
          const envName = e.name || "";
          const status = e.status || "updated";
          dispatch(
            enqueueSnackbarNotification({
              text: `Environment ${envName} ${status}.`,
            }),
          );
        },
        endpoint: "environments",
        room: orgName,
        schema: ENVIRONMENT,
        types: [SUBSCRIBE_ENVIRONMENT_UPDATES, RECEIVE_UPDATE_ENVIRONMENT, RECEIVE_UPDATE_ENVIRONMENT],
      },
    });
  };
};

export const fetchEnvironmentLogs = (environment: Environment) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: `environments/${environment.uid}/logs`,
        schema: ENVIRONMENT_LOGS,
        successActionParams: {
          environmentId: environment.uid,
        },
        types: [REQUEST_ENVIRONMENT_LOGS, RECEIVE_ENVIRONMENT_LOGS, RECEIVE_ENVIRONMENT_LOGS],
      },
    });
  };
};
