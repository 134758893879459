import { API_GET, ApiMiddlewareAction } from "@sandtable/datastore/middleware";
import { RECEIVE_REPOS, REFRESH_REPOS, REQUEST_REPOS } from "@sandtable/datastore/repos";
import { REPO_LIST } from "@sandtable/datastore/repos/types";

export const fetchRepos = (refresh: boolean = false) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: "repos",
        schema: REPO_LIST,
        types: [refresh ? REFRESH_REPOS : REQUEST_REPOS, RECEIVE_REPOS, RECEIVE_REPOS],
      },
    });
  };
};
