import { ViewDrawer } from "@sandtable/component-library";
import { PlatformUser } from "@sandtable/datastore";
import OrganisationsTab from "components/Management/ViewUserDrawer/OrganisationsTab";
import ProjectsTab from "components/Management/ViewUserDrawer/ProjectsTab";
import ResourcesTab from "components/Management/ViewUserDrawer/ResourcesTab";
import UserActionSection from "components/Management/ViewUserDrawer/UserActionSection";
import UserTab from "components/Management/ViewUserDrawer/UserTab";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  deactivateUser: (user: PlatformUser) => void;
  onClose: () => void;
  reactivateUser: (user: PlatformUser) => void;
  users: PlatformUser[];
}

const ViewUserDrawer = (props: Props) => {
  const { id } = useParams();
  const user = props.users.find((u: PlatformUser) => u.id === id)!; // TODO: Handle undefined

  return (
    <ViewDrawer
      {...props}
      actionSection={<UserActionSection {...props} user={user} />}
      open={true}
      title={"View User"}
      tabGaAction={"view-user-drawer-user-tab-tabs"}
      tabNames={["User", "Organisations", "Projects", "Resources"]}
      tabContents={[
        <UserTab key={"user-tab"} {...props} user={user} />,
        <OrganisationsTab key={"organisations-tab"} {...props} user={user} />,
        <ProjectsTab key={"projects-tab"} {...props} user={user} />,
        <ResourcesTab key={"resources-tab"} {...props} user={user} />,
      ]}
    />
  );
};

export default ViewUserDrawer;
