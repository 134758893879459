import { SectionListObject } from "@sandtable/component-library";
import SectionListServerSide from "@sandtable/component-library/components/SectionList/SectionListServerSide";
import { Environment } from "@sandtable/datastore";
import { PageableResource } from "@sandtable/datastore/pagination/types";
import { EnvironmentsObject, pluraliseString } from "@sandtable/utils";
import * as React from "react";

interface Props {
  addEnvironment: (e: Environment) => void;
  environmentsObject: EnvironmentsObject;
  fetchEnvironments: (page?: number, perPage?: number, searchQuery?: string) => void;
  onEnvironmentSelect: (name: string) => void;
  onNewEnvironmentClick: () => void;
}

const EnvironmentsList = (props: Props): JSX.Element => {
  let objects: SectionListObject[] = [];
  if (typeof props.environmentsObject !== "undefined") {
    objects = Object.keys(props.environmentsObject).map((environmentName: string) => {
      const env = props.environmentsObject[environmentName];
      const numberOfEnvs = env ? env.length : 0;
      const secondaryText = `${numberOfEnvs} ${pluraliseString("environment", numberOfEnvs)}`;
      return {
        data: { name: environmentName },
        gaAction: "environments-list-card",
        gaLabel: environmentName,
        onCardClick: () => props.onEnvironmentSelect(environmentName),
        primaryText: environmentName,
        secondaryText,
      } as SectionListObject; //tslint:disable-line
    });
  }

  return (
    <>
      <SectionListServerSide
        headerButtonText="New environment"
        objects={objects}
        onHeaderButtonClick={props.onNewEnvironmentClick}
        fetchResources={props.fetchEnvironments}
        searchKeys={["name"]}
        showSearch={true}
        serverSidePaginationResource={PageableResource.ENVIRONMENTS_V1}
      />
    </>
  );
};

export default EnvironmentsList;
