import { DrawerTable } from "@sandtable/component-library";
import { PlatformUser } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  user: PlatformUser;
}

const UserTab = (props: Props) => {
  const data = [
    { name: "ID", value: props.user.id },
    { name: "Active", value: !props.user.deactivated },
    { name: "Email", value: props.user.email },
    { name: "Name", value: props.user.name },
  ];
  return <DrawerTable tableData={data} tableKey={"user-table"} />;
};

export default UserTab;
