import { DrawerTable, Syntax, Tabs } from "@sandtable/component-library";
import { Application } from "@sandtable/datastore";
import { decamelizeKeys } from "humps";
import * as React from "react";

interface Props {
  application: Application;
}

const ApplicationTab = (props: Props): JSX.Element => {
  const data = [
    {
      name: "Application ID",
      value: props.application.id,
    },
    {
      name: "Created",
      value: props.application.created,
    },
    {
      name: "Creator",
      value: props.application.creator,
    },
    {
      name: "Description",
      value: props.application.description || "None",
    },
    {
      name: "Name",
      value: props.application.name,
    },
    {
      name: "Organisation",
      value: props.application.organisation,
    },
    {
      name: "Project",
      value: props.application.project,
    },
    {
      name: "Version",
      value: props.application.version,
    },
    {
      name: "Inputs are available",
      value: props.application.inputsAreAvailable,
    },
    {
      name: "Proto is available",
      value: props.application.protoIsAvailable,
    },
    {
      name: "Total executions",
      value: props.application.totalExecutions.toString(),
    },
  ];

  return (
    <>
      <DrawerTable tableData={data} tableKey={props.application.id} />
      <Tabs labels={["Requirements"]} />
      <Syntax text={JSON.stringify(decamelizeKeys(props.application.requirements), null, "\t")} mode="javascript" />
    </>
  );
};

export default ApplicationTab;
