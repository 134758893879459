import {
  RECEIVE_DELETE_UPLOAD,
  RECEIVE_UPLOAD_COMMIT,
  RECEIVE_UPLOADS,
  REQUEST_UPLOADS,
  UploadsActionTypes,
  UploadState,
} from "@sandtable/datastore/uploads";

const initialState: UploadState = {
  isFetching: true,
  uploads: {},
};

export const uploadsReducer = (state = initialState, action: UploadsActionTypes): UploadState => {
  switch (action.type) {
    case REQUEST_UPLOADS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_UPLOADS:
      return {
        ...state,
        isFetching: false,
        uploads: action.uploads || {},
      };
    case RECEIVE_DELETE_UPLOAD:
    case RECEIVE_UPLOAD_COMMIT:
      const newUploads = { ...state.uploads };
      delete newUploads[action.uploadId];
      return {
        ...state,
        uploads: newUploads,
      };
    default:
      return state;
  }
};
