import { Typography as MTypography } from "@material-ui/core";
import { Icon } from "@sandtable/component-library";
import * as React from "react";
import "./DrawerTable.css";

type DrawerTableRowData = string | boolean | JSX.Element;

export interface DrawerTableData {
  name: string;
  value: DrawerTableRowData;
}

interface Props {
  style?: object;
  tableData: DrawerTableData[];
  tableKey: string;
}

const DrawerTable = (props: Props): JSX.Element => {
  const data = props.tableData.map(({ name, value }) => {
    return (
      <tr key={props.tableKey + "-" + name}>
        <td>
          <MTypography style={{ color: "grey" }}>{name}</MTypography>
        </td>
        <td>
          <DrawerTableRowData value={value} />
        </td>
      </tr>
    );
  });
  return (
    <table className="section-content" style={props.style}>
      <tbody>{data}</tbody>
    </table>
  );
};

const DrawerTableRowData = (props: { value: DrawerTableRowData }) => {
  switch (typeof props.value) {
    case "string":
      return <MTypography>{props.value}</MTypography>;
    case "boolean":
      // TODO: Might need to restrict the height of the icon/this component:
      return <Icon icon="circle" className={props.value ? "running" : "stopped"} />;
    default:
      return <>{props.value}</>;
  }
};

export default DrawerTable;
