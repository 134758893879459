import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  Checkbox as MCheckbox,
  ListItem as MListItem,
  ListItemSecondaryAction as MListItemSecondaryAction,
  ListItemText as MListItemText,
  Tooltip as MTooltip,
  WithStyles,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import { Icon } from "@sandtable/component-library";
import * as React from "react";
import CardButton from "./CardButton";

const styles = createStyles({
  container: {
    listStyle: "none",
    maxHeight: "60px",
  },
  secondaryAction: {
    maxHeight: "60px",
    right: "8px",
  },
});

export interface Props extends WithStyles {
  checkboxDisabled?: boolean;
  checkboxTooltip?: string;
  gaAction: string;
  gaLabel?: string;
  keyValue?: number | string;
  mainComponent?: JSX.Element;
  onCardClick?: any;
  onCheckboxClick?: any;
  primaryIcon?: IconProp;
  primaryIconClassName?: string;
  primaryIconTooltip?: string;
  primaryText: string | JSX.Element;
  secondaryIcon?: IconProp;
  secondaryIconClick?: any;
  secondaryIconTooltip?: string;
  secondaryText?: string;
  tertiaryIcon?: IconProp;
  tertiaryIconClick?: any;
  tertiaryIconTooltip?: string;
}

const Card = (props: Props) => {
  const primaryIconElement = props.primaryIcon && (
    <div style={{ marginRight: "16px" }}>
      <Icon className={props.primaryIconClassName} icon={props.primaryIcon} tooltipText={props.primaryIconTooltip} />
    </div>
  );
  let secondaryIconElement = props.secondaryIcon && (
    <CardButton
      icon={props.secondaryIcon}
      onIconClick={props.secondaryIconClick}
      iconTooltip={props.secondaryIconTooltip}
    />
  );
  if (props.onCheckboxClick) {
    secondaryIconElement = (
      <MTooltip title={props.checkboxTooltip || ""}>
        <MCheckbox onChange={props.onCheckboxClick} disabled={props.checkboxDisabled} />
      </MTooltip>
    );
  }
  const tertiaryIconElement = props.tertiaryIcon && (
    <CardButton
      icon={props.tertiaryIcon}
      onIconClick={props.tertiaryIconClick}
      iconTooltip={props.tertiaryIconTooltip}
    />
  );
  const listItemSecondaryAction = (tertiaryIconElement || secondaryIconElement) && (
    <MListItemSecondaryAction>
      {tertiaryIconElement}
      {secondaryIconElement}
    </MListItemSecondaryAction>
  );
  return (
    <MListItem
      button={true}
      classes={props.classes}
      dense={true}
      key={props.keyValue}
      onClick={props.onCardClick}
      role={undefined}
      data-ga-event-category={window.location.pathname.slice(1)}
      data-ga-event-action={props.gaAction.toLowerCase()}
      data-ga-event-label={(props.gaLabel || "").toLowerCase()}
    >
      {primaryIconElement}
      <MListItemText primary={props.primaryText} secondary={props.secondaryText} />
      {props.mainComponent}
      {listItemSecondaryAction}
    </MListItem>
  );
};

export default withStyles(styles)(Card);
