export interface Upload {
  datetimeUploaded: string;
  fileSize: string;
  id: string;
  originalFilename: string;
  uploader: string;
}

export interface UploadMap {
  [id: string]: Upload;
}

export interface UploadState {
  isFetching: boolean;
  uploads: UploadMap;
}

export const REFRESH_UPLOADS = "REFRESH_UPLOADS";
export const REQUEST_UPLOADS = "REQUEST_UPLOADS";
export const RECEIVE_UPLOADS = "RECEIVE_UPLOADS";
export const REQUEST_UPLOAD_COMMIT = "REQUEST_UPLOAD_COMMIT";
export const RECEIVE_UPLOAD_COMMIT = "RECEIVE_UPLOAD_COMMIT";
export const REQUEST_DELETE_UPLOAD = "REQUEST_DELETE_UPLOAD";
export const RECEIVE_DELETE_UPLOAD = "RECEIVE_DELETE_UPLOAD";

interface RequestUploadsAction {
  type: typeof REQUEST_UPLOADS;
}

interface ReceiveUploadsAction {
  type: typeof RECEIVE_UPLOADS;
  uploads: UploadMap;
}

interface RequestUploadCommitAction {
  type: typeof REQUEST_UPLOAD_COMMIT;
}

interface ReceiveUploadCommitAction {
  type: typeof RECEIVE_UPLOAD_COMMIT;
  uploadId: string;
}

interface RequestDeleteUploadAction {
  type: typeof REQUEST_DELETE_UPLOAD;
}

interface ReceiveDeleteUploadAction {
  type: typeof RECEIVE_DELETE_UPLOAD;
  uploadId: string;
}

export type UploadsActionTypes =
  | RequestUploadsAction
  | ReceiveUploadsAction
  | ReceiveUploadCommitAction
  | RequestUploadCommitAction
  | RequestDeleteUploadAction
  | ReceiveDeleteUploadAction;
