import { Typography as MTypography } from "@material-ui/core";
import { Alert, TextField } from "@sandtable/component-library";
import { Cluster, User } from "@sandtable/datastore";
import { capitaliseString, injectElement, removeElement } from "@sandtable/utils";
import * as React from "react";

interface Props {
  action: "scale" | "start" | "restart" | "stop" | "delete" | "upgrade";
  cluster: Cluster;
  callback: (c: Cluster) => void;
  user: User;
}

export const openModifyClusterDialog = (props: Props) => {
  const dialog = <ModifyClusterDialog {...props} />;
  injectElement(dialog);
};

const ModifyClusterDialog = (props: Props) => {
  const [textboxCopy, setTextboxCopy] = React.useState("");
  const [cluster, setCluster] = React.useState<Cluster>(props.cluster);

  const isOwnCluster = cluster.owner === props.user.email;

  const requiresConfirmation = !isOwnCluster;
  const isConfirmed = textboxCopy !== cluster.owner;
  const confirmation = (
    <>
      <MTypography style={{ marginTop: "14px" }}>
        Please enter the email address of the owner ({cluster.owner}) to confirm.
      </MTypography>
      <TextField
        style={{ marginTop: "22px" }}
        defaultValue={""}
        label="Owner email address"
        onChange={(e: any) => setTextboxCopy(e.target.value)}
        placeholder="Owner email address"
      />
    </>
  );

  const setClusterInstances = React.useCallback((instances: number) => {
    setCluster((prev: Cluster) => ({ ...prev, instances: instances || 0 })); // || 0 handles NaNs
  }, []);
  const selectNInstances = (
    <>
      <MTypography style={{ marginTop: "14px" }}>
        Please enter the number of instances to {props.action} the cluster {props.action === "scale" ? "to" : "with"}.
      </MTypography>
      <TextField
        numerical={true}
        style={{ marginTop: "22px" }}
        defaultValue={cluster.instances}
        label="Number of instances"
        onChange={(e: any) => setClusterInstances(parseInt(e.target.value, 10))}
        placeholder="Number of instances"
      />
    </>
  );

  const setEnvironmentId = React.useCallback((environmentId: string) => {
    setCluster((prev: Cluster) => ({ ...prev, environmentId }));
  }, []);
  const setEnvironment = (
    <>
      <MTypography style={{ marginTop: "14px" }}>Please enter the ID of the environment.</MTypography>
      <TextField
        style={{ marginTop: "22px" }}
        defaultValue={cluster.environmentId}
        label="Environment ID"
        onChange={(e: any) => setEnvironmentId(e.target.value)}
        placeholder="Environment ID"
      />
    </>
  );

  const alertContent = (
    <>
      <MTypography>{`This action will ${props.action} cluster ${cluster.masterDomainName}, ${
        !isOwnCluster ? "which is not" : ""
      } owned by you.`}</MTypography>
      {requiresConfirmation && confirmation}
      {(props.action === "scale" || props.action === "start") && selectNInstances}
      {props.action === "upgrade" && setEnvironment}
    </>
  );
  return (
    <Alert
      content={alertContent}
      disableYesButton={(requiresConfirmation && isConfirmed) || !cluster.instances || !cluster.environmentId}
      handleClose={removeElement}
      handleSuccess={() => props.callback(cluster)}
      open={true}
      title={`${capitaliseString(props.action)} cluster`}
      noButtonCopy="Cancel"
      yesButtonCopy={`${props.action} cluster`}
    />
  );
};

export default ModifyClusterDialog;
