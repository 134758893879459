import { ViewDrawer } from "@sandtable/component-library";
import { Environment, EnvironmentsMap } from "@sandtable/datastore";
import EnvironmentActionSection from "components/Environments/ViewEnvironmentDrawer/EnvironmentActionSection";
import EnvironmentDependenciesTab from "components/Environments/ViewEnvironmentDrawer/EnvironmentDependenciesTab";
import EnvironmentDockerfileTab from "components/Environments/ViewEnvironmentDrawer/EnvironmentDockerfileTab";
import EnvironmentLogsTab from "components/Environments/ViewEnvironmentDrawer/EnvironmentLogsTab";
import EnvironmentPackageListTab from "components/Environments/ViewEnvironmentDrawer/EnvironmentPackageListTab";
import EnvironmentTab from "components/Environments/ViewEnvironmentDrawer/EnvironmentTab";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  environments: EnvironmentsMap;
  fetchEnvironmentLogs: (e: Environment) => void;
  onClose: () => void;
  onDeleteEnvironmentClick: (e: Environment) => void;
  onUpdateEnvironmentClick: (e: Environment) => void;
}

const ViewEnvironmentDrawer = (props: Props): JSX.Element => {
  const { uid } = useParams();
  const environment = props.environments[uid!]; // TODO: Handle undefined

  return (
    <ViewDrawer
      actionSection={<EnvironmentActionSection {...props} environment={environment} />}
      onClose={props.onClose}
      maxWidth={"800px"}
      open={true}
      tabGaAction={"view-environment-drawer-tabs"}
      tabContents={[
        <EnvironmentTab {...props} key={"environment-tab"} environment={environment} />,
        <EnvironmentDockerfileTab {...props} key={"dockerfile-tab"} environment={environment} />,
        <EnvironmentDependenciesTab {...props} key={"dependencies-tab"} environment={environment} />,
        <EnvironmentPackageListTab {...props} key={"package-list-tab"} environment={environment} />,
        <EnvironmentLogsTab {...props} key={"logs-tab"} environment={environment} />,
      ]}
      tabNames={["Environment", "Dockerfile", "Dependencies", "Packages", "Logs"]}
      title={"View Environment"}
    />
  );
};

export default ViewEnvironmentDrawer;
