import { MuiThemeProvider } from "@material-ui/core";
import { Theme } from "@sandtable/component-library";
import * as React from "react";

interface Props {
  children: JSX.Element;
}

const ThemeProvider = (props: Props) => {
  return <MuiThemeProvider {...props} theme={Theme} />;
};

export default ThemeProvider;
