import { Card, CardListObject } from "@sandtable/component-library";
import * as React from "react";

interface Props {
  object: CardListObject;
}

const CardListRow = (props: Props) => <Card {...props.object} />;

export default CardListRow;
