import { Snackbar } from "@sandtable/component-library";
import { SnackbarNotification } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  notifications: SnackbarNotification[];
  removeSnackbarNotification: (n: SnackbarNotification) => any;
}

const Notifier = (props: Props) => {
  if (!props.notifications.length) {
    return <></>;
  }
  const notification = props.notifications[0];
  return (
    <Snackbar
      key={notification.key}
      handleClose={() => props.removeSnackbarNotification(notification)}
      open={!!props.notifications.length}
      message={notification.text}
    />
  );
};

export default Notifier;
