export interface Execution {
  applicationId: string;
  applicationName: string;
  applicationVersion: string;
  clusterId: string;
  counterId: number;
  created: Date;
  creator: string;
  error: string;
  finished: Date;
  id: string;
  organisation: string;
  parameters: JSON;
  progress: number;
  project: string;
  results: JSON[];
  started: Date;
  status: string;
}

export interface ExecutionMap {
  [id: string]: Execution;
}

export interface ApplicationExecutionMap {
  [applicationId: string]: ExecutionMap;
}

// Map application ID to its executions:
export interface ExecutionState {
  executions: ApplicationExecutionMap;
}

export const RECEIVE_EXECUTIONS_FOR_APP = "RECEIVE_EXECUTIONS_FOR_APP";
export const REQUEST_EXECUTIONS_FOR_APP = "REQUEST_EXECUTIONS_FOR_APP";
export const RECEIVE_DELETE_EXECUTION = "RECEIVE_DELETE_EXECUTION";
export const REQUEST_DELETE_EXECUTION = "REQUEST_DELETE_EXECUTION";

interface RequestExecutionAction {
  type: typeof REQUEST_EXECUTIONS_FOR_APP;
}

interface ReceiveExecutionAction {
  applicationId: string;
  type: typeof RECEIVE_EXECUTIONS_FOR_APP;
  executions: ExecutionMap;
}

interface RequestDeleteExecutionAction {
  type: typeof REQUEST_DELETE_EXECUTION;
}

interface ReceiveDeleteExecutionAction {
  applicationId: string;
  executionId: string;
  type: typeof RECEIVE_DELETE_EXECUTION;
}

export type ExecutionActionTypes =
  | RequestExecutionAction
  | ReceiveExecutionAction
  | RequestDeleteExecutionAction
  | ReceiveDeleteExecutionAction;
