import { TablePagination as MTablePagination } from "@material-ui/core";
import { CardList, CardListObject } from "@sandtable/component-library";
import * as React from "react";

interface Props {
  fetchResources: (page?: number, perPage?: number, stringQuery?: string) => void;
  objects: CardListObject[];
  serverSidePaginationResource: string;
  page: number;
  perPage: number;
  count: number;
  searchQuery: string;
}

const PaginatedCardListServerSide = (props: Props) => {
  const PAGE_DEFAULT = 1;
  const TABLE_STYLES: React.CSSProperties = { display: "flex", flexDirection: "row-reverse" };
  const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];
  const { fetchResources } = props;
  const { page, perPage, count, objects, searchQuery } = props;

  const handleChangePage = (newPage: number) => {
    fetchResources(newPage + 1, perPage, searchQuery);
  };

  const handleChangeRowsPerPage = (newPerPage: string) => {
    fetchResources(PAGE_DEFAULT, parseInt(newPerPage, 10), searchQuery);
  };

  return (
    <>
      <CardList objects={objects} />
      <table style={TABLE_STYLES}>
      <tbody>
        <tr>
          <MTablePagination
            count={count || 0}
            onChangePage={(event, page) => handleChangePage(page)}
            onChangeRowsPerPage={(event) => handleChangeRowsPerPage(event.target.value)}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={page - 1 || 0}
            rowsPerPage={perPage || 0}
          />
        </tr>
      </tbody>
    </table>
    </>
  );
};

export default PaginatedCardListServerSide;
