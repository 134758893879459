import { Loading } from "@sandtable/component-library";
import { Application, ApplicationExecutionMap, ApplicationMap, Execution } from "@sandtable/datastore";
import ApplicationsList from "components/Applications/ApplicationsList";
import ViewApplicationDrawer from "components/Applications/ViewApplicationDrawer/ViewApplicationDrawer";
import ViewExecutionDrawer from "components/Applications/ViewExecutionDrawer/ViewExecutionDrawer";
import * as React from "react";
import { generatePath, Route, useHistory } from "react-router-dom";

const APPLICATIONS_PATH = "/applications";
const VIEW_APPLICATION_PATH = "/applications/view-application/:applicationId";
const VIEW_EXECUTION_PATH = "/applications/view-application/:applicationId/view-execution/:executionId";

interface Props {
  addApplicationUser: (application: Application, userEmail: string) => void;
  applications: ApplicationMap;
  deleteApplication: (application: Application) => void;
  deleteExecution: (execution: Execution) => void;
  executions: ApplicationExecutionMap;
  fetchApplications: () => void;
  fetchExecutionsForApplication: (applicationId: string) => void;
  inheritApplicationUsers: (application: Application, fromApplicationId: string) => void;
  isFetching: boolean;
  onApplicationLoad: (applicationId: string) => void;
  removeApplicationUser: (application: Application, userEmail: string) => void;
}

const Applications = (props: Props) => {
  const history = useHistory();

  const handleGoBack = React.useCallback(() => history.push(APPLICATIONS_PATH), [history]);
  const handleApplicationSelect = React.useCallback(
    (applicationId: string) => history.push(generatePath(VIEW_APPLICATION_PATH, { applicationId })),
    [history],
  );
  const handleExecutionSelect = React.useCallback(
    (e: Execution) =>
      history.push(generatePath(VIEW_EXECUTION_PATH, { applicationId: e.applicationId, executionId: e.id })),
    [history],
  );

  if (props.isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Route path={APPLICATIONS_PATH}>
        <ApplicationsList {...props} onApplicationSelect={handleApplicationSelect} />
      </Route>
      <Route path={VIEW_APPLICATION_PATH} exact={true}>
        <ViewApplicationDrawer {...props} onExecutionSelect={handleExecutionSelect} onClose={handleGoBack} />
      </Route>
      <Route path={VIEW_EXECUTION_PATH}>
        <ViewExecutionDrawer {...props} onClose={handleApplicationSelect} />
      </Route>
    </>
  );
};

export default Applications;
