import { DrawerTable, Syntax, Tabs } from "@sandtable/component-library";
import { Execution } from "@sandtable/datastore";
import { capitaliseString } from "@sandtable/utils";
import * as React from "react";

interface Props {
  execution: Execution | undefined;
}

const ExecutionBody = (props: Props) => {
  const [subTab, setSubTab] = React.useState(0);

  const execution = props.execution;

  if (!execution) {
    return <></>;
  }

  const data = [
    {
      name: "Execution ID",
      value: execution.id,
    },
    {
      name: "Status",
      value: capitaliseString(execution.status),
    },
    {
      name: "Application version",
      value: execution.applicationVersion,
    },
    {
      name: "Cluster ID",
      value: execution.clusterId,
    },
    {
      name: "Created",
      value: new Date(execution.created).toUTCString(),
    },
    {
      name: "Started",
      value: new Date(execution.started).toUTCString(),
    },
    {
      name: "Finished",
      value: new Date(execution.finished).toUTCString(),
    },
    {
      name: "Progress",
      value: execution.progress ? execution.progress.toString() : "N/A",
    },
  ];

  const resultsString = execution.results
    ? execution.results.map((r: any) => JSON.stringify(r, null, "\t")).join("\n\n")
    : "N/A";
  const parametersSyntax = <Syntax text={JSON.stringify(execution.parameters, null, "\t")} mode="javascript" />;
  const errorResultsSyntax = <Syntax text={execution.error || resultsString} mode="javascript" />;

  return (
    <>
      <DrawerTable tableData={data} tableKey={execution.id} />
      <Tabs
        value={subTab}
        labels={["Parameters", execution.error ? "Error" : "Results"]}
        onChange={setSubTab}
        gaAction={"view-application-drawer-execution-body-tabs"}
      />
      {subTab === 0 ? parametersSyntax : errorResultsSyntax}
    </>
  );
};

export default ExecutionBody;
