import {
  ENQUEUE_SNACKBAR_NOTIFICATION,
  REMOVE_SNACKBAR_NOTIFICATION,
  SnackbarNotification,
  SnackbarNotificationActionTypes,
} from "@sandtable/datastore/snackbar";

export const enqueueSnackbarNotification = (notification: SnackbarNotification): SnackbarNotificationActionTypes => {
  return {
    item: {
      key: new Date().getTime() + Math.random(),
      ...notification,
    },
    type: ENQUEUE_SNACKBAR_NOTIFICATION,
  };
};

export const removeSnackbarNotification = (notification: SnackbarNotification): SnackbarNotificationActionTypes => {
  if (!notification) {
    throw new Error("Notification does not exist");
  }
  if (notification.key === undefined) {
    throw new Error("Notification does not have a key");
  }
  return {
    key: notification.key,
    type: REMOVE_SNACKBAR_NOTIFICATION,
  };
};
