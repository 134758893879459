import { schema } from "normalizr";

const experimentSchema = new schema.Entity(
  "experiments",
  {},
  {
    idAttribute: (experiment: any) => experiment.id,
  },
);

export const EXPERIMENT = experimentSchema;
export const EXPERIMENT_ARRAY = [experimentSchema];
