import { Button, Icon, Search } from "@sandtable/component-library";
import * as React from "react";
import "./Header.css";

const REFRESH_ICON_STYLES: React.CSSProperties = {
  color: "dimgray",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginRight: "14px",
};

export interface HeaderProps {
  buttonText?: string;
  handleButtonClick?: () => void;
  handleSearch?: (v: string) => void;
  handleRefreshIconClick: ((page?: number, perPage?: number, searchQuery?: string) => void) | (() => void);
  showSearch?: boolean;
  searchQuery?: string;
  searchTooltipText?: string;
}

const HeaderServerSide = (props: HeaderProps) => {
  const PAGE_DEFAULT = 1;
  const PER_PAGE_DEFAULT = 10;
  return (
    <div className="header">
      {props.buttonText && (
        <Button
          text={props.buttonText}
          onButtonClick={props.handleButtonClick}
          gaAction={`header-${props.buttonText.split(" ").join("-")}`.toLowerCase()}
        />
      )}
      {props.showSearch
        &&
        <Search
          className="input"
          searchQuery={props.searchQuery}
          handleSearch={props.handleSearch}
          tooltipText={props.searchTooltipText || ""}
        />
      }
      {props.handleRefreshIconClick && (
        <div onClick={() => props.handleRefreshIconClick(PAGE_DEFAULT, PER_PAGE_DEFAULT)} style={REFRESH_ICON_STYLES}>
          <Icon container={false} gaAction="header-refresh" icon="redo" tooltipText="Refresh" />
        </div>
      )}
    </div>
  );
};

HeaderServerSide.defaultProps = {
  showSearch: true,
};

export default HeaderServerSide;
