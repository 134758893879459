import { APPLICATION_USER, APPLICATION_USER_ARRAY } from "@sandtable/datastore/applications/schema";
import {
  Deployment,
  DEPLOYMENT_SCHEMA,
  EXECUTION_V2_SCHEMA,
  ExecutionV2,
  RECEIVE_ADD_DEPLOYMENT_USER,
  RECEIVE_DELETE_DEPLOYMENT,
  RECEIVE_DELETE_EXECUTION_V2,
  RECEIVE_DEPLOYMENT_USERS,
  RECEIVE_DEPLOYMENTS,
  RECEIVE_EXECUTIONS,
  RECEIVE_REMOVE_DEPLOYMENT_USER,
  REQUEST_ADD_DEPLOYMENT_USER,
  REQUEST_DELETE_DEPLOYMENT,
  REQUEST_DELETE_EXECUTION_V2,
  REQUEST_DEPLOYMENT_USERS,
  REQUEST_DEPLOYMENTS,
  REQUEST_EXECUTIONS,
  REQUEST_REMOVE_DEPLOYMENT_USER,
} from "@sandtable/datastore/deployments";
import { API_DELETE, API_GET, API_POST, ApiMiddlewareAction } from "@sandtable/datastore/middleware";

export const fetchDeployments = () => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: "/deployments",
        schema: [DEPLOYMENT_SCHEMA],
        types: [REQUEST_DEPLOYMENTS, RECEIVE_DEPLOYMENTS, RECEIVE_DEPLOYMENTS],
      },
    });
  };
};

export const fetchDeploymentExecutions = (d: Deployment) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: `/deployments/${d.id}/executions`,
        schema: [EXECUTION_V2_SCHEMA],
        successActionParams: {
          deploymentId: d.id,
        },
        types: [REQUEST_EXECUTIONS, RECEIVE_EXECUTIONS, RECEIVE_EXECUTIONS],
      },
    });
  };
};

export const deleteDeployment = (d: Deployment) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_DELETE]: {
        endpoint: `/deployments/${d.id}`,
        successActionParams: {
          deploymentId: d.id,
        },
        types: [REQUEST_DELETE_DEPLOYMENT, RECEIVE_DELETE_DEPLOYMENT, "RECEIVE_DELETE_DEPLOYMENT_FAILURE"],
      },
    });
  };
};

export const deleteExecutionV2 = (d: Deployment, e: ExecutionV2) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `/deployments/${d.id}/executions/${e.id}/delete`,
        successActionParams: {
          deploymentId: d.id,
          executionId: e.id,
        },
        types: [REQUEST_DELETE_EXECUTION_V2, RECEIVE_DELETE_EXECUTION_V2, "RECEIVE_DELETE_EXECUTION_V2_FAILURE"],
      },
    });
  };
};

export const fetchDeploymentUsers = (d: Deployment) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: `/deployments/${d.id}/permissions`,
        schema: APPLICATION_USER_ARRAY,
        successActionParams: {
          deploymentId: d.id,
        },
        types: [REQUEST_DEPLOYMENT_USERS, RECEIVE_DEPLOYMENT_USERS, "RECEIVE_DEPLOYMENT_USERS_FAILURE"],
      },
    });
  };
};

export const addDeploymentUser = (d: Deployment, userEmail: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `/deployments/${d.id}/permissions`,
        params: {
          orgName: d.organisation,
          projName: d.project,
          userEmail,
        },
        schema: APPLICATION_USER,
        successActionParams: {
          deploymentId: d.id,
        },
        types: [REQUEST_ADD_DEPLOYMENT_USER, RECEIVE_ADD_DEPLOYMENT_USER, "RECEIVE_ADD_DEPLOYMENT_USER_FAILURE"],
      },
    });
  };
};

export const removeDeploymentUser = (d: Deployment, userEmail: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_DELETE]: {
        endpoint: `/deployments/${d.id}/permissions`,
        params: {
          orgName: d.organisation,
          projName: d.project,
          userEmail,
        },
        successActionParams: {
          deploymentId: d.id,
          userEmail,
        },
        types: [
          REQUEST_REMOVE_DEPLOYMENT_USER,
          RECEIVE_REMOVE_DEPLOYMENT_USER,
          "RECEIVE_REMOVE_DEPLOYMENT_USER_FAILURE",
        ],
      },
    });
  };
};
