import { SectionList, SectionListObject } from "@sandtable/component-library";
import { PlatformUser } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  fetchUsers: () => void;
  users: PlatformUser[];
  onUserSelect: (u: PlatformUser) => void;
}

const UsersList = (props: Props) => {
  let objects: SectionListObject[] = [];
  if (typeof props.users !== "undefined") {
    objects = props.users.map((u: PlatformUser, i: number) => {
      return {
        data: u,
        gaAction: "users-list-card",
        gaLabel: u.name,
        onCardClick: () => props.onUserSelect(u),
        primaryText: `${u.name} • ${u.email} ${u.deactivated ? " (deactivated)" : ""}`,
      } as SectionListObject; //tslint:disable-line
    });
  }

  return (
    <SectionList
      objects={objects}
      onHeaderButtonClick={() => {}} //tslint:disable-line
      fetchResources={() => props.fetchUsers()}
      searchKeys={["email", "name"]}
      showSearch={true}
    />
  );
};

export default UsersList;
