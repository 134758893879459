import { SectionTreeView, SectionTreeViewObject } from "@sandtable/component-library";
import { RepoMap, RepoObject } from "@sandtable/datastore";
import { generateRecursiveRepoMap, RecursiveRepo, RecursiveRepoMap } from "@sandtable/utils";
import * as React from "react";

interface Props {
  fetchRepos: () => void;
  repos: RepoMap;
}

// TODO: Move to util or similar when needed elsewhere.
const generateTreeViewObjectsFromRepoMap = (repoMap: RepoMap): SectionTreeViewObject[] => {
  const generateTreeViewObjectsFromRecursiveRepoMap = (recursiveRepoMap: RecursiveRepoMap): SectionTreeViewObject[] =>
    Object.values(recursiveRepoMap).map((repo: RecursiveRepo) => ({
      children: [
        ...generateTreeViewObjectsFromRecursiveRepoMap(repo.subDirs),
        ...repo.objects.map((r: RepoObject): SectionTreeViewObject => ({ id: r.name, name: r.name })),
      ],
      data: {
        files: repo.fileNames,
        name: repo.name,
      },
      id: repo.name,
      name: repo.name,
    }));
  return generateTreeViewObjectsFromRecursiveRepoMap(generateRecursiveRepoMap(repoMap));
};

const RepoList = (props: Props) => {
  const { repos } = props;
  const objects: SectionTreeViewObject[] = React.useMemo(() => generateTreeViewObjectsFromRepoMap(repos), [repos]);

  return (
    <SectionTreeView
      objects={objects}
      fetchResources={props.fetchRepos}
      searchKeys={["name", "files"]}
      showSearch={true}
    />
  );
};

export default RepoList;
