import {
  FormControl as MFormControl,
  InputLabel as MInputLabel,
  MenuItem as MMenuItem,
  OutlinedInput as MOutlinedInput,
  Select as MSelect,
  WithStyles,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import * as React from "react";

const styles = createStyles({
  disabled: {
    backgroundColor: "lightgray",
  },
});

interface Value {
  value?: number | string;
}

interface Option extends Value {
  text: string;
}

export interface Props extends Partial<Value>, WithStyles {
  className?: string;
  fullWidth?: boolean; // TODO: deprecate?
  insertNoneOption?: boolean;
  label: string;
  labelWidth: number;
  name?: string;
  onChange: any;
  options: Option[];
  style?: React.CSSProperties;
}

const Select = ({
  label,
  labelWidth,
  options,
  name,
  className,
  insertNoneOption = true,
  onChange,
  value = "",
  fullWidth = true,
  style,
  classes,
}: Props) => {
  if (insertNoneOption) {
    options.unshift({ text: "None", value: "" });
  }
  const menuItems = options.map((option: Option, key: number) => {
    return (
      <MMenuItem value={option.value ? option.value : option.text} key={key}>
        {option.text}
      </MMenuItem>
    );
  });
  const outlinedInput = <MOutlinedInput labelWidth={labelWidth} />;
  return (
    <>
      <MFormControl className={className} variant="outlined" fullWidth={fullWidth} style={style}>
        <MInputLabel>{label}</MInputLabel>
        <MSelect
          classes={classes}
          disabled={!options.length}
          input={outlinedInput}
          name={name}
          onChange={onChange}
          value={value}
          fullWidth={fullWidth}
        >
          {menuItems}
        </MSelect>
      </MFormControl>
    </>
  );
};

export default withStyles(styles)(Select);
