import { AnalyticsProvider, Spinner, ThemeProvider, useAuth, UserProvider } from "@sandtable/component-library";
import { AppState, fetchUser, User } from "@sandtable/datastore";
import Nav from "components/Nav/Nav";
import SideNav from "components/SideNav/SideNav";
import Notifier from "containers/Notifier/Notifier";
import * as React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "Routes";
import UserOnboardingGuard from "UserOnboardingGuard";

interface Props {
  fetchUser: () => void;
  isFetching: boolean;
  user: User;
}

const App = (props: Props) => {
  return (
    <AuthGuard onLogin={props.fetchUser}>
      <ThemeProvider>
        <SandtableSpinner show={props.isFetching}>
          <UserProvider user={props.user}>
            <AnalyticsProvider user={props.user}>
              <UserOnboardingGuard user={props.user}>
                <>
                  <Notifier />
                  <Router>
                    <div className="root">
                      <Nav user={props.user} />
                      <div className="container">
                        <SideNav />
                        <main className="main-container">
                          <Routes />
                        </main>
                      </div>
                    </div>
                  </Router>
                </>
              </UserOnboardingGuard>
            </AnalyticsProvider>
          </UserProvider>
        </SandtableSpinner>
      </ThemeProvider>
    </AuthGuard>
  );
};

const AuthGuard = (props: { onLogin: () => void; children: JSX.Element }) => {
  const { loading, isAuthenticated, login } = useAuth();
  const { onLogin } = props;

  React.useEffect(() => {
    if (loading) {
      return;
    }

    if (!isAuthenticated) {
      login();
    } else {
      onLogin();
    }
  }, [loading, isAuthenticated]);

  if (!isAuthenticated) {
    return <></>;
  }

  return props.children;
};

const SandtableSpinner = (props: { show: boolean; children: JSX.Element }) => {
  if (props.show) {
    return <Spinner type="sandtable" />;
  }
  return props.children;
};

const mapStateToProps = (state: AppState) => {
  return {
    isFetching: state.user.isFetching,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  fetchUser,
})(App);
