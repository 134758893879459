import { ViewDrawer } from "@sandtable/component-library";
import { Cluster, ClusterMap, ConfigurationMap } from "@sandtable/datastore";
import ClusterActionSection from "components/Clusters/ViewClusterDrawer/ClusterActionSection";
import ClusterConfigurationTab from "components/Clusters/ViewClusterDrawer/ClusterConfigurationTab";
import ClusterLogsTab from "components/Clusters/ViewClusterDrawer/ClusterLogsTab";
import ClusterMonitoringTab from "components/Clusters/ViewClusterDrawer/ClusterMonitoringTab";
import ClusterTab from "components/Clusters/ViewClusterDrawer/ClusterTab";
import * as React from "react";
import { useParams } from "react-router-dom";

export interface Props {
  clusters: ClusterMap;
  configurations: ConfigurationMap;
  deleteCluster: (c: Cluster) => void;
  fetchClusterLogs: (c: Cluster) => void;
  onClose: () => void;
  restartCluster: (c: Cluster) => void;
  scaleCluster: (c: Cluster) => void;
  startCluster: (c: Cluster) => void;
  stopCluster: (c: Cluster) => void;
  updateCluster: (c: Cluster) => void;
  upgradeCluster: (c: Cluster) => void;
}

const ViewClusterDrawer = (props: Props) => {
  const { clusterId } = useParams();

  const cluster = props.clusters[clusterId!]; // TODO: Handle undefined
  const configuration = props.configurations[cluster.clusterConfiguration]; // TODO: Handle undefined

  return (
    <>
      <ViewDrawer
        actionSection={<ClusterActionSection {...props} cluster={cluster} />}
        onClose={props.onClose}
        open={true}
        tabContents={[
          <ClusterMonitoringTab {...props} key={"monitoring-tab"} cluster={cluster} />,
          <ClusterTab {...props} key={"cluster-tab"} cluster={cluster} />,
          <ClusterConfigurationTab
            {...props}
            key={"configuration-tab"}
            cluster={cluster}
            configuration={configuration}
          />,
          <ClusterLogsTab {...props} key={"logs-tab"} cluster={cluster} />,
        ]}
        tabGaAction={"view-cluster-drawer-tabs"}
        tabNames={["Monitoring", "Cluster", "Configuration", "Logs"]}
        title={"View Cluster"}
      />
    </>
  );
};

export default ViewClusterDrawer;
