import { RECEIVE_ACCEPT_TERMS, RECEIVE_USER, UserActionTypes, UserState } from "@sandtable/datastore/user";

export const initialState: UserState = {
  isFetching: true,
  user: {
    credentialId: "",
    credentialSecret: "",
    downloadUrl: "",
    email: "",
    emailVerified: false,
    name: "",
    organisation: {
      name: "",
      projects: [],
    },
    picture: "",
    redeemedCode: false,
    superAdmin: false,
    termsAgreed: false,
    visibleRoutes: [],
  },
};

export const userReducer = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case RECEIVE_USER:
      const users = Object.values(action.user);
      if (users.length !== 1) {
        throw new Error("Invalid no. users.");
      }
      return { ...state, user: users[0], isFetching: false };
    case RECEIVE_ACCEPT_TERMS:
      return {
        ...state,
        user: {
          ...state.user,
          termsAgreed: true,
        },
      };
    default:
      return state;
  }
};
