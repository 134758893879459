import { FormGroup as MFormGroup } from "@material-ui/core";
import { Button, useUser } from "@sandtable/component-library";
import { Cluster } from "@sandtable/datastore";
import { openModifyClusterDialog } from "components/Clusters/ViewClusterDrawer/ModifyClusterDialog";
import * as React from "react";

interface Props {
  cluster: Cluster;
  deleteCluster: (c: Cluster) => void;
  restartCluster: (c: Cluster) => void;
  scaleCluster: (c: Cluster) => void;
  startCluster: (c: Cluster) => void;
  stopCluster: (c: Cluster) => void;
  updateCluster: (c: Cluster) => void;
  upgradeCluster: (c: Cluster) => void;
}

const ClusterActionSection = (props: Props) => {
  const user = useUser();

  const { cluster } = props;

  // TODO: Move these to the Clusters container? Not sure.
  const scaleCluster = () =>
    openModifyClusterDialog({
      action: "scale",
      callback: props.scaleCluster,
      cluster,
      user,
    });
  const upgradeCluster = () =>
    openModifyClusterDialog({
      action: "upgrade",
      callback: props.upgradeCluster,
      cluster,
      user,
    });
  const startCluster = () =>
    openModifyClusterDialog({
      action: "start",
      callback: props.startCluster,
      cluster,
      user,
    });
  const stopCluster = () =>
    openModifyClusterDialog({
      action: "stop",
      callback: props.stopCluster,
      cluster,
      user,
    });
  const restartCluster = () =>
    openModifyClusterDialog({
      action: "restart",
      callback: props.restartCluster,
      cluster,
      user,
    });
  const deleteCluster = () =>
    openModifyClusterDialog({
      action: "delete",
      callback: props.deleteCluster,
      cluster,
      user,
    });

  return (
    <>
      <MFormGroup row={true} style={{ marginBottom: "10px" }}>
        <Button
          disabled={cluster.status !== "available"}
          gaAction={"view-cluster-drawer-action-section-scale"}
          icon="arrow-circle-up"
          onButtonClick={scaleCluster}
          text={"Scale Instances"}
          style={{ flex: 1, marginRight: "5px" }}
        />
        <Button
          disabled={cluster.status !== "available"}
          gaAction={"view-cluster-drawer-action-section-upgrade-environment"}
          icon="arrow-circle-up"
          onButtonClick={upgradeCluster}
          text={"Upgrade Environment"}
          style={{ flex: 1, marginLeft: "5px" }}
        />
      </MFormGroup>
      <MFormGroup row={true}>
        <Button
          disabled={cluster.status === "terminating"}
          gaAction={"view-cluster-drawer-action-section-" + (cluster.status === "stopped" ? "start" : "stop")}
          gaLabel={cluster.clusterId}
          icon={cluster.status === "stopped" ? "play" : "stop"}
          onButtonClick={cluster.status === "stopped" ? startCluster : stopCluster} // tslint:disable-line
          secondary={cluster.status !== "stopped"}
          text={cluster.status === "stopped" ? "Start" : "Stop"}
          style={{ flex: 1, marginRight: "5px" }}
        />
        <Button
          disabled={cluster.status !== "available"}
          gaAction={"view-cluster-drawer-action-section-restart"}
          gaLabel={cluster.clusterId}
          icon="undo"
          onButtonClick={restartCluster}
          secondary={true}
          text={"Restart"}
          style={{ flex: 1, margin: "0 5px 0 5px" }}
        />
        <Button
          icon="trash"
          gaAction={"view-cluster-drawer-action-section-delete"}
          gaLabel={cluster.clusterId}
          onButtonClick={deleteCluster}
          secondary={true}
          text="Delete"
          style={{ flex: 1, marginLeft: "5px" }}
        />
      </MFormGroup>
    </>
  );
};

export default ClusterActionSection;
