import { Drawer, DrawerContainer } from "@sandtable/component-library";
import * as React from "react";

interface Props extends Partial<any> {
  onClose: any;
  open: boolean;
}

export const withDrawer = (DrawerBody: any, drawerTitle: string, style?: object) => {
  return (props: Props): JSX.Element => {
    return (
      <Drawer onClose={props.onClose} open={props.open} title={drawerTitle}>
        <DrawerContainer style={style}>
          <DrawerBody {...props} />
        </DrawerContainer>
      </Drawer>
    );
  };
};
