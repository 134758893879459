import {
  addApplicationUser,
  Application,
  ApplicationExecutionMap,
  ApplicationMap,
  AppState,
  deleteApplication,
  deleteExecution,
  Execution,
  fetchApplications,
  fetchApplicationUsers,
  fetchExecutionsForApplication,
  inheritApplicationUsers,
  removeApplicationUser,
} from "@sandtable/datastore";
import Applications from "components/Applications/Applications";
import * as React from "react";
import { connect } from "react-redux";

interface Props {
  addApplicationUser: (application: Application, userEmail: string) => void;
  applications: ApplicationMap;
  deleteApplication: (application: Application) => void;
  deleteExecution: (execution: Execution) => void;
  executions: ApplicationExecutionMap;
  fetchApplications: () => void;
  fetchApplicationUsers: (applicationId: string) => void;
  fetchExecutionsForApplication: (applicationId: string) => void;
  inheritApplicationUsers: (application: Application, fromApplicationId: string) => void;
  isFetching: boolean;
  removeApplicationUser: (application: Application, userEmail: string) => void;
}

const ApplicationsContainer = (props: Props) => {
  const { fetchApplications, fetchExecutionsForApplication, fetchApplicationUsers } = props;

  React.useEffect(() => {
    fetchApplications();
  }, []);

  const handleApplicationLoad = React.useCallback((applicationId: string) => {
    // Load executions + users
    fetchExecutionsForApplication(applicationId);
    fetchApplicationUsers(applicationId);
  }, []);

  return <Applications {...props} onApplicationLoad={handleApplicationLoad} />;
};

const mapStateToProps = (state: AppState) => {
  return {
    applications: state.applications.applications,
    executions: state.executions.executions,
    isFetching: state.applications.isFetching,
  };
};

export default connect(mapStateToProps, {
  addApplicationUser,
  deleteApplication,
  deleteExecution,
  fetchApplicationUsers,
  fetchApplications,
  fetchExecutionsForApplication,
  inheritApplicationUsers,
  removeApplicationUser,
})(ApplicationsContainer);
