import { Loading } from "@sandtable/component-library";
import {
  addDeploymentUser,
  AppState,
  deleteDeployment,
  deleteExecutionV2,
  Deployment,
  DeploymentMap,
  ExecutionV2,
  ExecutionV2Map,
  fetchDeploymentExecutions,
  fetchDeployments,
  fetchDeploymentUsers,
  removeDeploymentUser,
} from "@sandtable/datastore";
import DeploymentsList from "components/Deployments/DeploymentsList";
import ViewDeploymentDrawer from "components/Deployments/ViewDeploymentDrawer/ViewDeploymentDrawer";
import * as React from "react";
import { connect } from "react-redux";
import { generatePath, Route, useHistory } from "react-router-dom";

const DEPLOYMENTS_PATH = "/deployments"; // TODO: Inherit route from parent
const VIEW_DEPLOYMENT_PATH = "/deployments/view-deployment/:id";

interface Props {
  addDeploymentUser: (d: Deployment, userEmail: string) => void;
  deleteDeployment: (d: Deployment) => void;
  deleteExecutionV2: (d: Deployment, e: ExecutionV2) => void;
  deployments: DeploymentMap;
  executions: ExecutionV2Map;
  fetchDeploymentExecutions: (d: Deployment) => void;
  fetchDeployments: () => void;
  fetchDeploymentUsers: (d: Deployment) => void;
  isFetching: boolean;
  removeDeploymentUser: (d: Deployment, userEmail: string) => void;
}

const Deployments = (props: Props) => {
  const history = useHistory();

  React.useEffect(() => {
    props.fetchDeployments();
  }, []);

  const handlers = {
    onClose: () => history.push(DEPLOYMENTS_PATH),
    onDeploymentSelect: (d: Deployment) => history.push(generatePath(VIEW_DEPLOYMENT_PATH, { id: d.id })),
  };

  if (props.isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Route path={DEPLOYMENTS_PATH}>
        <DeploymentsList {...props} {...handlers} />
      </Route>
      <Route path={VIEW_DEPLOYMENT_PATH}>
        <ViewDeploymentDrawer {...props} {...handlers} />
      </Route>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    deployments: state.deployments.deployments,
    executions: state.deployments.executions,
    isFetching: state.deployments.isFetching,
  };
};

export default connect(mapStateToProps, {
  addDeploymentUser,
  deleteDeployment,
  deleteExecutionV2,
  fetchDeploymentExecutions,
  fetchDeploymentUsers,
  fetchDeployments,
  removeDeploymentUser,
})(Deployments);
