import {
  DeploymentsActionTypes,
  DeploymentsState,
  RECEIVE_ADD_DEPLOYMENT_USER,
  RECEIVE_DELETE_DEPLOYMENT,
  RECEIVE_DELETE_EXECUTION_V2,
  RECEIVE_DEPLOYMENT_USERS,
  RECEIVE_DEPLOYMENTS,
  RECEIVE_EXECUTIONS,
  RECEIVE_REMOVE_DEPLOYMENT_USER,
  REQUEST_DEPLOYMENTS,
} from "@sandtable/datastore/deployments";

const initialState: DeploymentsState = {
  deployments: {},
  executions: {},
  isFetching: true,
};

export const deploymentsReducer = (state = initialState, action: DeploymentsActionTypes): DeploymentsState => {
  switch (action.type) {
    case REQUEST_DEPLOYMENTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_DEPLOYMENTS:
      return {
        ...state,
        deployments: action.deployments,
        isFetching: false,
      };
    case RECEIVE_EXECUTIONS:
      return {
        ...state,
        executions: {
          ...state.executions,
          [action.deploymentId]: action.executions,
        },
      };
    case RECEIVE_DELETE_DEPLOYMENT:
      const deployments = { ...state.deployments };
      delete deployments[action.deploymentId];
      return {
        ...state,
        deployments,
      };
    case RECEIVE_DELETE_EXECUTION_V2:
      const executions = { ...state.executions };
      delete executions[action.deploymentId][action.executionId];
      return {
        ...state,
        executions,
      };
    // TODO: Combine these cases:
    case RECEIVE_DEPLOYMENT_USERS:
      return {
        ...state,
        deployments: {
          ...state.deployments,
          [action.deploymentId]: {
            ...state.deployments[action.deploymentId],
            users: Object.values(action.applicationUsers),
          },
        },
      };
    case RECEIVE_ADD_DEPLOYMENT_USER:
      return {
        ...state,
        deployments: {
          ...state.deployments,
          [action.deploymentId]: {
            ...state.deployments[action.deploymentId],
            users: [...(state.deployments[action.deploymentId].users || []), ...Object.values(action.applicationUsers)],
          },
        },
      };
    case RECEIVE_REMOVE_DEPLOYMENT_USER:
      return {
        ...state,
        deployments: {
          ...state.deployments,
          [action.deploymentId]: {
            ...state.deployments[action.deploymentId],
            users: (state.deployments[action.deploymentId].users || []).filter(
              (u: any) => u.email !== action.userEmail,
            ),
          },
        },
      };
    default:
      return state;
  }
};
