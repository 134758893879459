import {
  addUserToWhitelist,
  AppState,
  commitUpload,
  deleteUpload,
  deleteUserFromWhitelist,
  fetchRepos,
  fetchUploads,
  fetchWhitelist,
  RepoMap,
  UploadMap,
  WhitelistMap,
} from "@sandtable/datastore";
import Data from "components/Data/Data";
import * as React from "react";
import { connect } from "react-redux";

interface Props {
  addUserToWhitelist: (email: string) => void;
  commitUpload: (uploadId: string, filename: string, repo: string) => void;
  deleteUpload: (uploadId: string) => void;
  deleteUserFromWhitelist: (email: string) => void;
  fetchRepos: (refresh?: boolean) => void;
  fetchUploads: (refresh?: boolean) => void;
  fetchWhitelist: () => void;
  isFetching: boolean;
  repos: RepoMap;
  uploads: UploadMap;
  whitelist: WhitelistMap;
  refreshRepos: () => void;
  refreshUploads: () => void;
}

const DataContainer = (props: Props) => {
  const { fetchRepos, fetchUploads, fetchWhitelist } = props;

  React.useEffect(() => {
    fetchRepos();
    fetchUploads();
    fetchWhitelist();
  }, []);

  return <Data {...props} />;
};

const mapStateToProps = (state: AppState) => {
  return {
    isFetching: state.repos.isFetching || state.uploads.isFetching || state.whitelist.isFetching,
    repos: state.repos.repos,
    uploads: state.uploads.uploads,
    whitelist: state.whitelist.whitelist,
  };
};

export default connect(mapStateToProps, {
  addUserToWhitelist,
  commitUpload,
  deleteUpload,
  deleteUserFromWhitelist,
  fetchRepos,
  fetchUploads,
  fetchWhitelist,
  refreshRepos: () => fetchRepos(true),
  refreshUploads: () => fetchUploads(true),
})(DataContainer);
