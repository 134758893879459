import { Syntax } from "@sandtable/component-library";
import { Environment, EnvironmentLog } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  environment: Environment;
  fetchEnvironmentLogs: (e: Environment) => void;
}

const environmentLogToString = (l: EnvironmentLog) => `${new Date(l.timestamp * 1000).toUTCString()}:\n${l.message}`;

const EnvironmentLogsTab = (props: Props) => {
  const logs = props.environment.logs ? props.environment.logs.map(environmentLogToString) : "Loading...";
  return (
    <Syntax
      height="500px"
      mode="javascript"
      onRefreshClick={() => props.fetchEnvironmentLogs(props.environment)}
      text={logs}
    />
  );
};

export default EnvironmentLogsTab;
