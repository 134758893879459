import {
  Button,
  CardList,
  CardListObject,
  Tabs,
  TextField,
  TextFieldAutocomplete,
  TextFieldAutocompleteOption,
} from "@sandtable/component-library";
import { Membership } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  onMemberAdd: (memberId: string) => void;
  onMemberDelete: (memberId: string) => void;
  onMemberUpdate: (memberId: string, owner: boolean) => void;
  memberships: Membership[];
  membersToAdd?: Membership[];
}

const MembersTab = (props: Props) => {
  const objects: CardListObject[] = props.memberships.map(
    (m: Membership, key: any) =>
      ({
        gaAction: "view-organisation-drawer-members-tab-card",
        gaLabel: m.id,
        onCardClick: () => {}, //tslint:disable-line
        primaryIcon: "user",
        primaryText: m.name + (m.owner ? " (owner)" : ""),
        secondaryIcon: "trash",
        secondaryIconClick: () => props.onMemberDelete(m.user_id),
        secondaryIconTooltip: "Remove user",
        tertiaryIcon: m.owner ? "user-minus" : "user-plus",
        tertiaryIconClick: () => props.onMemberUpdate(m.user_id, !m.owner),
        tertiaryIconTooltip: m.owner ? "Remove ownership" : "Grant ownership",
      } as CardListObject), //tslint:disable-line
  );
  return (
    <>
      <CardList objects={objects} />
      <MembersTabActions {...props} />
    </>
  );
};

const MembersTabActions = (props: Props) => {
  const [selectedMember, setSelectedMember] = React.useState<TextFieldAutocompleteOption | null>(null);

  const onTextFieldChange = (e: React.ChangeEvent<any>) => setSelectedMember({ label: "", value: e.target.value });
  const onTextFieldAutoChange = (m: TextFieldAutocompleteOption) => setSelectedMember(m);

  const handleAddMember = () => {
    if (selectedMember) {
      props.onMemberAdd(selectedMember.value);
      setSelectedMember(null);
    }
  };

  return (
    <>
      <Tabs value={0} labels={["Actions"]} gaAction={""} />
      {!props.membersToAdd && (
        <TextField
          label="User email"
          onChange={onTextFieldChange}
          placeholder="User email"
          style={{ marginBottom: "14px" }}
          value={selectedMember ? selectedMember.value : ""}
        />
      )}
      {props.membersToAdd && (
        <TextFieldAutocomplete
          placeholder="User name"
          style={{ marginBottom: "14px" }}
          onChange={onTextFieldAutoChange}
          options={props.membersToAdd.map((m: Membership) => ({
            label: `${m.name} (${m.email})`,
            value: m.email,
          }))}
          value={selectedMember}
        />
      )}
      <Button
        disabled={!(selectedMember && selectedMember.value)}
        gaAction={"view-organisation-drawer-members-tab-add-user"}
        fullWidth={true}
        iconRight="check"
        onButtonClick={handleAddMember}
        text={"Add user"}
      />
    </>
  );
};

export default MembersTab;
