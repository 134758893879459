export interface Membership {
  email: string;
  id: string;
  name: string;
  owner: boolean;
  user_id: string;
}

export interface Organisation {
  created: Date;
  id: string;
  memberships: Membership[];
  name: string;
  projects: Project[];
}

export interface Project {
  id: string;
  name: string;
  memberships: Membership[];
}

export interface OrganisationsState {
  isFetching: boolean;
  organisations: Organisation[];
}

export const RECEIVE_ADD_ORGANISATION = "RECEIVE_ADD_ORGANISATION";
export const RECEIVE_ORGANISATIONS = "RECEIVE_ORGANISATIONS";
export const REQUEST_ADD_ORGANISATION = "REQUEST_ADD_ORGANISATION";
export const REQUEST_ORGANISATIONS = "REQUEST_ORGANISATIONS";
export const REQUEST_ADD_PROJECT_TO_ORG = "REQUEST_ADD_PROJECT_TO_ORG";
export const RECEIVE_ADD_PROJECT_TO_ORG = "RECEIVE_ADD_PROJECT_TO_ORG";
export const REQUEST_ADD_MEMBER_TO_ORG = "REQUEST_ADD_MEMBER_TO_ORG";
export const RECEIVE_ADD_MEMBER_TO_ORG = "RECEIVE_ADD_MEMBER_TO_ORG";
export const REQUEST_ADD_MEMBER_TO_PROJECT = "REQUEST_ADD_MEMBER_TO_PROJECT";
export const RECEIVE_ADD_MEMBER_TO_PROJECT = "RECEIVE_ADD_MEMBER_TO_PROJECT";
export const REQUEST_REMOVE_MEMBER_FROM_PROJECT = "REQUEST_REMOVE_MEMBER_FROM_PROJECT";
export const RECEIVE_REMOVE_MEMBER_FROM_PROJECT = "RECEIVE_REMOVE_MEMBER_FROM_PROJECT";
export const REQUEST_UPDATE_MEMBER_OF_PROJECT = "REQUEST_UPDATE_MEMBER_OF_PROJECT";
export const RECEIVE_UPDATE_MEMBER_OF_PROJECT = "RECEIVE_UPDATE_MEMBER_OF_PROJECT";
export const REQUEST_REMOVE_MEMBER_FROM_ORGANISATION = "REQUEST_REMOVE_MEMBER_FROM_ORGANISATION";
export const RECEIVE_REMOVE_MEMBER_FROM_ORGANISATION = "RECEIVE_REMOVE_MEMBER_FROM_ORGANISATION";
export const REQUEST_UPDATE_MEMBER_OF_ORGANISATION = "REQUEST_UPDATE_MEMBER_OF_ORGANISATION";
export const RECEIVE_UPDATE_MEMBER_OF_ORGANISATION = "RECEIVE_UPDATE_MEMBER_OF_ORGANISATION";

interface RequestOrganisationsAction {
  type: typeof REQUEST_ORGANISATIONS;
}

interface ReceiveOrganisationsAction {
  type: typeof RECEIVE_ORGANISATIONS;
  organisations: Organisation[];
}

interface RequestAddOrganisationAction {
  name: string;
  type: typeof REQUEST_ADD_ORGANISATION;
}

interface ReceiveAddOrganisationAction {
  type: typeof RECEIVE_ADD_ORGANISATION;
  organisation: Organisation;
}

interface RequestAddProjectToOrgAction {
  name: string;
  organisationId: string;
  type: typeof REQUEST_ADD_PROJECT_TO_ORG;
}

interface ReceiveAddProjectToOrgAction {
  organisationId: string;
  project: Project;
  type: typeof RECEIVE_ADD_PROJECT_TO_ORG;
}

interface RequestAddMemberToOrgAction {
  memberEmail: string;
  organisationId: string;
  type: typeof REQUEST_ADD_MEMBER_TO_ORG;
}

interface ReceiveAddMemberToOrgAction {
  membership: Membership;
  organisationId: string;
  type: typeof RECEIVE_ADD_MEMBER_TO_ORG;
}

interface RequestAddMemberToProjectAction {
  memberEmail: string;
  organisationId: string;
  projectId: string;
  type: typeof REQUEST_ADD_MEMBER_TO_PROJECT;
}

interface ReceiveAddMemberToProjectAction {
  membership: Membership;
  organisationId: string;
  projectId: string;
  type: typeof RECEIVE_ADD_MEMBER_TO_PROJECT;
}

interface RequestRemoveMemberFromProjectAction {
  memberId: string;
  organisationId: string;
  projectId: string;
  type: typeof REQUEST_REMOVE_MEMBER_FROM_PROJECT;
}

interface ReceiveRemoveMemberFromProjectAction {
  organisationId: string;
  projectId: string;
  memberId: string;
  type: typeof RECEIVE_REMOVE_MEMBER_FROM_PROJECT;
}

interface RequestUpdateMemberOfProjectAction {
  memberId: string;
  organisationId: string;
  projectId: string;
  owner: boolean;
  type: typeof REQUEST_UPDATE_MEMBER_OF_PROJECT;
}

interface ReceiveUpdateMemberOfProjectAction {
  organisationId: string;
  projectId: string;
  memberId: string;
  owner: boolean;
  type: typeof RECEIVE_UPDATE_MEMBER_OF_PROJECT;
}

interface RequestRemoveMemberFromOrganisationAction {
  memberId: string;
  organisationId: string;
  type: typeof REQUEST_REMOVE_MEMBER_FROM_ORGANISATION;
}

interface ReceiveRemoveMemberFromOrganisationAction {
  memberId: string;
  organisationId: string;
  type: typeof RECEIVE_REMOVE_MEMBER_FROM_ORGANISATION;
}

interface RequestUpdateMemberOfOrganisationAction {
  memberId: string;
  organisationId: string;
  owner: boolean;
  type: typeof REQUEST_UPDATE_MEMBER_OF_ORGANISATION;
}

interface ReceiveUpdateMemberOfOrganisationAction {
  organisationId: string;
  memberId: string;
  owner: boolean;
  type: typeof RECEIVE_UPDATE_MEMBER_OF_ORGANISATION;
}

export type OrganisationActionTypes =
  | RequestOrganisationsAction
  | ReceiveOrganisationsAction
  | RequestAddOrganisationAction
  | ReceiveAddOrganisationAction
  | RequestAddProjectToOrgAction
  | ReceiveAddProjectToOrgAction
  | RequestAddMemberToOrgAction
  | ReceiveAddMemberToOrgAction
  | RequestAddMemberToProjectAction
  | ReceiveAddMemberToProjectAction
  | RequestRemoveMemberFromProjectAction
  | ReceiveRemoveMemberFromProjectAction
  | RequestUpdateMemberOfProjectAction
  | ReceiveUpdateMemberOfProjectAction
  | RequestRemoveMemberFromOrganisationAction
  | ReceiveRemoveMemberFromOrganisationAction
  | RequestUpdateMemberOfOrganisationAction
  | ReceiveUpdateMemberOfOrganisationAction;
