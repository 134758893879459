import { IconButton as MIconButton, Tooltip as MTooltip, Typography as MTypography } from "@material-ui/core";
import { Alert, Header, Icon, IconSize, Table, TextField } from "@sandtable/component-library";
import { WhitelistEntry, WhitelistMap } from "@sandtable/datastore";
import { injectElement, removeElement } from "@sandtable/utils";
import * as React from "react";

const COLUMNS = ["Email address", "Added by", "Added on", ""];

interface Props {
  addUserToWhitelist: (email: string) => void;
  deleteUserFromWhitelist: (email: string) => void;
  fetchWhitelist: () => void;
  whitelist: WhitelistMap;
}

const Whitelist = (props: Props) => {
  const data = Object.values(props.whitelist).map((we: WhitelistEntry) => [
    we.email,
    we.creator,
    new Date(we.datetimeAdded).toUTCString(),
    <DeleteWhitelistUserButton onDelete={() => props.deleteUserFromWhitelist(we.email)} key={we.email} />,
  ]);

  const handleAddUserToWhitelist = (email: string) => {
    props.addUserToWhitelist(email);
    injectElement(<NewUserInstructionDialog email={email} />);
  };

  const openNewUserDialog = React.useCallback(() => {
    injectElement(<NewWhitelistUserDialog handleAddUserToWhitelist={handleAddUserToWhitelist} />);
  }, []);

  return (
    <>
      <Header
        buttonText={"Add email address"}
        handleButtonClick={openNewUserDialog}
        showSearch={false}
        handleRefreshIconClick={props.fetchWhitelist}
      />
      <Table data={data} columns={COLUMNS} />
    </>
  );
};

const NewWhitelistUserDialog = (props: { handleAddUserToWhitelist: (email: string) => void }) => {
  const [textboxCopy, setTextboxCopy] = React.useState("");
  const content = (
    <>
      <MTypography style={{ marginTop: "14px" }}>Please enter an email address to add to the whitelist.</MTypography>
      <TextField
        style={{ marginTop: "22px" }}
        defaultValue={""}
        label="Email address"
        onChange={(e: any) => setTextboxCopy(e.target.value)}
        placeholder="Email address"
      />
    </>
  );
  return (
    <Alert
      content={content}
      handleClose={removeElement}
      handleSuccess={() => props.handleAddUserToWhitelist(textboxCopy)}
      open={true}
      title="Add email address to whitelist"
      noButtonCopy="Cancel"
      yesButtonCopy="Add"
    />
  );
};

const DeleteWhitelistUserButton = (props: { onDelete: () => void }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <MTooltip title={"Remove email address"}>
        <MIconButton style={{ alignSelf: "flex-end" }} onClick={props.onDelete}>
          <Icon icon={"trash"} size={IconSize.Small} />
        </MIconButton>
      </MTooltip>
    </div>
  );
};

const NewUserInstructionDialog = (props: { email: string }) => {
  const content = (
    <>
      <MTypography>Email the user at {props.email}, providing the following details:</MTypography>
      <ol>
        <li>
          <MTypography>Create an account at https://drop.sandman.ai using the email address {props.email}</MTypography>
        </li>
        <li>
          <MTypography>Verify your email address (remember to check spam folder)</MTypography>
        </li>
        <li>
          <MTypography>Log in and upload file(s)</MTypography>
        </li>
      </ol>
    </>
  );
  return (
    <Alert
      content={content}
      handleClose={removeElement}
      open={true}
      title="File Drop User Onboarding"
      yesButtonCopy="Close"
    />
  );
};

export default Whitelist;
