import { Button } from "@sandtable/component-library";
import * as React from "react";

interface Props {
  deleteUpload: () => void;
}

const DeleteTab = (props: Props) => (
  <Button
    gaAction={"view-staged-drawer-delete-tab-delete"}
    fullWidth={true}
    iconRight="check"
    onButtonClick={props.deleteUpload}
    text={"Delete file without committing to Sandgit"}
  />
);

export default DeleteTab;
