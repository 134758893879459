import { Alert } from "@material-ui/lab";
import * as React from "react";

interface Props {
  children: JSX.Element;
  style: React.CSSProperties;
  type: "error" | "warning" | "info" | "success";
}

const Info = (props: Props) => {
  return (
    <Alert severity={props.type} style={props.style}>
      {props.children}
    </Alert>
  );
};

export default Info;
