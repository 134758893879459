import Fuse from "fuse.js";
import React from "react";

export const useFilter = <T>(objects: T[], searchOptions: Fuse.FuseOptions<T>): [(v: string) => void, T[]] => {
  const [filterString, setFilterString] = React.useState<string>("");
  const fuse = React.useMemo(() => new Fuse(objects, searchOptions), [objects, searchOptions]);

  const filteredObjects: T[] = React.useMemo(() => {
    const newObjects: any[] = filterString.length ? fuse.search(filterString) : objects;

    // If objects is a primitive type array, .search will return an array of indices
    if (typeof newObjects[0] === "number") {
      return newObjects.map((o: any) => objects[o]);
    }

    return newObjects;
  }, [filterString, fuse]);

  return [setFilterString, filteredObjects];
};
