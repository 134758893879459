import { ViewDrawer } from "@sandtable/component-library";
import { ExperimentMap } from "@sandtable/datastore";
import ExperimentTab from "components/Experiments/ViewExperimentDrawer/ExperimentTab";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  onClose: () => void;
  experiments: ExperimentMap;
}

const ViewExperimentDrawer = (props: Props) => {
  const { uid } = useParams();

  const experiment = props.experiments[uid!]; // TODO: Handle undefined;

  return (
    <ViewDrawer
      {...props}
      onClose={props.onClose}
      maxWidth={"640px"}
      open={true}
      title={"View Experiment"}
      tabNames={["Experiment"]}
      tabGaAction={""}
      tabContents={[<ExperimentTab {...props} experiment={experiment} key={"experiment-tab"} />]}
    />
  );
};

export default ViewExperimentDrawer;
