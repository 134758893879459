import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconButton as MIconButton, Tooltip as MTooltip } from "@material-ui/core";
import { Icon, IconSize } from "@sandtable/component-library";
import * as React from "react";

interface Props {
  icon: IconProp;
  onIconClick: any;
  iconTooltip?: string;
}

const CardButton = (props: Props) => {
  return (
    <>
      <MTooltip title={props.iconTooltip || ""}>
        <MIconButton color="default" onClick={props.onIconClick}>
          <Icon icon={props.icon} size={IconSize.Small} />
        </MIconButton>
      </MTooltip>
    </>
  );
};

export default CardButton;
