import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconButton as MIconButton, Tooltip as MTooltip } from "@material-ui/core";
import { Icon, IconSize } from "@sandtable/component-library";
import * as React from "react";

interface Props {
  icon: IconProp;
  onClick?: (e: React.ChangeEvent<{}>) => void;
  tooltipText: string;
}

const NavButton = (props: Props) => {
  return (
    <>
      <MTooltip title={props.tooltipText} disableFocusListener={true}>
        <MIconButton onClick={props.onClick} color="inherit" focusRipple={false}>
          <Icon icon={props.icon} size={IconSize.Medium} />
        </MIconButton>
      </MTooltip>
    </>
  );
};

export default NavButton;
