import { Button as MButton } from "@material-ui/core";
import * as React from "react";

const STYLES = {
  height: "200px",
  width: "540px",
};

const IMAGE_STYLES = {
  height: "75px",
  width: "75px",
};

interface Props {
  icon?: any;
  imageUrl?: string;
  onClick?: any;
  style?: object;
  textSection: JSX.Element;
}

const Panel = (props: Props) => {
  return (
    <MButton
      style={{
        ...STYLES,
        backgroundColor: props.onClick || "#DCDCDC",
        marginBottom: "16px",
      }}
      variant="outlined"
      onClick={props.onClick}
      disabled={!props.onClick}
    >
      {props.textSection}
      {props.imageUrl && (
        <div style={IMAGE_STYLES}>
          <img {...IMAGE_STYLES} src={props.imageUrl} />
        </div>
      )}
    </MButton>
  );
};

export default Panel;
