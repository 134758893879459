import * as React from "react";

const DRAWER_PADDING_STYLES = { margin: "0 24px 24px 24px", minWidth: "540px" };

interface Props {
  children: any;
  style?: object;
}

const DrawerContainer = (props: Props) => {
  const styles = {
    ...props.style,
    ...DRAWER_PADDING_STYLES,
  };
  return <div style={styles}>{props.children}</div>;
};

export default DrawerContainer;
