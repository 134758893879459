import { Syntax } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  environment: Environment;
}

const EnvironmentPackageListTab = (props: Props) => (
  <Syntax text={props.environment.packageList ? props.environment.packageList.join("\n") : "None"} />
);

export default EnvironmentPackageListTab;
