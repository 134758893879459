// https://auth0.com/docs/quickstart/spa/react/01-login (outdated)
import createAuth0Client, { Auth0Client, Auth0ClientOptions, RedirectLoginResult } from "@auth0/auth0-spa-js";
import { setAuthInstance } from "@sandtable/utils";
import React, { useContext, useEffect, useState } from "react";

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

interface AuthContext {
  getToken: () => void;
  isAuthenticated: boolean;
  loading: boolean;
  login: () => void;
  logout: (returnTo: string) => void;
}

export const AuthContext = React.createContext<AuthContext>({
  getToken: () => undefined,
  isAuthenticated: false,
  loading: true,
  login: () => undefined,
  logout: () => undefined,
});
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: {
  children: JSX.Element;
  onRedirectCallback?: (result?: RedirectLoginResult) => void;
} & Auth0ClientOptions) => {
  const [state, setState] = useState<Pick<AuthContext, "isAuthenticated" | "loading"> & { client?: Auth0Client }>({
    client: undefined,
    isAuthenticated: false,
    loading: true,
  });

  useEffect(() => {
    const initAuth0 = async () => {
      const client = await createAuth0Client(initOptions);
      setState((prev) => ({ ...prev, client }));
      setAuthInstance(client);

      if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
        const {
          appState,
        }: {
          appState?: RedirectLoginResult;
        } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await client.isAuthenticated();

      setState((prev) => ({ ...prev, isAuthenticated, loading: false }));
    };
    initAuth0();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        getToken: () => state.client!.getTokenSilently(),
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        login: () => state.client!.loginWithRedirect(),
        logout: (returnTo: string) => state.client!.logout({ returnTo }),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
