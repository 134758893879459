import * as ReactDOM from "react-dom";

// NOTE: this will only currently work for one injected element at a time (ideal for alert modals)
// TODO: Convert to hook.

const ELEMENT_NAME = "injected-element";

export const injectElement = (element: JSX.Element) => {
  const target = document.createElement("div");
  target.setAttribute("id", ELEMENT_NAME);
  ReactDOM.render(element, target);
  document.body.appendChild(target);
};

export const removeElement = () => {
  const target = document.getElementById(ELEMENT_NAME);
  if (target) {
    ReactDOM.unmountComponentAtNode(target);
    target.remove();
  }
};
