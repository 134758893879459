// This is essentially a copy of Deployments/ViewDeploymentDrawer/UsersTab
// TODO: Abstract these components
import { Button, CardList, CardListObject, Tabs, TextField } from "@sandtable/component-library";
import { ApplicationUser, Deployment } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  addDeploymentUser: (deployment: Deployment, userEmail: string) => void;
  deployment: Deployment;
  removeDeploymentUser: (deployment: Deployment, userEmail: string) => void;
}

const UsersTab = (props: Props) => {
  const [actionTab, setActionTab] = React.useState(0);

  const users: ApplicationUser[] = props.deployment.users || [];

  const objects: CardListObject[] = users.map(
    (u: ApplicationUser) =>
      ({
        gaAction: "view-deployment-drawer-users-tab-card",
        gaLabel: u.name,
        onCardClick: () => {}, //tslint:disable-line
        primaryIcon: "user",
        primaryText: `${u.name} • ${u.email}`,
        secondaryIcon: "trash",
        secondaryIconClick: () => props.removeDeploymentUser(props.deployment, u.email),
        secondaryIconTooltip: "Remove user",
      } as CardListObject), //tslint:disable-line
  );

  return (
    <>
      <CardList objects={objects} />
      <Tabs
        value={actionTab}
        labels={["Add User"]}
        onChange={setActionTab}
        gaAction={"view-deployment-drawer-users-tab-tabs"}
      />
      {actionTab === 0 && <AddUserTab {...props} />}
    </>
  );
};

const AddUserTab = (props: Props) => {
  const [newMemberEmail, setNewMemberEmail] = React.useState("");

  const onTextFieldChange = (e: any) => setNewMemberEmail(e.target.value);
  const handleAddMember = () => {
    if (newMemberEmail) {
      props.addDeploymentUser(props.deployment, newMemberEmail);
      setNewMemberEmail("");
    }
  };

  return (
    <>
      <TextField
        label="User email"
        onChange={onTextFieldChange}
        placeholder="User email"
        style={{ marginBottom: "14px" }}
        value={newMemberEmail}
      />
      <Button
        disabled={!newMemberEmail.length}
        fullWidth={true}
        gaAction={"view-deployment-drawer-users-tab-add-user"}
        iconRight="check"
        onButtonClick={handleAddMember}
        text={"Add user"}
      />
    </>
  );
};

export default UsersTab;
