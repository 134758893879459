import { User } from "@sandtable/datastore";
import ReferralCode from "containers/ReferralCode/ReferralCode";
import TermsConditions from "containers/TermsConditions/TermsConditions";
import * as React from "react";

interface Props {
  children: JSX.Element;
  user: User;
}

const UserOnboardingGuard = (props: Props) => {
  // What should a user see etc before they can use the app?

  // If not redeemed and no org
  if (!props.user.redeemedCode && !props.user.organisation.name) {
    return <ReferralCode />; // TODO: Track
  }
  // Redirect to T&Cs if not agreed
  if (props.user.email && !props.user.termsAgreed) {
    return <TermsConditions />; // TODO: Track
  }

  return props.children;
};

export default UserOnboardingGuard;
