import { ViewDrawer } from "@sandtable/component-library";
import { RepoMap, Upload } from "@sandtable/datastore";
import CommitTab from "components/Data/ViewStagedDrawer/CommitTab";
import DeleteTab from "components/Data/ViewStagedDrawer/DeleteTab";
import StagedTab from "components/Data/ViewStagedDrawer/StagedTab";
import * as React from "react";

interface Props {
  onClose: () => void;
  deleteUpload: (uploadId: string) => void;
  commitUpload: (uploadId: string, filename: string, repo: string) => void;
  open: boolean;
  repos: RepoMap;
  staged: Upload;
}

const ViewStagedDrawer = (props: Props) => {
  const deleteUpload = React.useCallback(() => props.deleteUpload(props.staged.id), [props.staged]);

  return (
    <ViewDrawer
      {...props}
      title={"View Staged File"}
      tabNames={["File Details", "Commit file", "Delete file"]}
      tabContents={[
        <StagedTab {...props} key={"staged-tab"} />,
        <CommitTab {...props} key={"commit-tab"} />,
        <DeleteTab {...props} key={"delete-tab"} deleteUpload={deleteUpload} />,
      ]}
      tabGaAction={"view-repo-drawer-tabs"}
    />
  );
};

export default ViewStagedDrawer;
