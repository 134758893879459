import { MenuItem as MMenuItem } from "@material-ui/core";
import { NavButton, NavMenu, useAnalytics, useAuth } from "@sandtable/component-library";
import { User } from "@sandtable/datastore";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard"; // TODO: Move to util

interface Props {
  user: User;
}

const ProfileButton = (props: Props) => {
  const { logout } = useAuth();
  const analytics = useAnalytics();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>();
  const closeMenu = () => setAnchorEl(undefined);

  return (
    <>
      <NavButton
        icon="user-circle"
        onClick={(e: React.ChangeEvent<{}>) => {
          analytics.trackTopNavClick("user-menu");
          setAnchorEl(e.currentTarget as HTMLElement);
        }} // tslint:disable-line
        tooltipText="User menu"
      />
      <NavMenu anchorEl={anchorEl} onClose={closeMenu} open={!!anchorEl}>
        <CopyToClipboard
          text={props.user ? props.user.credentialId : ""}
          onCopy={() => {
            analytics.trackTopNavClick("copy-key-id");
            closeMenu();
          }} // tslint:disable-line
        >
          <MMenuItem>Copy Key ID</MMenuItem>
        </CopyToClipboard>
        <CopyToClipboard
          text={props.user ? props.user.credentialSecret : ""}
          onCopy={() => {
            analytics.trackTopNavClick("copy-key-secret");
            closeMenu();
          }} // tslint:disable-line
        >
          <MMenuItem>Copy Key Secret</MMenuItem>
        </CopyToClipboard>
        <MMenuItem
          onClick={() => {
            analytics.trackTopNavClick("logout");
            logout(window.location.origin);
          }}
        >
          Logout
        </MMenuItem>
      </NavMenu>
    </>
  );
};

export default ProfileButton;
