import { Typography as MTypography } from "@material-ui/core";
import * as React from "react";

const CONTAINER_STYLES: React.CSSProperties = {
  backgroundColor: "#4589F6",
  padding: "8px",
};

const COPY_STYLES: React.CSSProperties = {
  color: "white",
  fontFamily: '"Roboto Mono","Courier New",Courier,monospace"',
};

interface Props {
  code: string;
}

const OnboardingCode = (props: Props) => {
  return (
    <div style={CONTAINER_STYLES}>
      <MTypography style={COPY_STYLES}>{`$ ${props.code.toLowerCase()}`}</MTypography>
    </div>
  );
};

export default OnboardingCode;
