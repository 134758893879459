import { Configuration } from "@sandtable/datastore";

export const SEARCH_OPTIONS: Fuse.FuseOptions<Configuration> = {
  distance: 100,
  keys: ["name"],
  location: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.4,
};
