import { enterReferralCode } from "@sandtable/datastore/user/actions";
import ReferralCode from "components/ReferralCode/ReferralCode";
import * as React from "react";
import { connect } from "react-redux";

interface Props {
  enterReferralCode: (s: string) => void;
}

const ReferralCodeContainer = (props: Props) => <ReferralCode {...props} />;

export default connect(() => ({}), {
  enterReferralCode,
})(ReferralCodeContainer);
