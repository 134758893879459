import { Spinner, withDrawer } from "@sandtable/component-library";
import { Experiment } from "@sandtable/datastore";
import { getApiAuthHeaders } from "@sandtable/utils";
import { BASE_URL } from "@sandtable/utils/constants";
import axios from "axios";
import ExperimentsTab from "components/Experiments/NewExperimentDrawer/ExperimentsTab";
import ExperimentTypeTab from "components/Experiments/NewExperimentDrawer/ExperimentTypeTab";
import { ExperimentType } from "components/Experiments/NewExperimentDrawer/types";
import UploadTab from "components/Experiments/NewExperimentDrawer/UploadTab";
import humps from "humps";
import * as React from "react";

interface Props {
  onExperimentAdd: (fileId: string, experimentName: string) => void;
}

const NewExperimentDrawer = (props: Props) => {
  const [type, setType] = React.useState<ExperimentType | undefined>();
  const [file, setFile] = React.useState<File | undefined>();
  const [fileId, setFileId] = React.useState("");
  const [experiments, setExperiments] = React.useState<Experiment[]>([]);

  // TODO: Move to datastore (use API MW)
  // XXX: Should be callback
  React.useEffect(() => {
    const uploadFile = async () => {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const authHeaders = await getApiAuthHeaders();
        const headers = {
          headers: {
            authHeaders,
            "content-type": "multipart/form-data",
          },
        };
        let res;
        try {
          res = await axios.post(`${BASE_URL}/files/netlogo`, formData, headers);
        } catch (err) {
          alert("Failed to upload Netlogo file. Does the file contain at least 1 experiment?");
          setFile(undefined);
          return;
        }
        const data: any = humps.camelizeKeys(res.data);
        setFileId(data.id);
        setExperiments(data.experiments);
      }
    };
    uploadFile();
  }, [file]);

  if (type === undefined) {
    return <ExperimentTypeTab onTypeSelect={setType} />;
  } else if (file === undefined) {
    return <UploadTab onFileUpload={setFile} />;
  } else if (experiments.length) {
    return <ExperimentsTab {...props} fileId={fileId} experiments={experiments} />;
  }
  return (
    <div style={{ marginTop: "50px" }}>
      <Spinner type="circular" />
    </div>
  );
};

export default withDrawer(NewExperimentDrawer, "New Experiment", { maxWidth: "540px" });
