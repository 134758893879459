// This awful config file essentially allows us to set/update environment variables at "runtime"
// See this issue https://github.com/facebook/create-react-app/issues/578
// I'm using an .env.development file for development:
// https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables

export const AUTH0_AUDIENCE =
  (window as any).env && (window as any).env.REACT_APP_AUTH0_AUDIENCE
    ? (window as any).env.REACT_APP_AUTH0_AUDIENCE
    : process.env.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_CLIENT_ID =
  (window as any).env && (window as any).env.REACT_APP_AUTH0_CLIENT_ID
    ? (window as any).env.REACT_APP_AUTH0_CLIENT_ID
    : process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN =
  (window as any).env && (window as any).env.REACT_APP_AUTH0_DOMAIN
    ? (window as any).env.REACT_APP_AUTH0_DOMAIN
    : process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_REDIRECT_URI =
  (window as any).env && (window as any).env.REACT_APP_AUTH0_REDIRECT_URI
    ? (window as any).env.REACT_APP_AUTH0_REDIRECT_URI
    : process.env.REACT_APP_AUTH0_REDIRECT_URI;

export const BASE_URL =
  (window as any).env && (window as any).env.REACT_APP_API_BASE_URL
    ? (window as any).env.REACT_APP_API_BASE_URL
    : process.env.REACT_APP_API_BASE_URL;
export const WS_BASE_URL =
  (window as any).env && (window as any).env.REACT_APP_WS_BASE_URL
    ? (window as any).env.REACT_APP_WS_BASE_URL
    : process.env.REACT_APP_WS_BASE_URL;

export const GRAFANA_URL =
  (window as any).env && (window as any).env.REACT_APP_GRAFANA_URL
    ? (window as any).env.REACT_APP_GRAFANA_URL
    : process.env.REACT_APP_GRAFANA_URL;
export const KIBANA_URL =
  (window as any).env && (window as any).env.REACT_APP_KIBANA_URL
    ? (window as any).env.REACT_APP_KIBANA_URL
    : process.env.REACT_APP_KIBANA_URL;

export const GOOGLE_ANALYTICS_ID =
  (window as any).env && (window as any).env.REACT_APP_GOOGLE_ANALYTICS_ID
    ? (window as any).env.REACT_APP_GOOGLE_ANALYTICS_ID
    : process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

export const COMMIT_HASH =
  (window as any).env && (window as any).env.REACT_APP_COMMIT_HASH
    ? (window as any).env.REACT_APP_COMMIT_HASH
    : process.env.REACT_APP_COMMIT_HASH;

export const JUPYTER_URL =
  (window as any).env && (window as any).env.REACT_APP_JUPYTER_URL
    ? (window as any).env.REACT_APP_JUPYTER_URL
    : process.env.REACT_APP_JUPYTER_URL;

export const DOCS_URL =
  (window as any).env && (window as any).env.REACT_APP_DOCS_URL
    ? (window as any).env.REACT_APP_DOCS_URL
    : process.env.REACT_APP_DOCS_URL;

export const PYPI_URL =
  (window as any).env && (window as any).env.REACT_APP_PYPI_URL
    ? (window as any).env.REACT_APP_PYPI_URL
    : process.env.REACT_APP_PYPI_URL;
