/*
 *  MUI Data Table sort is broken for our <x GB> strings. Use custom sort instead.
 *  TODO: Refactor.
 */
export const customSort = (data: any, colIndex: number, order: string): any => {
  return data.sort((a: any, b: any) => {
    const asc = order === "asc";
    const aData = (asc ? a.data[colIndex] : b.data[colIndex]) as string;
    const bData = (asc ? b.data[colIndex] : a.data[colIndex]) as string;
    if (colIndex === 1) {
      return parseInt(aData, 10) - parseInt(bData, 10);
    }
    if (colIndex === 2 || colIndex === 3) {
      return parseFloat(aData.split(" ")[0]) - parseFloat(bData.split(" ")[0]);
    }
    if (colIndex === 4 || colIndex === 5) {
      return parseFloat(aData.replace("$", "")) - parseFloat(bData.replace("$", ""));
    }
    return aData.localeCompare(bData);
  });
};
