import { ViewDrawer } from "@sandtable/component-library";
import { EnvironmentsV2Map, EnvironmentV2 } from "@sandtable/datastore";
import EnvironmentV2ActionsTab from "components/EnvironmentsV2/ViewEnvironmentV2Drawer/EnvironmentV2ActionsTab";
import EnvironmentV2DockerfileTab from "components/EnvironmentsV2/ViewEnvironmentV2Drawer/EnvironmentV2DockerfileTab";
import EnvironmentV2LogsTab from "components/EnvironmentsV2/ViewEnvironmentV2Drawer/EnvironmentV2LogsTab";
import EnvironmentV2Tab from "components/EnvironmentsV2/ViewEnvironmentV2Drawer/EnvironmentV2Tab";
import EnvironmentV2YamlTab from "components/EnvironmentsV2/ViewEnvironmentV2Drawer/EnvironmentV2YamlTab";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  cancelEnvironmentV2: (environment: EnvironmentV2) => void;
  deleteEnvironmentV2: (environment: EnvironmentV2) => void;
  getEnvironmentV2: (environment: EnvironmentV2) => void;
  environments: EnvironmentsV2Map;
  onClose: (e: EnvironmentV2) => void;
}

const ViewEnvironmentV2Drawer = (props: Props) => {
  const { uid } = useParams();

  const environment = props.environments[uid!]; // TODO: Handle undefined.

  return (
    <ViewDrawer
      actionSection={<EnvironmentV2ActionsTab {...props} environment={environment} />}
      onClose={() => props.onClose(environment)}
      maxWidth={"700px"}
      open={true}
      tabGaAction={"view-environment-v2-drawer-tabs"}
      tabContents={[
        <EnvironmentV2Tab environment={environment} key={"environment-tab"} />,
        <EnvironmentV2YamlTab environment={environment} key={"yaml-tab"} />,
        <EnvironmentV2DockerfileTab environment={environment} key={"dockerfile-tab"} />,
        <EnvironmentV2LogsTab {...props} environment={environment} key={"logs-tab"} />,
      ]}
      tabNames={["Environment", "YAML", "Dockerfile", "Logs"]}
      title={"View Environment"}
    />
  );
};

export default ViewEnvironmentV2Drawer;
