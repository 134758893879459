import { ChevronRight as ChevronRightIcon, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { TreeItem as MTreeItem, TreeView as MTreeView } from "@material-ui/lab";
import { RenderTree, RenderTreeList } from "@sandtable/component-library";
import * as React from "react";

interface Props {
  objects: RenderTreeList;
}

const generateTree = (treeList: RenderTreeList) =>
  treeList.map((tree: RenderTree) => (
    <MTreeItem key={tree.id} nodeId={tree.id} label={tree.name}>
      {Array.isArray(tree.children) ? generateTree(tree.children) : null}
    </MTreeItem>
  ));

const TreeView = (props: Props) => {
  const { objects } = props;
  const tree = React.useMemo(() => generateTree(objects), [objects]);

  return (
    <MTreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />} defaultEndIcon={<></>}>
      {tree}
    </MTreeView>
  );
};

export default TreeView;
