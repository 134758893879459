import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // tslint:disable-line
import { Fab, Typography as MTypography } from "@material-ui/core";
import * as React from "react";

const CONTAINER_STYLE = { display: "flex" };
const FAB_STYLE = { paddingLeft: "3%", paddingRight: "3%" };
const ICON_STYLE = { marginLeft: "7px", transform: "scale(0.8) translateY(23%)" };
const TYPOGRAPHY_STYLE = {
  fontFamily: "Helvetica",
  justifyContent: "space-evenly",
  marginTop: "2px",
};

export enum OnboardingButtonSize {
  Large = "large",
  Small = "small",
}

export interface Props {
  disabled?: boolean;
  gaAction: string;
  gaLabel?: string;
  onButtonClick?: () => void;
  secondary?: boolean;
  showCheckIcon?: boolean;
  size?: OnboardingButtonSize;
  text?: string;
}

const OnboardingButton = (props: Props) => {
  return (
    <Fab
      color={!props.secondary ? "primary" : "secondary"}
      disabled={props.disabled}
      variant="extended"
      onClick={props.onButtonClick}
      size={props.size}
      style={FAB_STYLE}
      data-ga-event-category={window.location.pathname.slice(1)}
      data-ga-event-action={props.gaAction.toLowerCase()}
      data-ga-event-label={(props.gaLabel || "").toLowerCase()}
    >
      <MTypography
        color={props.secondary ? "textSecondary" : undefined}
        style={{
          ...TYPOGRAPHY_STYLE,
          color: props.secondary ? "" : "white",
          fontSize: props.size === OnboardingButtonSize.Small ? "0.9em" : "1.5em",
          textTransform: "none",
        }}
      >
        <div style={{ ...CONTAINER_STYLE, flexDirection: "row" }}>
          {props.text || ""}
          {props.showCheckIcon ? <FontAwesomeIcon style={ICON_STYLE} icon={"check"} className="button-icon" /> : ""}
        </div>
      </MTypography>
    </Fab>
  );
};

OnboardingButton.defaultProps = {
  size: OnboardingButtonSize.Small,
};

export default OnboardingButton;
