import {
  Application,
  ApplicationsActionTypes,
  ApplicationState,
  ApplicationUser,
  RECEIVE_ADD_APPLICATION_USER,
  RECEIVE_APPLICATION_USERS,
  RECEIVE_APPLICATIONS,
  RECEIVE_DELETE_APPLICATION,
  RECEIVE_INHERIT_APPLICATION_USERS,
  RECEIVE_REMOVE_APPLICATION_USER,
  REQUEST_APPLICATIONS,
} from "@sandtable/datastore/applications/types";

const initialState: ApplicationState = {
  applications: {},
  isFetching: true,
};

export const applicationsReducer = (state = initialState, action: ApplicationsActionTypes): ApplicationState => {
  switch (action.type) {
    case REQUEST_APPLICATIONS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_APPLICATIONS:
      return { applications: action.applications, isFetching: false };
    case RECEIVE_APPLICATION_USERS:
    case RECEIVE_ADD_APPLICATION_USER:
    case RECEIVE_REMOVE_APPLICATION_USER:
    case RECEIVE_INHERIT_APPLICATION_USERS:
      const app = state.applications[action.applicationId];
      if (!app) {
        return state;
      }
      let updatedApp: Application | undefined;
      switch (action.type) {
        case RECEIVE_APPLICATION_USERS:
          updatedApp = {
            ...app,
            users: Object.values(action.applicationUsers || {}),
          };
          break;
        case RECEIVE_ADD_APPLICATION_USER:
          updatedApp = {
            ...app,
            users: app.users
              ? [...app.users, Object.values(action.applicationUsers)[0]]
              : [Object.values(action.applicationUsers)[0]],
          };
          break;
        case RECEIVE_REMOVE_APPLICATION_USER:
          updatedApp = {
            ...app,
            users: app.users ? app.users.filter((u: ApplicationUser) => u.email !== action.userEmail) : [],
          };
          break;
        case RECEIVE_INHERIT_APPLICATION_USERS:
          updatedApp = {
            ...app,
            users: app.users
              ? [...app.users, ...Object.values(action.applicationUsers || {})]
              : Object.values(action.applicationUsers || {}),
          };
      }

      return {
        ...state,
        applications: {
          ...state.applications,
          [updatedApp!.id]: updatedApp!,
        },
      };
    case RECEIVE_DELETE_APPLICATION:
      const applications = { ...state.applications };
      delete applications[action.applicationId];
      return {
        ...state,
        applications,
      };
    default:
      return state;
  }
};
