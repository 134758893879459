import MUIDataTable from "mui-datatables";
import * as React from "react";

export interface Props {
  columns: any[];
  customSort?: any;
  data: any[];
  onRowClick?: any;
  selectedRow?: number;
  sort?: boolean;
  style?: React.CSSProperties; // MUIDataTable Paper styles
}

export const OPTIONS = {
  download: false,
  filter: false,
  pagination: false,
  print: false,
  responsive: "scroll",
  search: false,
  selectableRows: false,
  textLabels: {
    body: {
      noMatch: "No items to display",
    },
  },
  viewColumns: false,
};

const Table = ({ columns, customSort, data, onRowClick, selectedRow, sort = true, style }: Props) => {
  return (
    <MUIDataTable
      data={data}
      columns={columns}
      options={{ ...OPTIONS, sort, onRowClick, customSort, rowsSelected: [selectedRow] }}
      style={style}
    />
  );
};

export default Table;
