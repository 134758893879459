// XXX: Near duplicate of V1 CodeSection. Deprecate V1 version.
import { Syntax } from "@sandtable/component-library";
import { NewEnvironmentV2 } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  environment: NewEnvironmentV2;
  onChange: (e: Partial<NewEnvironmentV2>, error?: boolean) => void;
}

const EnvironmentV2DockerfileTab = (props: Props): JSX.Element => {
  return (
    <Syntax
      text={props.environment.dockerfile!}
      onChange={(a: any, b: any, dockerfile: string) => props.onChange({ dockerfile }, !dockerfile)}
      height={"440px"}
      title="Environment Dockerfile"
    />
  );
};

export default EnvironmentV2DockerfileTab;
