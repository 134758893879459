// Near copy of ApplicationActionSection.tsx. TODO: Abstract.
import { Alert, Button } from "@sandtable/component-library";
import { Deployment } from "@sandtable/datastore";
import { injectElement, removeElement } from "@sandtable/utils";
import * as React from "react";

interface Props {
  deployment: Deployment;
  deleteDeployment: (deployment: Deployment) => void;
}

const DeploymentActionSection = (props: Props) => {
  const handleClick = () => {
    injectElement(
      <Alert
        content={"Are you sure that you want to delete this deployment?"}
        handleClose={removeElement}
        noButtonCopy={"Cancel"}
        handleSuccess={() => props.deleteDeployment(props.deployment)}
        open={true}
        title={"Delete deployment"}
      />,
    );
  };

  return (
    <Button
      fullWidth={true}
      gaAction={"view-deployment-drawer-delete-deployment"}
      iconRight={"trash"}
      text={"Delete deployment"}
      onButtonClick={handleClick}
    />
  );
};

export default DeploymentActionSection;
