import { Syntax } from "@sandtable/component-library";
import { Cluster, ClusterLog } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  cluster: Cluster;
  fetchClusterLogs: (c: Cluster) => void;
}

const ClusterLogsTab = (props: Props) => {
  const logs = props.cluster.logs
    ? props.cluster.logs.map((l: ClusterLog) => `${new Date(l.timestamp * 1000).toUTCString()}:\n${l.message}`)
    : "Loading...";

  return (
    <Syntax
      height="500px"
      maxWidth="608px"
      mode="javascript"
      onRefreshClick={() => props.fetchClusterLogs(props.cluster)}
      text={logs}
    />
  );
};

export default ClusterLogsTab;
