import { Drawer, DrawerContainer, New } from "@sandtable/component-library";
import { Props as NewProps } from "@sandtable/component-library/components/New/New";
import * as React from "react";

interface Props extends NewProps {
  onClose: any;
  maxWidth?: string;
  open: boolean;
  title: string;
}

const NewDrawer = (props: Props) => {
  return (
    <Drawer onClose={props.onClose} open={props.open} title={props.title}>
      <DrawerContainer style={{ maxWidth: props.maxWidth }}>
        <New {...props} />
      </DrawerContainer>
    </Drawer>
  );
};

export default NewDrawer;
