import { Button, TextField } from "@sandtable/component-library";
import * as React from "react";

const OUTER_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
};
const INNER_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
};
const BUTTON_STYLES: React.CSSProperties = {
  marginTop: "10px",
};

interface Props {
  enterReferralCode: (s: string) => void;
}

const ReferralCode = (props: Props) => {
  const [code, setCode] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);

  const updateCode = (e: any) => setCode(e.target.value);
  const handleEnterKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleButtonClick();
    }
  };
  const handleButtonClick = () => {
    setSubmitting(true);
    props.enterReferralCode(code);
  };

  return (
    <div style={OUTER_CONTAINER_STYLES}>
      <div style={INNER_CONTAINER_STYLES}>
        <TextField
          label="Referral code"
          onChange={updateCode}
          onKeyPress={handleEnterKeyPress}
          placeholder="Referral code"
        />
        <Button
          style={BUTTON_STYLES}
          gaAction={"referral-code-submit"}
          text="Submit"
          onButtonClick={handleButtonClick}
          disabled={submitting}
        />
      </div>
    </div>
  );
};

export default ReferralCode;
