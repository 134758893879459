import { schema } from "normalizr";

const COLLECTION_NAME = "environments";
const ID_KEY = "uid";

const environmentSchema = new schema.Entity(
  COLLECTION_NAME,
  {},
  {
    idAttribute: (environment: EnvironmentV2) => environment[ID_KEY],
  },
);

export const ENVIRONMENT_V2_SCHEMA = environmentSchema;
export const ENVIRONMENT_V2_ARRAY_AND_PAGINATION = [environmentSchema];

// Environment interfaces:
export interface EnvironmentV2Log {
  message: string;
  timestamp: number;
}

export interface EnvironmentV2 {
  buildLog?: EnvironmentV2Log[];
  buildLogShort: EnvironmentV2Log[];
  condaEnvironmentFile: string;
  containerEngineLog: any[];
  createdDatetime: string;
  creator: string;
  diffPackageList?: string[];
  dockerfile: string;
  dockerRepository: string;
  dockerTag: string;
  finishedDatetime: string;
  isPublic: boolean;
  name: string;
  organisation: string;
  packageList?: string[];
  sizeBytes?: number;
  startedDatetime: string;
  status: string;
  uid: string;
}

export interface EnvironmentsV2Map {
  [key: string]: EnvironmentV2;
}

export interface EnvironmentsV2Pagination {
  page: number;
  perPage: number;
  environmentsCount: number;
}

export interface EnvironmentsV2State {
  [COLLECTION_NAME]: EnvironmentsV2Map;
  pagination: EnvironmentsV2Pagination;
  searchQuery: string;
  isFetching: boolean;
  subscribedToEnvironmentUpdates: boolean;
}

export type NewEnvironmentV2 = Pick<EnvironmentV2, "condaEnvironmentFile" | "dockerfile" | "isPublic">;

export const REQUEST_ENVIRONMENTS_V2 = "REQUEST_ENVIRONMENTS_V2";
export const RECEIVE_ENVIRONMENTS_V2 = "RECEIVE_ENVIRONMENTS_V2";
export const REQUEST_GET_ENVIRONMENT_V2 = "REQUEST_GET_ENVIRONMENT_V2";
export const RECEIVE_GET_ENVIRONMENT_V2 = "RECEIVE_GET_ENVIRONMENT_V2";
export const REQUEST_ADD_ENVIRONMENT_V2 = "REQUEST_ADD_ENVIRONMENT_V2";
export const RECEIVE_ADD_ENVIRONMENT_V2 = "RECEIVE_ADD_ENVIRONMENT_V2";
export const REQUEST_UPDATE_ENVIRONMENT_V2 = "REQUEST_UPDATE_ENVIRONMENT_V2";
export const RECEIVE_UPDATE_ENVIRONMENT_V2 = "RECEIVE_UPDATE_ENVIRONMENT_V2";
export const REQUEST_CANCEL_ENVIRONMENT_V2 = "REQUEST_CANCEL_ENVIRONMENT_V2";
export const RECEIVE_CANCEL_ENVIRONMENT_V2 = "RECEIVE_CANCEL_ENVIRONMENT_V2";
export const REQUEST_DELETE_ENVIRONMENT_V2 = "REQUEST_DELETE_ENVIRONMENT_V2";
export const RECEIVE_DELETE_ENVIRONMENT_V2 = "RECEIVE_DELETE_ENVIRONMENT_V2";
export const SUBSCRIBE_ENVIRONMENT_V2_UPDATES = "SUBSCRIBE_ENVIRONMENT_V2_UPDATES";

interface RequestEnvironmentsV2Action {
  type:
    | typeof REQUEST_ENVIRONMENTS_V2
    | typeof REQUEST_GET_ENVIRONMENT_V2
    | typeof REQUEST_ADD_ENVIRONMENT_V2
    | typeof REQUEST_UPDATE_ENVIRONMENT_V2
    | typeof REQUEST_CANCEL_ENVIRONMENT_V2
    | typeof REQUEST_DELETE_ENVIRONMENT_V2
    | typeof SUBSCRIBE_ENVIRONMENT_V2_UPDATES;
}

interface ReceiveEnvironmentsV2Action {
  [COLLECTION_NAME]: EnvironmentsV2Map;
  pagination: EnvironmentsV2Pagination;
  searchQuery: string;
  type: typeof RECEIVE_ENVIRONMENTS_V2;
}

interface ReceiveEnvironmentV2Action {
  [COLLECTION_NAME]: EnvironmentsV2Map;
  type:
    typeof RECEIVE_GET_ENVIRONMENT_V2
    | typeof RECEIVE_ADD_ENVIRONMENT_V2
    | typeof RECEIVE_UPDATE_ENVIRONMENT_V2
    | typeof RECEIVE_CANCEL_ENVIRONMENT_V2
    | typeof RECEIVE_DELETE_ENVIRONMENT_V2;
}

export type EnvironmentsV2ActionTypes = RequestEnvironmentsV2Action
  | ReceiveEnvironmentsV2Action
  | ReceiveEnvironmentV2Action;
