import { schema } from "normalizr";

const clusterSchema = new schema.Entity(
  "clusters",
  {},
  {
    idAttribute: (cluster: any) => cluster.clusterId,
  },
);

export const CLUSTER = clusterSchema;
export const CLUSTER_ARRAY = [clusterSchema];
export const CLUSTER_ARRAY_AND_PAGINATION = [clusterSchema];

const clusterLogSchema = new schema.Entity(
  "clusterLogs",
  {},
  {
    idAttribute: (x: any) => "all",
  },
);

export const CLUSTER_LOGS = clusterLogSchema;
