import { Typography as MTypography } from "@material-ui/core";
import { Panel } from "@sandtable/component-library";
import { ExperimentType } from "components/Experiments/NewExperimentDrawer/types";
import * as React from "react";

const NETLOGO_LOGO_URL = "/netlogo.png";

interface Props {
  onTypeSelect: (e: ExperimentType) => void;
}

const ExperimentTypeTab = (props: Props) => {
  return (
    <>
      <Panel
        imageUrl={NETLOGO_LOGO_URL}
        textSection={
          <MTypography style={{ marginRight: "16px" }}>
            Run a NetLogo Behaviour Space Experiment <span style={{ color: "red" }}>(Alpha)</span>
          </MTypography>
        } // tslint:disable-line
        onClick={() => props.onTypeSelect(ExperimentType.Netlogo)}
      />
      <Panel
        textSection={<MTypography style={{ marginRight: "16px" }}>Run a Netlogo Experiment (coming soon)</MTypography>}
      />
    </>
  );
};

export default ExperimentTypeTab;
