import { Icon, Syntax } from "@sandtable/component-library";
import { clickInputElement, readFile } from "@sandtable/utils";
import * as React from "react";

const UPLOAD_ICON_STYLES: React.CSSProperties = {
  marginTop: "6px",
  position: "absolute",
  right: "28px",
  zIndex: 1,
};

interface Props {
  onChange: (s: string) => void;
  placeholder?: string;
  title: string;
  value: string;
}

const EnvironmentCodeSection = (props: Props): JSX.Element => {
  const inputId = props.title.split(" ")[1] + "-upload";
  const onIconChange = () => clickInputElement(inputId);
  const onInputChange = (e: React.ChangeEvent<any>) => readFile(e.target.files[0], props.onChange);

  return (
    <div>
      <div style={UPLOAD_ICON_STYLES}>
        <input type="file" id={inputId} accept="text/*" style={{ display: "none" }} onChange={onInputChange} />
        <Icon
          icon="file-upload"
          tooltipText="Upload file"
          onIconClick={onIconChange}
          gaAction={"new-environment-drawer-code-section-upload"}
          gaLabel={props.title}
        />
      </div>
      <Syntax
        height={"201px"}
        onChange={(a: any, b: any, c: any) => props.onChange(c)}
        placeholder={props.placeholder}
        text={props.value}
        title={props.title}
      />
    </div>
  );
};

export default EnvironmentCodeSection;
