import { schema } from "normalizr";

const COLLECTION_NAME = "environments";
const ID_KEY = "uid";

const environmentSchema = new schema.Entity(
  COLLECTION_NAME,
  {},
  {
    idAttribute: (environment: Environment) => environment[ID_KEY],
  },
);

const environmentLogSchema = new schema.Entity(
  "environmentLogs",
  {},
  {
    idAttribute: (x: any) => "all",
  },
);

export const ENVIRONMENT = environmentSchema;
export const ENVIRONMENT_ARRAY_AND_PAGINATION = [environmentSchema];
export const ENVIRONMENT_LOGS = environmentLogSchema;

// Environment interfaces:
export interface EnvironmentLog {
  timestamp: number;
  message: string;
}

export interface Environment {
  condaRequirements?: string[];
  created: Date;
  creator?: string;
  dockerfile: string;
  error?: string;
  isPublic: boolean;
  name: string;
  organisation?: string;
  packageList?: string[];
  parentEnvUid?: string;
  pipRequirements?: string[];
  pythonVersion: string;
  runtimeVersion: string;
  size?: number;
  status: string;
  uid: string;
  logs?: EnvironmentLog[];
}

export interface EnvironmentsMap {
  [key: string]: Environment;
}

export interface EnvironmentsPagination {
  page: number;
  perPage: number;
  environmentsCount: number;
}

export interface EnvironmentsState {
  environments: EnvironmentsMap;
  pagination: EnvironmentsPagination;
  searchQuery: string;
  isFetching: boolean;
  subscribedToEnvironmentUpdates: boolean;
}

// Action constants:
export const RECEIVE_ADD_ENVIRONMENT = "RECEIVE_ADD_ENVIRONMENT";
export const RECEIVE_DELETE_ENVIRONMENT = "RECEIVE_DELETE_ENVIRONMENT";
export const RECEIVE_ENVIRONMENTS = "RECEIVE_ENVIRONMENTS";
export const RECEIVE_UPDATE_ENVIRONMENT = "RECEIVE_UPDATE_ENVIRONMENT";
export const REQUEST_ADD_ENVIRONMENT = "REQUEST_ADD_ENVIRONMENT";
export const REQUEST_DELETE_ENVIRONMENT = "REQUEST_DELETE_ENVIRONMENT";
export const REQUEST_ENVIRONMENTS = "REQUEST_ENVIRONMENTS";
export const REQUEST_UPDATE_ENVIRONMENT = "REQUEST_UPDATE_ENVIRONMENT";
export const SUBSCRIBE_ENVIRONMENT_UPDATES = "SUBSCRIBE_ENVIRONMENT_UPDATES";
export const REQUEST_ENVIRONMENT_LOGS = "REQUEST_ENVIRONMENT_LOGS";
export const RECEIVE_ENVIRONMENT_LOGS = "RECEIVE_ENVIRONMENT_LOGS";

// Action types:
interface RequestEnvironmentsAction {
  type: typeof REQUEST_ENVIRONMENTS;
}

interface ReceiveEnvironmentsAction {
  environments: EnvironmentsMap;
  pagination: EnvironmentsPagination;
  searchQuery: string;
  type: typeof RECEIVE_ENVIRONMENTS;
}

interface RequestAddEnvironmentAction {
  type: typeof REQUEST_ADD_ENVIRONMENT;
}

interface RequestDeleteEnvironmentAction {
  type: typeof REQUEST_DELETE_ENVIRONMENT;
}

interface ReceiveAddEnvironmentAction {
  environments: EnvironmentsMap;
  type: typeof RECEIVE_ADD_ENVIRONMENT;
}

interface RequestUpdateEnvironmentAction {
  type: typeof REQUEST_UPDATE_ENVIRONMENT;
}

interface ReceiveUpdateEnvironmentAction {
  environments: EnvironmentsMap;
  type: typeof RECEIVE_UPDATE_ENVIRONMENT;
}

interface ReceiveDeleteEnvironmentAction {
  environmentId: string;
  type: typeof RECEIVE_DELETE_ENVIRONMENT;
}

interface SubscribeEnvironmentUpdatesAction {
  type: typeof SUBSCRIBE_ENVIRONMENT_UPDATES;
}

interface RequestEnvironmentLogsAction {
  type: typeof REQUEST_ENVIRONMENT_LOGS;
}

interface ReceiveEnvironmentLogsAction {
  environmentId: string;
  environmentLogs: { [all: string]: EnvironmentLog[] };
  type: typeof RECEIVE_ENVIRONMENT_LOGS;
}

export type EnvironmentsActionTypes =
  | RequestEnvironmentsAction
  | ReceiveEnvironmentsAction
  | RequestAddEnvironmentAction
  | ReceiveAddEnvironmentAction
  | RequestUpdateEnvironmentAction
  | ReceiveUpdateEnvironmentAction
  | RequestDeleteEnvironmentAction
  | ReceiveDeleteEnvironmentAction
  | SubscribeEnvironmentUpdatesAction
  | RequestEnvironmentLogsAction
  | ReceiveEnvironmentLogsAction;
