import { Tooltip as MTooltip } from "@material-ui/core";
import { Icon, IconSize, SectionListObject, StatusIndicator } from "@sandtable/component-library";
import SectionListServerSide from "@sandtable/component-library/components/SectionList/SectionListServerSide";
import { Cluster, ClusterMap } from "@sandtable/datastore";
import { PageableResource } from "@sandtable/datastore/pagination/types";
import { copyToClipboard, openNewTab } from "@sandtable/utils";
import * as React from "react";

const COPY_ICON_STYLES: React.CSSProperties = { color: "grey", marginLeft: "5px", marginTop: "2px" };

export interface Props {
  clusters: ClusterMap;
  onClusterSelect: (c: Cluster) => void;
  onNewClusterClick: () => void;
  fetchClusters: (page?: number, perPage?: number, stringQuery?: string) => void;
}

// Sort clusters by last available (or created if last available is false)
const sortClusters = (a: Cluster, b: Cluster) => {
  return new Date(b.lastAvailable || b.created).getTime() - new Date(a.lastAvailable || a.created).getTime();
};

const ClustersList = (props: Props) => {
  let objects: SectionListObject[] = [];
  if (typeof props.clusters !== "undefined") {
    objects = Object.values(props.clusters)
    .sort(sortClusters)
    .map((cluster: Cluster, i: number) => {
      const isAvailable = cluster.status === "available";

      const primaryText = (
        <span>
          {isAvailable ? <OpenClusterInterface cluster={cluster} /> : <span>{cluster.masterDomainName}</span>}
          <CopyHostnameIcon cluster={cluster} />
        </span>
      );

      const secondaryText = `${cluster.clusterConfiguration} • ${cluster.project} project • ${cluster.owner}`;

      return {
        data: cluster,
        gaAction: "clusters-list-card",
        gaLabel: cluster.clusterId,
        mainComponent: <StatusIndicator state={cluster.status!} />,
        onCardClick: () => props.onClusterSelect(cluster),
        primaryText,
        secondaryText,
      } as SectionListObject; //tslint:disable-line
    });
  }

  return (
    <>
      <SectionListServerSide
        headerButtonText="New Cluster"
        objects={objects}
        onHeaderButtonClick={props.onNewClusterClick}
        fetchResources={props.fetchClusters}
        showSearch={true}
        searchKeys={[
          "clusterConfiguration",
          "clusterId",
          "environmentId",
          "masterDomainName",
          "owner",
          "project",
          "status",
        ]}
        serverSidePaginationResource={PageableResource.CLUSTERS}
      />
    </>
  );
};

const CopyHostnameIcon = (props: { cluster: Cluster }) => {
  const handleClick = (e: React.ChangeEvent<{}>) => copyToClipboard(e, props.cluster.masterDomainName);
  const isPrivate = props.cluster.publiclyAccessible === false; // Will be undefined for old clusters.
  const tooltipText = "Copy hostname" + (isPrivate ? ". NOTE: Cluster is not publicly accessible." : "");

  return (
    <>
      <span onClick={handleClick} style={COPY_ICON_STYLES}>
        <Icon
          container={false}
          gaAction={"clusters-list-copy-hostname"}
          icon="clone"
          size={IconSize.Small}
          tooltipText={tooltipText}
        />
      </span>
    </>
  );
};

const OpenClusterInterface = (props: { cluster: Cluster }) => (
  <MTooltip title="Open cluster interface">
    <span onClick={(e: React.ChangeEvent<{}>) => openNewTab(e, `https://${props.cluster.masterDomainName}`)}>
      {props.cluster.masterDomainName}
    </span>
  </MTooltip>
);

export default ClustersList;
