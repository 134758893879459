/* tslint:disable */
import { PYPI_URL } from "@sandtable/utils/constants";

const DEFAULT_DOCKERFILE = `RUN echo "http://dl-1.alpinelinux.org/alpine/edge/community" >> /etc/apk/repositories; \ echo "http://dl-2.alpinelinux.org/alpine/edge/community" >> /etc/apk/repositories; \ echo "http://dl-3.alpinelinux.org/alpine/edge/community" >> /etc/apk/repositories; \ echo "http://dl-4.alpinelinux.org/alpine/edge/community" >> /etc/apk/repositories; \ echo "http://dl-5.alpinelinux.org/alpine/edge/community" >> /etc/apk/repositories \ && apk --update add --no-cache --virtual .build-deps-dslib-pip g++\n\n`;
const DOCKERFILE_CLEANUP = "RUN apk del .build-deps-dslib-pip\n\nENV MKL_NUM_THREADS 1";
const DOCKERFILE_CONDA_REQUIREMENTS_PART_1 =
  "COPY requirements-conda.txt requirements-conda.txt\n\nRUN conda install --quiet --yes";
const DOCKERFILE_CONDA_REQUIREMENTS_PART_2 =
  " --file requirements-conda.txt && conda install --quiet --yes conda-build && conda build purge-all\n\n";
const DOCKERFILE_PIP_REQUIREMENTS = `COPY requirements.txt requirements.txt\n\nRUN conda install --yes pip\n\nRUN pip install --index-url ${PYPI_URL} --no-cache-dir -r requirements.txt\n\n`;

export const buildDockerfile = (
  condaRequirements?: string[],
  pipRequirements?: string[],
  condaChannel?: string,
): string => {
  const conda = condaRequirements && condaRequirements.length;
  const pip = pipRequirements && pipRequirements.length;

  const condaChannelSection = condaChannel ? ` --channel ${condaChannel}` : "";
  const condaSection = conda
    ? [DOCKERFILE_CONDA_REQUIREMENTS_PART_1, condaChannelSection, DOCKERFILE_CONDA_REQUIREMENTS_PART_2].join("")
    : "";
  const pipSection = pip ? DOCKERFILE_PIP_REQUIREMENTS : "";

  return [DEFAULT_DOCKERFILE, condaSection, pipSection, DOCKERFILE_CLEANUP].join("");
};
