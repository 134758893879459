import { Typography as MTypography } from "@material-ui/core";
import { New, TextField, TextFieldAutocomplete, TextFieldAutocompleteOption } from "@sandtable/component-library";
import { Repo, RepoMap, RepoObject, Upload } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  commitUpload: (uploadId: string, filename: string, repo: string) => void;
  repos: RepoMap;
  staged: Upload;
}

const CommitTab = (props: Props) => {
  const [fileName, setFileName] = React.useState(props.staged.originalFilename);
  const [repo, setRepo] = React.useState<TextFieldAutocompleteOption | null>(null);

  const { repos } = props;
  const repoNames: string[] = React.useMemo(() => {
    const e: Set<string> = new Set();

    Object.values(repos).forEach((r: Repo) => {
      r.objects.forEach((ro: RepoObject) => {
        e.add(ro.uri.match(/(?:.*):\/\/(.*)\//)![1]);
      });
    });

    return Array.from(e).sort();
  }, [repos]);

  const onSubmit = () => {
    const [r, f, _]: string[] = `${repo!.value}/${fileName}`.split(/\/(.+)/);
    props.commitUpload(props.staged.id, f, r);
    setFileName("");
    setRepo(null);
  };

  return (
    <New
      decrementStepGaAction={"view-staged-drawer-commit-tab-previous-step"}
      error={!fileName || !repo}
      incrementStepGaAction={"view-staged-drawer-commit-tab-next-step"}
      onSubmit={onSubmit}
      stepContents={[
        <TextFieldAutocomplete
          placeholder="Sandgit repo/path"
          style={{ marginBottom: "14px" }}
          onChange={setRepo}
          options={repoNames.map((e: string) => ({
            label: e,
            value: e,
          }))}
          value={repo}
          key={"select-repo"}
        />,
        <TextField
          label={"New file name (e.g. file.txt)"}
          onChange={(e: any) => setFileName(e.target.value)}
          placeholder={"New file name (e.g. file.txt)"}
          style={{ marginBottom: "14px" }}
          value={fileName}
          key={"enter-filename"}
        />,
        <MTypography align={"center"} style={{ marginBottom: "14px" }} key={"confirm"}>
          The file will be committed at {repo ? repo.value : ""}/{fileName} .
        </MTypography>,
      ]}
      stepNames={["Select repo/path", "Enter filename", "Confirm"]}
    />
  );
};

export default CommitTab;
