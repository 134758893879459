import { Typography as MTypography } from "@material-ui/core";
import { NewDrawer } from "@sandtable/component-library";
import { Environment, EnvironmentsMap } from "@sandtable/datastore";
import EnvironmentDockerfileTab from "components/Environments/NewEnvironmentDrawer/EnvironmentDockerfileTab";
import EnvironmentDependenciesTab from "components/Environments/UpdateEnvironmentDrawer/EnvironmentDependenciesTab";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  environments: EnvironmentsMap;
  onClose: () => void;
  onEnvironmentUpdate: (e: Environment) => void;
}

const UpdateEnvironmentDrawer = (props: Props): JSX.Element => {
  const { uid } = useParams();
  const [updatedEnvironment, setUpdatedEnvironment] = React.useState(props.environments[uid!]); // TODO: Handle undef

  const handleChange = (e: Partial<Environment>) => setUpdatedEnvironment((state: Environment) => ({ ...state, ...e }));

  return (
    <NewDrawer
      onClose={props.onClose}
      error={false}
      decrementStepGaAction={"update-environment-drawer-previous-step"}
      incrementStepGaAction={"update-environment-drawer-next-step"}
      maxWidth={"800px"}
      onSubmit={() => props.onEnvironmentUpdate(updatedEnvironment)}
      open={true}
      stepContents={[
        <EnvironmentDependenciesTab
          environment={updatedEnvironment}
          onChange={handleChange}
          key={"dependencies-tab"}
        />,
        <>
          <MTypography>Note: do not remove `FROM {}` from the Dockerfile.</MTypography>
          <EnvironmentDockerfileTab environment={updatedEnvironment} onChange={handleChange} key={"dockerfile-tab"} />
        </>,
      ]}
      stepNames={["Dependencies", "Dockerfile"]}
      title={"Update Environment"}
    />
  );
};

export default UpdateEnvironmentDrawer;
