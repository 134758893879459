import { User } from "@sandtable/datastore";
import { GOOGLE_ANALYTICS_ID } from "@sandtable/utils/constants"; // tslint:disable-line
import sha256 from "crypto-js/sha256"; //tslint:disable-line
import * as React from "react";
import ReactGA from "react-ga";

interface Props {
  children: JSX.Element;
  user: User;
}

interface Analytics {
  trackClick: (action: string, category: string, label: string) => void;
  trackTopNavClick: (item: string) => void;
  trackPageView: (pathname: string) => void;
}

export const AnalyticsContext = React.createContext({} as Analytics); // tslint:disable-line

export const useAnalytics = () => React.useContext(AnalyticsContext);

const AnalyticsProvider = (props: Props) => {
  const { user } = props;
  React.useEffect(() => {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
      gaOptions: {
        userId: sha256(user.email).toString(),
      },
    });
  }, [user]);

  const handleClick = (event: any) => {
    const rootNode = document;
    let element = event.target;

    while (element && element !== rootNode) {
      const category = element.getAttribute("data-ga-event-category");

      if (category) {
        const action = element.getAttribute("data-ga-event-action");
        const label = element.getAttribute("data-ga-event-label");
        if (action) {
          ReactGA.event({
            action,
            category,
            label,
          });
        }
        break;
      }

      element = element.parentNode;
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", (event: any) => handleClick(event));
  }, []);

  return (
    <AnalyticsContext.Provider
      {...props}
      value={{
        trackClick: (action: string, category: string, label: string) => {
          ReactGA.event({
            action,
            category,
            label,
          });
        },
        trackPageView: (pathname: string) => {
          ReactGA.pageview(pathname);
        },
        trackTopNavClick: (item: string) => {
          ReactGA.event({
            action: item.toLowerCase(),
            category: "nav",
            label: "",
          });
        },
      }}
    />
  );
};

export default AnalyticsProvider;
