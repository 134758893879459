import { Loading, NavTabs } from "@sandtable/component-library";
import { RepoMap, UploadMap, WhitelistMap } from "@sandtable/datastore";
import RepoList from "components/Data/RepoList";
import Staged from "components/Data/Staged";
import Whitelist from "components/Data/Whitelist";
import * as React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";

const DATA_PATH = "/data";
const REPO_PATH = "/data/repos";
const STAGED_PATH = "/data/staged";
const WHITELIST_PATH = "/data/whitelist";

interface Props {
  addUserToWhitelist: (email: string) => void;
  commitUpload: (uploadId: string, filename: string, repo: string) => void;
  deleteUpload: (uploadId: string) => void;
  deleteUserFromWhitelist: (email: string) => void;
  fetchRepos: () => void;
  fetchUploads: () => void;
  fetchWhitelist: () => void;
  isFetching: boolean;
  refreshRepos: () => void;
  refreshUploads: () => void;
  repos: RepoMap;
  uploads: UploadMap;
  whitelist: WhitelistMap;
}

const Data = (props: Props) => {
  const history = useHistory();

  const handleChange = (n: number) => {
    switch (n) {
      case 0:
        props.refreshRepos();
        history.push(REPO_PATH);
        return;
      case 1:
        props.refreshUploads();
        history.push(STAGED_PATH);
        return;
      case 2:
        props.refreshUploads();
        history.push(WHITELIST_PATH);
        return;
      default:
        throw Error();
    }
  };

  if (props.isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Route path={DATA_PATH} exact={true}>
        <Redirect to={REPO_PATH} />
      </Route>
      <NavTabs tabs={["Repos", "Staged", "Whitelist"]} onChange={handleChange} />
      <Route path={REPO_PATH}>
        <RepoList {...props} />
      </Route>
      <Route path={STAGED_PATH}>
        <Staged {...props} />
      </Route>
      <Route path={WHITELIST_PATH}>
        <Whitelist {...props} />
      </Route>
    </>
  );
};

export default Data;
