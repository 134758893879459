import {
  RECEIVE_ADD_EXPERIMENT,
  RECEIVE_EXPERIMENT_UPDATE,
  RECEIVE_EXPERIMENTS,
  REQUEST_ADD_EXPERIMENT,
  REQUEST_EXPERIMENTS,
  SUBSCRIBE_EXPERIMENT_UPDATES,
} from "@sandtable/datastore/experiments";
import { EXPERIMENT, EXPERIMENT_ARRAY } from "@sandtable/datastore/experiments/schema";
import {
  API_GET,
  API_POST,
  ApiMiddlewareAction,
  WS_SUBSCRIBE,
  WsMiddlewareAction,
} from "@sandtable/datastore/middleware";

export const fetchExperiments = () => {
  return (dispatch: (action: ApiMiddlewareAction) => void) =>
    dispatch({
      [API_GET]: {
        endpoint: "experiments/netlogo",
        schema: EXPERIMENT_ARRAY,
        types: [REQUEST_EXPERIMENTS, RECEIVE_EXPERIMENTS, RECEIVE_EXPERIMENTS],
      },
    });
};

export const addExperiment = (fileId: string, experimentName: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: "experiments/netlogo",
        params: { experiment_name: experimentName, file_id: fileId },
        schema: EXPERIMENT,
        successMessage: "Experiment created",
        types: [REQUEST_ADD_EXPERIMENT, RECEIVE_ADD_EXPERIMENT, RECEIVE_ADD_EXPERIMENT],
      },
    });
  };
};

export const subscribeToExperimentUpdates = (orgName: string) => {
  return (dispatch: (action: WsMiddlewareAction) => void) => {
    dispatch({
      [WS_SUBSCRIBE]: {
        endpoint: "experiments",
        room: orgName,
        schema: EXPERIMENT,
        types: [SUBSCRIBE_EXPERIMENT_UPDATES, RECEIVE_EXPERIMENT_UPDATE, RECEIVE_EXPERIMENT_UPDATE],
      },
    });
  };
};
