import { getAuthToken } from "@sandtable/utils";
import { WS_BASE_URL } from "@sandtable/utils/constants"; // tslint:disable-line
import humps from "humps";
import { normalize, Schema } from "normalizr";
import io from "socket.io-client";

export const WS_SUBSCRIBE = "WS_SUBSCRIBE";

type ActionSchema = Schema | Schema[];

export interface WsMiddlewareAction {
  [WS_SUBSCRIBE]: {
    endpoint: string;
    room?: string;
    types: string[];
    schema?: ActionSchema;
    callback?: (res: any) => void;
  };
}

export default (store: any) => (next: any) => (action: any) => {
  const wsAction = action[WS_SUBSCRIBE];
  if (typeof wsAction === "undefined") {
    return next(action);
  }

  const { endpoint, room, types, schema, callback } = wsAction;

  if (!endpoint) {
    throw new Error("Specify an endpoint");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type: any) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }
  if (!schema) {
    throw new Error("Specify one of the exported Schemas.");
  }

  const [requestType, successType, failureType] = types; // tslint:disable-line

  next({
    type: requestType,
  });

  (async () => {
    const token = await getAuthToken();
    const socket = io(`${WS_BASE_URL}/${endpoint}?room=${encodeURIComponent(room)}&token=${token}`);
    socket.on("update", (data: string) => {
      const response = normalize(humps.camelizeKeys(JSON.parse(data)), schema).entities;
      if (callback) {
        callback(response);
      }
      next({
        ...response,
        type: successType,
      });
    });
  })();
};
