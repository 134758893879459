import { acceptTermsConditions, AppState, User } from "@sandtable/datastore";
import TermsConditions from "components/TermsConditions/TermsConditions";
import * as React from "react";
import { connect } from "react-redux";

interface Props {
  acceptTermsConditions: () => void;
  user: User;
}

const TermsConditionsContainer = (props: Props) => <TermsConditions {...props} />;

const mapStateToProps = (state: AppState) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  acceptTermsConditions,
})(TermsConditionsContainer);
