import { Drawer, DrawerContainer, Tabs } from "@sandtable/component-library";
import * as React from "react";

interface Props {
  actionSection?: JSX.Element;
  onClose: any;
  maxWidth?: string;
  open: boolean;
  tabContents: JSX.Element[];
  tabGaAction: string;
  tabNames: string[];
  title: string;
}

const ViewDrawer = (props: Props) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  if (props.tabNames.length !== props.tabContents.length) {
    throw new Error("Length of tab names and tab contents must be equal.");
  }

  // Reset state on drawer re-open
  React.useEffect(() => {
    if (props.open) {
      setCurrentTab(0);
    }
  }, [props.open]);

  return (
    <Drawer onClose={props.onClose} open={props.open} title={props.title}>
      <DrawerContainer style={{ maxWidth: props.maxWidth }}>
        <Tabs value={currentTab} labels={props.tabNames} onChange={setCurrentTab} gaAction={props.tabGaAction} />
        {props.tabContents[currentTab]}
        {props.actionSection ? (
          <>
            <Tabs labels={["Actions"]} />
            {props.actionSection}
          </>
        ) : (
          ""
        )}
      </DrawerContainer>
    </Drawer>
  );
};

export default ViewDrawer;
