import { Button, TextField, withDrawer } from "@sandtable/component-library";
import * as React from "react";

interface Props {
  addOrganisation: (organisationName: string) => void;
  onClose: () => void;
}

const NewOrganisationDrawer = (props: Props) => {
  const [organisationName, setOrganisationName] = React.useState("");

  return (
    <>
      <TextField
        label="New organisation name"
        onChange={(e: React.ChangeEvent<any>) => setOrganisationName(e.target.value)}
        placeholder="New organisation name"
        style={{ marginBottom: "14px" }}
      />
      <Button
        disabled={!organisationName.length}
        gaAction={"new-organisation-drawer-experiments-tab-create"}
        fullWidth={true}
        text="Create organisation"
        onButtonClick={() => {
          props.addOrganisation(organisationName);
          props.onClose();
        }}
        iconRight="check"
      />
    </>
  );
};

export default withDrawer(NewOrganisationDrawer, "New Organisation");
