import {
  ExecutionActionTypes,
  ExecutionState,
  RECEIVE_DELETE_EXECUTION,
  RECEIVE_EXECUTIONS_FOR_APP,
} from "@sandtable/datastore/executions";

const initialState: ExecutionState = {
  executions: {},
};

export const executionsReducer = (state = initialState, action: ExecutionActionTypes): ExecutionState => {
  switch (action.type) {
    case RECEIVE_EXECUTIONS_FOR_APP:
      return {
        ...state,
        executions: {
          ...state.executions,
          [action.applicationId]: action.executions || {},
        },
      };
    case RECEIVE_DELETE_EXECUTION:
      const executions = { ...state.executions };
      delete executions[action.applicationId][action.executionId];
      return {
        ...state,
        executions,
      };
    default:
      return state;
  }
};
