import { API_DELETE, API_GET, API_PUT, ApiMiddlewareAction } from "@sandtable/datastore/middleware";
import {
  RECEIVE_DELETE_UPLOAD,
  RECEIVE_UPLOAD_COMMIT,
  RECEIVE_UPLOADS,
  REFRESH_UPLOADS,
  REQUEST_DELETE_UPLOAD,
  REQUEST_UPLOAD_COMMIT,
  REQUEST_UPLOADS,
} from "@sandtable/datastore/uploads";
import { UPLOAD_ARRAY } from "@sandtable/datastore/uploads/schema";

export const fetchUploads = (refresh: boolean = false) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: "uploads",
        schema: UPLOAD_ARRAY,
        types: [refresh ? REFRESH_UPLOADS : REQUEST_UPLOADS, RECEIVE_UPLOADS, RECEIVE_UPLOADS],
      },
    });
  };
};

export const commitUpload = (uploadId: string, filename: string, repo: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_PUT]: {
        endpoint: `uploads/${uploadId}/commit`,
        failureMessage: `Failed to commit file. Is the file name unique under repo ${repo}?`,
        params: {
          filename,
          repo,
        },
        successActionParams: {
          uploadId,
        },
        successMessage: `Successfully committed file to repo ${repo}`,
        types: [REQUEST_UPLOAD_COMMIT, RECEIVE_UPLOAD_COMMIT, RECEIVE_UPLOAD_COMMIT],
      },
    });
  };
};

export const deleteUpload = (uploadId: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_DELETE]: {
        endpoint: `uploads/${uploadId}`,
        failureMessage: "Failed to delete file.",
        successActionParams: {
          uploadId,
        },
        successMessage: "Successfully deleted file.",
        types: [REQUEST_DELETE_UPLOAD, RECEIVE_DELETE_UPLOAD, RECEIVE_DELETE_UPLOAD],
      },
    });
  };
};
