import { Menu as MMenu } from "@material-ui/core";
import * as React from "react";

interface Props {
  anchorEl: HTMLElement | undefined;
  children: JSX.Element[];
  onClose: () => void;
  open: boolean;
}

const NavMenu = ({ anchorEl, onClose, open, children }: Props): JSX.Element => {
  return (
    <MMenu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
      {children}
    </MMenu>
  );
};

export default NavMenu;
