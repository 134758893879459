import { NewDrawer } from "@sandtable/component-library";
import { EnvironmentsV2Map, EnvironmentV2 } from "@sandtable/datastore";
import EnvironmentV2DockerfileTab from "components/EnvironmentsV2/NewEnvironmentV2Drawer/EnvironmentV2DockerfileTab";
import EnvironmentV2YamlTab, {
  isCondaEnvironmentFileValid,
} from "components/EnvironmentsV2/NewEnvironmentV2Drawer/EnvironmentV2YamlTab";
import * as React from "react";
import { useParams } from "react-router-dom";

interface State {
  environment: EnvironmentV2;
  error: boolean;
}

interface Props {
  environments: EnvironmentsV2Map;
  onClose: (e: EnvironmentV2) => void;
  onUpdateEnvironmentSubmit: (environment: EnvironmentV2) => void;
}

const UpdateEnvironmentV2Drawer = (props: Props) => {
  const { uid } = useParams();
  const [state, setState] = React.useState<State>({
    environment: props.environments[uid!], // TODO: Handle undefined.
    error: isCondaEnvironmentFileValid(props.environments[uid!].condaEnvironmentFile),
  });

  const handleChange = (e: Partial<EnvironmentV2>, err?: boolean) =>
    setState((prev: State) => {
      const environment = { ...prev.environment, ...e };
      const error = err !== undefined ? err : prev.error;
      return { ...prev, environment, error };
    });

  const handleSubmit = () => {
    props.onUpdateEnvironmentSubmit(state.environment);
    props.onClose(state.environment);
  };

  return (
    <NewDrawer
      onClose={() => props.onClose(state.environment)}
      decrementStepGaAction={"update-environment-v2-drawer-previous-step"}
      error={state.error}
      incrementStepGaAction={"update-environment-v2-drawer-next-step"}
      maxWidth={"600px"}
      onSubmit={handleSubmit}
      open={true}
      stepContents={[
        <EnvironmentV2YamlTab
          {...state}
          key={"environment-v2-yaml-tab"}
          showIsPublicSwitch={false}
          onChange={handleChange}
        />,
        <EnvironmentV2DockerfileTab {...state} key={"environment-v2-dockerfile-tab"} onChange={handleChange} />,
      ]}
      stepNames={["Environment YAML", "Environment Dockerfile"]}
      title={"Update Environment"}
    />
  );
};

export default UpdateEnvironmentV2Drawer;
