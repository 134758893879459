import { Organisation, Project } from "@sandtable/datastore";
import NewOrganisationDrawer from "components/Management/NewOrganisationDrawer";
import OrganisationsList from "components/Management/OrganisationsList";
import ViewOrganisationDrawer from "components/Management/ViewOrganisationDrawer/ViewOrganisationDrawer";
import ViewProjectDrawer from "components/Management/ViewProjectDrawer";
import * as React from "react";
import { generatePath, Route, useHistory } from "react-router-dom";

// TODO: Inherit path(s) from parent?
const ORGANISATIONS_PATH = "/management/organisations";
const NEW_ORGANISATION_PATH = "/management/organisations/new-organisation";
const VIEW_ORGANISATION_PATH = "/management/organisations/view-organisation/:id";
// TODO: Un-nest projects from organisations in DS, avoiding the need to do this:
const VIEW_PROJECT_PATH = "/management/organisations/view-organisation/:id/view-project/:pid";

interface Props {
  addMemberToOrganisation: (organisationId: string, memberEmail: string) => any;
  addMemberToProject: (organisationId: string, projectId: string, memberEmail: string) => any;
  addOrganisation: (name: string) => any;
  addProjectToOrganisation: (organisationId: string, name: string) => any;
  fetchOrganisations: () => void;
  organisations: Organisation[];
  removeMemberFromOrganisation: (organisationId: string, memberId: string) => any;
  removeMemberFromProject: (organisationId: string, projectId: string, memberId: string) => any;
  updateMemberOfOrganisation: (organisationId: string, memberId: string, owner: boolean) => any;
  updateMemberOfProject: (organisationId: string, projectId: string, memberId: string, owner: boolean) => any;
}

const OrganisationsTab = (props: Props) => {
  const history = useHistory();

  const handleGoBack = () => history.push(ORGANISATIONS_PATH);
  const handleNewOrganisationClick = () => history.push(NEW_ORGANISATION_PATH);
  const handleOrganisationSelect = (o: Organisation) =>
    history.push(generatePath(VIEW_ORGANISATION_PATH, { id: o.id }));
  const handleProjectSelect = (o: Organisation, p: Project) =>
    history.push(generatePath(VIEW_PROJECT_PATH, { id: o.id, pid: p.id }));

  return (
    <>
      <OrganisationsList
        {...props}
        onOrganisationSelect={handleOrganisationSelect}
        onNewOrganisationClick={handleNewOrganisationClick}
      />
      <Route path={NEW_ORGANISATION_PATH}>
        <NewOrganisationDrawer {...props} open={true} onClose={handleGoBack} />
      </Route>
      <Route path={VIEW_ORGANISATION_PATH} exact={true}>
        <ViewOrganisationDrawer {...props} onClose={handleGoBack} onProjectSelect={handleProjectSelect} />
      </Route>
      <Route path={VIEW_PROJECT_PATH} exact={true}>
        <ViewProjectDrawer {...props} onClose={handleGoBack} />
      </Route>
    </>
  );
};

export default OrganisationsTab;
