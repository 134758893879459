import { Search, Slider, Table } from "@sandtable/component-library";
import { Configuration, ConfigurationMap, NewCluster } from "@sandtable/datastore";
import { customSort, useFilter } from "@sandtable/utils";
import { SEARCH_OPTIONS } from "components/Clusters/NewClusterDrawer/filter";
import * as React from "react";

interface Props {
  cluster: NewCluster;
  configurations: ConfigurationMap;
  onChange: (c: Partial<NewCluster>, e: boolean) => void;
}

const EXPERIMENT_PLANNER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-around",
};
const SEARCH_MARGIN_STYLES: React.CSSProperties = {
  marginTop: "14px",
};
const EXPERIMENT_PLANNER_TABLE_STYLES: React.CSSProperties = {
  display: "flex",
  margin: "0 -24px 24px -24px",
  maxHeight: "60vh",
};

const filterConfiguration = (maxMemory: number, numberOfCores: number) => {
  return (c: Configuration) => {
    return c.vcpus >= numberOfCores && c.totalMemory >= maxMemory;
  };
};

const mapConfigurationToArray = (c: Configuration) => {
  return [
    c.name,
    c.vcpus,
    (c.totalMemory / c.vcpus).toFixed(2) + " GB",
    c.totalMemory + " GB",
  ];
};

const ClusterExperimentPlanner = (props: Props) => {
  const { cluster, configurations, onChange } = props;
  React.useEffect(() => {
    onChange({}, !cluster.clusterConfiguration);
  }, []);

  const configurationValues = React.useMemo(() => Object.values(configurations), [configurations]);
  const [setFilterString, filteredConfigurations] = useFilter(configurationValues, SEARCH_OPTIONS);
  const [numberOfCores, setNumberOfCores] = React.useState(1);
  const [maxMemory, setMaxMemory] = React.useState(1);

  const filteredConfigNames = React.useMemo(
    () => filteredConfigurations.filter(filterConfiguration(maxMemory, numberOfCores)).map(mapConfigurationToArray),
    [numberOfCores, maxMemory, filteredConfigurations],
  );

  return (
    <>
      <div style={EXPERIMENT_PLANNER_STYLES}>
        <Search handleSearch={setFilterString} placeholder={"Search names"} style={SEARCH_MARGIN_STYLES} />
        <Slider
          label="Number of cores"
          max={100}
          min={1}
          onChange={setNumberOfCores}
          secondaryLabel={`${numberOfCores} core${numberOfCores > 1 ? "s" : ""}`}
          step={1}
          value={numberOfCores}
          width="350px"
        />
        <Slider
          label="Minimum memory (RAM)"
          max={1000}
          min={1}
          onChange={setMaxMemory}
          secondaryLabel={`${maxMemory} GB`}
          step={1}
          value={maxMemory}
          width="350px"
        />
      </div>
      <div style={EXPERIMENT_PLANNER_TABLE_STYLES}>
        <Table
          columns={["Name", "Cores", "Memory/core", "Memory"]}
          customSort={customSort}
          data={filteredConfigNames}
          onRowClick={(row: string[]) => {
            props.onChange({ clusterConfiguration: row[0] }, false);
          }}
          selectedRow={filteredConfigNames.findIndex((c: any) => c[0] === props.cluster.clusterConfiguration)}
          style={{ flex: 1 }}
        />
      </div>
    </>
  );
};

export default ClusterExperimentPlanner;
