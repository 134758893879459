import { Button, CardList, CardListObject, Chip, withDrawer } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import { EnvironmentsObject, mapEnvironmentStateToColorClass } from "@sandtable/utils";
import * as React from "react";
import { useParams } from "react-router-dom";

const MAX_CHECKED_BOXES = 2;

const ROOT_STYLE = { display: "flex" };
const CARD_LIST_STYLE = { marginRight: "14px" };
const CHIP_STYLE = {
  backgroundColor: "#4E9BF6",
  height: "22px",
  width: "50px",
};

interface State {
  [key: string]: boolean;
}

interface Props {
  onClose: () => void;
  environmentsObject: EnvironmentsObject;
  onEnvironmentSelect: (e: Environment) => void;
  onDiffEnvironmentsClick: (oldUid: string, newUid: string) => void;
}

const ViewEnvironmentsDrawer = (props: Props) => {
  const { name } = useParams();
  const environments = props.environmentsObject[name!] || []; // TODO: Handle undefined

  const [state, setState] = React.useState<State>({});

  const [maxCheckedBoxes, sortedKeys] = React.useMemo((): [boolean, string[]] => {
    const uids = environments.map((env: Environment) => env.uid);
    const selectedKeys = Object.keys(state).filter((key: string) => state[key]);
    const newSortedKeys = selectedKeys.sort((a, b) => uids.indexOf(a) - uids.indexOf(b)); // Assumes `uids` is sorted...
    return [selectedKeys.length === MAX_CHECKED_BOXES, newSortedKeys];
  }, [state]);

  return (
    <>
      {maxCheckedBoxes && (
        <div style={{ ...ROOT_STYLE, flexDirection: "row-reverse" }}>
          <Button
            gaAction="view-environment-drawer-diff-environments"
            text="Diff environments"
            onButtonClick={() => props.onDiffEnvironmentsClick(sortedKeys[1]!, sortedKeys[0]!)}
          />
        </div>
      )}
      <CardList
        objects={environments.map(
          (e: Environment) =>
            ({
              checkboxDisabled: maxCheckedBoxes && !state[e.uid],
              checkboxTooltip: "Diff environment",
              gaAction: "view-environments-drawer-card",
              gaLabel: e.uid,
              mainComponent:
                maxCheckedBoxes && state[e.uid] ? (
                  <div style={CARD_LIST_STYLE}>
                    {<Chip label={sortedKeys.indexOf(e.uid) ? "old" : "new"} style={CHIP_STYLE} />}
                  </div>
                ) : undefined,
              onCardClick: () => props.onEnvironmentSelect(e),
              onCheckboxClick:
                environments.length > 1 ? () => setState({ ...state, [e.uid]: !state[e.uid] }) : undefined,
              primaryIcon: "circle",
              primaryIconClassName: mapEnvironmentStateToColorClass(e.status),
              primaryIconTooltip: e.status,
              primaryText: `${e.runtimeVersion} • ${new Date(e.created).toUTCString()}`,
              secondaryText: `${e.pythonVersion} • ${e.uid}`,
            } as CardListObject), // tslint:disable-line
        )}
      />
    </>
  );
};

export default withDrawer(ViewEnvironmentsDrawer, "View Environments");
