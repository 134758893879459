import {
  ConfigurationsActionTypes,
  ConfigurationState,
  RECEIVE_CONFIGURATIONS,
} from "@sandtable/datastore/configurations";

const initialState: ConfigurationState = {
  configurations: {},
};

export const configurationsReducer = (state = initialState, action: ConfigurationsActionTypes): ConfigurationState => {
  switch (action.type) {
    case RECEIVE_CONFIGURATIONS:
      return {
        ...state,
        configurations: action.configurations,
      };
    default:
      return state;
  }
};
