import { Syntax, Tabs } from "@sandtable/component-library";
import { EnvironmentV2, EnvironmentV2Log } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  environment: EnvironmentV2;
  getEnvironmentV2: (environment: EnvironmentV2) => void;
}

const EnvironmentV2LogsTab = (props: Props) => {
  const [tab, setTab] = React.useState(+(props.environment.status === "failed"));

  const { environment, getEnvironmentV2 } = props;
  React.useEffect(() => getEnvironmentV2(environment), []);

  const log = tab ? props.environment.buildLog : props.environment.buildLogShort;

  return (
    <>
      <Tabs labels={["Short log", "Build log"]} value={tab} onChange={setTab} />
      <Syntax
        height={"600px"}
        width={"640px"}
        mode={"javascript"}
        onRefreshClick={() => props.getEnvironmentV2(props.environment)}
        text={
          log
            ? log.map((l: EnvironmentV2Log) => `${new Date(l.timestamp * 1000).toUTCString()}:\n${l.message}`)
            : "Loading..."
        }
      />
    </>
  );
};

export default EnvironmentV2LogsTab;
