import { LinearProgress } from "@material-ui/core";
import * as React from "react";

const CONTAINER_STYLES: React.CSSProperties = {
  margin: "-20px",
};

const Loading = () => (
  <div style={CONTAINER_STYLES}>
    <LinearProgress color="secondary" />
  </div>
);

export default Loading;
