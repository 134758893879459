import { Slider as MSlider, Typography as MTypography } from "@material-ui/core";
import * as React from "react";

const CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  flex: "0 0 26%",
  flexDirection: "column",
  marginBottom: "22px",
};

interface Props {
  label: string;
  secondaryLabel: string;
  max: number;
  min: number;
  onChange: (n: number) => void;
  step: number;
  value: number;
  width: string;
}

const Slider = (props: Props) => {
  return (
    <div style={{ ...CONTAINER_STYLES, width: props.width }}>
      <div style={{ display: "flex" }}>
        <MTypography>{props.label}</MTypography>
        <MTypography style={{ marginLeft: "auto" }}>{props.secondaryLabel}</MTypography>
      </div>
      <MSlider
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        onChange={(e: any, v: number | number[]) => {
          if (typeof v !== "number") {
            throw new Error("Slider callback: unhandled data type.");
          }
          props.onChange(v!);
        }}
        style={{ marginTop: "22px" }}
      />
    </div>
  );
};

export default Slider;
