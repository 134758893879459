import { IconButton as MIconButton, Snackbar as MSnackbar } from "@material-ui/core";
import MCloseIcon from "@material-ui/icons/Close"; // tslint:disable-line
import * as React from "react";

export interface Props {
  handleClose: (e: any) => void;
  message: string;
  open: boolean;
}

const Snackbar = ({ handleClose, open, message }: Props) => {
  const action = (
    <MIconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
      <MCloseIcon />
    </MIconButton>
  );
  return (
    <MSnackbar
      action={action}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={3300}
      ContentProps={{ "aria-describedby": "message-id" }}
      message={<span id="message-id">{message}</span>}
      transitionDuration={300}
      onClose={handleClose}
      open={open}
    />
  );
};

export default Snackbar;
