import { SectionList, SectionListObject } from "@sandtable/component-library";
import { Experiment, ExperimentMap } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  experiments: ExperimentMap;
  fetchExperiments: () => void;
  onExperimentSelect: (e: Experiment) => void;
  onNewExperimentClick: () => void;
}

const ExperimentsList = (props: Props) => {
  let objects: SectionListObject[] = [];
  if (typeof props.experiments !== "undefined") {
    objects = Object.values(props.experiments).map((e: Experiment, i: number) => {
      return {
        data: e,
        gaAction: "experiments-list-card",
        gaLabel: e.id,
        onCardClick: () => props.onExperimentSelect(e),
        primaryText: `${e.id.split("-")[0]} • ${e.name}`,
        secondaryText: `${new Date(e.created).toUTCString()} • ${e.status} • ${((e.progress || 0) * 100).toFixed(0)}%`,
      } as SectionListObject; //tslint:disable-line
    });
  }

  return (
    <SectionList
      headerButtonText="New experiment"
      objects={objects}
      onHeaderButtonClick={props.onNewExperimentClick}
      fetchResources={props.fetchExperiments}
      showSearch={true}
      searchKeys={["creator", "name", "status"]}
    />
  );
};

export default ExperimentsList;
