import { schema } from "normalizr";

const applicationSchema = new schema.Entity(
  "applications",
  {},
  {
    idAttribute: (application: any) => application.id,
  },
);

const applicationUserSchema = new schema.Entity(
  "applicationUsers",
  {},
  {
    idAttribute: (applicationUser: any) => applicationUser.email,
  },
);

export const APPLICATION = applicationSchema;
export const APPLICATION_ARRAY = [applicationSchema];

export const APPLICATION_USER = applicationUserSchema;
export const APPLICATION_USER_ARRAY = [applicationUserSchema];
