import { Button } from "@sandtable/component-library";
import { PlatformUser } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  deactivateUser: (user: PlatformUser) => void;
  reactivateUser: (user: PlatformUser) => void;
  user: PlatformUser;
}

const UserActionSection = (props: Props) => {
  return (
    <Button
      fullWidth={true}
      gaAction={props.user.deactivated ? "view-user-drawer-reactivate-user" : "view-user-drawer-deactivate-user"}
      iconRight={props.user.deactivated ? "check" : "ban"}
      text={props.user.deactivated ? "Reactivate user" : "Deactivate user"}
      onButtonClick={() =>
        props.user.deactivated ? props.reactivateUser(props.user) : props.deactivateUser(props.user)
      }
    />
  );
};

export default UserActionSection;
