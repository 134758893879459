import {
  addEnvironment,
  AppState,
  deleteEnvironment,
  Environment,
  EnvironmentsMap,
  fetchEnvironmentLogs,
  fetchEnvironments,
  subscribeToEnvironmentUpdates,
  updateEnvironment,
  User,
} from "@sandtable/datastore";
import {
  EnvironmentsObject,
  mapEnvironmentsToObject,
  mapEnvironmentsToPythonVersionObject,
  PythonVersionToEnvironmentsObject,
} from "@sandtable/utils";
import Environments from "components/Environments/Environments";
import * as React from "react";
import { connect } from "react-redux";

interface Props {
  addEnvironment: (e: Environment) => void;
  baseEnvironmentsObject: PythonVersionToEnvironmentsObject;
  deleteEnvironment: (e: Environment) => void;
  environments: EnvironmentsMap;
  environmentsObject: EnvironmentsObject;
  fetchEnvironmentLogs: (e: Environment) => void;
  fetchEnvironments: (page?: number, perPage?: number, searchQuery?: string) => void;
  isFetching: boolean;
  subscribedToEnvironmentUpdates: boolean;
  subscribeToEnvironmentUpdates: (orgName: string) => void;
  updateEnvironment: (e: Environment) => void;
  user: User;
}

const EnvironmentsContainer = (props: Props) => {
  const { fetchEnvironments, subscribedToEnvironmentUpdates, subscribeToEnvironmentUpdates, user } = props;
  const PAGE_DEFAULT = 1;
  const PER_PAGE_DEFAULT = 10;

  React.useEffect(() => {
    fetchEnvironments(PAGE_DEFAULT, PER_PAGE_DEFAULT);
    if (!subscribedToEnvironmentUpdates) {
      subscribeToEnvironmentUpdates(user.organisation.name);
    }
  }, []);

  return <Environments {...props} />;
};

const mapStateToProps = (state: AppState) => {
  let environmentsObject: EnvironmentsObject = {};
  if (typeof (state.environments.environments) !== "undefined") {
    environmentsObject = mapEnvironmentsToObject(Object.values(state.environments.environments));
  }

  return {
    baseEnvironmentsObject: mapEnvironmentsToPythonVersionObject(environmentsObject["base"] || []), // tslint:disable-line
    environments: state.environments.environments,
    environmentsObject,
    isFetching: state.environments.isFetching,
    subscribedToEnvironmentUpdates: state.environments.subscribedToEnvironmentUpdates,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  addEnvironment,
  deleteEnvironment,
  fetchEnvironmentLogs,
  fetchEnvironments,
  subscribeToEnvironmentUpdates,
  updateEnvironment,
})(EnvironmentsContainer);
