import {
  Button as MButton,
  Dialog as MDialog,
  DialogActions as MDialogActions,
  DialogContent as MDialogContent,
  DialogTitle as MDialogTitle,
} from "@material-ui/core";
import * as React from "react";

export interface Props {
  content: string | JSX.Element;
  disableYesButton?: boolean;
  handleClose: any;
  handleSuccess?: any;
  noButtonCopy?: string;
  open: boolean;
  title: string;
  yesButtonCopy?: string;
}

const Alert = (props: Props) => {
  return (
    <MDialog open={props.open} onClose={props.handleClose}>
      <MDialogTitle>{props.title}</MDialogTitle>
      <MDialogContent>{props.content}</MDialogContent>
      <MDialogActions>
        {props.noButtonCopy && <MButton onClick={props.handleClose}>{props.noButtonCopy}</MButton>}
        <MButton
          autoFocus={true}
          disabled={props.disableYesButton}
          onClick={() => {
            if (props.handleSuccess) {
              props.handleSuccess();
            }
            props.handleClose();
          }}
        >
          {props.yesButtonCopy || "Yes"}
        </MButton>
      </MDialogActions>
    </MDialog>
  );
};

export default Alert;
