// TODO: Refactor all styling. This was done in a rush.
import { Typography as MTypography } from "@material-ui/core";
import { DrawerTable, Syntax, withDrawer } from "@sandtable/component-library";
import { Environment, EnvironmentsMap } from "@sandtable/datastore";
import { diffEnvironmentRequirements } from "components/Environments/DiffEnvironmentsDrawer/diff";
import * as React from "react";
import { useParams } from "react-router-dom";

const COLUMN_WIDTH = "200px";
const SYNTAX_STYLES = { transform: "translate(8px, 8px)" };
const TITLE_STYLE = {
  width: COLUMN_WIDTH,
};
const TITLES_STYLE = {
  display: "flex",
  justifyContent: "space-evenly",
  marginBottom: "15px",
  marginTop: "15px",
};
const ROOT_STYLE = { display: "flex", height: "100%" };
const DRAWER_TABLE_STYLE = { transform: "scale(0.75) translate(-55px, -50px)" };
const DIFF_SECTION_STYLE = { display: "flex", justifyContent: "space-evenly" };

interface Props {
  environments: EnvironmentsMap;
}

interface DiffEnvironmentDescriptionProps {
  env: Environment;
}

const DiffEnvironmentsDrawer = (props: Props) => {
  const { uid, uid2 } = useParams();
  const environmentOne = props.environments[uid!]; // TODO: Handle undefined
  const environmentTwo = props.environments[uid2!]; // TODO: Handle undefined

  return (
    <div style={{ ...ROOT_STYLE, flexDirection: "column" }}>
      <div style={TITLES_STYLE}>
        <DiffEnvironmentDescription env={environmentOne} />
        <DiffEnvironmentDescription env={environmentTwo} />
        <MTypography style={TITLE_STYLE} />
      </div>

      <DiffSection
        height="350px"
        requirementsOne={environmentOne.condaRequirements}
        requirementsTwo={environmentTwo.condaRequirements}
        title="Conda dependencies"
      />
      <DiffSection
        height="175px"
        requirementsOne={environmentOne.pipRequirements}
        requirementsTwo={environmentTwo.pipRequirements}
        title="Pip dependencies"
      />
    </div>
  );
};

const DiffEnvironmentDescription = (props: DiffEnvironmentDescriptionProps) => {
  const env = props.env;
  const drawerTableData = [
    {
      name: "Created",
      value: new Date(env.created).toUTCString(),
    },
    {
      name: "Sandman revision",
      value: env.runtimeVersion,
    },
    {
      name: "Python version",
      value: env.pythonVersion,
    },
  ];
  return (
    <div style={TITLE_STYLE}>
      <DrawerTable style={DRAWER_TABLE_STYLE} tableData={drawerTableData} tableKey={env.uid} />
    </div>
  );
};

interface DiffSectionProps {
  height: string;
  title: string;
  requirementsOne?: string[];
  requirementsTwo?: string[];
}

const DiffSection = (props: DiffSectionProps) => {
  const diff = diffEnvironmentRequirements(props.requirementsOne || [], props.requirementsTwo || []);
  return (
    <div style={DIFF_SECTION_STYLE}>
      <DiffSyntax height={props.height} title={props.title} text={(props.requirementsOne || ["None"]).join("\n")} />
      <DiffSyntax height={props.height} title={props.title} text={(props.requirementsTwo || ["None"]).join("\n")} />
      <DiffSyntax
        text={diff.map((d: any) => [d.added ? "+" + d.value : d.removed ? "-" + d.value : ""].join(" ")).join("\n")}
        title="Diff"
        mode="diff"
        height={props.height}
      />
    </div>
  );
};

interface DiffSyntaxProps {
  height: string;
  mode?: string;
  text: string;
  title: string;
}

const DiffSyntax = (props: DiffSyntaxProps) => <Syntax {...props} width={COLUMN_WIDTH} titleStyles={SYNTAX_STYLES} />;

export default withDrawer(DiffEnvironmentsDrawer, "Diff Environments", {
  width: "800px",
});
