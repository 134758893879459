import { ViewDrawer } from "@sandtable/component-library";
import { Organisation, Project } from "@sandtable/datastore";
import MembersTab from "components/Management/ViewOrganisationDrawer/MembersTab";
import OrganisationProjectsTab from "components/Management/ViewOrganisationDrawer/OrganisationProjectsTab";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  addMemberToOrganisation: (organisationId: string, memberEmail: string) => any;
  addProjectToOrganisation: (organisationId: string, name: string) => any;
  onClose: () => void;
  onProjectSelect: (o: Organisation, p: Project) => void;
  organisations: Organisation[];
  removeMemberFromOrganisation: (organisationId: string, memberId: string) => any;
  updateMemberOfOrganisation: (organisationId: string, memberId: string, owner: boolean) => any;
}

const ViewOrganisationDrawer = (props: Props) => {
  const { id } = useParams();
  const organisation = props.organisations.find((o: Organisation) => o.id === id)!; // TODO: Handle undefined

  return (
    <ViewDrawer
      onClose={props.onClose}
      maxWidth={"700px"}
      open={true}
      tabGaAction={"view-organisation-drawer-tabs"}
      tabContents={[
        <MembersTab
          {...props}
          {...organisation}
          key={"members-tab"}
          onMemberAdd={(email: string) => props.addMemberToOrganisation(organisation.id, email)}
          onMemberDelete={(memberId: string) => props.removeMemberFromOrganisation(organisation.id, memberId)}
          onMemberUpdate={(memberId: string, owner: boolean) =>
            props.updateMemberOfOrganisation(organisation.id, memberId, owner)
          }
        />,
        <OrganisationProjectsTab
          {...props}
          key={"projects-tab"}
          organisation={organisation}
          onProjectSelect={(p: Project) => props.onProjectSelect(organisation, p)}
        />,
      ]}
      tabNames={["Members", "Projects"]}
      title={"View Organisation"}
    />
  );
};

export default ViewOrganisationDrawer;
