// This is essentially a copy of Management/ViewOrganisationDrawer/MembersTab
// TODO: Abstract these components
import {
  Button,
  CardList,
  CardListObject,
  Tabs,
  TextField,
  TextFieldAutocomplete,
  TextFieldAutocompleteOption,
} from "@sandtable/component-library";
import { Application, ApplicationUser } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  addApplicationUser: (application: Application, userEmail: string) => void;
  application: Application;
  inheritApplicationUsers: (application: Application, fromApplicationId: string) => void;
  removeApplicationUser: (application: Application, userEmail: string) => void;
  selectedApplicationVersions: Application[];
}

const UsersTab = (props: Props) => {
  const [actionTab, setActionTab] = React.useState(0);

  const users = props.application.users || [];

  const objects: CardListObject[] = users.map(
    (u: ApplicationUser) =>
      ({
        gaAction: "view-application-drawer-users-tab-card",
        gaLabel: u.name,
        onCardClick: () => {}, //tslint:disable-line
        primaryIcon: "user",
        primaryText: `${u.name} • ${u.email}`,
        secondaryIcon: "trash",
        secondaryIconClick: () => props.removeApplicationUser(props.application, u.email),
        secondaryIconTooltip: "Remove user",
      } as CardListObject), //tslint:disable-line
  );

  return (
    <>
      <CardList objects={objects} />
      <Tabs
        value={actionTab}
        labels={["Add User", "Inherit Users"]}
        onChange={setActionTab}
        gaAction={"view-application-drawer-users-tab-tabs"}
      />
      {actionTab === 0 && <AddUserTab {...props} />}
      {actionTab === 1 && <InheritUsersTab {...props} />}
    </>
  );
};

const AddUserTab = (props: Props) => {
  const [newMemberEmail, setNewMemberEmail] = React.useState("");

  const onTextFieldChange = React.useCallback((e: any) => setNewMemberEmail(e.target.value), []);
  const handleAddMember = React.useCallback(() => {
    if (newMemberEmail) {
      props.addApplicationUser(props.application, newMemberEmail);
      setNewMemberEmail("");
    }
  }, [newMemberEmail]);

  return (
    <>
      <TextField
        label="User email"
        onChange={onTextFieldChange}
        placeholder="User email"
        style={{ marginBottom: "14px" }}
        value={newMemberEmail}
      />
      <Button
        disabled={!newMemberEmail.length}
        fullWidth={true}
        gaAction={"view-application-drawer-users-tab-add-user"}
        iconRight="check"
        onButtonClick={handleAddMember}
        text={"Add user"}
      />
    </>
  );
};

const InheritUsersTab = (props: Props) => {
  const [selectedVersion, setSelectedVersion] = React.useState<TextFieldAutocompleteOption | null>(null);

  const handleAddMembers = React.useCallback(() => {
    if (selectedVersion) {
      props.inheritApplicationUsers(props.application, selectedVersion.value);
      setSelectedVersion(null);
    }
  }, [selectedVersion]);
  const onTextFieldChange = React.useCallback((e: any) => setSelectedVersion(e), []);

  const applicationVersions = props.selectedApplicationVersions
    .filter((a: Application) => a.version !== props.application.version)
    .map((a: Application) => ({
      label: `${a.name} • ${a.version}`,
      value: a.id,
    }))
    .reverse();

  return (
    <>
      <TextFieldAutocomplete
        placeholder="Application version"
        style={{ marginBottom: "14px" }}
        onChange={onTextFieldChange}
        options={applicationVersions}
        value={selectedVersion}
      />
      <Button
        disabled={!selectedVersion}
        fullWidth={true}
        gaAction={"view-application-drawer-users-tab-inherit-users"}
        iconRight="check"
        onButtonClick={handleAddMembers}
        text={"Inherit users"}
      />
    </>
  );
};

export default UsersTab;
