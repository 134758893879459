import { Syntax } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  environment: Environment;
}

const EnvironmentDockerfileTab = (props: Props) => (
  <Syntax
    text={
      props.environment && props.environment.dockerfile
        ? props.environment.dockerfile.replace(/[\r\n]+/g, "\n\n")
        : "N/A"
    }
  />
);

export default EnvironmentDockerfileTab;
