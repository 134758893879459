import { Repo, RepoMap, RepoObject } from "@sandtable/datastore";

export interface RecursiveRepo extends Repo {
  fileNames?: string[];
  subDirs: RecursiveRepoMap;
}

export interface RecursiveRepoMap {
  [name: string]: RecursiveRepo;
}

export const generateRecursiveRepoMap = (repoMap: RepoMap): RecursiveRepoMap => {
  const addObjectAtPath = (r: RecursiveRepo, ro: RepoObject, path: string[], i: number) => {
    // If at end of path, add to objects.
    if (i === path.length - 1) {
      r.objects.push(ro);
      return;
    }

    // Create subDirs entry for the next path, if it doesn't already exist.
    const nextPath = path[i + 1];
    if (!r.subDirs[nextPath]) {
      r.subDirs[nextPath] = {
        name: nextPath,
        objects: [],
        subDirs: {},
      };
    }

    addObjectAtPath(r.subDirs[nextPath], ro, path, i + 1);
  };

  const recursiveRepoMap: RecursiveRepoMap = {};
  Object.values(repoMap).forEach((repo: Repo) => {
    // Initialise repo entry. Useful for empty repos.
    recursiveRepoMap[repo.name] = {
      fileNames: [],
      name: repo.name,
      objects: [],
      subDirs: {},
    };
    repo.objects.forEach((ro: RepoObject) => {
      const strippedPath = ro.path.replace(/^\/|\/$/g, ""); // TODO: Combine these two lines with a nice regex:
      const fullObjectPath = [repo.name, ...(strippedPath ? strippedPath.split("/") : [])];
      addObjectAtPath(recursiveRepoMap[repo.name], ro, fullObjectPath, 0);
      recursiveRepoMap[repo.name].fileNames!.push(ro.name);
    });
  });
  return recursiveRepoMap;
};
