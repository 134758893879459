import { Nav } from "@sandtable/component-library";
import { User } from "@sandtable/datastore";
import * as React from "react";
import NavItems from "./NavItems";

interface Props {
  user: User;
}

const NavImpl = (props: Props) => {
  return <Nav text={"choreograph"} items={<NavItems {...props} />} />;
};

export default NavImpl;
