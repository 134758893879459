import { Header, SectionListObject } from "@sandtable/component-library";
import { AppState } from "@sandtable/datastore";
import { PageableResource } from "@sandtable/datastore/pagination/types";
import * as React from "react";
import { connect } from "react-redux";
import PaginatedCardListServerSide from "../PaginatedCardList/PaginatedCardListServerSide";

interface OwnProps {
  headerButtonText?: string;
  objects: SectionListObject[];
  onHeaderButtonClick?: () => void;
  fetchResources: (page?: number, perPage?: number, stringQuery?: string) => void | (() => void);
  searchKeys?: string[];
  showSearch: boolean;
  serverSidePaginationResource: PageableResource;
}

interface Props extends OwnProps {
  count: number;
  page: number;
  perPage: number;
  searchQuery?: string;
}

const PAGE_DEFAULT = 1;

const SectionListServerSide = (props: Props) => {
  const handleSearch = (stringQuery: string) => {
    fetchResources(PAGE_DEFAULT, perPage, stringQuery);
  };

  let searchTooltipText = "";
  if (props.serverSidePaginationResource === PageableResource.ENVIRONMENTS_V1
    || props.serverSidePaginationResource === PageableResource.ENVIRONMENTS_V2) {
      searchTooltipText = "Search by environment name or user";
  } else if (props.serverSidePaginationResource === PageableResource.CLUSTERS) {
    searchTooltipText = "Search cluster name, project name, or user";
  }

  const {
    count,
    fetchResources,
    headerButtonText,
    objects,
    onHeaderButtonClick,
    page,
    perPage,
    searchQuery,
    serverSidePaginationResource,
    showSearch,
  } = props;

  return (
    <>
      <Header
        buttonText={headerButtonText}
        handleButtonClick={onHeaderButtonClick}
        showSearch={showSearch}
        handleSearch={handleSearch}
        handleRefreshIconClick={fetchResources}
        searchQuery={searchQuery}
        searchTooltipText={searchTooltipText}
      />
      <PaginatedCardListServerSide
        fetchResources={fetchResources}
        objects={objects}
        serverSidePaginationResource={serverSidePaginationResource}
        count={count}
        page={page}
        perPage={perPage}
        searchQuery={searchQuery || ""}
      />
    </>
  );
};

const mapStateToProps = (state: AppState, ownProps: OwnProps) => {
  if (ownProps.serverSidePaginationResource === PageableResource.CLUSTERS) {
    return {
      count: state.clusters.pagination.clustersCount,
      page: state.clusters.pagination.page,
      perPage: state.clusters.pagination.perPage,
      searchQuery: state.clusters.searchQuery,
    };
  } else if (ownProps.serverSidePaginationResource === PageableResource.ENVIRONMENTS_V1) {
    return {
      count: state.environments.pagination.environmentsCount,
      page: state.environments.pagination.page,
      perPage: state.environments.pagination.perPage,
      searchQuery: state.environments.searchQuery,
    };
  } else if (ownProps.serverSidePaginationResource === PageableResource.ENVIRONMENTS_V2) {
    return {
      count: state.environmentsV2.pagination.environmentsCount,
      page: state.environmentsV2.pagination.page,
      perPage: state.environmentsV2.pagination.perPage,
      searchQuery: state.environmentsV2.searchQuery,
    };
  }
};

export default connect(mapStateToProps)(SectionListServerSide);
