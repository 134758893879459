import { Paper as MPaper, Tab as MTab, Tabs as MTabs } from "@material-ui/core";
import * as React from "react";

export interface Props {
  gaAction?: string;
  gaLabel?: string;
  labels: string[];
  onChange?: (v: number) => void;
  style?: object;
  value?: number;
}

const Tabs = (props: Props) => {
  const { onChange } = props;
  const onTabChange = React.useCallback((e: React.ChangeEvent<{}>, v: number) => {
    if (onChange) {
      onChange(v);
    }
  }, []);

  const tabs = props.labels.map((label: string, key: number) => {
    const action = props.gaAction ? props.gaAction.toLowerCase() + "-" + label.toLowerCase().split(" ").join("-") : "";

    return (
      <MTab
        disableRipple={props.labels.length === 1}
        key={key}
        label={label}
        style={props.labels.length === 1 ? { cursor: "default" } : {}}
        value={key}
        data-ga-event-category={window.location.pathname.slice(1)}
        data-ga-event-action={action}
      />
    );
  });

  return (
    <MPaper style={{ marginBottom: "26px", ...props.style, border: "none" }}>
      <MTabs value={props.value} onChange={onTabChange} centered={true} indicatorColor="primary" variant="standard">
        {tabs}
      </MTabs>
    </MPaper>
  );
};

Tabs.defaultProps = {
  value: 0,
};

export default Tabs;
