import { Chip as MChip } from "@material-ui/core";
import * as React from "react";

const CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  marginBottom: "14px",
};

export interface Props {
  icon?: JSX.Element;
  label: string;
  onDelete?: any;
  style?: object;
  fullWidth?: boolean;
}

const Chip = (props: Props) => {
  const chip = (
    <MChip icon={props.icon} label={props.label} onDelete={props.onDelete} style={props.style} color="secondary" />
  );
  if (!props.fullWidth) {
    return chip;
  }
  return <div style={CONTAINER_STYLES}>{chip}</div>;
};

export default Chip;
