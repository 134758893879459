// Configuration interfaces:
export interface Configuration {
  name: string;
  cores: number;
  vcpus: number;
  totalMemory: number;
  memoryPerCpu: number;
}

export interface ConfigurationMap {
  [name: string]: Configuration;
}

export interface ConfigurationState {
  configurations: ConfigurationMap;
}

// Action constants:
export const REQUEST_CONFIGURATIONS = "REQUEST_CONFIGURATIONS";
export const RECEIVE_CONFIGURATIONS = "RECEIVE_CONFIGURATIONS";

// Action types:
interface RequestConfigurationsAction {
  type: typeof REQUEST_CONFIGURATIONS;
}

interface ReceiveConfigurationsAction {
  configurations: ConfigurationMap;
  type: typeof RECEIVE_CONFIGURATIONS;
}

export type ConfigurationsActionTypes = RequestConfigurationsAction | ReceiveConfigurationsAction;
