import { ViewDrawer } from "@sandtable/component-library";
import { ApplicationExecutionMap, Execution } from "@sandtable/datastore";
import ExecutionActionSection from "components/Applications/ViewExecutionDrawer/ExecutionActionSection";
import ExecutionBody from "components/Applications/ViewExecutionDrawer/ExecutionBody";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  executions: ApplicationExecutionMap;
  deleteExecution: (execution: Execution) => void;
  onClose: (applicationId: string) => void;
  onApplicationLoad: (applicationId: string) => void;
}

const ViewExecutionDrawer = (props: Props) => {
  const { applicationId, executionId } = useParams();

  // Load executions + users
  const { onApplicationLoad } = props;
  React.useEffect(() => {
    if (applicationId) {
      onApplicationLoad(applicationId);
    }
  }, [applicationId, onApplicationLoad]);

  // TODO: Handle undefined:
  const applicationExecutions = props.executions[applicationId!] || {};
  const execution = applicationExecutions[executionId!];

  if (!execution) {
    props.onClose(applicationId!); // TODO: Handle more gracefully.
    return <></>;
  }

  return (
    <ViewDrawer
      actionSection={<ExecutionActionSection {...props} execution={execution} />}
      maxWidth={"540px"}
      onClose={() => props.onClose(applicationId!)}
      open={true}
      tabContents={[<ExecutionBody key={"executionBody"} {...props} execution={execution} />]}
      tabGaAction={"view-execution-drawer-tabs"}
      tabNames={["Execution"]}
      title={"View Execution"}
    />
  );
};

export default ViewExecutionDrawer;
