import { Paper as MPaper, Typography as MTypography } from "@material-ui/core";
import { Button, useAuth } from "@sandtable/component-library";
import React from "react";

const CONTAINER_STYLES: React.CSSProperties = { margin: "10px 10px 10px 10px", padding: "20px 20px 20px 20px" };
const BUTTON_CONTAINER_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-evenly",
  paddingTop: "20px",
};

interface Props {
  acceptTermsConditions: () => void;
}

const TermsConditions = (props: Props): JSX.Element => {
  const { logout } = useAuth();
  const [submitting, setSubmitting] = React.useState(false);

  return (
    <MPaper elevation={1} style={CONTAINER_STYLES}>
      <div>
        <MTypography variant="h5" component="h1">
          SOFTWARE LICENSE AGREEMENT
        </MTypography>
        <p>PLEASE READ THIS SOFTWARE LICENSE AGREEMENT CAREFULLY BEFORE INSTALLING OR USING THE SOFTWARE.</p>
        <p>
          BY CLICKING ON THE "ACCEPT" BUTTON, INSTALLING OR USING THE PRODUCT YOU ARE CONSENTING TO BE BOUND BY THIS
          AGREEMENT.
        </p>
        <p>Choreograph has developed and owns the Sandman2 (Software).</p>
        <p>Choreograph grants to the Customer a non-exclusive and non-transferable license to use the Software.</p>
        <p>
          The Customer shall not copy, in whole or in part, the Software; modify the Software; reverse compile or
          reverse engineer all or any portion of the Software; or rent, lease, distribute, sell or create derivative
          works of the Software.
        </p>
        <p>
          The Customer agrees that aspects of the licensed materials constitute trade secrets and/or copyrighted
          material of Choreograph. The Customer agrees not to disclose, provide, or otherwise make available such trade
          secrets or copyrighted material in any form to any third party without the prior written consent of
          Choreograph. The Customer agrees to implement reasonable security measures to protect such trade
          secrets and copyrighted material. Title to Choreograph Software and documentation shall remain solely
          with Choreograph.
        </p>
        <p>
          Choreograph may terminate this Agreement immediately on giving written notice to the Customer in
          the event that the Customer is in breach of any of the terms of this Agreement and such breach is
          incapable of being remedied; or in breach of any of the terms of this Agreement and, the breach being
          remediable, the Customerfails to remedy the breach within 14 days from the date of a written request
          to do so.
        </p>
        <p>
          Choreograph may terminate this agreement at any time for any reason by giving the Customer 4 weeks written
          notice.
        </p>
        <p>Upon termination, the Customer must destroy all copies of Software.</p>
        <p>
          THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
          TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
          THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF
          CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER
          DEALINGS IN THE SOFTWARE.
        </p>
      </div>
      <div style={BUTTON_CONTAINER_STYLES}>
        <Button
          gaAction={"terms-conditions-accept"}
          text="Accept"
          onButtonClick={() => {
            props.acceptTermsConditions();
            setSubmitting(true);
          }}
          disabled={submitting}
        />
        <Button
          gaAction={"terms-conditions-refuse"}
          text="Refuse"
          secondary={true}
          onButtonClick={() => logout(window.location.origin)}
        />
      </div>
    </MPaper>
  );
};

export default TermsConditions;
