import {
  ENQUEUE_SNACKBAR_NOTIFICATION,
  REMOVE_SNACKBAR_NOTIFICATION,
  SnackbarNotification,
  SnackbarNotificationActionTypes,
  SnackbarNotificationList,
} from "@sandtable/datastore/snackbar";

const initialState: SnackbarNotificationList = {
  notifications: [],
};

export const snackbarNotificationsReducer = (
  state = initialState,
  action: SnackbarNotificationActionTypes,
): SnackbarNotificationList => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.item],
      };
    case REMOVE_SNACKBAR_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((n: SnackbarNotification) => n.key !== action.key),
      };
    default:
      return state;
  }
};
