// XXX: Near duplicate of V1 CodeSection. Deprecate V1 version. Move to component-library.
import { Icon, Syntax } from "@sandtable/component-library";
import { clickInputElement, openNewTab, readFile } from "@sandtable/utils";
import * as React from "react";

const UPLOAD_ICON_STYLES: React.CSSProperties = {
  marginTop: "6px",
  position: "absolute",
  right: "28px",
  zIndex: 1,
};
const CONDA_DOCUMENTATION =
  "https://docs.conda.io/projects/conda/en/latest/user-guide/tasks/manage-environments.html#creating-an-environment-file-manually";

interface Props {
  onChange: (s: string) => void;
  title: string;
  value: string;
}

const CodeSection = (props: Props): JSX.Element => {
  const inputId = props.title.split(" ")[1] + "-upload";
  const onIconChange = () => clickInputElement(inputId);
  const onInputChange = (e: any) => readFile(e.target.files[0], props.onChange);

  return (
    <div>
      <div style={UPLOAD_ICON_STYLES}>
        {/* TODO: define `accept` */}
        <input type="file" id={inputId} style={{ display: "none" }} onChange={onInputChange} />
        <Icon
          icon="file-upload"
          tooltipText="Upload YAML" // XXX: generalise
          onIconClick={onIconChange}
          gaAction={"new-environment-v2-drawer-code-section-upload"}
          gaLabel={props.title}
        />
        <Icon
          icon="question-circle"
          tooltipText="Open Conda documentation"
          onIconClick={(e: React.ChangeEvent) => openNewTab(e, CONDA_DOCUMENTATION)}
          gaAction={"new-environment-v2-drawer-code-section-conda-link"}
          gaLabel={props.title}
        />
      </div>
      <Syntax
        height={"460px"}
        mode={"yaml"}
        onChange={(a: any, b: any, c: any) => props.onChange(c)}
        text={props.value}
        title={props.title}
      />
    </div>
  );
};

export default CodeSection;
