import { Typography as MTypography } from "@material-ui/core";
import { Table } from "@sandtable/component-library";
import { Cluster, ClusterMap, NewCluster } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  onChange: (c: Partial<NewCluster>, e: boolean) => void;
  recentClusterConfigurations: ClusterMap;
}

const ClusterRecentConfigurations = (props: Props) => {
  const [selectedRow, setSelectedRow] = React.useState<number | undefined>(undefined); // TODO: Move to Table

  const clusters = Object.values(props.recentClusterConfigurations);

  const handleRowClick = (rowData: any, rowMeta: any) => {
    setSelectedRow(rowMeta.rowIndex);
    const c = clusters[rowMeta.rowIndex]; // XXX: Order is important. Disable table sort.
    props.onChange(
      {
        clusterConfiguration: c.clusterConfiguration,
        hyperthreading: c.hyperthreading,
        instances: c.instances,
        project: c.project,
        spotInstances: c.spotInstances,
        useIdleShutdown: c.useIdleShutdown,
      },
      false,
    );
  };

  return (
    <>
      <MTypography style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
        Pre-fill cluster attributes from an existing configuration:
      </MTypography>
      <div style={{ margin: "0 -24px 24px -24px" }}>
        <Table
          columns={["Created", "Project", "Instance type"]}
          data={clusters.map((c: Cluster) => [
            new Date(c.created || "").toUTCString(),
            c.project,
            c.clusterConfiguration,
          ])}
          onRowClick={handleRowClick}
          selectedRow={selectedRow}
          sort={false} // XXX: See above.
        />
      </div>
    </>
  );
};

export default ClusterRecentConfigurations;
