export interface ApplicationMap {
  [id: string]: Application;
}

export interface ApplicationUserMap {
  [email: string]: ApplicationUser;
}

export interface ApplicationState {
  applications: ApplicationMap;
  isFetching: boolean;
}

export interface ApplicationUser {
  email: string;
  name: string;
}

interface ApplicationRequirements {
  cluster: {
    instanceName: string;
    nbInstances: number;
    useHyperthreading: boolean;
  };
  environment: {
    name: string;
    pythonVersion: string;
    sandman2Version: string;
  };
}

export interface Application {
  created: string;
  creator: string;
  description: string;
  environmentId: string;
  id: string;
  inputsAreAvailable: boolean;
  metadata: string;
  name: string;
  organisation: string;
  parameters: any; // TODO: Type
  project: string;
  protoIsAvailable: boolean;
  requirements: ApplicationRequirements;
  results: any; // TODO: Type
  totalExecutions: number;
  url: string;
  version: string;
  users?: ApplicationUser[];
}

export const RECEIVE_APPLICATIONS = "RECEIVE_APPLICATIONS";
export const REQUEST_APPLICATIONS = "REQUEST_APPLICATIONS";
export const RECEIVE_APPLICATION_USERS = "RECEIVE_APPLICATION_USERS";
export const REQUEST_APPLICATION_USERS = "REQUEST_APPLICATION_USERS";
export const RECEIVE_ADD_APPLICATION_USER = "RECEIVE_ADD_APPLICATION_USER";
export const REQUEST_ADD_APPLICATION_USER = "REQUEST_ADD_APPLICATION_USER";
export const RECEIVE_REMOVE_APPLICATION_USER = "RECEIVE_REMOVE_APPLICATION_USER";
export const REQUEST_REMOVE_APPLICATION_USER = "REQUEST_REMOVE_APPLICATION_USER";
export const RECEIVE_INHERIT_APPLICATION_USERS = "RECEIVE_INHERIT_APPLICATION_USERS";
export const REQUEST_INHERIT_APPLICATION_USERS = "REQUEST_INHERIT_APPLICATION_USERS";
export const RECEIVE_DELETE_APPLICATION = "RECEIVE_DELETE_APPLICATION";
export const REQUEST_DELETE_APPLICATION = "REQUEST_DELETE_APPLICATION";

interface RequestApplicationsAction {
  type: typeof REQUEST_APPLICATIONS;
}

interface ReceiveApplicationsAction {
  type: typeof RECEIVE_APPLICATIONS;
  applications: ApplicationMap;
}

interface RequestApplicationUsersAction {
  type: typeof REQUEST_APPLICATION_USERS;
}

interface ReceiveApplicationUsersAction {
  type: typeof RECEIVE_APPLICATION_USERS;
  applicationId: string;
  applicationUsers: ApplicationUserMap;
}

interface RequestAddApplicationUserAction {
  type: typeof REQUEST_ADD_APPLICATION_USER;
}

interface ReceiveAddApplicationUserAction {
  type: typeof RECEIVE_ADD_APPLICATION_USER;
  applicationId: string;
  applicationUsers: ApplicationUserMap;
}

interface RequestRemoveApplicationUserAction {
  type: typeof REQUEST_REMOVE_APPLICATION_USER;
}

interface ReceiveRemoveApplicationUserAction {
  type: typeof RECEIVE_REMOVE_APPLICATION_USER;
  applicationId: string;
  userEmail: string;
}

interface RequestInheritApplicationUsersAction {
  type: typeof REQUEST_INHERIT_APPLICATION_USERS;
}

interface ReceiveInheritApplicationUsersAction {
  type: typeof RECEIVE_INHERIT_APPLICATION_USERS;
  applicationId: string;
  applicationUsers: ApplicationUserMap;
}

interface RequestDeleteApplication {
  type: typeof REQUEST_DELETE_APPLICATION;
}

interface ReceiveDeleteApplication {
  type: typeof RECEIVE_DELETE_APPLICATION;
  applicationId: string;
}

export type ApplicationsActionTypes =
  | RequestApplicationsAction
  | ReceiveApplicationsAction
  | RequestApplicationUsersAction
  | ReceiveApplicationUsersAction
  | RequestAddApplicationUserAction
  | ReceiveAddApplicationUserAction
  | RequestRemoveApplicationUserAction
  | ReceiveRemoveApplicationUserAction
  | RequestInheritApplicationUsersAction
  | ReceiveInheritApplicationUsersAction
  | RequestDeleteApplication
  | ReceiveDeleteApplication;
