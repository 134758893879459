import { Loading } from "@sandtable/component-library";
import { Experiment, ExperimentMap } from "@sandtable/datastore";
import ExperimentsList from "components/Experiments/ExperimentsList";
import NewExperimentDrawer from "components/Experiments/NewExperimentDrawer/NewExperimentDrawer";
import ViewExperimentDrawer from "components/Experiments/ViewExperimentDrawer/ViewExperimentDrawer";
import * as React from "react";
import { generatePath, Route, useHistory } from "react-router-dom";

const EXPERIMENTS_PATH = "/experiments";
const NEW_EXPERIMENT_PATH = "/experiments/new-experiment";
const VIEW_EXPERIMENT_PATH = "/experiments/view-experiment/:uid";

interface Props {
  addExperiment: (fileId: string, experimentName: string) => void;
  experiments: ExperimentMap;
  fetchExperiments: () => void;
  isFetching: boolean;
}

const Experiments = (props: Props) => {
  const history = useHistory();

  const handleGoBack = React.useCallback(() => history.push(EXPERIMENTS_PATH), [history]);
  const handleNewExperimentClick = React.useCallback(() => history.push(NEW_EXPERIMENT_PATH), [history]);
  const handleExperimentSelect = React.useCallback(
    (e: Experiment) => history.push(generatePath(VIEW_EXPERIMENT_PATH, { uid: e.id })),
    [history],
  );
  const handleExperimentAdd = React.useCallback(
    (fileId: string, experimentName: string) => {
      props.addExperiment(fileId, experimentName);
      handleGoBack();
    },
    [history],
  );

  if (props.isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Route path={EXPERIMENTS_PATH}>
        <ExperimentsList
          {...props}
          onNewExperimentClick={handleNewExperimentClick}
          onExperimentSelect={handleExperimentSelect}
        />
      </Route>
      <Route path={NEW_EXPERIMENT_PATH}>
        <NewExperimentDrawer onExperimentAdd={handleExperimentAdd} onClose={handleGoBack} open={true} />
      </Route>
      <Route path={VIEW_EXPERIMENT_PATH}>
        <ViewExperimentDrawer {...props} onClose={handleGoBack} />
      </Route>
    </>
  );
};

export default Experiments;
