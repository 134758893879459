import { DrawerTable } from "@sandtable/component-library";
import { Deployment } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  deployment: Deployment;
}

const DeploymentTab = (props: Props) => {
  const data = [
    {
      name: "Deployment ID",
      value: props.deployment.id,
    },
    {
      name: "Created",
      value: new Date(props.deployment.created).toUTCString(),
    },
    {
      name: "Creator",
      value: props.deployment.creator,
    },
    {
      name: "Description",
      value: props.deployment.description,
    },
    {
      name: "Name",
      value: props.deployment.name,
    },
    {
      name: "Organisation",
      value: props.deployment.organisation,
    },
    {
      name: "Project",
      value: props.deployment.project,
    },
    {
      name: "Git repo",
      value: props.deployment.gitConfig.repo,
    },
    {
      name: "Git branch",
      value: props.deployment.gitConfig.branch,
    },
    {
      name: "Git commit",
      value: props.deployment.gitConfig.commit,
    },
  ];

  return <DrawerTable tableData={data} tableKey={props.deployment.id} />;
};

export default DeploymentTab;
