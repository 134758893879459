import { useUser } from "@sandtable/component-library";
import * as React from "react";
import { Redirect } from "react-router-dom";

interface Props {
  component: JSX.Element;
  path: string;
  redirectPath: string;
}

const ProtectedComponent = (props: Props): JSX.Element => {
  const user = useUser();
  const isAuthorised = user.visibleRoutes.indexOf(props.path) > -1;
  if (isAuthorised) {
    return props.component;
  }
  return <Redirect to={props.redirectPath} />;
};

export default ProtectedComponent;
