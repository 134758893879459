import { FormControl as MFormControl } from "@material-ui/core";
import * as React from "react";
import Select from "react-select";
import { PRIMARY_COLOR } from "../theme";

export interface TextFieldAutocompleteOption {
  value: string;
  label: string;
}

export interface Props {
  onChange: any;
  options: TextFieldAutocompleteOption[];
  placeholder: string;
  style?: object;
  value: TextFieldAutocompleteOption | null;
}

const styles = {
  container: (provided: any, state: any) => ({
    ...provided,
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    height: "46px",
    padding: "16px 1px",
  }),
  menu: (provided: any) => ({ ...provided, zIndex: "9999 !important" }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
};

const TextFieldAutocomplete = (props: Props) => {
  return (
    <>
      <MFormControl fullWidth={true} style={props.style}>
        <Select
          placeholder={props.placeholder}
          styles={styles}
          theme={(theme: any) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: PRIMARY_COLOR,
              primary25: "#e0e0e0",
            },
          })}
          onChange={props.onChange}
          options={props.options}
          value={props.value}
          menuPortalTarget={document.querySelector("body")}
        />
      </MFormControl>
    </>
  );
};

export default TextFieldAutocomplete;
