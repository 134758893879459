import { NewDrawer } from "@sandtable/component-library";
import { NewEnvironmentV2 } from "@sandtable/datastore";
import { PYPI_URL } from "@sandtable/utils/constants";
import EnvironmentV2DockerfileTab from "components/EnvironmentsV2/NewEnvironmentV2Drawer/EnvironmentV2DockerfileTab";
import EnvironmentV2YamlTab from "components/EnvironmentsV2/NewEnvironmentV2Drawer/EnvironmentV2YamlTab";
import * as React from "react";

const DEFAULT_DOCKERFILE: string = `FROM continuumio/miniconda3:4.10.3

RUN mkdir /workspace
RUN echo "{}" > /workspace/environment.yaml

ENV MKL_NUM_THREADS 1
ENV NUMEXPR_NUM_THREADS 1
ENV OPENBLAS_NUM_THREADS 1
ENV OMP_NUM_THREADS 1
ENV VECLIB_MAXIMUM_THREADS 1
ENV STAN_NUM_THREADS 1

RUN apt update
RUN apt install build-essential=12.6 -y
RUN apt install gcc=4:8.3.0-1 -y
RUN apt install g++=4:8.3.0-1 -y

RUN mkdir ~/.pip
RUN echo "[global]" >> ~/.pip/pip.conf
RUN echo "index-url = ${PYPI_URL}" >> ~/.pip/pip.conf

RUN conda config --set auto_update_conda False

RUN conda env update --name base -f /workspace/environment.yaml
RUN conda clean --quiet --yes -a`;

const DEFAULT_YAML: string = `name: environment_name
channels:
- default
dependencies:
- python=3.6.10
- pip=20.0.2
- pip:
  - sandman2==3.21.13`;

interface Props {
  addEnvironmentV2: (environment: NewEnvironmentV2) => void;
  onClose: () => void;
}

interface State {
  environment: NewEnvironmentV2;
  error: boolean;
}

const initialState: State = {
  environment: {
    condaEnvironmentFile: DEFAULT_YAML,
    dockerfile: DEFAULT_DOCKERFILE,
    isPublic: false,
  },
  error: false, // Assumes that DEFAULT_YAML is valid
};

const NewEnvironmentV2Drawer = (props: Props) => {
  const [state, setState] = React.useState<State>(initialState);

  const handleChange = (e: Partial<NewEnvironmentV2>, err?: boolean) =>
    setState((prev: State) => {
      const environment = { ...prev.environment, ...e };
      const error = err !== undefined ? err : prev.error;
      return { ...prev, environment, error };
    });

  const handleSubmit = () => {
    props.addEnvironmentV2(state.environment);
    props.onClose();
  };

  return (
    <NewDrawer
      onClose={props.onClose}
      decrementStepGaAction={"new-environment-v2-drawer-previous-step"}
      error={state.error}
      incrementStepGaAction={"new-environment-v2-drawer-next-step"}
      maxWidth={"600px"}
      onSubmit={handleSubmit}
      open={true}
      stepContents={[
        <EnvironmentV2YamlTab {...state} key={"environment-v2-yaml-tab"} onChange={handleChange} />,
        <EnvironmentV2DockerfileTab {...state} key={"environment-v2-dockerfile-tab"} onChange={handleChange} />,
      ]}
      stepNames={["Environment YAML", "Environment Dockerfile"]}
      title={"New Environment"}
    />
  );
};

export default NewEnvironmentV2Drawer;
