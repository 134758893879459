import { DrawerTable } from "@sandtable/component-library";
import { Upload } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  staged: Upload;
}

const StagedTab = (props: Props) => {
  const tableData = [
    {
      name: "Upload ID",
      value: props.staged.id,
    },
    {
      name: "File name",
      value: props.staged.originalFilename,
    },
    {
      name: "Creator",
      value: props.staged.uploader,
    },
    {
      name: "Upload date",
      value: new Date(props.staged.datetimeUploaded).toUTCString(),
    },
    {
      name: "File size",
      value: props.staged.fileSize,
    },
  ];

  return <DrawerTable tableData={tableData} tableKey={"staged-tab-drawer"} />;
};

export default StagedTab;
