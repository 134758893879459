import { SectionList, SectionListObject } from "@sandtable/component-library";
import { Application, ApplicationMap } from "@sandtable/datastore";
import { pluraliseString } from "@sandtable/utils";
import * as React from "react";

interface Props {
  onApplicationSelect: (applicationId: string) => void;
  applications: ApplicationMap;
  fetchApplications: () => void;
}

const ApplicationsList = (props: Props) => {
  let objects: SectionListObject[] = [];
  if (typeof props.applications !== "undefined") {
      objects = Object.values(props.applications).map((a: Application) => {
      const nExecutions = a.totalExecutions > 0 ? a.totalExecutions : 0;
      const secondaryText = `${a.version} • ${a.project} project • ${a.creator} • ${nExecutions} ${pluraliseString(
        "execution",
        nExecutions,
      )}`;
      return {
        data: a,
        gaAction: "applications-list-card",
        gaLabel: a.id,
        onCardClick: () => props.onApplicationSelect(a.id),
        primaryText: a.name,
        secondaryText,
      } as SectionListObject; //tslint:disable-line
    });
  }

  return (
    <SectionList
      objects={objects}
      fetchResources={props.fetchApplications}
      searchKeys={["name", "version", "project", "creator"]}
      showSearch={true}
    />
  );
};

export default ApplicationsList;
