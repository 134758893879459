import { TextField } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import EnvironmentCodeSection from "components/Environments/NewEnvironmentDrawer/EnvironmentCodeSection";
import * as React from "react";

interface Props {
  condaChannel?: string;
  environment: Environment;
  onChange: (e: Partial<Environment>, error?: boolean, condaChannel?: string) => void;
}

const EnvironmentDependenciesTab = (props: Props): JSX.Element => {
  return (
    <>
      <EnvironmentCodeSection
        onChange={(s: string) =>
          props.onChange(
            { condaRequirements: s === "" ? [] : s.trim().split("\n") },
            s.length > 0 && props.condaChannel === "",
          )
        }
        placeholder={"numpy==1.15.0"}
        title={"Add Conda dependencies (optional)"}
        value={props.environment.condaRequirements!.join("\n")}
      />
      {props.environment.condaRequirements!.length ? (
        <TextField
          label="Conda channel"
          onChange={(e: React.ChangeEvent<any>) => props.onChange({}, e.target.value === "", e.target.value)}
          placeholder="Conda channel"
          style={{ marginTop: "-10px", marginBottom: "14px" }}
          value={props.condaChannel}
        />
      ) : undefined}
      <EnvironmentCodeSection
        onChange={(s: string) => props.onChange({ pipRequirements: s === "" ? [] : s.trim().split("\n") })}
        placeholder={"numpy==1.15.0"}
        title={"Add Pip dependencies (optional)"}
        value={props.environment.pipRequirements!.join("\n")}
      />
    </>
  );
};

export default EnvironmentDependenciesTab;
