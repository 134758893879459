import { FormGroup as MFormGroup } from "@material-ui/core";
import { Button, Tabs } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import { injectElement } from "@sandtable/utils";
import EnvironmentDeleteDialog from "components/Environments/ViewEnvironmentDrawer/EnvironmentDeleteDialog";
import * as React from "react";

interface Props {
  onDeleteEnvironmentClick: (e: Environment) => void;
  environment: Environment;
  onUpdateEnvironmentClick: (e: Environment) => void;
}

const EnvironmentActionSection = (props: Props): JSX.Element => {
  return (
    <>
      <MFormGroup row={true} style={{ marginBottom: "-10px" }}>
        <Button
          gaAction={"view-environment-drawer-update"}
          gaLabel={props.environment.uid}
          icon="arrow-circle-up"
          onButtonClick={() => props.onUpdateEnvironmentClick(props.environment)}
          text={"Update environment"}
          style={{ flex: 1, marginRight: "5px" }}
        />
        <Button
          gaAction={"view-environment-drawer-delete"}
          gaLabel={props.environment.uid}
          icon="trash"
          onButtonClick={() =>
            injectElement(
              <EnvironmentDeleteDialog
                {...props}
                onSuccess={() => props.onDeleteEnvironmentClick(props.environment)}
              />,
            )
          }
          secondary={true}
          text={"Delete environment"}
          style={{ flex: 1, marginLeft: "5px" }}
        />
      </MFormGroup>
    </>
  );
};

export default EnvironmentActionSection;
