import { enqueueSnackbarNotification } from "@sandtable/datastore/snackbar";
import { apiDelete, ApiError, apiGet, apiPatch, apiPost, apiPut } from "@sandtable/utils";
import humps from "humps";
import { normalize, Schema } from "normalizr";
import { CLUSTER_ARRAY_AND_PAGINATION } from "../clusters/schema";
import { ENVIRONMENT_ARRAY_AND_PAGINATION } from "../environments";
import { ENVIRONMENT_V2_ARRAY_AND_PAGINATION } from "../environments_v2";

export const API_DELETE = "API_DELETE";
export const API_GET = "API_GET";
export const API_PATCH = "API_PATCH";
export const API_POST = "API_POST";
export const API_PUT = "API_PUT";

export type ApiMiddlewareAction = DeleteAction | GetAction | PatchAction | PostAction | PutAction;

type ActionSchema = Schema | Schema[];

interface BaseActionContents {
  endpoint: string;
  schema?: ActionSchema;
  types: string[];
  params?: object;
}

interface RetrieveActionContents extends BaseActionContents {
  successActionParams?: object;
}

interface ModifyActionContents extends BaseActionContents {
  params?: object;
  failureMessage?: string;
  successActionParams?: object;
  successMessage?: string;
}

interface DeleteAction {
  [API_DELETE]: ModifyActionContents;
}

interface GetAction {
  [API_GET]: RetrieveActionContents;
}

interface PatchAction {
  [API_PATCH]: ModifyActionContents;
}

interface PostAction {
  [API_POST]: ModifyActionContents;
}

interface PutAction {
  [API_PUT]: ModifyActionContents;
}

export class ApiMiddlewareError extends Error {}

const CALL_FUNCTION: { [name: string]: any } = {
  API_DELETE: apiDelete,
  API_GET: apiGet,
  API_PATCH: apiPatch,
  API_POST: apiPost,
  API_PUT: apiPut,
};

export const callApi = (callType: string, endpoint: string, schema: any | undefined, params: object | undefined) => {
  const callFunction = CALL_FUNCTION[callType];

  if (!callFunction) {
    throw new ApiMiddlewareError("Invalid call type");
  }

  let schemaKey: string;
  // This should really be an array of length 1, unless the schema is incorrect
  if (schema) {
    if (schema.length) {
      if (schema.length > 1) {
        throw new ApiMiddlewareError("Cannot handle multiple schemas");
      }
      schemaKey = schema[0]._key;
    } else {
      schemaKey = schema._key;
    }
  }

  const decamelizedParams = params ? humps.decamelizeKeys(params) : {};

  return callFunction(endpoint, decamelizedParams)
    .then((res: any) => {
      if (!schema) {
        return;
      }

      if (res.data && res.data.length === 0) {
        if (schemaKey) {
          return { [schemaKey]: {} };
        }
        return {};
      }

      let data = res.data;

      // A GET for Clusters with pagination details returns two objects and requires separate handling
      if (schema === CLUSTER_ARRAY_AND_PAGINATION) {
        data = res.data.clusters;
      } else if (schema === ENVIRONMENT_ARRAY_AND_PAGINATION || schema === ENVIRONMENT_V2_ARRAY_AND_PAGINATION) {
        data = res.data.environments;
      }

      const camelizedData = humps.camelizeKeys(data);

      try {
        if (schema === CLUSTER_ARRAY_AND_PAGINATION
          || schema === ENVIRONMENT_ARRAY_AND_PAGINATION
          || schema === ENVIRONMENT_V2_ARRAY_AND_PAGINATION
        ) {
          let normalizedData = normalize(camelizedData, schema).entities;
          normalizedData = {
            ...normalizedData,
            pagination: humps.camelizeKeys(res.data.pagination),
            searchQuery: res.data.search_query,
          };
          return normalizedData;
        } else {
          return normalize(camelizedData, schema).entities;
        }
      } catch (err) {
        throw new ApiMiddlewareError("Could not normalise response data with provided schema.");
      }
    })
    .catch((error: ApiError | ApiMiddlewareError) => {
      return Promise.reject({
        data: schemaKey ? { [schemaKey]: {} } : {},
        error,
      });
    });
};

// TODO: Type action?
export default (store: any) => (next: any) => (action: any) => {
  const callAPI = action[API_GET] || action[API_POST] || action[API_DELETE] || action[API_PUT] || action[API_PATCH];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  if (Object.keys(action).length > 1) {
    throw new ApiMiddlewareError("Provide only one action type.");
  }

  const callType = Object.keys(action)[0];
  const { endpoint, types, schema, params, successMessage, successActionParams, failureMessage } = callAPI;

  if (CALL_FUNCTION[callType] === undefined) {
    throw new ApiMiddlewareError("Invalid calltype.");
  }
  if (typeof endpoint !== "string") {
    throw new ApiMiddlewareError("Specify a string endpoint URL.");
  }
  // TODO: Make failure type optional
  if (!Array.isArray(types) || types.length !== 3) {
    throw new ApiMiddlewareError("Expected an array of three action types.");
  }
  if (!types.every((type: any) => typeof type === "string")) {
    throw new ApiMiddlewareError("Expected action types to be strings.");
  }

  const actionWith = (data: any) => {
    const finalAction = Object.assign({}, action, data); // tslint:disable-line
    delete finalAction[callType];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  const callback = (response: any) => {
    next(
      actionWith({
        ...response,
        ...successActionParams,
        type: successType,
      }),
    );
    if (successMessage) {
      next(enqueueSnackbarNotification({ text: successMessage }));
    }
  };

  const failureCallback = (err: { error: any; data: object }) => {
    const { error, data } = err;
    next(actionWith({ ...data, type: failureType }));
    if (error) {
      next(enqueueSnackbarNotification({ text: error.message }));
    }
    // TODO: Deprecate failure message
    if (failureMessage) {
      next(enqueueSnackbarNotification({ text: failureMessage }));
    }
  };

  return callApi(callType, endpoint, schema, params).then(callback).catch(failureCallback);
};
