import { Typography as MTypography } from "@material-ui/core";
import { DrawerTable, Icon } from "@sandtable/component-library";
import { EnvironmentV2 } from "@sandtable/datastore";
import { capitaliseString, mapEnvironmentStateToColorClass } from "@sandtable/utils";
import * as React from "react";

interface Props {
  environment: EnvironmentV2;
}

const EnvironmentV2Tab = (props: Props) => {
  const data = [
    {
      name: "Status",
      value: (
        <span style={{ display: "flex", marginLeft: "-4px", maxHeight: "21px" }}>
          <Icon icon="circle" className={mapEnvironmentStateToColorClass(props.environment.status)} />
          <MTypography style={{ margin: "2px" }}>{capitaliseString(props.environment.status)}</MTypography>
        </span>
      ),
    },
    {
      name: "Environment ID",
      value: props.environment.uid,
    },
    {
      name: "Created",
      value: new Date(props.environment.createdDatetime).toUTCString(),
    },
    {
      name: "Name",
      value: props.environment.name,
    },
    {
      name: "Creator",
      value: props.environment.creator,
    },
    {
      name: "Organisation",
      value: props.environment.organisation,
    },
    {
      name: "Size",
      value: `${(props.environment.sizeBytes || 0).toString()} bytes`,
    },
    {
      name: "Public",
      value: props.environment.isPublic ? "True" : "False",
    },
    {
      name: "Docker repository",
      value: props.environment.dockerRepository,
    },
  ];
  return <DrawerTable tableData={data} tableKey={props.environment.uid} />;
};

export default EnvironmentV2Tab;
