import { Select, Switch, TextField } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import { EnvironmentsObject, PythonVersionToEnvironmentsObject } from "@sandtable/utils";
import * as React from "react";

const SELECT_GROUP_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "14px",
};
const SWITCH_GROUP_STYLES: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  margin: "10px 0 10px 0",
};
const SELECT_STYLES: React.CSSProperties = { flex: "0 0 48%" };

interface Props {
  baseEnvironmentsObject: PythonVersionToEnvironmentsObject;
  environmentsObject: EnvironmentsObject;
  environment: Environment;
  onChange: (e: Partial<Environment>, error?: boolean, condaChannel?: string) => void;
}

const EnvironmentDetailsTab = (props: Props): JSX.Element => {
  const pythonVersions = Object.keys(props.baseEnvironmentsObject).sort((a: string, b: string) => b.localeCompare(a));
  const runtimeEnvironments = props.baseEnvironmentsObject[props.environment.pythonVersion];

  return (
    <>
      <TextField
        autoFocus={true}
        defaultValue={props.environment.name}
        label="Environment name"
        onChange={(e: React.ChangeEvent<any>) => {
          props.onChange({ name: e.target.value }, e.target.value === "" || !!props.environmentsObject[e.target.value]);
        }}
        placeholder="Environment name"
      />
      <div style={SELECT_GROUP_STYLES}>
        <Select
          insertNoneOption={false}
          label="Python version"
          labelWidth={108}
          onChange={(e: React.ChangeEvent<any>) =>
            props.onChange({
              parentEnvUid: props.baseEnvironmentsObject[e.target.value][0].uid,
              pythonVersion: e.target.value,
            })
          }
          options={pythonVersions.map((s: string) => ({ text: s }))}
          value={props.environment.pythonVersion}
          style={SELECT_STYLES}
        />
        <Select
          insertNoneOption={false}
          label="Sandman revision"
          labelWidth={126}
          onChange={(e: React.ChangeEvent<any>) => props.onChange({ parentEnvUid: e.target.value })}
          options={runtimeEnvironments.map((e: Environment) => ({
            text: e.runtimeVersion,
            value: e.uid,
          }))}
          value={props.environment.parentEnvUid}
          style={SELECT_STYLES}
        />
      </div>
      <div style={SWITCH_GROUP_STYLES}>
        <Switch
          checked={props.environment.isPublic}
          label="Public"
          onChange={(e: React.ChangeEvent<any>) => props.onChange({ isPublic: !props.environment.isPublic })}
          tooltipLabel="Public environments can be viewed by users outside of your organisation."
          value={props.environment.isPublic}
        />
      </div>
    </>
  );
};

export default EnvironmentDetailsTab;
