import {
  addEnvironmentV2,
  AppState,
  cancelEnvironmentV2,
  deleteEnvironmentV2,
  EnvironmentsV2Map,
  EnvironmentV2,
  fetchEnvironmentsV2,
  getEnvironmentV2,
  NewEnvironmentV2,
  subscribeToEnvironmentV2Updates,
  updateEnvironmentV2,
  User,
} from "@sandtable/datastore";
import { EnvironmentsV2Object, mapEnvironmentsV2ToObject } from "@sandtable/utils";
import EnvironmentsV2 from "components/EnvironmentsV2/EnvironmentsV2";
import * as React from "react";
import { connect } from "react-redux";

interface Props {
  addEnvironmentV2: (environment: NewEnvironmentV2) => void;
  cancelEnvironmentV2: (environment: EnvironmentV2) => void;
  deleteEnvironmentV2: (environment: EnvironmentV2) => void;
  environments: EnvironmentsV2Map;
  environmentsObject: EnvironmentsV2Object;
  fetchEnvironmentsV2: (page?: number, perPage?: number, searchQuery?: string) => void;
  getEnvironmentV2: (environment: EnvironmentV2) => void;
  isFetching: boolean;
  subscribedToEnvironmentUpdates: boolean;
  subscribeToEnvironmentV2Updates: (orgName: string) => void;
  updateEnvironmentV2: (environment: EnvironmentV2) => void;
  user: User;
}

const EnvironmentsV2Container = (props: Props) => {
  const { subscribedToEnvironmentUpdates, subscribeToEnvironmentV2Updates, fetchEnvironmentsV2, user } = props;
  const PAGE_DEFAULT = 1;
  const PER_PAGE_DEFAULT = 10;

  React.useEffect(() => {
    if (!subscribedToEnvironmentUpdates) {
      subscribeToEnvironmentV2Updates(user.organisation.name);
    }
    fetchEnvironmentsV2(PAGE_DEFAULT, PER_PAGE_DEFAULT);
  }, []);

  return <EnvironmentsV2 {...props} />;
};

const mapStateToProps = (state: AppState) => {
  return {
    environments: state.environmentsV2.environments,
    environmentsObject: typeof (state.environmentsV2.environments) !== "undefined"
    ? mapEnvironmentsV2ToObject(Object.values(state.environmentsV2.environments))
    : {},
    isFetching: state.environmentsV2.isFetching,
    subscribedToEnvironmentUpdates: state.environmentsV2.subscribedToEnvironmentUpdates,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  addEnvironmentV2,
  cancelEnvironmentV2,
  deleteEnvironmentV2,
  fetchEnvironmentsV2,
  getEnvironmentV2,
  subscribeToEnvironmentV2Updates,
  updateEnvironmentV2,
})(EnvironmentsV2Container);
