import { AppState, removeSnackbarNotification, SnackbarNotification } from "@sandtable/datastore";
import Notifier from "components/Notifier/Notifier";
import * as React from "react";
import { connect } from "react-redux";

interface Props {
  removeSnackbarNotification: (n: SnackbarNotification) => any;
  notifications: SnackbarNotification[];
}

const NotifierContainer = (props: Props) => <Notifier {...props} />;

const mapStateToProps = (state: AppState) => {
  return {
    notifications: state.snackbarNotifications.notifications,
  };
};

export default connect(mapStateToProps, {
  removeSnackbarNotification,
})(NotifierContainer);
