import {
  FormControl as MFormControl,
  InputAdornment as MInputAdornment,
  TextField as MTextField,
  Tooltip as MTooltip,
  WithStyles,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import { Icon } from "@sandtable/component-library";
import joinClassNames from "classnames";
import * as React from "react";
import "./Search.css";

const styles = createStyles({
  root: {
    maxHeight: "36px",
  },
});

interface Props extends WithStyles {
  className?: string;
  handleSearch?: (v: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  searchQuery?: string;
  tooltipText?: string;
}

const Search = (props: Props) => {
  const {
    classes,
    className,
    handleSearch,
    placeholder,
    style,
    searchQuery,
    tooltipText,
  } = props;

  const [value, setValue] = React.useState(searchQuery || "");

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const onKeyDown = (e: any) => {
    if (typeof(handleSearch) !== "undefined") {
      // If user hits enter key, search.
      if (e.keyCode === 13) {
        handleSearch(value);
      }
    }
  };

  const inputAdornment = (
    <MTooltip title={tooltipText || ""} disableFocusListener={true}>
      <MInputAdornment position="start">
        <Icon className="search-icon" icon="search" />
      </MInputAdornment>
    </MTooltip>

  );
  return (
    <>
      <MFormControl className={joinClassNames("search-input", className)} style={style}>
        <MTextField
          onChange={onChange}
          onKeyDown={onKeyDown}
          InputProps={{
            classes,
            startAdornment: inputAdornment,
          }}
          placeholder={placeholder || "Search"}
          variant="outlined"
          value={value}
        />
      </MFormControl>
    </>
  );
};

export default withStyles(styles)(Search);
