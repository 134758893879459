import {
  Cluster,
  NewCluster,
  RECEIVE_ADD_CLUSTER,
  RECEIVE_CLUSTER_LOGS,
  RECEIVE_CLUSTER_UPDATE,
  RECEIVE_CLUSTERS,
  RECEIVE_DELETE_CLUSTER,
  RECEIVE_RECENT_CLUSTER_CONFIGURATIONS,
  RECEIVE_RESTART_CLUSTER,
  RECEIVE_SCALE_CLUSTER,
  RECEIVE_START_CLUSTER,
  RECEIVE_STOP_CLUSTER,
  RECEIVE_UPDATE_CLUSTER,
  RECEIVE_UPGRADE_CLUSTER,
  REQUEST_ADD_CLUSTER,
  REQUEST_CLUSTER_LOGS,
  REQUEST_CLUSTERS,
  REQUEST_DELETE_CLUSTER,
  REQUEST_RECENT_CLUSTER_CONFIGURATIONS,
  REQUEST_RESTART_CLUSTER,
  REQUEST_SCALE_CLUSTER,
  REQUEST_START_CLUSTER,
  REQUEST_STOP_CLUSTER,
  REQUEST_UPDATE_CLUSTER,
  REQUEST_UPGRADE_CLUSTER,
  SUBSCRIBE_CLUSTER_UPDATES,
} from "@sandtable/datastore/clusters";
import { CLUSTER, CLUSTER_ARRAY, CLUSTER_ARRAY_AND_PAGINATION, CLUSTER_LOGS } from "@sandtable/datastore/clusters/schema";
import {
  API_DELETE,
  API_GET,
  API_POST,
  ApiMiddlewareAction,
  WS_SUBSCRIBE,
  WsMiddlewareAction,
} from "@sandtable/datastore/middleware";
import { enqueueSnackbarNotification, SnackbarNotificationActionTypes } from "@sandtable/datastore/snackbar";

// TODO: Combine these two functions:
export const fetchClusters = (page?: number, perPage?: number, searchQuery?: string) => {
  const params = {
    ...(page && { page }),
    ...(perPage && { perPage }),
    ...(searchQuery && { searchQuery }),
  };
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: "clusters",
        params,
        schema: CLUSTER_ARRAY_AND_PAGINATION,
        types: [REQUEST_CLUSTERS, RECEIVE_CLUSTERS, RECEIVE_CLUSTERS],
      },
    });
  };
};

export const fetchRecentClusterConfigurations = () => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: "clusters/recent",
        schema: CLUSTER_ARRAY,
        types: [
          REQUEST_RECENT_CLUSTER_CONFIGURATIONS,
          RECEIVE_RECENT_CLUSTER_CONFIGURATIONS,
          RECEIVE_RECENT_CLUSTER_CONFIGURATIONS,
        ],
      },
    });
  };
};

export const fetchClusterLogs = (cluster: Cluster) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: `clusters/${cluster.clusterId}/logs`,
        schema: CLUSTER_LOGS,
        successActionParams: {
          clusterId: cluster.clusterId,
        },
        types: [REQUEST_CLUSTER_LOGS, RECEIVE_CLUSTER_LOGS, RECEIVE_CLUSTER_LOGS],
      },
    });
  };
};

export const addCluster = (cluster: NewCluster) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: "clusters",
        params: {
          ...cluster,
        },
        schema: CLUSTER,
        types: [REQUEST_ADD_CLUSTER, RECEIVE_ADD_CLUSTER, RECEIVE_ADD_CLUSTER],
      },
    });
  };
};

export const deleteCluster = (cluster: Cluster) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_DELETE]: {
        endpoint: `clusters/${cluster.clusterId}`,
        types: [REQUEST_DELETE_CLUSTER, RECEIVE_DELETE_CLUSTER, RECEIVE_DELETE_CLUSTER],
      },
    });
  };
};

export const restartCluster = (cluster: Cluster) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `clusters/${cluster.clusterId}/restart`,
        types: [REQUEST_RESTART_CLUSTER, RECEIVE_RESTART_CLUSTER, RECEIVE_RESTART_CLUSTER],
      },
    });
  };
};

export const updateCluster = (cluster: Cluster) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `clusters/${cluster.clusterId}/update`,
        params: {
          ...cluster,
        },
        types: [REQUEST_UPDATE_CLUSTER, RECEIVE_UPDATE_CLUSTER, RECEIVE_UPDATE_CLUSTER],
      },
    });
  };
};

export const upgradeCluster = (cluster: Cluster) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `clusters/${cluster.clusterId}/upgrade`,
        params: {
          ...cluster,
        },
        types: [REQUEST_UPGRADE_CLUSTER, RECEIVE_UPGRADE_CLUSTER, RECEIVE_UPGRADE_CLUSTER],
      },
    });
  };
};

export const scaleCluster = (cluster: Cluster) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `clusters/${cluster.clusterId}/scale`,
        params: {
          instances: cluster.instances,
        },
        types: [REQUEST_SCALE_CLUSTER, RECEIVE_SCALE_CLUSTER, RECEIVE_SCALE_CLUSTER],
      },
    });
  };
};

export const stopCluster = (cluster: Cluster) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `clusters/${cluster.clusterId}/stop`,
        types: [REQUEST_STOP_CLUSTER, RECEIVE_STOP_CLUSTER, RECEIVE_STOP_CLUSTER],
      },
    });
  };
};

export const startCluster = (cluster: Cluster) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: `clusters/${cluster.clusterId}/start`,
        params: {
          instances: cluster.instances,
        },
        schema: CLUSTER,
        types: [REQUEST_START_CLUSTER, RECEIVE_START_CLUSTER, RECEIVE_START_CLUSTER],
      },
    });
  };
};

export const subscribeToClusterUpdates = (orgName: string) => {
  return (dispatch: (action: WsMiddlewareAction | SnackbarNotificationActionTypes) => void) => {
    dispatch({
      [WS_SUBSCRIBE]: {
        callback: (res: any) => {
          const cluster: any = Object.values(res.clusters)[0];
          const hostname = cluster.masterDomainName || "";
          const status = cluster.status || "updated";
          dispatch(
            enqueueSnackbarNotification({
              text: `Cluster ${hostname.split(".")[0]} ${status}`,
            }),
          );
        },
        endpoint: "clusters",
        room: orgName,
        schema: CLUSTER,
        types: [SUBSCRIBE_CLUSTER_UPDATES, RECEIVE_CLUSTER_UPDATE, RECEIVE_CLUSTER_UPDATE],
      },
    });
  };
};
