import {
  RECEIVE_DEACTIVATE_USER,
  RECEIVE_REACTIVATE_USER,
  RECEIVE_USERS,
  REQUEST_USERS,
  UsersActionTypes,
  UsersState,
} from "@sandtable/datastore/users";

const initialState: UsersState = {
  isFetching: true,
  users: {},
};

export const usersReducer = (state = initialState, action: UsersActionTypes): UsersState => {
  switch (action.type) {
    case REQUEST_USERS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_USERS:
      return {
        ...state,
        isFetching: false,
        users: action.users,
      };
    // TODO: Pass back user doc from API:
    case RECEIVE_DEACTIVATE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.userEmail]: { ...state.users[action.userEmail], deactivated: true },
        },
      };
    case RECEIVE_REACTIVATE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.userEmail]: { ...state.users[action.userEmail], deactivated: false },
        },
      };
    default:
      return state;
  }
};
