import { Auth0Client } from "@auth0/auth0-spa-js";

let instance: Auth0Client | null;

export class AuthError extends Error {}

export const getAuthToken = async () => {
  if (!instance) {
    throw new AuthError("Auth instance has not been initialised.");
  }

  return instance.getTokenSilently();
};

export const setAuthInstance = (i: Auth0Client) => (instance = i);
