import { getAuthToken } from "@sandtable/utils";
import { BASE_URL } from "@sandtable/utils/constants"; //tslint:disable-line
import axios from "axios";
import { getStatusText } from "http-status-codes";

const AUTH_HEADER_KEY = "X-Sandman-Token";

export class ApiError extends Error {}

export const getApiAuthHeaders = async () => {
  const token = await getAuthToken();
  return { [AUTH_HEADER_KEY]: token };
};

export const apiGet = (endpoint: string, parameters: object | undefined = undefined) => {
  if (typeof parameters !== "undefined") {
    endpoint += "?";
    Object.keys(parameters).map(
      (parameter: string, index: number) => endpoint += `${parameter}=${Object.values(parameters)[index]}&`,
    );
    endpoint = endpoint.slice(0, -1);
  }
  return apiAction(endpoint, "get");
};

export const apiDelete = (endpoint: string, data: object) => {
  return apiAction(endpoint, "delete", data);
};

export const apiPatch = (endpoint: string, data: object) => {
  return apiAction(endpoint, "patch", data);
};

export const apiPost = (endpoint: string, data: object) => {
  return apiAction(endpoint, "post", data);
};

export const apiPut = (endpoint: string, data: object) => {
  return apiAction(endpoint, "put", data);
};

const apiAction = async (endpoint: string, method: any, data?: object) => {
  if (endpoint[0] === "/") {
    endpoint = endpoint.substring(1);
  }

  const url = `${BASE_URL}/${endpoint}`;
  const headers = await getApiAuthHeaders();

  try {
    return await axios.request({
      data,
      headers,
      method,
      url,
    });
  } catch (e) {
    const res = e.response;
    if (!res) {
      throw new ApiError("Unknown error.");
    }

    const error = (res.data && res.data.error) || {};
    const message = error.message || "Unknown error";
    const statusCode: string | undefined = error.status_code || res.status;

    let statusText;
    if (statusCode) {
      try {
        statusText = getStatusText(Number.parseInt(statusCode!, 10));
      } catch {} // tslint:disable-line
    }

    throw new ApiError(`${message}: ${statusText || statusCode}.`);
  }
};
