import { schema } from "normalizr";

const COLLECTION_NAME = "whitelist";
const ID_KEY = "email";

const whitelistSchema = new schema.Entity(
  COLLECTION_NAME,
  {},
  {
    idAttribute: (whitelistEntry: WhitelistEntry) => whitelistEntry[ID_KEY],
  },
);

export interface WhitelistEntry {
  [ID_KEY]: string;
  creator: string;
  datetimeAdded: string;
}

export interface WhitelistMap {
  [ID_KEY: string]: WhitelistEntry;
}

export interface WhitelistState {
  isFetching: boolean;
  [COLLECTION_NAME]: WhitelistMap;
}

export const REQUEST_WHITELIST = "REQUEST_WHITELIST";
export const RECEIVE_WHITELIST = "RECEIVE_WHITELIST";
export const REQUEST_ADD_WHITELIST_ENTRY = "REQUEST_ADD_WHITELIST_ENTRY";
export const RECEIVE_ADD_WHITELIST_ENTRY = "RECEIVE_ADD_WHITELIST_ENTRY";
export const REQUEST_DELETE_WHITELIST_ENTRY = "REQUEST_DELETE_WHITELIST_ENTRY";
export const RECEIVE_DELETE_WHITELIST_ENTRY = "RECEIVE_DELETE_WHITELIST_ENTRY";

interface RequestWhitelistAction {
  type: typeof REQUEST_WHITELIST;
}

interface ReceiveWhitelistAction {
  type: typeof RECEIVE_WHITELIST;
  [COLLECTION_NAME]: WhitelistMap;
}

interface RequestAddWhitelistEntry {
  type: typeof REQUEST_ADD_WHITELIST_ENTRY;
}

interface ReceiveAddWhitelistEntry {
  type: typeof RECEIVE_ADD_WHITELIST_ENTRY;
  [COLLECTION_NAME]: WhitelistMap;
}

interface RequestDeleteWhitelistEntry {
  type: typeof REQUEST_DELETE_WHITELIST_ENTRY;
}

interface ReceiveDeleteWhitelistEntry {
  type: typeof RECEIVE_DELETE_WHITELIST_ENTRY;
  [ID_KEY]: typeof ID_KEY;
}

export const WHITELIST_ENTRY_SCHEMA = whitelistSchema;
export const WHITELIST_SCHEMA = [whitelistSchema];

export type WhitelistActionTypes =
  | RequestWhitelistAction
  | ReceiveWhitelistAction
  | RequestAddWhitelistEntry
  | ReceiveAddWhitelistEntry
  | RequestDeleteWhitelistEntry
  | ReceiveDeleteWhitelistEntry;
