import { Alert, Button } from "@sandtable/component-library";
import { Execution } from "@sandtable/datastore";
import { injectElement, removeElement } from "@sandtable/utils";
import * as React from "react";

interface Props {
  execution: Execution;
  deleteExecution: (execution: Execution) => void;
}

export const handleExecutionDelete = (onDelete: () => void) => {
  injectElement(
    <Alert
      content={"Are you sure that you want to delete this execution?"}
      handleClose={removeElement}
      noButtonCopy={"Cancel"}
      handleSuccess={onDelete}
      open={true}
      title={"Delete execution"}
    />,
  );
};

const ExecutionActionSection = (props: Props) => {
  return (
    <Button
      fullWidth={true}
      gaAction={"view-execution-drawer-delete-execution"}
      iconRight={"trash"}
      text={"Delete execution"}
      onButtonClick={() => handleExecutionDelete(() => props.deleteExecution(props.execution))}
    />
  );
};

export default ExecutionActionSection;
