import { NewDrawer } from "@sandtable/component-library";
import { ClusterMap, ConfigurationMap, EnvironmentsV2Map, NewCluster } from "@sandtable/datastore";
import { EnvironmentsObject, EnvironmentsV2Object } from "@sandtable/utils";
import ClusterConfigurationForm from "components/Clusters/NewClusterDrawer/ClusterConfigurationForm";
import ClusterEnvironmentForm from "components/Clusters/NewClusterDrawer/ClusterEnvironmentForm";
import ClusterExperimentPlanner from "components/Clusters/NewClusterDrawer/ClusterExperimentPlanner";
import ClusterRecentConfigurations from "components/Clusters/NewClusterDrawer/ClusterRecentConfigurations";
import * as React from "react";

interface Props {
  onClusterAdd: (c: NewCluster) => void;
  configurations: ConfigurationMap;
  completedEnvironments: EnvironmentsObject;
  completedEnvironmentsV2: EnvironmentsV2Object;
  environmentsV2: EnvironmentsV2Map;
  onClose: () => void;
  projects: string[];
  recentClusterConfigurations: ClusterMap;
}

interface State {
  cluster: NewCluster;
  error: boolean;
}

const initialState: State = {
  cluster: {
    clusterConfiguration: "",
    environmentId: "",
    hyperthreading: true,
    instances: 1,
    project: "",
    spotInstances: true,
    useIdleShutdown: true,
  },
  error: false,
};

const NewClusterDrawer = (props: Props) => {
  const [state, setState] = React.useState<State>(initialState);

  const handleChange = React.useCallback(
    (c: Partial<NewCluster>, error: boolean) =>
      setState((prev: State) => {
        const cluster = { ...prev.cluster, ...c };
        return { ...prev, cluster, error };
      }),
    [],
  );

  const handleSubmit = () => props.onClusterAdd(state.cluster);

  return (
    <NewDrawer
      onClose={props.onClose}
      error={state.error}
      decrementStepGaAction={"new-cluster-drawer-previous-step"}
      incrementStepGaAction={"new-cluster-drawer-next-step"}
      maxWidth={"1000px"}
      onSubmit={handleSubmit}
      open={true}
      stepContents={[
        <ClusterRecentConfigurations
          key={"recent-configurations"}
          onChange={handleChange}
          recentClusterConfigurations={props.recentClusterConfigurations}
        />,
        <ClusterExperimentPlanner
          {...props}
          cluster={state.cluster}
          key={"experiment-planner"}
          onChange={handleChange}
        />,
        <ClusterConfigurationForm
          {...props}
          cluster={state.cluster}
          configuration={props.configurations[state.cluster.clusterConfiguration]}
          key={"configuration-form"}
          onChange={handleChange}
        />,
        <ClusterEnvironmentForm {...props} cluster={state.cluster} key={"environment-form"} onChange={handleChange} />,
      ]}
      stepNames={["Recent configurations", "Select Instance", "Configure Cluster", "Configure Environment"]}
      title={"New Cluster"}
    />
  );
};

export default NewClusterDrawer;
