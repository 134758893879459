import { Header, PaginatedCardList, SectionListObject } from "@sandtable/component-library";
import { useFilter } from "@sandtable/utils";
import * as React from "react";

const SEARCH_OPTIONS: Fuse.FuseOptions<SectionListObject> = {
  distance: 100,
  location: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.4,
};

interface Props {
  headerButtonText?: string;
  objects: SectionListObject[];
  onHeaderButtonClick?: () => void;
  fetchResources: (page?: number, perPage?: number, searchQuery?: string) => void | (() => void);
  searchKeys?: string[];
  showSearch: boolean;
}

const SectionList = (props: Props) => {
  const [setFilterString, filteredObjects] = useFilter<SectionListObject>(props.objects, {
    ...SEARCH_OPTIONS,
    keys: props.searchKeys && props.searchKeys.map((k: string) => "data." + k),
  });

  return (
    <>
      <Header
        buttonText={props.headerButtonText}
        handleButtonClick={props.onHeaderButtonClick}
        showSearch={props.showSearch}
        handleSearch={setFilterString}
        handleRefreshIconClick={props.fetchResources}
      />
      <PaginatedCardList
        objects={filteredObjects}
      />
    </>
  );
};

export default SectionList;
