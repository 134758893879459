import { API_DELETE, API_GET, API_POST, ApiMiddlewareAction } from "@sandtable/datastore/middleware";
import {
  RECEIVE_ADD_WHITELIST_ENTRY,
  RECEIVE_DELETE_WHITELIST_ENTRY,
  RECEIVE_WHITELIST,
  REQUEST_ADD_WHITELIST_ENTRY,
  REQUEST_DELETE_WHITELIST_ENTRY,
  REQUEST_WHITELIST,
  WHITELIST_ENTRY_SCHEMA,
  WHITELIST_SCHEMA,
} from "@sandtable/datastore/whitelist";

export const fetchWhitelist = () => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: "whitelist",
        schema: WHITELIST_SCHEMA,
        types: [REQUEST_WHITELIST, RECEIVE_WHITELIST, RECEIVE_WHITELIST],
      },
    });
  };
};

export const addUserToWhitelist = (email: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: "whitelist",
        failureMessage: "Could not add email address to whitelist",
        params: {
          email,
        },
        schema: WHITELIST_ENTRY_SCHEMA,
        successMessage: "Email address added to whitelist",
        types: [REQUEST_ADD_WHITELIST_ENTRY, RECEIVE_ADD_WHITELIST_ENTRY, RECEIVE_ADD_WHITELIST_ENTRY],
      },
    });
  };
};

export const deleteUserFromWhitelist = (email: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_DELETE]: {
        endpoint: `whitelist/${email}`,
        failureMessage: "Could not remove email address from whitelist",
        successActionParams: {
          email,
        },
        successMessage: "Email address removed from whitelist",
        types: [REQUEST_DELETE_WHITELIST_ENTRY, RECEIVE_DELETE_WHITELIST_ENTRY, RECEIVE_DELETE_WHITELIST_ENTRY],
      },
    });
  };
};
