import {
  Application,
  ApplicationUser,
  RECEIVE_ADD_APPLICATION_USER,
  RECEIVE_APPLICATION_USERS,
  RECEIVE_APPLICATIONS,
  RECEIVE_DELETE_APPLICATION,
  RECEIVE_INHERIT_APPLICATION_USERS,
  RECEIVE_REMOVE_APPLICATION_USER,
  REQUEST_ADD_APPLICATION_USER,
  REQUEST_APPLICATION_USERS,
  REQUEST_APPLICATIONS,
  REQUEST_DELETE_APPLICATION,
  REQUEST_INHERIT_APPLICATION_USERS,
  REQUEST_REMOVE_APPLICATION_USER,
} from "@sandtable/datastore/applications";
import { APPLICATION_ARRAY, APPLICATION_USER, APPLICATION_USER_ARRAY } from "@sandtable/datastore/applications/schema";
import { API_DELETE, API_GET, API_POST, ApiMiddlewareAction } from "@sandtable/datastore/middleware";
import { enqueueSnackbarNotification, SnackbarNotificationActionTypes } from "@sandtable/datastore/snackbar";

export const fetchApplications = () => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: "applications",
        schema: APPLICATION_ARRAY,
        types: [REQUEST_APPLICATIONS, RECEIVE_APPLICATIONS, RECEIVE_APPLICATIONS],
      },
    });
  };
};

export const fetchApplicationUsers = (applicationId: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: `/applications/permissions/${applicationId}`,
        schema: APPLICATION_USER_ARRAY,
        successActionParams: {
          applicationId,
        },
        types: [REQUEST_APPLICATION_USERS, RECEIVE_APPLICATION_USERS, RECEIVE_APPLICATION_USERS],
      },
    });
  };
};

export const addApplicationUser = (application: Application, userEmail: string) => {
  return (dispatch: (action: ApiMiddlewareAction | SnackbarNotificationActionTypes) => void) => {
    const exists = application.users && application.users.find((a: ApplicationUser) => a.email === userEmail);
    if (exists) {
      dispatch(
        enqueueSnackbarNotification({
          text: "User already has access to the application",
        }),
      );
      return;
    }
    dispatch({
      [API_POST]: {
        endpoint: `/applications/permissions/${application.id}`,
        failureMessage: "Failed to add user to application. Does the user exist in the system?",
        params: {
          orgName: application.organisation,
          projName: application.project,
          userEmail,
        },
        schema: APPLICATION_USER,
        successActionParams: {
          applicationId: application.id,
        },
        successMessage: "User added to application",
        types: [REQUEST_ADD_APPLICATION_USER, RECEIVE_ADD_APPLICATION_USER, RECEIVE_ADD_APPLICATION_USER],
      },
    });
  };
};

export const inheritApplicationUsers = (application: Application, fromApplicationId: string) => {
  return (dispatch: (action: ApiMiddlewareAction | SnackbarNotificationActionTypes) => void) => {
    dispatch(enqueueSnackbarNotification({ text: "Inheriting users" }));
    dispatch({
      [API_POST]: {
        endpoint: `/applications/permissions/${application.id}`,
        failureMessage: "Failed to inherit users",
        params: {
          fromApplicationId,
        },
        schema: APPLICATION_USER_ARRAY,
        successActionParams: {
          applicationId: application.id,
        },
        successMessage: "Inherited users",
        types: [
          REQUEST_INHERIT_APPLICATION_USERS,
          RECEIVE_INHERIT_APPLICATION_USERS,
          RECEIVE_INHERIT_APPLICATION_USERS,
        ],
      },
    });
  };
};

export const removeApplicationUser = (application: Application, userEmail: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_DELETE]: {
        endpoint: `applications/permissions/${application.id}`,
        failureMessage: "Failed to remove user from application",
        params: {
          orgName: application.organisation,
          projName: application.project,
          userEmail,
        },
        successActionParams: {
          applicationId: application.id,
          userEmail,
        },
        successMessage: "User removed from application",
        types: [REQUEST_REMOVE_APPLICATION_USER, RECEIVE_REMOVE_APPLICATION_USER, RECEIVE_REMOVE_APPLICATION_USER],
      },
    });
  };
};

export const deleteApplication = (application: Application) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_DELETE]: {
        endpoint: `applications/${application.id}`,
        successActionParams: {
          applicationId: application.id,
        },
        types: [REQUEST_DELETE_APPLICATION, RECEIVE_DELETE_APPLICATION, "RECEIVE_DELETE_APPLICATION_FAILURE"],
      },
    });
  };
};
