import { Alert, Button } from "@sandtable/component-library";
import { Application } from "@sandtable/datastore";
import { injectElement, removeElement } from "@sandtable/utils";
import * as React from "react";

interface Props {
  application: Application;
  deleteApplication: (application: Application) => void;
}

const ApplicationActionSection = (props: Props) => {
  const handleClick = () => {
    injectElement(
      <Alert
        content={"Are you sure that you want to delete this application?"}
        handleClose={removeElement}
        noButtonCopy={"Cancel"}
        handleSuccess={() => props.deleteApplication(props.application)}
        open={true}
        title={"Delete application"}
      />,
    );
  };

  return (
    <Button
      fullWidth={true}
      gaAction={"view-application-drawer-delete-application"}
      iconRight={"trash"}
      text={"Delete application"}
      onButtonClick={handleClick}
    />
  );
};

export default ApplicationActionSection;
