import { User } from "@sandtable/datastore";
import * as React from "react";
import ApiButton from "./ApiButton";
import DocsButton from "./DocsButton";
import ProfileButton from "./ProfileButton";

interface Props {
  user: User;
}

const NavItems = (props: Props): JSX.Element => {
  return (
    <div>
      <DocsButton />
      <ApiButton {...props} />
      <ProfileButton {...props} />
    </div>
  );
};

export default NavItems;
