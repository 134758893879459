import { Syntax } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  environment: Environment;
}

const EnvironmentDependenciesTab = (props: Props) => {
  return (
    <>
      <Syntax
        text={props.environment.condaRequirements ? props.environment.condaRequirements.join("\n") : "None"}
        title="Conda dependencies"
      />
      <Syntax
        text={props.environment.pipRequirements ? props.environment.pipRequirements.join("\n") : "None"}
        title="Pip dependencies"
      />
    </>
  );
};

export default EnvironmentDependenciesTab;
