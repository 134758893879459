import { SectionList, SectionListObject } from "@sandtable/component-library";
import { Organisation } from "@sandtable/datastore";
import { pluraliseString } from "@sandtable/utils";
import * as React from "react";

interface Props {
  fetchOrganisations: () => void;
  onNewOrganisationClick: () => void;
  onOrganisationSelect: (o: Organisation) => void;
  organisations: Organisation[];
}

const OrganisationsList = (props: Props) => {
  let objects: SectionListObject[] = [];
  if (typeof props.organisations !== "undefined") {
    objects = props.organisations.map((o: Organisation, i: number) => {
      const secondaryText = `${o.memberships.length} ${pluraliseString("member", o.memberships.length)} • ${
        o.projects.length
      } ${pluraliseString("project", o.projects.length)}`;
      return {
        data: o,
        gaAction: "organisations-list-card",
        gaLabel: o.id,
        onCardClick: () => props.onOrganisationSelect(o),
        primaryText: o.name,
        secondaryText,
      } as SectionListObject; //tslint:disable-line
    });
  }

  return (
    <SectionList
      headerButtonText="New organisation"
      objects={objects}
      onHeaderButtonClick={props.onNewOrganisationClick}
      fetchResources={props.fetchOrganisations}
      showSearch={true}
      searchKeys={["name", "projects.name"]}
    />
  );
};

export default OrganisationsList;
