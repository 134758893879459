import { ViewDrawer } from "@sandtable/component-library";
import { Deployment, DeploymentMap, ExecutionV2, ExecutionV2Map } from "@sandtable/datastore";
import DeploymentActionSection from "components/Deployments/ViewDeploymentDrawer/DeploymentActionSection";
import DeploymentTab from "components/Deployments/ViewDeploymentDrawer/DeploymentTab";
import ExecutionsTab from "components/Deployments/ViewDeploymentDrawer/ExecutionsTab";
import UsersTab from "components/Deployments/ViewDeploymentDrawer/UsersTab";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  addDeploymentUser: (d: Deployment, userEmail: string) => void;
  removeDeploymentUser: (d: Deployment, userEmail: string) => void;
  deleteDeployment: (d: Deployment) => void;
  deleteExecutionV2: (d: Deployment, e: ExecutionV2) => void;
  deployments: DeploymentMap;
  executions: ExecutionV2Map;
  fetchDeploymentExecutions: (d: Deployment) => void;
  fetchDeploymentUsers: (d: Deployment) => void;
  onClose: () => void;
}

const ViewDeploymentDrawer = (props: Props) => {
  const { id } = useParams();

  const deployment = props.deployments[id!]; // TODO: Handle undefined.
  const executions = props.executions[id!] || {};

  const { fetchDeploymentExecutions, fetchDeploymentUsers } = props;
  React.useEffect(() => {
    if (deployment) {
      fetchDeploymentExecutions(deployment);
      fetchDeploymentUsers(deployment);
    }
  }, []);

  if (!deployment) {
    props.onClose(); // TODO: Handle more gracefully.
    return <></>;
  }

  return (
    <ViewDrawer
      actionSection={<DeploymentActionSection {...props} deployment={deployment} />}
      title={"View Deployment"}
      open={true}
      onClose={props.onClose}
      tabNames={["Deployment", "Executions", "External users"]}
      tabContents={[
        <DeploymentTab key={"deployment-tab"} {...props} deployment={deployment} />,
        <ExecutionsTab key={"executions-tab"} {...props} deployment={deployment} executions={executions} />,
        <UsersTab key={"users-tab"} {...props} deployment={deployment} />,
      ]}
      tabGaAction={"view-deployment-drawer-tabs"}
    />
  );
};

export default ViewDeploymentDrawer;
