import { Typography as MTypography } from "@material-ui/core";
import { Button, DrawerTable, Tabs } from "@sandtable/component-library";
import { Experiment } from "@sandtable/datastore";
import { apiGet, downloadFile, pluraliseString } from "@sandtable/utils";
import * as React from "react";

interface Props {
  experiment: Experiment;
}

const mapParametersArrayToString = (parameters: any) => {
  return parameters.map((p: any) => `(${p[0]}, ${p[1]})`).join(", ");
};

/* tslint:disable */
// TODO: get rid of this crap. last minute hack hefore summer school.
const getHHMMSS = (seconds: number) => {
  let hours: any = Math.floor(seconds / 3600);
  let minutes: any = Math.floor((seconds - hours * 3600) / 60);
  let seconds_: any = seconds - hours * 3600 - minutes * 60;

  return `${hours} ${pluraliseString("hour", hours)}, ${minutes} ${pluraliseString(
    "minute",
    minutes,
  )}, and ${seconds_.toFixed(3)} ${pluraliseString("second", seconds_)}`;
};

const downloadResult = async (experimentId: string, resultName: string) => {
  const res = await apiGet(`/experiments/netlogo/${experimentId}/results`);
  const result = res.data.find((r: any) => r.name === resultName);
  downloadFile(result.http_url, resultName);
};

const ExperimentTab = (props: Props) => {
  const experiment = props.experiment;
  const data = [
    {
      name: "ID",
      value: experiment.id,
    },
    {
      name: "Netlogo file",
      value: experiment.metadata.fileName,
    },
    {
      name: "Creator",
      value: experiment.creator,
    },
    {
      name: "Created",
      value: new Date(experiment.created).toUTCString(),
    },
    {
      name: "Finished",
      value: experiment.finished ? new Date(experiment.finished).toUTCString() : "N/A",
    },
    {
      name: "Execution time",
      value: experiment.finished
        ? getHHMMSS((new Date(experiment.finished).getTime() - new Date(experiment.created).getTime()) / 1000)
        : "N/A",
    },
    {
      name: "Experiment name",
      value: experiment.name,
    },
    {
      name: "Status",
      value: experiment.status,
    },
    {
      name: "Progress",
      value: `${((experiment.progress || 0) * 100).toFixed(0)}%`,
    },
    {
      name: "Repetitions",
      value: experiment.repetitions.toString(),
    },
    {
      name: "Run metrics every step",
      value: experiment.runMetricsEveryStep.toString(),
    },
    {
      name: "Number of simulations",
      value: experiment.numberOfSimulations.toString(),
    },
    {
      name: "Go",
      value: experiment.go.join(", "),
    },
    {
      name: "Parameters",
      value: mapParametersArrayToString(experiment.parameters),
    },
    {
      name: "Metrics",
      value: experiment.metrics.join(", "),
    },
  ];
  const resultsData = experiment.results
    ? experiment.results.map((res: any) => {
        return {
          name: res.name,
          value: (
            <Button
              gaAction={"view-experiment-drawer-experiment-tab-download"}
              gaLabel={props.experiment.id}
              text="Download"
              onButtonClick={() => downloadResult(experiment.id, res.name)}
            />
          ),
        };
      })
    : [];
  return (
    <>
      <DrawerTable tableData={data} tableKey={props.experiment.id + "experiment-table"} />
      <Tabs value={0} labels={["Results"]} gaAction={""} />
      {resultsData.length ? (
        <DrawerTable tableData={resultsData} tableKey={props.experiment.id + "results-table"} />
      ) : (
        <MTypography align={"center"}>No items to display</MTypography>
      )}
    </>
  );
};

export default ExperimentTab;
