import { Typography as MTypography } from "@material-ui/core";
import { Alert, TextField } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import { removeElement } from "@sandtable/utils";
import * as React from "react";

interface Props {
  environment: Environment;
  onSuccess: (e: Environment) => void;
}

const EnvironmentDeleteDialog = (props: Props) => {
  const [textboxCopy, setTextboxCopy] = React.useState("");
  const copy = `This will delete version ${props.environment.uid} of the ${props.environment.name} environment.`;
  const content = (
    <>
      <MTypography>{copy}</MTypography>
      <MTypography>Please enter the name of the environment ({props.environment.name}) to confirm.</MTypography>
      <TextField
        style={{ marginTop: "22px" }}
        defaultValue={""}
        label="Environment name"
        onChange={(e: any) => setTextboxCopy(e.target.value)}
        placeholder="Environment name"
      />
    </>
  );
  return (
    <Alert
      content={content}
      disableYesButton={textboxCopy !== props.environment.name}
      handleClose={removeElement}
      handleSuccess={() => props.onSuccess(props.environment)}
      open={true}
      title="Delete environment"
      noButtonCopy="Cancel"
      yesButtonCopy="Delete"
    />
  );
};

export default EnvironmentDeleteDialog;
