import { CardList, CardListObject, Drawer, DrawerContainer } from "@sandtable/component-library";
import { EnvironmentV2 } from "@sandtable/datastore";
import { EnvironmentsV2Object, mapEnvironmentStateToColorClass } from "@sandtable/utils";
import * as React from "react";
import { useParams } from "react-router-dom";

interface Props {
  environmentsObject: EnvironmentsV2Object;
  onClose: () => void;
  onEnvironmentSelect: (e: EnvironmentV2) => void;
}

const ViewEnvironmentsV2Drawer = (props: Props) => {
  const { name } = useParams();

  const environments = props.environmentsObject[name!] || []; // TODO: Handle undefined.

  return (
    <Drawer onClose={props.onClose} open={true} title={"View Environments"}>
      <DrawerContainer>
        <CardList
          objects={environments.map(
            (e: EnvironmentV2) =>
              ({
                gaAction: "view-environments-v2-drawer-card",
                gaLabel: e.uid,
                onCardClick: () => props.onEnvironmentSelect(e),
                primaryIcon: "circle",
                primaryIconClassName: mapEnvironmentStateToColorClass(e.status),
                primaryIconTooltip: e.status,
                primaryText: `${new Date(e.createdDatetime).toUTCString()}`,
                secondaryText: `${e.uid} • ${e.creator}`,
              } as CardListObject), // tslint:disable-line
          )}
        />
      </DrawerContainer>
    </Drawer>
  );
};

export default ViewEnvironmentsV2Drawer;
