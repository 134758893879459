import { Environment } from "@sandtable/datastore";
import EnvironmentCodeSection from "components/Environments/NewEnvironmentDrawer/EnvironmentCodeSection";
import * as React from "react";

interface Props {
  environment: Environment;
  onChange: (e: Partial<Environment>) => void;
}

const DependenciesTab = (props: Props): JSX.Element => {
  return (
    <>
      <EnvironmentCodeSection
        onChange={(s: string) => props.onChange({ condaRequirements: s === "" ? [] : s.trim().split("\n") })}
        placeholder={"numpy==1.15.0"}
        title={"Update Conda dependencies (optional)"}
        value={(props.environment.condaRequirements || []).join("\n")}
      />
      <EnvironmentCodeSection
        onChange={(s: string) => props.onChange({ pipRequirements: s === "" ? [] : s.trim().split("\n") })}
        placeholder={"numpy==1.15.0"}
        title={"Update Pip dependencies (optional)"}
        value={(props.environment.pipRequirements || []).join("\n")}
      />
    </>
  );
};

export default DependenciesTab;
