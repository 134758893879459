import { Select } from "@sandtable/component-library";
import { Environment, NewCluster } from "@sandtable/datastore";
import { EnvironmentsObject, removeDups } from "@sandtable/utils";
import EnvironmentDependenciesTab from "components/Environments/ViewEnvironmentDrawer/EnvironmentDependenciesTab";
import * as React from "react";

export interface Props {
  cluster: NewCluster;
  completedEnvironments: EnvironmentsObject;
  onChange: (c: Partial<NewCluster>, e: boolean) => void;
}

export interface State {
  environmentName: string;
  filteredEnvironments: Environment[];
  filteredPythonVersions: string[];
  filteredRuntimeVersions: string[];
  pythonVersion: string;
  runtimeVersion: string;
  selectedEnvironment?: Environment;
}

const ClusterEnvironmentV1Form: React.StatelessComponent<Props> = (props: Props) => {
  const { completedEnvironments, onChange } = props;

  const formReducer = React.useCallback(
    (currentState: State, action: { name: string; value: string }): State => {
      const { value, name } = action;
      switch (name) {
        case "environmentName": {
          const environments = completedEnvironments[value];
          const threeSixEnvironments = environments.filter((e: Environment) => e.pythonVersion === "3.6");
          const filteredEnvironments = threeSixEnvironments.length ? threeSixEnvironments : environments;
          const selectedEnvironment = filteredEnvironments[0];
          const filteredPythonVersions = removeDups(environments.map((e: Environment) => e.pythonVersion)).sort(
            (a: string, b: string) => {
              return +b - +a;
            },
          );
          const filteredRuntimeVersions = removeDups(environments.map((e: Environment) => e.runtimeVersion));
          onChange({ environmentId: selectedEnvironment.uid }, false);
          return {
            ...currentState,
            environmentName: value,
            filteredEnvironments,
            filteredPythonVersions,
            filteredRuntimeVersions,
            pythonVersion: selectedEnvironment.pythonVersion,
            runtimeVersion: selectedEnvironment.runtimeVersion,
            selectedEnvironment,
          };
        }
        case "pythonVersion": {
          const environments = completedEnvironments[currentState.environmentName];
          const filteredEnvironments = environments.filter((e: Environment) => e.pythonVersion === value);
          const selectedEnvironment = filteredEnvironments[0];
          onChange({ environmentId: selectedEnvironment.uid }, false);
          const filteredRuntimeVersions = removeDups(environments.map((e: Environment) => e.runtimeVersion));
          return {
            ...currentState,
            filteredEnvironments,
            filteredRuntimeVersions,
            pythonVersion: value,
            runtimeVersion: selectedEnvironment.runtimeVersion,
            selectedEnvironment,
          };
        }
        case "runtimeVersion": {
          const environments = completedEnvironments[currentState.environmentName];
          const filteredEnvironments = environments.filter((e: Environment) => {
            return e.pythonVersion === currentState.pythonVersion && e.runtimeVersion === value;
          });
          const selectedEnvironment = filteredEnvironments[0];
          onChange({ environmentId: selectedEnvironment.uid }, false);
          return {
            ...currentState,
            filteredEnvironments,
            pythonVersion: currentState.pythonVersion,
            runtimeVersion: value,
            selectedEnvironment,
          };
        }
        case "environmentId": {
          const environments = completedEnvironments[currentState.environmentName];
          const selectedEnvironment = environments.find((e: Environment) => e.uid === value) || undefined;
          onChange({ environmentId: selectedEnvironment ? selectedEnvironment.uid : "" }, false);
          return {
            ...currentState,
            selectedEnvironment,
          };
        }
        default:
          throw new Error();
      }
    },
    [completedEnvironments, onChange],
  );

  const [state, dispatch] = React.useReducer(formReducer, {
    environmentName: "",
    filteredEnvironments: [],
    filteredPythonVersions: [],
    filteredRuntimeVersions: [],
    pythonVersion: "",
    runtimeVersion: "",
  });

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<any>) => dispatch({ name: e.target.name, value: e.target.value }),
    [],
  );

  return (
    <form style={{ marginBottom: "18px" }}>
      <Select
        insertNoneOption={false}
        label="Environment name"
        labelWidth={136}
        onChange={handleChange}
        options={Object.keys(completedEnvironments).map((s: string) => ({
          text: s,
        }))}
        name="environmentName"
        value={state.environmentName}
        style={{ marginBottom: "14px" }}
      />
      <div style={{ display: "flex", marginBottom: "14px", justifyContent: "space-between" }}>
        <Select
          fullWidth={false}
          insertNoneOption={false}
          label="Python version"
          labelWidth={108}
          onChange={handleChange}
          options={state.filteredPythonVersions.map((s: string) => ({ text: s }))}
          name="pythonVersion"
          value={state.pythonVersion}
          style={{ flex: "0 0 48%" }}
        />
        <Select
          fullWidth={false}
          insertNoneOption={false}
          label="Sandman revision"
          labelWidth={118}
          onChange={handleChange}
          options={state.filteredRuntimeVersions.map((s: string) => ({ text: s }))}
          name="runtimeVersion"
          value={state.runtimeVersion}
          style={{ flex: "0 0 48%" }}
        />
      </div>
      <Select
        insertNoneOption={false}
        label="Environment version"
        labelWidth={150}
        onChange={handleChange}
        options={state.filteredEnvironments.map((e: Environment) => ({
          text: new Date(e.created).toUTCString(),
          value: e.uid,
        }))} //tslint:disable-line
        name="environmentId"
        value={state.selectedEnvironment && state.selectedEnvironment.uid}
      />
      {state.selectedEnvironment && <EnvironmentDependenciesTab environment={state.selectedEnvironment} />}
    </form>
  );
};

export default ClusterEnvironmentV1Form;
