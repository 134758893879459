import { Typography as MTypography } from "@material-ui/core";
import { DrawerTable, Icon, Syntax, Tabs } from "@sandtable/component-library";
import { Environment } from "@sandtable/datastore";
import { capitaliseString, mapEnvironmentStateToColorClass } from "@sandtable/utils";
import * as React from "react";

interface Props {
  environment: Environment;
}

const EnvironmentTab = (props: Props): JSX.Element => {
  const environment = props.environment;
  const drawerTableData = [
    {
      name: "Status",
      value: (
        <span style={{ display: "flex", marginLeft: "-4px", maxHeight: "21px" }}>
          <Icon icon="circle" className={mapEnvironmentStateToColorClass(environment.status)} />
          <MTypography style={{ margin: "2px" }}>{capitaliseString(environment.status)}</MTypography>
        </span>
      ),
    },
    {
      name: "Environment ID",
      value: environment.uid || "N/A",
    },
    {
      name: "Created",
      value: new Date(environment.created).toUTCString(),
    },
    {
      name: "Name",
      value: environment.name,
    },
    {
      name: "Creator",
      value: environment.creator || "N/A",
    },
    {
      name: "Organisation",
      value: environment.organisation || "N/A",
    },
    {
      name: "Size",
      value: (environment.size || 0).toString(),
    },
    {
      name: "Sandman revision",
      value: environment.runtimeVersion,
    },
    {
      name: "Python version",
      value: environment.pythonVersion,
    },
  ];
  const errorSection = (
    <>
      <Tabs value={0} labels={["Error"]} gaAction={""} />
      <Syntax text={environment.error || "N/A"} />
    </>
  );
  return (
    <>
      <DrawerTable tableData={drawerTableData} tableKey={environment.uid} />
      {environment.error ? errorSection : ""}
    </>
  );
};

export default EnvironmentTab;
