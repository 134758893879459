import { StepIcon as MStepIcon } from "@material-ui/core";
import * as React from "react";

interface Props {
  active: boolean;
  iconNumber: number;
}

const Balloon = (props: Props) => <MStepIcon icon={props.iconNumber} active={props.active} />;

Balloon.defaultProps = {
  active: true,
};

export default Balloon;
