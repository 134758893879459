import { RepoMap, UploadMap } from "@sandtable/datastore";
import { useDrawerState } from "@sandtable/utils";
import StagedList from "components/Data/StagedList";
import ViewStagedDrawer from "components/Data/ViewStagedDrawer/ViewStagedDrawer";
import * as React from "react";

interface Props {
  commitUpload: (uploadId: string, filename: string, repo: string) => void;
  deleteUpload: (uploadId: string) => void;
  fetchUploads: () => void;
  repos: RepoMap;
  uploads: UploadMap;
}

const Staged = (props: Props) => {
  const [selectedStagedId, setSelectedStagedId] = React.useState("");
  const [isDrawerOpen, handleOpen, handleClose] = useDrawerState();

  const onCardClick = (v: string) => {
    setSelectedStagedId(v);
    handleOpen();
  };

  const commitUpload = React.useCallback((uploadId: string, filename: string, repo: string) => {
    props.commitUpload(uploadId, filename, repo);
    handleClose();
  }, []);

  const deleteUpload = React.useCallback((uploadId: string) => {
    props.deleteUpload(uploadId);
    handleClose();
  }, []);

  return (
    <>
      <StagedList {...props} onCardClick={onCardClick} />
      <ViewStagedDrawer
        {...props}
        commitUpload={commitUpload}
        deleteUpload={deleteUpload}
        open={isDrawerOpen}
        onClose={handleClose}
        staged={props.uploads[selectedStagedId]}
      />
    </>
  );
};

export default Staged;
