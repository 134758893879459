import { Loading } from "@sandtable/component-library";
import { Cluster, ClusterMap, ConfigurationMap, EnvironmentsV2Map, NewCluster } from "@sandtable/datastore";
import { EnvironmentsObject, EnvironmentsV2Object } from "@sandtable/utils";
import ClustersList from "components/Clusters/ClustersList";
import NewClusterDrawer from "components/Clusters/NewClusterDrawer/NewClusterDrawer";
import ViewClusterDrawer from "components/Clusters/ViewClusterDrawer/ViewClusterDrawer";
import * as React from "react";
import { generatePath, Route, useHistory } from "react-router-dom";

const CLUSTERS_PATH = "/clusters";
const NEW_CLUSTER_PATH = "/clusters/new-cluster";
const VIEW_CLUSTER_PATH = "/clusters/view-cluster/:clusterId";

interface Props {
  addCluster: (c: NewCluster) => void;
  clusters: ClusterMap;
  completedEnvironments: EnvironmentsObject;
  completedEnvironmentsV2: EnvironmentsV2Object;
  configurations: ConfigurationMap;
  deleteCluster: (C: Cluster) => void;
  environments: EnvironmentsObject;
  environmentsV2: EnvironmentsV2Map;
  fetchClusters: (page?: number, perPage?: number, stringQuery?: string) => void;
  fetchClusterLogs: (c: Cluster) => void;
  isFetching: boolean;
  projects: string[];
  recentClusterConfigurations: ClusterMap;
  restartCluster: (c: Cluster) => void;
  scaleCluster: (c: Cluster) => void;
  startCluster: (c: Cluster) => void;
  stopCluster: (c: Cluster) => void;
  updateCluster: (c: Cluster) => void;
  upgradeCluster: (c: Cluster) => void;
}

const Clusters = (props: Props) => {
  const history = useHistory();

  const handleGoBack = React.useCallback(() => history.push(CLUSTERS_PATH), [history]);
  const handleClusterSelect = React.useCallback(
    (c: Cluster) => history.push(generatePath(VIEW_CLUSTER_PATH, { clusterId: c.clusterId })),
    [history],
  );
  const handleNewClusterClick = React.useCallback(() => history.push(NEW_CLUSTER_PATH), [history]);
  const handleClusterAdd = React.useCallback(
    (c: NewCluster) => {
      props.addCluster(c);
      history.push(CLUSTERS_PATH);
    },
    [history, props],
  );

  if (props.isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Route path={CLUSTERS_PATH}>
        <ClustersList {...props} onClusterSelect={handleClusterSelect} onNewClusterClick={handleNewClusterClick} />
      </Route>
      <Route path={NEW_CLUSTER_PATH}>
        <NewClusterDrawer {...props} onClose={handleGoBack} onClusterAdd={handleClusterAdd} />
      </Route>
      <Route path={VIEW_CLUSTER_PATH}>
        <ViewClusterDrawer {...props} onClose={handleGoBack} />
      </Route>
    </>
  );
};

export default Clusters;
