import {
  RECEIVE_ADD_WHITELIST_ENTRY,
  RECEIVE_DELETE_WHITELIST_ENTRY,
  RECEIVE_WHITELIST,
  REQUEST_WHITELIST,
  WhitelistActionTypes,
  WhitelistState,
} from "@sandtable/datastore/whitelist";

const initialState: WhitelistState = {
  isFetching: true,
  whitelist: {},
};

export const whitelistReducer = (state = initialState, action: WhitelistActionTypes): WhitelistState => {
  switch (action.type) {
    case REQUEST_WHITELIST:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_WHITELIST:
      return {
        ...state,
        isFetching: false,
        whitelist: action.whitelist || {},
      };
    case RECEIVE_ADD_WHITELIST_ENTRY:
      return {
        ...state,
        whitelist: {
          ...state.whitelist,
          ...action.whitelist,
        },
      };
    case RECEIVE_DELETE_WHITELIST_ENTRY:
      const whitelist = { ...state.whitelist };
      delete whitelist[action.email];
      return {
        ...state,
        whitelist,
      };
    default:
      return state;
  }
};
