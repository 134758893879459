import { colors, createMuiTheme } from "@material-ui/core";

export const PRIMARY_COLOR = "#002f6c";
export const SECONDARY_COLOR = "#00838f";

const primaryPalette = {
  ...colors.grey,
  500: PRIMARY_COLOR,
  700: "#002049",
};

const secondaryPalette = {
  A400: SECONDARY_COLOR,
};

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: "none",
        fontSize: "0.84em",
      },
    },
    MuiPaper: {
      elevation1: {
        borderColor: "#c4c4c4",
        borderStyle: "solid",
        borderWidth: "1px",
        boxShadow: "none",
      },
      elevation2: {
        boxShadow: "none",
      },
      elevation4: {
        boxShadow: "none",
      },
    },
  },
  palette: {
    primary: primaryPalette,
    secondary: secondaryPalette,
  },
});

export default theme;
