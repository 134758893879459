import {
  EnvironmentsActionTypes,
  EnvironmentsState,
  RECEIVE_ADD_ENVIRONMENT,
  RECEIVE_DELETE_ENVIRONMENT,
  RECEIVE_ENVIRONMENT_LOGS,
  RECEIVE_ENVIRONMENTS,
  RECEIVE_UPDATE_ENVIRONMENT,
  REQUEST_ENVIRONMENTS,
  SUBSCRIBE_ENVIRONMENT_UPDATES,
} from "@sandtable/datastore/environments";

const initialState: EnvironmentsState = {
  environments: {},
  isFetching: true,
  pagination: {
    environmentsCount: 0,
    page: 1,
    perPage: 10,
  },
  searchQuery: "",
  subscribedToEnvironmentUpdates: false,
};

export const environmentsReducer = (state = initialState, action: EnvironmentsActionTypes): EnvironmentsState => {
  switch (action.type) {
    case REQUEST_ENVIRONMENTS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_ENVIRONMENTS: {
      return {
        ...state,
        environments: action.environments,
        isFetching: false,
        pagination: action.pagination,
        searchQuery: action.searchQuery,
      };
    }
    case RECEIVE_ADD_ENVIRONMENT: {
      return {
        ...state,
        environments: {
          ...state.environments,
          ...action.environments,
        },
      };
    }
    case RECEIVE_UPDATE_ENVIRONMENT: {
      const environments = { ...state.environments };
      const updatedEnvironments = Object.values(action.environments);
      if (updatedEnvironments.length !== 1) {
        // Should only ever be one.
        throw new Error("Invalid no. environments");
      }
      const environment = updatedEnvironments[0];

      // If found, update existing item. Used for WS messages.
      if (environments[environment.uid]) {
        if (environment.status === "deleted") {
          delete environments[environment.uid];
        } else {
          environments[environment.uid] = environment;
        }

        return { ...state, environments };
      }

      // If not found, add to new list
      return {
        ...state,
        environments: {
          ...environments,
          [environment.uid]: environment,
        },
      };
    }
    case RECEIVE_DELETE_ENVIRONMENT:
      const environments = { ...state.environments };
      delete environments[action.environmentId];
      return {
        ...state,
        environments,
      };
    case RECEIVE_ENVIRONMENT_LOGS: {
      return {
        ...state,
        environments: {
          ...state.environments,
          [action.environmentId]: {
            ...state.environments[action.environmentId],
            logs: Object.values(action.environmentLogs.all),
          },
        },
      };
    }
    case SUBSCRIBE_ENVIRONMENT_UPDATES:
      return {
        ...state,
        subscribedToEnvironmentUpdates: true,
      };
    default:
      return state;
  }
};
