import { DrawerTable } from "@sandtable/component-library";
import { Cluster, Configuration } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  cluster: Cluster;
  configuration: Configuration;
}

const ClusterConfigurationTab = (props: Props) => {
  const data = [
    {
      name: "Name",
      value: props.configuration.name,
    },
    {
      name: "Cores",
      value: props.cluster.hyperthreading ? props.configuration.vcpus.toString() : props.configuration.cores.toString(),
    },
    {
      name: "Memory",
      value: props.configuration.totalMemory.toString() + " GB",
    },
  ];
  return <DrawerTable tableData={data} tableKey={props.cluster.clusterId + "config-table"} />;
};

export default ClusterConfigurationTab;
