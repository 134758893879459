import { RECEIVE_CONFIGURATIONS, REQUEST_CONFIGURATIONS } from "@sandtable/datastore/configurations";
import { CONFIGURATION_ARRAY } from "@sandtable/datastore/configurations/schema";
import { API_GET, ApiMiddlewareAction } from "@sandtable/datastore/middleware";

export const fetchConfigurations = () => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: "configurations",
        schema: CONFIGURATION_ARRAY,
        types: [REQUEST_CONFIGURATIONS, RECEIVE_CONFIGURATIONS, RECEIVE_CONFIGURATIONS],
      },
    });
  };
};
