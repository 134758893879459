export interface Experiment {
  created: Date;
  creator: string;
  experimentType: string;
  fileId: string;
  finished?: Date;
  go: string[];
  id: string;
  metadata: any;
  metrics: string[];
  name: string;
  numberOfSimulations: number;
  parameters: any;
  progress: number;
  repetitions: number;
  results?: any;
  runMetricsEveryStep: boolean;
  status: string;
  steps: number;
}

export interface ExperimentMap {
  [id: string]: Experiment;
}

export interface ExperimentsState {
  isFetching: boolean;
  experiments: ExperimentMap;
  subscribedToExperimentUpdates: boolean;
}

export const REQUEST_EXPERIMENTS = "REQUEST_EXPERIMENTS";
export const RECEIVE_EXPERIMENTS = "RECEIVE_EXPERIMENTS";
export const REQUEST_ADD_EXPERIMENT = "REQUEST_ADD_EXPERIMENT";
export const RECEIVE_ADD_EXPERIMENT = "RECEIVE_ADD_EXPERIMENT";
export const RECEIVE_EXPERIMENT_UPDATE = "RECEIVE_EXPERIMENT_UPDATE";
export const SUBSCRIBE_EXPERIMENT_UPDATES = "SUBSCRIBE_EXPERIMENT_UPDATES";

interface RequestExperimentsAction {
  type: typeof REQUEST_EXPERIMENTS;
}

interface ReceiveExperimentsAction {
  type: typeof RECEIVE_EXPERIMENTS;
  experiments: ExperimentMap;
}

interface RequestAddExperimentAction {
  type: typeof REQUEST_ADD_EXPERIMENT;
}

interface ReceiveAddExperimentAction {
  type: typeof RECEIVE_ADD_EXPERIMENT;
  experiments: ExperimentMap;
}

interface ReceiveExperimentUpdateAction {
  type: typeof RECEIVE_EXPERIMENT_UPDATE;
  experiments: ExperimentMap;
}

interface SubscribeExperimentUpdatesAction {
  type: typeof SUBSCRIBE_EXPERIMENT_UPDATES;
}

export type ExperimentsActionTypes =
  | RequestExperimentsAction
  | ReceiveExperimentsAction
  | RequestAddExperimentAction
  | ReceiveAddExperimentAction
  | ReceiveExperimentUpdateAction
  | SubscribeExperimentUpdatesAction;
