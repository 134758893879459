import { CardList, CardListObject } from "@sandtable/component-library";
import { Organisation, PlatformUser } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  user: PlatformUser;
}

const OrganisationsTab = (props: Props) => {
  return (
    <CardList
      objects={props.user.organisations.map(
        (o: Organisation, key: any) =>
          ({
            gaAction: "view-user-drawer-user-tab-organisations-card",
            gaLabel: o.id,
            primaryIcon: "user-cog",
            primaryText: o.name,
          } as CardListObject), //tslint:disable-line
      )}
    />
  );
};

export default OrganisationsTab;
