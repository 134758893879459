import {
  EnvironmentsV2ActionTypes,
  EnvironmentsV2State,
  RECEIVE_ADD_ENVIRONMENT_V2,
  RECEIVE_CANCEL_ENVIRONMENT_V2,
  RECEIVE_DELETE_ENVIRONMENT_V2,
  RECEIVE_ENVIRONMENTS_V2,
  RECEIVE_GET_ENVIRONMENT_V2,
  RECEIVE_UPDATE_ENVIRONMENT_V2,
  REQUEST_ENVIRONMENTS_V2,
  SUBSCRIBE_ENVIRONMENT_V2_UPDATES,
} from "@sandtable/datastore/environments_v2";

const initialState: EnvironmentsV2State = {
  environments: {},
  isFetching: true,
  pagination: {
    environmentsCount: 0,
    page: 1,
    perPage: 10,
  },
  searchQuery: "",
  subscribedToEnvironmentUpdates: false,
};

export const environmentsV2Reducer = (state = initialState, action: EnvironmentsV2ActionTypes): EnvironmentsV2State => {
  switch (action.type) {
    case REQUEST_ENVIRONMENTS_V2:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_ENVIRONMENTS_V2:
      return {
        ...state,
        environments: action.environments,
        isFetching: false,
        pagination: action.pagination,
        searchQuery: action.searchQuery,
      };
    case RECEIVE_UPDATE_ENVIRONMENT_V2:
      const environments = {
        ...state.environments,
        ...action.environments,
      };
      // Remove `deleted` environments.
      Object.keys(action.environments).forEach((uid: string) => {
        if (action.environments[uid].status === "deleted") {
          delete environments[uid];
        }
      });
      return {
        ...state,
        environments,
      };
    case RECEIVE_GET_ENVIRONMENT_V2:
    case RECEIVE_ADD_ENVIRONMENT_V2:
    case RECEIVE_CANCEL_ENVIRONMENT_V2:
    case RECEIVE_DELETE_ENVIRONMENT_V2:
      return {
        ...state,
        environments: {
          ...state.environments,
          ...action.environments,
        },
      };
    case SUBSCRIBE_ENVIRONMENT_V2_UPDATES:
      return {
        ...state,
        subscribedToEnvironmentUpdates: true,
      };
    default:
      return state;
  }
};
