import { schema } from "normalizr";

const COLLECTION_NAME = "user";
const ID_KEY = "email";

const userSchema = new schema.Entity(
  COLLECTION_NAME,
  {},
  {
    idAttribute: (user: User) => user[ID_KEY],
  },
);

// User interfaces:
export interface UserOrganisation {
  name: string;
  projects: string[];
}

export interface User {
  credentialId: string;
  credentialSecret: string;
  downloadUrl: string;
  email: string;
  emailVerified: boolean;
  name: string;
  organisation: UserOrganisation;
  picture: string;
  redeemedCode: boolean;
  showJupyter?: boolean;
  superAdmin: boolean;
  termsAgreed: boolean;
  visibleRoutes: string[];
}

export interface UserState {
  isFetching: boolean;
  user: User;
}

// Action constants:
export const RECEIVE_ACCEPT_TERMS = "RECEIVE_ACCEPT_TERMS";
export const RECEIVE_USER = "RECEIVE_USER";
export const REQUEST_ACCEPT_TERMS = "REQUEST_ACCEPT_TERMS";
export const REQUEST_USER = "REQUEST_USER";

// Action types:
interface RequestUserAction {
  type: typeof REQUEST_USER;
}

interface ReceiveUserAction {
  user: User;
  type: typeof RECEIVE_USER;
}

interface RequestAcceptTerms {
  type: typeof REQUEST_ACCEPT_TERMS;
}

interface ReceiveAcceptTerms {
  type: typeof RECEIVE_ACCEPT_TERMS;
}

export const USER_SCHEMA = userSchema;

export type UserActionTypes = RequestUserAction | ReceiveUserAction | RequestAcceptTerms | ReceiveAcceptTerms;
