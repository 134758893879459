import { schema } from "normalizr";

const configurationSchema = new schema.Entity(
  "configurations",
  {},
  {
    idAttribute: (configuration: any) => configuration.name,
  },
);

export const CONFIGURATION_ARRAY = [configurationSchema];
