import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button as MButton } from "@material-ui/core";
import { Icon } from "@sandtable/component-library";
import * as React from "react";
import "./Button.css";

export interface Props {
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
  gaAction: string;
  gaLabel?: string;
  icon?: IconProp;
  iconRight?: IconProp;
  onButtonClick?: any;
  secondary?: boolean;
  style?: React.CSSProperties;
  text: string;
}

const Button = (props: Props) => {
  return (
    <MButton
      className={props.className}
      color={!props.secondary ? "primary" : "secondary"}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      onClick={props.onButtonClick}
      variant="contained"
      disableFocusRipple={true}
      data-ga-event-category={window.location.pathname.slice(1)}
      data-ga-event-action={props.gaAction.toLowerCase()}
      data-ga-event-label={(props.gaLabel || "").toLowerCase()}
      style={props.style}
    >
      {props.icon ? <Icon icon={props.icon} className="button-icon" /> : ""}
      {props.text}
      {props.iconRight ? <Icon icon={props.iconRight} className="button-icon-right" /> : ""}
    </MButton>
  );
};

export default Button;
