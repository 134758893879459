import { Typography as MTypography } from "@material-ui/core";
import { CardListObject } from "@sandtable/component-library";
import * as React from "react";
import CardListRow from "./CardListRow";

interface Props {
  objects: CardListObject[];
}

const CardList = (props: Props) => {
  if (!props.objects.length) {
    return (
      <MTypography align={"center"} style={{ marginTop: "22px" }}>
        No items to display
      </MTypography>
    );
  }
  const rows = props.objects.map((o: CardListObject, key: number) => {
    return <CardListRow key={key} object={o} />;
  });
  return <ul style={{ listStyle: "none", padding: "0 0 0 0" }}>{rows}</ul>;
};

export default CardList;
