import { CardList, CardListObject } from "@sandtable/component-library";
import { Deployment, ExecutionV2, ExecutionV2Map } from "@sandtable/datastore";
// TODO: Abstract:
import { handleExecutionDelete } from "components/Applications/ViewExecutionDrawer/ExecutionActionSection";
import * as React from "react";

interface Props {
  deleteExecutionV2: (d: Deployment, e: ExecutionV2) => void;
  deployment: Deployment;
  executions: ExecutionV2Map;
}

const ExecutionsTab = (props: Props) => {
  const objects: CardListObject[] = Object.values(props.executions).map(
    (e: ExecutionV2) =>
      ({
        gaAction: "view-deployment-drawer-executions-tab-card",
        gaLabel: e.id,
        onCardClick: undefined,
        primaryIcon: "tablet-alt",
        primaryText: `${e.id} • ${new Date(e.created).toUTCString()}`,
        secondaryIcon: "trash",
        secondaryIconClick: () => handleExecutionDelete(() => props.deleteExecutionV2(props.deployment, e)),
      } as CardListObject), //tslint:disable-line
  );

  return <CardList objects={objects} />;
};

export default ExecutionsTab;
