import { CircularProgress as MCircularProgress } from "@material-ui/core";
import * as React from "react";

const LOGO_URL = "/logo.svg";

const CONTAINER_STYLES: React.CSSProperties = {
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
};

interface Props {
  type: "circular" | "sandtable";
}

const Spinner = (props: Props) => {
  return (
    <div style={CONTAINER_STYLES}>
      {props.type === "circular" ? <MCircularProgress size={100} /> : undefined}
      {props.type === "sandtable" ? <img src={LOGO_URL} style={{ maxHeight: "200px" }} /> : undefined}
    </div>
  );
};

export default Spinner;
