import {
  addCluster,
  AppState,
  Cluster,
  ClusterMap,
  ConfigurationMap,
  deleteCluster,
  Environment,
  EnvironmentsV2Map,
  EnvironmentV2,
  fetchClusterLogs,
  fetchClusters,
  fetchConfigurations,
  fetchEnvironments,
  fetchEnvironmentsV2,
  fetchRecentClusterConfigurations,
  NewCluster,
  restartCluster,
  scaleCluster,
  startCluster,
  stopCluster,
  subscribeToClusterUpdates,
  updateCluster,
  upgradeCluster,
  User,
} from "@sandtable/datastore";
import {
  EnvironmentsObject,
  EnvironmentsV2Object,
  mapEnvironmentsToObject,
  mapEnvironmentsV2ToObject,
} from "@sandtable/utils";
import Clusters from "components/Clusters/Clusters";
import * as React from "react";
import { connect } from "react-redux";

interface Props {
  addCluster: (c: NewCluster) => void;
  clusters: ClusterMap;
  completedEnvironments: EnvironmentsObject;
  completedEnvironmentsV2: EnvironmentsV2Object;
  configurations: ConfigurationMap;
  deleteCluster: (c: Cluster) => void;
  environments: EnvironmentsObject;
  environmentsV2: EnvironmentsV2Map;
  fetchClusterLogs: (c: Cluster) => void;
  fetchClusters: (page?: number, perPage?: number, stringQuery?: string) => void;
  fetchConfigurations: () => void;
  fetchEnvironments: ((page?: number, perPage?: number, searchQuery?: string) => void);
  fetchEnvironmentsV2: (page?: number, perPage?: number, searchQuery?: string) => void;
  fetchRecentClusterConfigurations: () => void;
  isFetching: boolean;
  projects: string[];
  recentClusterConfigurations: ClusterMap;
  restartCluster: (c: Cluster) => void;
  scaleCluster: (c: Cluster) => void;
  startCluster: (c: Cluster) => void;
  stopCluster: (c: Cluster) => void;
  subscribedToClusterUpdates: boolean;
  subscribeToClusterUpdates: (orgName: string) => void;
  updateCluster: (c: Cluster) => void;
  upgradeCluster: (c: Cluster) => void;
  user: User;
}

const ClustersContainer = (props: Props) => {
  const {
    fetchClusters,
    fetchConfigurations,
    fetchEnvironments,
    fetchEnvironmentsV2,
    fetchRecentClusterConfigurations,
    subscribeToClusterUpdates,
    subscribedToClusterUpdates,
    user,
  } = props;

  const PAGE_DEFAULT = 1;
  const PER_PAGE_DEFAULT = 10;

  React.useEffect(() => {
    fetchClusters(PAGE_DEFAULT, PER_PAGE_DEFAULT);
    fetchConfigurations();
    fetchEnvironments();
    fetchEnvironmentsV2();
    fetchRecentClusterConfigurations();

    if (!subscribedToClusterUpdates) {
      subscribeToClusterUpdates(user.organisation.name);
    }
  }, []);

  return <Clusters {...props} />;
};

const mapStateToProps = (state: AppState) => {
  const environments = typeof state.environments.environments !== "undefined"
    ? Object.values(state.environments.environments)
    : [];
  const completedEnvs = environments.filter((e: Environment) => e.status === "completed");

  const environmentsV2 = typeof state.environmentsV2.environments !== "undefined"
    ? Object.values(state.environmentsV2.environments)
    : [];
  const completedEnvsV2 = environmentsV2.filter((e: EnvironmentV2) => e.status === "completed");

  const organisation = state.user.user.organisation;

  return {
    clusters: state.clusters.clusters,
    completedEnvironments: mapEnvironmentsToObject(completedEnvs),
    completedEnvironmentsV2: mapEnvironmentsV2ToObject(completedEnvsV2),
    configurations: state.configurations.configurations,
    environments: mapEnvironmentsToObject(environments),
    environmentsV2: state.environmentsV2.environments,
    isFetching: state.clusters.isFetching || state.environments.isFetching || state.environmentsV2.isFetching,
    projects: organisation && organisation.projects ? organisation.projects.sort() : [],
    recentClusterConfigurations: state.clusters.recentClusterConfigurations,
    subscribedToClusterUpdates: state.clusters.subscribedToClusterUpdates,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  addCluster,
  deleteCluster,
  fetchClusterLogs,
  fetchClusters,
  fetchConfigurations,
  fetchEnvironments,
  fetchEnvironmentsV2,
  fetchRecentClusterConfigurations,
  restartCluster,
  scaleCluster,
  startCluster,
  stopCluster,
  subscribeToClusterUpdates,
  updateCluster,
  upgradeCluster,
})(ClustersContainer);
