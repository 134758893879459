import { AppBar, Tab, Tabs } from "@material-ui/core";
import * as React from "react";

const CONTAINER_STYLES: React.CSSProperties = { margin: "-20px", marginBottom: "20px", display: "flex" };

interface Props {
  tabs: string[];
  onChange: (i: number) => void;
}

const NavTabs = (props: Props) => {
  const [currentTab, setCurrentTab] = React.useState(0);

  const { onChange } = props;
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<any>, v: number) => {
      setCurrentTab(v);
      onChange(v);
    },
    [onChange],
  );

  const linkTabs = props.tabs.map((t: string, i: number) => <LinkTab label={t} key={i} />);
  return (
    <div style={CONTAINER_STYLES}>
      <AppBar position="static">
        <Tabs value={currentTab} onChange={handleChange}>
          {linkTabs}
        </Tabs>
      </AppBar>
    </div>
  );
};

interface LinkTabProps {
  label: string;
}

const LinkTab = (props: LinkTabProps) => {
  return <Tab component="a" onClick={(event: React.ChangeEvent<{}>) => event.preventDefault()} {...props} />;
};

export default NavTabs;
