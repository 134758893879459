import { schema } from "normalizr";

const executionSchema = new schema.Entity(
  "executions",
  {},
  {
    idAttribute: (execution: any) => execution.id,
  },
);

export const EXECUTION_ARRAY = [executionSchema];
