export interface ClusterLog {
  timestamp: number;
  message: string;
}

export interface Cluster {
  clusterConfiguration: string;
  clusterId: string;
  created: Date;
  lastAvailable?: Date;
  environmentId: string;
  environmentName?: string;
  environmentVersion?: string;
  hyperthreading: boolean;
  useIdleShutdown: boolean;
  masterDomainName?: string;
  instances: number;
  runtimeVersion?: string;
  owner: string;
  organisation: string;
  project: string;
  pythonVersion?: string;
  publiclyAccessible?: boolean;
  spotInstances: boolean;
  status: string;
  logs?: ClusterLog[];
}

export type NewCluster = Pick<
  Cluster,
  | "clusterConfiguration"
  | "environmentId"
  | "hyperthreading"
  | "instances"
  | "project"
  | "spotInstances"
  | "useIdleShutdown"
>;

export interface ClusterMap {
  [id: string]: Cluster;
}

export interface ClusterPagination {
  page: number;
  perPage: number;
  clustersCount: number;
}

// Cluster interfaces:
export interface ClusterState {
  clusters: ClusterMap;
  pagination: ClusterPagination;
  isFetching: boolean;
  searchQuery: string;
  recentClusterConfigurations: ClusterMap;
  subscribedToClusterUpdates: boolean;
}

// Action constants:
export const RECEIVE_ADD_CLUSTER = "RECEIVE_ADD_CLUSTER";
export const RECEIVE_CLUSTER_UPDATE = "RECEIVE_CLUSTER_UPDATE";
export const RECEIVE_CLUSTERS = "RECEIVE_CLUSTERS";
export const RECEIVE_DELETE_CLUSTER = "RECEIVE_DELETE_CLUSTER";
export const RECEIVE_RECENT_CLUSTER_CONFIGURATIONS = "RECEIVE_RECENT_CLUSTER_CONFIGURATIONS";
export const RECEIVE_RESTART_CLUSTER = "RECEIVE_RESTART_CLUSTER";
export const RECEIVE_SCALE_CLUSTER = "RECEIVE_SCALE_CLUSTER";
export const RECEIVE_START_CLUSTER = "RECEIVE_START_CLUSTER";
export const RECEIVE_STOP_CLUSTER = "RECEIVE_STOP_CLUSTER";
export const RECEIVE_UPDATE_CLUSTER = "RECEIVE_UPDATE_CLUSTER";
export const RECEIVE_UPGRADE_CLUSTER = "RECEIVE_UPGRADE_CLUSTER";
export const REQUEST_ADD_CLUSTER = "REQUEST_ADD_CLUSTER";
export const REQUEST_CLUSTERS = "REQUEST_CLUSTERS";
export const REQUEST_DELETE_CLUSTER = "REQUEST_DELETE_CLUSTER";
export const REQUEST_RECENT_CLUSTER_CONFIGURATIONS = "REQUEST_RECENT_CLUSTER_CONFIGURATIONS";
export const REQUEST_RESTART_CLUSTER = "REQUEST_RESTART_CLUSTER";
export const REQUEST_SCALE_CLUSTER = "REQUEST_SCALE_CLUSTER";
export const REQUEST_START_CLUSTER = "REQUEST_START_CLUSTER";
export const REQUEST_STOP_CLUSTER = "REQUEST_STOP_CLUSTER";
export const REQUEST_UPDATE_CLUSTER = "REQUEST_UPDATE_CLUSTER";
export const REQUEST_UPGRADE_CLUSTER = "REQUEST_UPGRADE_CLUSTER";
export const SUBSCRIBE_CLUSTER_UPDATES = "SUBSCRIBE_CLUSTER_UPDATES";
export const REQUEST_CLUSTER_LOGS = "REQUEST_CLUSTER_LOGS";
export const RECEIVE_CLUSTER_LOGS = "RECEIVE_CLUSTER_LOGS";

// Action types:
interface RequestAddClusterAction {
  type: typeof REQUEST_ADD_CLUSTER;
}

interface ReceiveClusterUpdateAction {
  clusters: ClusterMap;
  type: typeof RECEIVE_CLUSTER_UPDATE;
}

interface ReceiveAddClusterAction {
  clusters: ClusterMap;
  type: typeof RECEIVE_ADD_CLUSTER;
}

interface RequestClustersAction {
  type: typeof REQUEST_CLUSTERS;
}

interface ReceiveClustersAction {
  clusters: ClusterMap;
  pagination: ClusterPagination;
  searchQuery: string;
  type: typeof RECEIVE_CLUSTERS;
}

interface RequestDeleteClusterAction {
  type: typeof REQUEST_DELETE_CLUSTER;
}

interface ReceiveDeleteClusterAction {
  clusters: ClusterMap;
  type: typeof RECEIVE_DELETE_CLUSTER;
}

interface RequestStartClusterAction {
  type: typeof REQUEST_START_CLUSTER;
}

interface ReceiveStartClusterAction {
  clusters: ClusterMap;
  type: typeof RECEIVE_START_CLUSTER;
}

interface RequestStopClusterAction {
  type: typeof REQUEST_STOP_CLUSTER;
}

interface ReceiveStopClusterAction {
  clusters: ClusterMap;
  type: typeof RECEIVE_STOP_CLUSTER;
}

interface RequestRestartClusterAction {
  type: typeof REQUEST_RESTART_CLUSTER;
}

interface ReceiveRestartClusterAction {
  clusters: ClusterMap;
  type: typeof RECEIVE_RESTART_CLUSTER;
}

interface RequestScaleClusterAction {
  type: typeof REQUEST_SCALE_CLUSTER;
}

interface ReceiveUpdateClusterAction {
  clusters: ClusterMap;
  type: typeof RECEIVE_UPDATE_CLUSTER;
}

interface RequestUpdateClusterAction {
  type: typeof REQUEST_UPDATE_CLUSTER;
}

interface ReceiveUpgradeClusterAction {
  clusters: ClusterMap;
  type: typeof RECEIVE_UPGRADE_CLUSTER;
}

interface RequestUpgradeClusterAction {
  type: typeof REQUEST_UPGRADE_CLUSTER;
}

interface ReceiveScaleClusterAction {
  clusters: ClusterMap;
  instances: number;
  type: typeof RECEIVE_SCALE_CLUSTER;
}

interface RequestRecentClusterConfigurationsAction {
  type: typeof REQUEST_RECENT_CLUSTER_CONFIGURATIONS;
}

interface ReceiveRecentClusterConfigurationsAction {
  clusters: ClusterMap;
  type: typeof RECEIVE_RECENT_CLUSTER_CONFIGURATIONS;
}

interface SubscribeClusterUpdatesAction {
  type: typeof SUBSCRIBE_CLUSTER_UPDATES;
}

interface RequestClusterLogsAction {
  type: typeof REQUEST_CLUSTER_LOGS;
}

interface ReceiveClusterLogsAction {
  clusterId: string;
  clusterLogs: { [all: string]: ClusterLog[] };
  type: typeof RECEIVE_CLUSTER_LOGS;
}

export type ClustersActionTypes =
  | ReceiveAddClusterAction
  | ReceiveClustersAction
  | ReceiveClusterUpdateAction
  | ReceiveDeleteClusterAction
  | ReceiveRestartClusterAction
  | ReceiveScaleClusterAction
  | ReceiveStartClusterAction
  | ReceiveStopClusterAction
  | ReceiveUpdateClusterAction
  | RequestAddClusterAction
  | RequestClustersAction
  | RequestDeleteClusterAction
  | RequestRestartClusterAction
  | RequestScaleClusterAction
  | RequestStartClusterAction
  | RequestStopClusterAction
  | RequestUpdateClusterAction
  | SubscribeClusterUpdatesAction
  | RequestRecentClusterConfigurationsAction
  | ReceiveRecentClusterConfigurationsAction
  | RequestClusterLogsAction
  | ReceiveClusterLogsAction
  | ReceiveUpgradeClusterAction
  | RequestUpgradeClusterAction;
