import { SectionListObject } from "@sandtable/component-library";
import SectionListServerSide from "@sandtable/component-library/components/SectionList/SectionListServerSide";
import { PageableResource } from "@sandtable/datastore/pagination/types";
import { EnvironmentsV2Object, pluraliseString } from "@sandtable/utils";
import * as React from "react";

interface Props {
  environmentsObject: EnvironmentsV2Object;
  fetchEnvironmentsV2: (page?: number, perPage?: number, searchQuery?: string) => void;
  onEnvironmentsSelect: (name: string) => void;
  onNewEnvironmentClick: () => void;
}

const EnvironmentsV2List = (props: Props) => {
  let objects: SectionListObject[] = [];
  if (typeof props.environmentsObject !== "undefined") {
    objects = Object.keys(props.environmentsObject).map((name: string) => {
      const env = props.environmentsObject[name];
      const numberOfEnvs = env ? env.length : 0;
      const secondaryText = `${numberOfEnvs} ${pluraliseString("environment", numberOfEnvs)}`;
      return {
        data: { name },
        gaAction: "environments-v2-list-card",
        gaLabel: name,
        onCardClick: () => props.onEnvironmentsSelect(name),
        primaryText: name,
        secondaryText,
      } as SectionListObject; //tslint:disable-line
    });
}

  return (
    <>
      <SectionListServerSide
        headerButtonText="New environment"
        objects={objects}
        onHeaderButtonClick={props.onNewEnvironmentClick}
        fetchResources={props.fetchEnvironmentsV2}
        searchKeys={["name"]}
        showSearch={true}
        serverSidePaginationResource={PageableResource.ENVIRONMENTS_V2}
      />
    </>
  );
};

export default EnvironmentsV2List;
