import {
  addExperiment,
  AppState,
  ExperimentMap,
  fetchExperiments,
  subscribeToExperimentUpdates,
  User,
} from "@sandtable/datastore";
import Experiments from "components/Experiments/Experiments";
import * as React from "react";
import { connect } from "react-redux";

interface Props {
  addExperiment: (fileId: string, experimentName: string) => void;
  experiments: ExperimentMap;
  fetchExperiments: () => void;
  isFetching: boolean;
  subscribeToExperimentUpdates: (orgName: string) => void;
  subscribedToExperimentUpdates: boolean;
  user: User;
}

const ExperimentsContainer = (props: Props) => {
  const { fetchExperiments, subscribedToExperimentUpdates, subscribeToExperimentUpdates, user } = props;

  React.useEffect(() => {
    fetchExperiments();
    if (!subscribedToExperimentUpdates) {
      subscribeToExperimentUpdates(user.organisation.name);
    }
  }, []);

  return <Experiments {...props} />;
};

const mapStateToProps = (state: AppState) => {
  return {
    experiments: state.experiments.experiments,
    isFetching: state.experiments.isFetching,
    subscribedToExperimentUpdates: state.experiments.subscribedToExperimentUpdates,
    user: state.user.user,
  };
};

export default connect(mapStateToProps, {
  addExperiment,
  fetchExperiments,
  subscribeToExperimentUpdates,
})(ExperimentsContainer);
