// TODO: Abstract all search stuff into new component. See SectionList.
import { Header, PaginatedTreeView } from "@sandtable/component-library";
import { useFilter } from "@sandtable/utils";
import * as React from "react";
import { SectionTreeViewObject } from "./types";

const SEARCH_OPTIONS: Fuse.FuseOptions<SectionTreeViewObject> = {
  distance: 100,
  location: 0,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.4,
};

interface Props {
  headerButtonText?: string;
  objects: SectionTreeViewObject[];
  onHeaderButtonClick?: () => void;
  fetchResources: () => void;
  searchKeys?: string[];
  showSearch: boolean;
}

const SectionList = (props: Props) => {
  let keys;
  if (typeof props.searchKeys !== "undefined") {
    keys = props.searchKeys.map((k: string) => "data." + k);
  }
  const [setFilterString, filteredObjects]: [(s: string) => void, SectionTreeViewObject[]] = useFilter<
    SectionTreeViewObject
  >(props.objects, {
    ...SEARCH_OPTIONS,
    keys,
  });

  return (
    <>
      <Header
        buttonText={props.headerButtonText}
        handleButtonClick={props.onHeaderButtonClick}
        showSearch={props.showSearch}
        handleSearch={setFilterString}
        handleRefreshIconClick={props.fetchResources}
      />
      <PaginatedTreeView objects={filteredObjects} />
    </>
  );
};

export default SectionList;
