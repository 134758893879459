import { Select } from "@sandtable/component-library";
import { EnvironmentsV2Map, EnvironmentV2, NewCluster } from "@sandtable/datastore";
import { EnvironmentsV2Object } from "@sandtable/utils";
import EnvironmentV2YamlTab from "components/EnvironmentsV2/ViewEnvironmentV2Drawer/EnvironmentV2YamlTab";
import * as React from "react";

export interface Props {
  cluster: NewCluster;
  environmentsV2: EnvironmentsV2Map;
  completedEnvironmentsV2: EnvironmentsV2Object;
  onChange: (c: Partial<NewCluster>, e: boolean) => void;
}

const ClusterEnvironmentV2Form = (props: Props) => {
  const [environmentName, setEnvironmentName] = React.useState<string | undefined>(undefined);

  const handleEnvironmentNameChange = (e: React.ChangeEvent<any>) => {
    const name = e.target.value;
    const autoSelectedEnvironment = props.completedEnvironmentsV2[name][0];
    props.onChange({ environmentId: autoSelectedEnvironment.uid }, false);
    setEnvironmentName(name);
  };

  const handleEnvironmentIdChange = (e: React.ChangeEvent<any>) =>
    props.onChange({ environmentId: e.target.value }, false);

  return (
    <form style={{ marginBottom: "18px" }}>
      <Select
        insertNoneOption={false}
        label="Environment name"
        labelWidth={136}
        onChange={handleEnvironmentNameChange}
        options={Object.keys(props.completedEnvironmentsV2).map((s: string) => ({
          text: s,
        }))}
        name="environmentName"
        value={environmentName}
        style={{ marginBottom: "14px" }}
      />
      <Select
        insertNoneOption={false}
        label="Environment version"
        labelWidth={150}
        onChange={handleEnvironmentIdChange}
        options={(props.completedEnvironmentsV2[environmentName!] || []).map((e: EnvironmentV2) => ({
          text: new Date(e.createdDatetime).toUTCString(),
          value: e.uid,
        }))}
        name="environmentId"
        value={props.cluster.environmentId}
      />
      {props.environmentsV2[props.cluster.environmentId] && (
        <EnvironmentV2YamlTab environment={props.environmentsV2[props.cluster.environmentId]} />
      )}
    </form>
  );
};

export default ClusterEnvironmentV2Form;
