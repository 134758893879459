import { Button, DrawerTable, Select } from "@sandtable/component-library";
import { Experiment } from "@sandtable/datastore";
import * as React from "react";

const mapParametersArrayToString = (parameters: any) => {
  return parameters.map((p: any) => `(${p[0]}, ${p[1]})`).join(", ");
};

interface Props {
  experiments: Experiment[];
  fileId: string;
  onExperimentAdd: (fileId: string, experimentName: string) => void;
}

const ExperimentsTab = (props: Props) => {
  const [selectedExperimentIndex, setSelectedExperimentIndex] = React.useState(0);
  const experimentOptions = props.experiments.map((e: Experiment, i: number) => ({
    text: e.name,
    value: i.toString(),
  }));
  const experiment = props.experiments[selectedExperimentIndex];
  const data = [
    {
      name: "Netlogo file",
      value: experiment.metadata.fileName,
    },
    {
      name: "Experiment name",
      value: experiment.name,
    },
    {
      name: "Repetitions",
      value: experiment.repetitions.toString(),
    },
    {
      name: "Run metrics every step",
      value: experiment.runMetricsEveryStep.toString(),
    },
    {
      name: "Number of simulations",
      value: experiment.numberOfSimulations.toString(),
    },
    {
      name: "Go",
      value: experiment.go.join(", "),
    },
    {
      name: "Parameters",
      value: mapParametersArrayToString(experiment.parameters),
    },
    {
      name: "Metrics",
      value: experiment.metrics.join(", "),
    },
  ];
  return (
    <>
      <Select
        label="Experiment"
        labelWidth={84}
        onChange={(e: any) => setSelectedExperimentIndex(e.target.value)}
        options={experimentOptions}
        insertNoneOption={false}
        value={selectedExperimentIndex}
      />
      <DrawerTable
        style={{ marginBottom: "16px" }}
        tableData={data}
        tableKey={experiment.id + "new-experiment-table"}
      />
      <Button
        gaAction={"new-experiment-drawer-experiments-tab-run"}
        text="Run experiment"
        iconRight="check"
        fullWidth={true}
        onButtonClick={() => {
          props.onExperimentAdd(props.fileId, props.experiments[selectedExperimentIndex].name);
        }}
      />
    </>
  );
};

export default ExperimentsTab;
