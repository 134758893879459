import { API_GET, API_POST, ApiMiddlewareAction } from "@sandtable/datastore/middleware";
import {
  RECEIVE_ACCEPT_TERMS,
  RECEIVE_USER,
  REQUEST_ACCEPT_TERMS,
  REQUEST_USER,
  USER_SCHEMA,
} from "@sandtable/datastore/user";

export const fetchUser = () => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_GET]: {
        endpoint: "user",
        schema: USER_SCHEMA,
        types: [REQUEST_USER, RECEIVE_USER, RECEIVE_USER],
      },
    });
  };
};

export const acceptTermsConditions = () => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: "user/accept_terms",
        types: [REQUEST_ACCEPT_TERMS, RECEIVE_ACCEPT_TERMS, RECEIVE_ACCEPT_TERMS],
      },
    });
  };
};

// TODO: Use new types/actions:
export const enterReferralCode = (code: string) => {
  return (dispatch: (action: ApiMiddlewareAction) => void) => {
    dispatch({
      [API_POST]: {
        endpoint: "user/redeem_code",
        params: {
          code,
        },
        types: [REQUEST_USER, RECEIVE_USER, RECEIVE_USER],
      },
    });
  };
};
