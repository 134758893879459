import { CardList, CardListObject } from "@sandtable/component-library";
import { Execution, ExecutionMap } from "@sandtable/datastore";
import { handleExecutionDelete } from "components/Applications/ViewExecutionDrawer/ExecutionActionSection";
import * as React from "react";

interface Props {
  executions: ExecutionMap;
  deleteExecution: (execution: Execution) => void;
  onExecutionSelect: (e: Execution) => void;
}

const ExecutionsTab = (props: Props) => {
  const objects: CardListObject[] = Object.values(props.executions).map(
    (e: Execution) =>
      ({
        gaAction: "view-application-drawer-executions-tab-card",
        gaLabel: e.id,
        onCardClick: () => props.onExecutionSelect(e),
        primaryIcon: "tablet-alt",
        primaryText: `${e.id} • ${e.status} • ${new Date(e.created).toUTCString()}`,
        secondaryIcon: "trash",
        secondaryIconClick: () => handleExecutionDelete(() => props.deleteExecution(e)),
      } as CardListObject), //tslint:disable-line
  );

  return <CardList objects={objects} />;
};

export default ExecutionsTab;
