import { Environment, EnvironmentV2 } from "@sandtable/datastore";

export interface EnvironmentsV2Object {
  [key: string]: EnvironmentV2[];
}

export const mapEnvironmentsV2ToObject = (environments: EnvironmentV2[]): EnvironmentsV2Object => {
  const envs: EnvironmentsV2Object = {};
  environments
    .sort((a: EnvironmentV2, b: EnvironmentV2) => {
      return new Date(b.createdDatetime).getTime() - new Date(a.createdDatetime).getTime();
    })
    .forEach((e: EnvironmentV2) => {
      envs[e.name] = [...(envs[e.name] || []), e];
    });
  return envs;
};

export interface EnvironmentsObject {
  [key: string]: Environment[];
}

export const mapEnvironmentsToObject = (environments: Environment[]): EnvironmentsObject => {
  const envs: EnvironmentsObject = {};
  environments
    .sort((a: Environment, b: Environment) => {
      return new Date(b.created).getTime() - new Date(a.created).getTime();
    })
    .forEach((e: Environment) => {
      envs[e.name] = [...(envs[e.name] || []), e];
    });
  return envs;
};

export interface PythonVersionToEnvironmentsObject {
  [pythonVersion: string]: Environment[];
}

export const mapEnvironmentsToPythonVersionObject = (
  environments: Environment[],
): PythonVersionToEnvironmentsObject => {
  const envs: PythonVersionToEnvironmentsObject = {};
  environments.forEach((e: Environment) => {
    envs[e.pythonVersion] = [...(envs[e.pythonVersion] || []), e];
  });
  return envs;
};
