import { NavButton, useAnalytics } from "@sandtable/component-library";
import { getAuthToken, openNewTab } from "@sandtable/utils";
import { DOCS_URL } from "@sandtable/utils/constants";
import * as React from "react";

const DocsButton = () => {
  const analytics = useAnalytics();

  return (
    <NavButton
      icon="file-alt"
      tooltipText="Open docs"
      onClick={async (e: React.ChangeEvent<{}>) => {
        e.persist();
        analytics.trackTopNavClick("sm-docs");
        const token = await getAuthToken();
        openNewTab(e, `${DOCS_URL}/index.html?token=${token}`);
      }}
    />
  );
};

export default DocsButton;
