import { TablePagination as MTablePagination } from "@material-ui/core";
import * as React from "react";

const TABLE_STYLES: React.CSSProperties = { display: "flex", flexDirection: "row-reverse" };

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

interface Props {
  count: number;
  handleChange: (offset: number, limit: number) => void;
}

const Pagination = (props: Props) => {
  const [state, setState] = React.useState({
    page: 0,
    rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
  });

  const { handleChange } = props;
  React.useEffect(() => {
    const offset = state.page * state.rowsPerPage;
    handleChange(offset, offset + state.rowsPerPage);
  }, [state]);

  const handleChangePage = React.useCallback((e: any, page: number) => setState((prev) => ({ ...prev, page })), []);
  const handleChangeRowsPerPage = React.useCallback(
    (e: any) => setState((prev) => ({ ...prev, page: 0, rowsPerPage: e.target.value })),
    [],
  );

  return (
    <table style={TABLE_STYLES}>
      <tbody>
        <tr>
          <MTablePagination
            count={props.count}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            page={state.page}
            rowsPerPage={state.rowsPerPage}
          />
        </tr>
      </tbody>
    </table>
  );
};

export default Pagination;
