import {
  Membership,
  Organisation,
  OrganisationActionTypes,
  OrganisationsState,
  Project,
  RECEIVE_ADD_MEMBER_TO_ORG,
  RECEIVE_ADD_MEMBER_TO_PROJECT,
  RECEIVE_ADD_ORGANISATION,
  RECEIVE_ADD_PROJECT_TO_ORG,
  RECEIVE_ORGANISATIONS,
  RECEIVE_REMOVE_MEMBER_FROM_ORGANISATION,
  RECEIVE_REMOVE_MEMBER_FROM_PROJECT,
  RECEIVE_UPDATE_MEMBER_OF_ORGANISATION,
  RECEIVE_UPDATE_MEMBER_OF_PROJECT,
  REQUEST_ORGANISATIONS,
} from "@sandtable/datastore/organisations";

export const initialState: OrganisationsState = {
  isFetching: true,
  organisations: [],
};

export const organisationsReducer = (state = initialState, action: OrganisationActionTypes): OrganisationsState => {
  switch (action.type) {
    case REQUEST_ORGANISATIONS:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_ORGANISATIONS:
      return {
        ...state,
        isFetching: false,
        organisations: action.organisations,
      };
    case RECEIVE_ADD_ORGANISATION:
      return {
        ...state,
        isFetching: false,
        organisations: [...state.organisations, action.organisation],
      };
    case RECEIVE_ADD_MEMBER_TO_ORG:
    case RECEIVE_ADD_PROJECT_TO_ORG:
    case RECEIVE_REMOVE_MEMBER_FROM_PROJECT:
    case RECEIVE_ADD_MEMBER_TO_PROJECT:
    case RECEIVE_UPDATE_MEMBER_OF_PROJECT:
    case RECEIVE_REMOVE_MEMBER_FROM_ORGANISATION:
    case RECEIVE_UPDATE_MEMBER_OF_ORGANISATION:
      const orgIndex = state.organisations.findIndex((o: Organisation) => o.id === action.organisationId);
      if (orgIndex < 0) {
        return state;
      }
      const org = state.organisations[orgIndex];
      let orgMemberships = [...org.memberships];
      let projects = [...org.projects];

      switch (action.type) {
        case RECEIVE_ADD_PROJECT_TO_ORG:
          projects = projects.concat(action.project);
          break;
        case RECEIVE_ADD_MEMBER_TO_ORG:
          orgMemberships = orgMemberships.concat(action.membership);
          break;
        case RECEIVE_REMOVE_MEMBER_FROM_ORGANISATION:
          orgMemberships = orgMemberships.filter((m: Membership) => m.user_id !== action.memberId);
          break;
        case RECEIVE_UPDATE_MEMBER_OF_ORGANISATION: {
          const memberIndex = orgMemberships.findIndex((m: Membership) => m.user_id === action.memberId);
          if (memberIndex < 0) {
            return state;
          }
          orgMemberships[memberIndex].owner = action.owner;
          break;
        }
        case RECEIVE_REMOVE_MEMBER_FROM_PROJECT:
        case RECEIVE_ADD_MEMBER_TO_PROJECT:
        case RECEIVE_UPDATE_MEMBER_OF_PROJECT:
          const projectIndex = projects.findIndex((p: Project) => p.id === action.projectId);
          if (projectIndex < 0) {
            return state;
          }
          let projectMemberships = [...projects[projectIndex].memberships];

          switch (action.type) {
            case RECEIVE_ADD_MEMBER_TO_PROJECT:
              projectMemberships = projectMemberships.concat(action.membership);
              break;
            case RECEIVE_REMOVE_MEMBER_FROM_PROJECT:
              projectMemberships = projectMemberships.filter((m: Membership) => m.user_id !== action.memberId);
              break;
            case RECEIVE_UPDATE_MEMBER_OF_PROJECT:
              const memberIndex = projectMemberships.findIndex((m: Membership) => m.user_id === action.memberId);
              if (memberIndex < 0) {
                return state;
              }
              projectMemberships[memberIndex].owner = action.owner;
          }
          projects = [
            ...projects.slice(0, projectIndex),
            {
              ...projects[projectIndex],
              memberships: projectMemberships,
            },
            ...org.projects.slice(projectIndex + 1),
          ];
      }

      return {
        ...state,
        organisations: [
          ...state.organisations.slice(0, orgIndex),
          {
            ...org,
            memberships: orgMemberships,
            projects,
          },
          ...state.organisations.slice(orgIndex + 1),
        ],
      };
    default:
      return state;
  }
};
