import { DrawerTable, Switch } from "@sandtable/component-library";
import { Cluster } from "@sandtable/datastore";
import { capitaliseString } from "@sandtable/utils";
import * as React from "react";

interface Props {
  cluster: Cluster;
  updateCluster: (c: Cluster) => void;
}

const ClusterTab = (props: Props) => {
  const cluster = props.cluster;
  const data = [
    {
      name: "Status",
      value: capitaliseString(cluster.status || "N/A"),
    },
    {
      name: "Cluster ID",
      value: cluster.clusterId,
    },
    {
      name: "Hostname",
      value: cluster.masterDomainName || "N/A",
    },
    {
      name: "Created",
      value: new Date(cluster.created).toUTCString(),
    },
    {
      name: "Last available",
      value: cluster.lastAvailable ? new Date(cluster.lastAvailable).toUTCString() : "N/A",
    },
    {
      name: "Owner",
      value: cluster.owner,
    },
    {
      name: "Organisation",
      value: cluster.organisation,
    },
    {
      name: "Project",
      value: cluster.project,
    },
    {
      name: "Number of instances",
      value: cluster.instances.toString(),
    },
    {
      name: "Environment ID",
      value: cluster.environmentId,
    },
    {
      name: "Environment name",
      value: cluster.environmentName || "N/A",
    },
    ...(cluster.environmentVersion
      ? [
          {
            name: "Environment version",
            value: new Date(cluster.environmentVersion).toUTCString(),
          },
        ]
      : []),
    ...(cluster.runtimeVersion
      ? [
          {
            name: "Sandman revision",
            value: cluster.runtimeVersion,
          },
        ]
      : []),
    ...(cluster.pythonVersion
      ? [
          {
            name: "Python version",
            value: cluster.pythonVersion,
          },
        ]
      : []),
    {
      name: "Hyperthreading",
      value: cluster.hyperthreading,
    },
    {
      name: "Spot instance",
      value: cluster.spotInstances,
    },
    {
      name: "Publicly accessible",
      value: cluster.publiclyAccessible === undefined ? true : cluster.publiclyAccessible,
    },
    {
      name: "Idle shutdown",
      value: (
        <Switch
          disabled={cluster.status !== "available"}
          checked={cluster.useIdleShutdown}
          onChange={() => props.updateCluster({ ...cluster, useIdleShutdown: !cluster.useIdleShutdown })}
          value={cluster.useIdleShutdown}
        />
      ), //tslint:disable-line
    },
  ];
  return <DrawerTable tableData={data} tableKey={props.cluster.clusterId + "cluster-table"} />;
};

export default ClusterTab;
