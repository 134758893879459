import { CardList, CardListObject } from "@sandtable/component-library";
import { PlatformUser, PlatformUserResource } from "@sandtable/datastore";
import * as React from "react";

interface Props {
  user: PlatformUser;
}

const ResourcesTab = (props: Props) => {
  return (
    <CardList
      objects={props.user.resources.map(
        (r: PlatformUserResource, key: any) =>
          ({
            gaAction: "view-user-drawer-user-tab-resources-card",
            gaLabel: r.id,
            primaryIcon: "tablet-alt",
            primaryText: `${r.id} • ${r.name}`,
          } as CardListObject), //tslint:disable-line
      )}
    />
  );
};

export default ResourcesTab;
