import { ApplicationUser } from "@sandtable/datastore";
import { schema } from "normalizr";

const COLLECTION_NAME = "deployments";
const ID_KEY = "id";

const deploymentSchema = new schema.Entity(
  COLLECTION_NAME,
  {},
  {
    idAttribute: (d: Deployment) => d[ID_KEY],
  },
);

export const DEPLOYMENT_SCHEMA = deploymentSchema;

const EXECUTIONS_COLLECTION_NAME = "executions";
const EXECUTIONS_ID_KEY = "id";

const executionV2Schema = new schema.Entity(
  EXECUTIONS_COLLECTION_NAME,
  {},
  {
    idAttribute: (e: ExecutionV2) => e[EXECUTIONS_ID_KEY],
  },
);

export const EXECUTION_V2_SCHEMA = executionV2Schema;

interface GitConfig {
  branch: string;
  commit: string;
  repo: string;
}

export interface Deployment {
  created: string;
  creator: string;
  description: string;
  gitConfig: GitConfig;
  id: string;
  name: string;
  organisation: string;
  project: string;
  users?: ApplicationUser[];
}

export interface DeploymentMap {
  [key: string]: Deployment;
}

export interface ExecutionV2 {
  created: string;
  id: string;
}

export interface ExecutionV2Map {
  [key: string]: any;
}

export interface DeploymentsState {
  [COLLECTION_NAME]: DeploymentMap;
  [EXECUTIONS_COLLECTION_NAME]: ExecutionV2Map;
  isFetching: boolean;
}

export const REQUEST_DEPLOYMENTS = "REQUEST_DEPLOYMENTS";
export const RECEIVE_DEPLOYMENTS = "RECEIVE_DEPLOYMENTS";
export const REQUEST_EXECUTIONS = "REQUEST_EXECUTIONS";
export const RECEIVE_EXECUTIONS = "RECEIVE_EXECUTIONS";
export const RECEIVE_DELETE_DEPLOYMENT = "RECEIVE_DELETE_DEPLOYMENT";
export const REQUEST_DELETE_DEPLOYMENT = "REQUEST_DELETE_DEPLOYMENT";
export const RECEIVE_DELETE_EXECUTION_V2 = "RECEIVE_DELETE_EXECUTION_V2";
export const REQUEST_DELETE_EXECUTION_V2 = "REQUEST_DELETE_EXECUTION_V2";
export const REQUEST_DEPLOYMENT_USERS = "REQUEST_DEPLOYMENT_USERS";
export const RECEIVE_DEPLOYMENT_USERS = "RECEIVE_DEPLOYMENT_USERS";
export const REQUEST_ADD_DEPLOYMENT_USER = "REQUEST_ADD_DEPLOYMENT_USER";
export const RECEIVE_ADD_DEPLOYMENT_USER = "RECEIVE_ADD_DEPLOYMENT_USER";
export const REQUEST_REMOVE_DEPLOYMENT_USER = "REQUEST_REMOVE_DEPLOYMENT_USER";
export const RECEIVE_REMOVE_DEPLOYMENT_USER = "RECEIVE_REMOVE_DEPLOYMENT_USER";

interface RequestDeploymentsAction {
  type:
    | typeof REQUEST_DEPLOYMENTS
    | typeof REQUEST_EXECUTIONS
    | typeof REQUEST_DELETE_EXECUTION_V2
    | typeof REQUEST_DELETE_DEPLOYMENT
    | typeof REQUEST_DEPLOYMENT_USERS
    | typeof REQUEST_ADD_DEPLOYMENT_USER
    | typeof REQUEST_REMOVE_DEPLOYMENT_USER;
}

interface ReceiveDeploymentsAction {
  [COLLECTION_NAME]: DeploymentMap;
  type: typeof RECEIVE_DEPLOYMENTS;
}

interface ReceiveExecutionsAction {
  [EXECUTIONS_COLLECTION_NAME]: ExecutionV2Map;
  deploymentId: string;
  type: typeof RECEIVE_EXECUTIONS;
}

interface ReceiveDeleteDeploymentAction {
  deploymentId: string;
  type: typeof RECEIVE_DELETE_DEPLOYMENT;
}

interface ReceiveDeleteExecutionV2Action {
  deploymentId: string;
  executionId: string;
  type: typeof RECEIVE_DELETE_EXECUTION_V2;
}

interface ReceiveDeploymentUsers {
  deploymentId: string;
  applicationUsers: ApplicationUser[];
  type: typeof RECEIVE_DEPLOYMENT_USERS;
}

interface ReceiveAddDeploymentUser {
  deploymentId: string;
  applicationUsers: ApplicationUser[];
  type: typeof RECEIVE_ADD_DEPLOYMENT_USER;
}

interface ReceiveRemoveDeploymentUser {
  deploymentId: string;
  userEmail: string;
  type: typeof RECEIVE_REMOVE_DEPLOYMENT_USER;
}

export type DeploymentsActionTypes =
  | RequestDeploymentsAction
  | ReceiveDeploymentsAction
  | ReceiveExecutionsAction
  | ReceiveDeleteDeploymentAction
  | ReceiveDeleteExecutionV2Action
  | ReceiveDeploymentUsers
  | ReceiveAddDeploymentUser
  | ReceiveRemoveDeploymentUser;
